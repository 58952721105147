import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseFee, DataResponseInterest } from './interface';
const httpApi = initAxios();

interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: TokenConfig = {
  secured: true,
};

export const getInterestListApi = (params: string): Promise<DataResponseInterest> => httpApi.get(`/loans/v1/logs/interest?${params}`, { ...TokenConfig });
export const getFeeListApi = (params: string): Promise<DataResponseFee> => httpApi.get(`/loans/v1/logs/fee?${params}`, { ...TokenConfig });

export const ExportExcelFee = (params: string): Promise<any> => httpApi.get(`/logs/fee/export?${params}`, { ...TokenConfig });

export const ExportExcelInterest = (params: string): Promise<any> => httpApi.get(`/logs/interest/export?${params}`, { ...TokenConfig });
