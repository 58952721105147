import React from 'react';
import { columnsConfigDetail } from './ColumnConfigDetail';
import Table from 'antd/lib/table';
import { DataDetailCusomerType } from '../../interfaces';
import * as S from '../../CustomerManagement.styles';

type Props = {
  dataDetail?: DataDetailCusomerType;
};

export default function TableDetail(props: Props) {
  return (
    <S.StyleTableDetailTab>
      <Table scroll={{ x: 1200 }} dataSource={props.dataDetail && props.dataDetail?.loans ? props.dataDetail?.loans : []} columns={columnsConfigDetail} sticky={true} rowKey="id" pagination={false} />
      <div className="watch-all">
        <div className="text-all">Xem tất cả</div>
      </div>
    </S.StyleTableDetailTab>
  );
}
