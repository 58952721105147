import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { DataResponseApiPartnerListProduct, DataTableDetailProduct } from '../../interface';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { PartnerContext, PartnerProvider } from '../../Context';
import { ModalCreatePartner } from '../../Partner.style';
import { getListTableProductInDetailPartner } from '../../Partner.api';
import { Form, Input } from 'antd';
import * as S2 from '../../Partner.style';
import { AiOutlineSearch } from 'react-icons/ai';
import { updateFiltersPartnerDetailProduct } from '../../Action';
import { useParams } from 'react-router-dom';
import ModalDetailCampaign from '../../Component/ModalDetailCampaign';

export default function TableDetailProduct() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataPartner, setDataPartner] = useState<DataResponseApiPartnerListProduct>();
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  const { uuid } = useParams();

  function callListTableProductInDetailPartner() {
    if (uuid) {
      setcheckLoading(true);
      getListTableProductInDetailPartner(uuid, queryStringParams(statePartner?.filtersPartnerDetailProduct))
        .then((res) => {
          if (res?.error) {
            setDataPartner(undefined);
          } else {
            setcheckLoading(false);
            setDataPartner(res);
          }
        })
        .catch((err) => {
          setcheckLoading(false);

          console.log(err);
          setDataPartner(undefined);
        });
    }
  }

  const handleMoveDetail = (value: DataTableDetailProduct) => {
    location.href = `/partner/management/${uuid}/${value.productName}`;
  };

  useEffect(() => {
    callListTableProductInDetailPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePartner?.filtersPartnerDetailProduct]);

  const columnsConfig: ColumnsType<DataTableDetailProduct> = [
    {
      title: 'Trạng thái',
      key: 'status',
      render: (value: DataTableDetailProduct) => <div className="text-loancode">{value ? (value?.status == 'ACTIVE' ? 'Hoạt động' : '-' || value?.status == 'INACTIVE' ? 'Hoạt động' : '-') : ''}</div>,
    },
    {
      title: 'Sản phẩm',
      key: 'productName',
      dataIndex: 'productName',
      render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
    },
    {
      title: 'Chính sách mặc định',
      key: 'policyCodeDefault',
      dataIndex: 'policyCodeDefault',
      render: (value: string) => <a className="text-loancode">{value ? value : '-'}</a>,
    },
    {
      title: 'Mã chính sách đang chạy',
      key: 'policyCodeActive',
      dataIndex: 'policyCodeActive',
      render: (value: string) => <div className="text-loancode">{value ? value : '-'}</div>,
    },
    {
      width: '300px',
      title: 'Thời gian chính sách đang chạy',
      key: 'code',
      render: (value: DataTableDetailProduct) => <div className="text-loancode">{value?.beginDate && value?.endDate ? `${value?.beginDate} - ${value?.endDate}` : '-'}</div>,
    },

    {
      width: '134px',
      title: 'Hành động',
      fixed: 'right',
      render: (value: DataTableDetailProduct) => {
        return (
          <div className="text-watch" onClick={() => handleMoveDetail(value)}>
            Chi tiết
          </div>
        );
      },
    },
  ];
  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchPartner(updateFiltersPartnerDetailProduct({ ...statePartner?.filtersPartner, productName: e.target.value, pageIndex: 1 }));
    }
  }

  return (
    <GS.WrapperOverrideInput>
      <PartnerProvider>
        <div className="box-content-table">
          <S2.FormSearch>
            <div className="box-formsearch">
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Sản phẩm'} />
              </Form.Item>
            </div>
          </S2.FormSearch>
          <Table
            scroll={{ x: 1300 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataPartner && dataPartner?.data}
            columns={columnsConfig}
            loading={checkLoading}
            sticky={true}
            rowKey="code"
            pagination={{
              current: dataPartner?.pageIndex,
              pageSize: dataPartner?.pageSize,
              total: dataPartner?.total,
              onChange: (page: number, pageSize: number) => {
                {
                  dispatchPartner(updateFiltersPartnerDetailProduct({ ...statePartner?.filtersPartnerDetailProduct, pageIndex: page, pageSize: pageSize }));
                }
              },
            }}
          />
        </div>
        <ModalDetailCampaign></ModalDetailCampaign>
        <ModalCreatePartner></ModalCreatePartner>
      </PartnerProvider>
    </GS.WrapperOverrideInput>
  );
}
