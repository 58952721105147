import styled from 'styled-components';
import { Layout } from 'antd';
import { media } from '@app/styles/themes/constants';

export const LayoutMaster = styled(Layout)`
  height: 100vh;
`;

export const LayoutMain = styled(Layout)`
  background-color: #1a1f25;
  @media only screen and ${media.md} {
    margin-left: 80px;
  }

  @media only screen and ${media.xl} {
    margin-left: unset;
  }
`;

export const BreadcrumbBox = styled.div`
  padding: 16px 0px 24px 24px;
  /* padding-bottom: 20px; */
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  & h2.title {
    margin: 19px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
  }
  .style-title-text-dashboard {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #828282;
  }
  .style-pathname {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #f2f2f2;
  }
`;
export const LayoutBreadCrumbTitleTab = styled.div`
  padding: 16px 24px 0px;
  background: #1a1f25;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
`;
