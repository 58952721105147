import React, { useContext, useState } from 'react';
import { Form, Input, DatePicker, DatePickerProps, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../Refund.style';
const { RangePicker } = DatePicker;
import { AiOutlineExport, AiOutlineSearch } from 'react-icons/ai';
import { RangePickerProps } from 'antd/lib/date-picker';
import { RefundContext } from '../Context';
import { updateFiltersRefund } from '../Action';
import { StatusRefund } from '@app/interfaces/interfaces';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { notificationController } from '@app/controllers/notificationController';
import { ExportExcelRefund } from '../Refund.api';

export const FormSearchRefund = () => {
  const { stateRefund, dispatchRefund } = useContext(RefundContext);
  const [checkLoading, setcheckLoading] = useState(false);
  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchRefund(updateFiltersRefund({ ...stateRefund?.filtersRefund, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchRefund(updateFiltersRefund({ ...stateRefund?.filtersRefund, fromDate: '', toDate: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchRefund(updateFiltersRefund({ ...stateRefund?.filtersRefund, key: e.target.value, pageIndex: 1 }));
    }
  }

  function onChangeSelect(e: string | StatusRefund | any) {
    dispatchRefund(updateFiltersRefund({ ...stateRefund?.filtersRefund, status: e, pageIndex: 1 }));
  }

  function ExportExcelFunc() {
    setcheckLoading(true);
    ExportExcelRefund(
      queryStringParams({
        key: stateRefund?.filtersRefund?.key,
        fromDate: stateRefund?.filtersRefund?.fromDate,
        toDate: stateRefund?.filtersRefund?.toDate,
      }),
    )
      .then((res) => {
        if (!res?.error) {
          setcheckLoading(false);
          notificationController.success({ message: `Xuất file thành công , vui lòng check mail` });
        } else {
          setcheckLoading(false);
        }
      })
      .catch(() => {
        setcheckLoading(false);
        console.log('err');
      });
  }

  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <div className="d-flex">
            <Form.Item name={'keyword'}>
              <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID giao dịch, ID khoản vay'} />
            </Form.Item>

            <div className={'filter-item'} style={{ marginLeft: '10px' }}>
              <Form.Item name={'statuses'}>
                <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
              </Form.Item>
            </div>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <Select onChange={onChangeSelect} placeholder={'Trạng thái thu tiền'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất cả</Select.Option>
                  <Select.Option value={'COLLECTED_MONEY'}>Đã thu</Select.Option>
                  <Select.Option value={'NOT_YET_COLLECTED_MONEY'}>Chưa thu</Select.Option>
                  <Select.Option value={'NO_COLLECTED_MONEY'}>Không thu</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </div>
          <GS.StyleBtnExcel style={{ marginTop: '5px' }} loading={checkLoading} onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
            Xuất Excel
          </GS.StyleBtnExcel>
        </div>
      </S2.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
