import React, { useContext } from 'react';
import { Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../../ReconciliationDisbursement.style';
import { AiOutlineSearch } from 'react-icons/ai';
import { updateCheckDataOriginal, updateFiltersReconciliationDisbursementDetail } from '../../Action';
import { ReconciliationDisbursementContext } from '../../Context';
import BtnExportExportExcel from '@app/components/common/BtnExportExcel/BtnExportExportExcel';
import { getReconciliationDisbursementExportExcel } from '../../ReconciliationDisbursement.api';

export const FormSearch = () => {
  const { stateReconciliationDisbursement, dispatchReconciliationDisbursement } = useContext(ReconciliationDisbursementContext);

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchReconciliationDisbursement(
        updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, key: e.target.value, pageIndex: 1 }),
      );
    }
  }

  function onChangeSelectData(e: string) {
    if (e == 'partner') {
      dispatchReconciliationDisbursement(updateCheckDataOriginal(true));
      dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, typeData: e, pageIndex: 1 }));
    } else {
      dispatchReconciliationDisbursement(updateCheckDataOriginal(false));
      dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, typeData: '', pageIndex: 1 }));
    }
  }

  function onChangeSelectStatus(e: string) {
    dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, status: e, pageIndex: 1 }));
  }
  function ExportExcelFunc() {
    getReconciliationDisbursementExportExcel({
      day: stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail.day,
      key: stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail?.key,
      status: stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail?.status,
      typeData: stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail?.typeData,
    })
      .then((res: string) => {
        window.open(`${res}`);
      })
      .catch(() => {
        console.log('err');
      });
  }
  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <div className="box-startend">
            <Form.Item name={'keyword'}>
              <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID khoản vay, ID giải ngân'} />
            </Form.Item>

            <div className={'filter-item'} style={{ marginLeft: '10px' }}>
              <Form.Item name={'data'}>
                <Select onChange={onChangeSelectData} placeholder={'Dữ liệu đối soát BG/BF'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}> Dữ liệu đối soát BG/BF</Select.Option>

                  <Select.Option value={'partner'}>Dữ liệu gốc Báo Gấm</Select.Option>
                </Select>
              </Form.Item>
            </div>
            {stateReconciliationDisbursement?.checkDataOriginal ? (
              ''
            ) : (
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectStatus} placeholder={'Trạng thái đối soát'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>
                    <Select.Option value={'FAILED'}>Cần xử lý</Select.Option>
                    <Select.Option value={'SUCCESS'}>Đã đối soát</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            )}
          </div>
          <div className="box-flexend">
            <BtnExportExportExcel onClick={ExportExcelFunc}></BtnExportExportExcel>
          </div>
        </div>
      </S2.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
