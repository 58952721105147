import initAxios from '@app/api/API.v2';
import { Oauth2Data } from '@app/interfaces/interfaces';

const httpApi = initAxios();

interface ResponseOAuth2Token extends Oauth2Data {
  error?: {
    code: number;
    data: any;
    message: any;
    status: number;
  };
}

export const getAccessToken = (code: string): Promise<ResponseOAuth2Token> => httpApi.post(`/oauth2/token?code=${code}`);
