import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import {
  DataResponseApiProductList,
  DataResponseDetailProduct,
  DataResponsePolicySelectApi,
  paramsApiCreateProduct,
  paramsApiEditProduct,
  paramsUpdateStatusProductApiType,
  responseApiCreateProduct,
  responseApiEditProduct,
} from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

export const getProductApi = (): Promise<DataResponseApiProductList[] | any> => httpApi.get(`/loans/v1/products`, { ...TokenConfig });

export const getDetailProduct = (code: string): Promise<DataResponseDetailProduct> => httpApi.get(`/loans/v1/products/${code}`, { ...TokenConfig });

export const getPolicySelect = (code: string | undefined): Promise<DataResponsePolicySelectApi> => httpApi.get(`loans/v1/policy/${code}`, { ...TokenConfig });

export const changeStatusProductApi = (code: string, params: paramsUpdateStatusProductApiType): Promise<any> => httpApi.put(`/loans/v1/Products/${code}/status?`, params, { ...TokenConfig });

export const CreateProductApi = (params: paramsApiCreateProduct): Promise<responseApiCreateProduct> => httpApi.post(`/loans/v1/products`, params, { ...TokenConfig });

export const EditProductApi = (code: string, params: paramsApiEditProduct): Promise<responseApiEditProduct> => httpApi.put(`/loans/v1/products/${code}`, params, { ...TokenConfig });
