import { Button, Form, Input, message, Select, Upload, UploadProps } from 'antd';
// import React, { useContext } from 'react';
import * as S from '../../DebtList/Loan/DebtList.styles';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import * as S2 from '../Loan.styles';
import { createDebtApi } from '../../DebtList/Loan/DebtList.api';
import { useContext, useEffect, useState } from 'react';
import { detailLoanApi } from '../Loan.api';
import Avatar from '@app/assets/icons/avatar.svg';
import { RcFile, UploadFile } from 'antd/lib/upload';
import ImgCrop from 'antd-img-crop';

import { DataLoanDetailType } from '../interface';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import { useParams } from 'react-router-dom';
import { UploadImgApi } from '@app/api/UploadFIle.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
type Props = {
  isModalOpenModalCreate: boolean;
  handleOkModalCreate: (e: any) => void;
  handleCancelModalCreate: (e: any) => void;
  callApiDetailLoan?: () => void;
};
export default function ModalCreateDebtDetailLoan(props: Props) {
  const [form] = Form.useForm();
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const { loancode } = useParams();
  const [loading, setloading] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>();
  const [checkValidateSelect, setcheckValidateSelect] = useState<boolean>(false);
  const [checkLabelSelect, setLabelSelect] = useState('Chọn trạng thái thanh toán');
  const [dataDetail, setdataDetail] = useState<DataLoanDetailType | undefined | null>(undefined);
  const [check, setCheck] = useState<boolean>();

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      string: '${label} không hợp lệ!',
    },
  };

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    name: 'file',
    onPreview: onPreview,
    maxCount: 1,

    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'PAYMENT',
            partnerUuid: dataDetail && dataDetail?.partnerUuid,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };
  const onFinish = async (values: any) => {
    const formData = new FormData();
    formData.append('code', values?.code);

    formData.append('isBifiinReceived', values?.isBifiinReceived && values?.isBifiinReceived);

    formData.append('fee', values?.fee ? values?.fee : 0);

    formData.append('interest', values?.interest ? values?.interest : 0);

    formData.append('principal', values?.principal ? values?.principal : 0);
    if (saveStringImg) {
      formData.append('imageKey', saveStringImg);
    }
    if (values?.isBifiinReceived != 'Chọn trạng thái thanh toán') {
      setloading(true);
      await createDebtApi(formData)
        .then((res) => {
          if (res?.error) {
            setloading(false);
          } else {
            notificationController.success({ message: `Tạo thành công` });
            dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalDebt: false }));
            setCheck(false);
            props?.callApiDetailLoan && props?.callApiDetailLoan();

            setloading(false);

            form.setFieldsValue({
              isBifiinReceived: 'Chọn trạng thái thanh toán',
              fee: '',
              interest: '',
              principal: '',
              total: '',
            });
            setcheckValidateSelect(false);
          }
        })
        .catch((err) => {
          setloading(false);
          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
        });
    } else {
      setcheckValidateSelect(true);
    }
  };

  function handleCancelModalCreate() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalDebt: false }));
    form.setFieldsValue({
      isBifiinReceived: 'Chọn trạng thái thanh toán',
      fee: '',
      interest: '',
      principal: '',
      total: '',
    });
    setCheck(false);
    setcheckValidateSelect(false);

    setLabelSelect('Chọn trạng thái thanh toán');
  }

  const callApiDetail = async () => {
    if (loancode) {
      await detailLoanApi(loancode)
        .then((res) => {
          if (res.error) {
            setdataDetail(null);
            setCheck(true);
          } else {
            setdataDetail(res);
            setCheck(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setdataDetail(null);
          setCheck(true);
        });
    }
  };

  function handleChangeUserWannaPayment(e: any) {
    if (dataDetail) {
      const feeApi = dataDetail?.accruedFeeAmount - dataDetail?.feePaidAmount - dataDetail?.refundFeeAmount;
      const interestApi = dataDetail?.accruedInterestAmount - dataDetail?.interestPaidAmount - dataDetail?.refundInterestAmount;
      const principalApi = dataDetail?.remainingPrincipalAmount;

      if (e.target.value <= feeApi + interestApi) {
        form.setFieldsValue({
          fee: e.target.value,
          interest: e.target.value - feeApi > interestApi ? interestApi : e.target.value - feeApi,
          principal: 0,
        });
      }
      if (e.target.value <= feeApi) {
        form.setFieldsValue({
          fee: e.target.value,
          interest: 0,
          principal: 0,
        });
      } else {
        form.setFieldsValue({
          fee: feeApi,
          interest: e.target.value - feeApi > interestApi ? interestApi : e.target.value - feeApi,
          principal: e.target.value - feeApi - interestApi > principalApi ? principalApi : e.target.value - feeApi - interestApi > 0 ? e.target.value - feeApi - interestApi : 0,
        });
      }
    }
  }

  useEffect(() => {
    setLabelSelect('Chọn trạng thái thanh toán');
  }, [dataDetail]);

  useEffect(() => {
    callApiDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataDetail) {
      form.setFieldsValue({
        code: dataDetail?.code,
        isBifiinReceived: 'Chọn trạng thái thanh toán',
        total: '',
        interest: '',
        fee: '',
        principal: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail]);

  return (
    <S.ModalCreate title="Tạo phiếu thanh toán" open={stateLoanList.stateModal?.stateModalDebt} onOk={handleCancelModalCreate} onCancel={handleCancelModalCreate} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} validateMessages={validateMessages} name="basic" onFinish={onFinish} style={{ width: '100%' }}>
          <div className="box-body-form">
            <div className="form-create">
              <div className="box-form">
                <div className="label">ID khoản vay</div>
                <Form.Item name={'code'} rules={[{ required: true, type: 'string' }]}>
                  <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập mã khoản vay'} />
                </Form.Item>
                <div className="err-text">{check ? 'Khoản vay không tồn tại' : ''}</div>
              </div>
              <div className="box-form">
                <div className="label">Trạng thái</div>
                <div className={'filter-item'}>
                  <Form.Item name={'isBifiinReceived'} rules={[{ required: true, type: 'string' }]}>
                    <Select className="style-select-status" placeholder={checkLabelSelect} size={'small'} dropdownMatchSelectWidth={200}>
                      <Select.Option value={'true'}>Bifiin đã nhận</Select.Option>
                      <Select.Option value={'false'}>Đối tác đã nhận</Select.Option>
                    </Select>
                  </Form.Item>
                  <div className="err-text">{checkValidateSelect ? 'Hãy chọn tráng thái đối tác' : ''}</div>
                </div>
              </div>
              <div className="box-form">
                <div className="label">Tổng số tiền thu</div>

                <Form.Item
                  name={'total'}
                  rules={[
                    {},
                    ({}) => ({
                      validator(_, value) {
                        if (dataDetail?.amount) {
                          if (
                            value >
                            dataDetail?.accruedFeeAmount - dataDetail?.feePaidAmount + (dataDetail?.accruedInterestAmount - dataDetail?.interestPaidAmount) + dataDetail?.remainingPrincipalAmount
                          ) {
                            return Promise.reject(new Error('Tổng số tiền thu phải < hoặc bằng số phải trả'));
                          }
                          if (value == 0) {
                            return Promise.reject(new Error('số tiền phải > 0'));
                          }
                          if (value < 0) {
                            return Promise.reject(new Error('số tiền phải > 0'));
                          }
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input onChange={(e) => handleChangeUserWannaPayment(e)} type="number" size={'small'} className="form-input-create" placeholder={'Nhập tổng số tiền thu'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Số tiền phí thu</div>

                <Form.Item
                  name={'fee'}
                  label="Số tiền phí thu"
                  rules={[
                    { required: true },
                    ({}) => ({
                      validator(_, value) {
                        if (dataDetail?.amount) {
                          if (value > dataDetail?.accruedFeeAmount - dataDetail?.feePaidAmount) {
                            return Promise.reject(new Error('Tổng số tiền thu phải < số tiền phí còn lại'));
                          }
                          // if (value == 0) {
                          //   return Promise.reject(new Error('số tiền phải > 0'));
                          // }
                          if (value < 0) {
                            return Promise.reject(new Error('số tiền phải > 0'));
                          }
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input type="number" size={'small'} className="form-input-create" placeholder={'Nhập Số tiền phí thu'} />
                </Form.Item>
              </div>

              <div className="box-form">
                <div className="label">Số tiền lãi thu</div>

                <Form.Item
                  name={'interest'}
                  label="Số tiền lãi thu"
                  rules={[
                    { required: true },
                    ({}) => ({
                      validator(_, value) {
                        if (dataDetail?.amount) {
                          if (value > dataDetail?.accruedInterestAmount - dataDetail?.interestPaidAmount) {
                            return Promise.reject(new Error('Tổng số tiền lãi thu phải < số tiền lãi còn lại'));
                          }

                          if (value < 0) {
                            return Promise.reject(new Error('số tiền phải > 0'));
                          }
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input type="number" size={'small'} className="form-input-create" placeholder={'Nhập Số tiền lãi thu'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Số tiền gốc thu</div>

                <Form.Item
                  name={'principal'}
                  label="Số tiền gốc thu"
                  rules={[
                    { required: true },
                    ({}) => ({
                      validator(_, value) {
                        if (dataDetail?.amount) {
                          if (value > dataDetail?.remainingPrincipalAmount) {
                            return Promise.reject(new Error('Tổng số tiền gốc  thu phải < số tiền gốc còn lại'));
                          }

                          if (value < 0) {
                            return Promise.reject(new Error('số tiền phải > 0'));
                          }
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <Input type="number" size={'small'} className="form-input-create" placeholder={'Nhập số tiền gốc thu'} />
                </Form.Item>
              </div>
              <div className="label">Chứng từ</div>
              <div className="save-image">
                <ImgCrop rotate>
                  <Dragger {...propsUpdate}>
                    <p className="ant-upload-drag-icon">
                      <IconUploadFile />
                    </p>
                    <p className="ant-upload-text">
                      Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                    </p>
                  </Dragger>
                </ImgCrop>
              </div>
            </div>
            <div className="information">
              <div className="infor-title">Thông tin khoản vay </div>
              <div className="infor-content">
                <div className="box-branch">
                  <div className="label">Tên khách hàng</div>
                  <UserNameAvatarColumn avatar={Avatar} name={dataDetail && dataDetail != undefined ? dataDetail?.user?.fullName : ''}></UserNameAvatarColumn>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc đã giải ngân</div>
                  <div>{dataDetail ? (dataDetail?.disbursedAmount ? formatNumberWithCommas(dataDetail?.disbursedAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc hoàn (Sau GN)</div>
                  <div>{dataDetail ? (dataDetail?.refundPrincipalAmount ? formatNumberWithCommas(dataDetail?.refundPrincipalAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc đã thu</div>
                  <div>{dataDetail ? (dataDetail?.principalPaidAmount ? formatNumberWithCommas(dataDetail?.principalPaidAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi luỹ kế</div>
                  <div>{dataDetail ? (dataDetail?.accruedInterestAmount ? formatNumberWithCommas(dataDetail?.accruedInterestAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi hoàn(Sau GN)</div>
                  <div>{dataDetail ? (dataDetail?.refundInterestAmount ? formatNumberWithCommas(dataDetail?.refundInterestAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi đã thu</div>
                  <div>{dataDetail ? (dataDetail?.interestPaidAmount ? formatNumberWithCommas(dataDetail?.interestPaidAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Phí luỹ kế</div>
                  <div>{dataDetail ? (dataDetail?.accruedFeeAmount ? formatNumberWithCommas(dataDetail?.accruedFeeAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Phí hoàn(Sau GN)</div>
                  <div>{dataDetail ? (dataDetail?.refundFeeAmount ? formatNumberWithCommas(dataDetail?.refundFeeAmount) : 0) : null}</div>
                </div>
                <div className="box-branch" style={{ borderBottom: '1px solid #3C4450', paddingBottom: '15px' }}>
                  <div className="label">Phí đã thu </div>
                  <div>{dataDetail ? (dataDetail?.feePaidAmount ? formatNumberWithCommas(dataDetail?.feePaidAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc còn lại</div>
                  <div>{dataDetail ? (dataDetail?.remainingPrincipalAmount ? formatNumberWithCommas(dataDetail?.remainingPrincipalAmount) : 0) : null}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi còn lại</div>
                  <div>
                    {dataDetail
                      ? dataDetail?.accruedInterestAmount | dataDetail?.interestPaidAmount
                        ? formatNumberWithCommas(dataDetail?.accruedInterestAmount - dataDetail?.refundInterestAmount - dataDetail?.interestPaidAmount)
                        : 0
                      : null}
                  </div>
                </div>
                <div className="box-branch">
                  <div className="label">Phí còn lại</div>
                  <div>
                    {dataDetail
                      ? dataDetail?.accruedFeeAmount | dataDetail?.feePaidAmount
                        ? formatNumberWithCommas(dataDetail?.accruedFeeAmount - dataDetail?.feePaidAmount - dataDetail?.refundFeeAmount)
                        : 0
                      : null}
                  </div>
                </div>
                <div className="box-branch">
                  <div className="label">Tổng tiền phải trả</div>
                  <div>
                    {dataDetail
                      ? dataDetail?.accruedFeeAmount | dataDetail?.feePaidAmount | dataDetail?.accruedInterestAmount | dataDetail?.interestPaidAmount | dataDetail?.remainingPrincipalAmount
                        ? dataDetail?.accruedFeeAmount -
                          dataDetail?.feePaidAmount -
                          dataDetail?.refundFeeAmount +
                          (dataDetail?.accruedInterestAmount - dataDetail?.refundInterestAmount - dataDetail?.interestPaidAmount) +
                          dataDetail?.remainingPrincipalAmount
                        : 0
                      : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <S2.StyleFooterModalCreate>
            <Button key="back" onClick={handleCancelModalCreate}>
              Huỷ
            </Button>
            <Button className="btn-create-submit" loading={loading} key="submit" htmlType="submit" style={{ marginLeft: '10px' }} onClick={props.handleOkModalCreate}>
              Xác nhận thanh toán
            </Button>
          </S2.StyleFooterModalCreate>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreate>
  );
}
