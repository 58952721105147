import { useAppDispatch } from '@app/hooks/reduxHooks';
import React, { useContext, useEffect, useState } from 'react';
import { EkycManagementContext, EkycManagementProvider } from '../Context';
import { useParams } from 'react-router-dom';
import { DataDetailEkycType } from '../interface';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { approvalDetailEkycApi, getDetailEkyc } from '../EkycManagement.api';
import * as S from '../EkycManagement.styles';
import * as GS from '@app/styles/GlobalStyle';
import SectionInforGeneralEkyc from './SectionInforGeneralEkyc';
import InforPaper from './InforPaper';
import SectionImage from './SectionImage';
import { Button } from 'antd';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import ModalReject from './ModalReject';
import { updateStateModalEkycManagement } from '../Action';
import { notificationController } from '@app/controllers/notificationController';
import ModalEditInforPaper from './ModalEditInforPaper';

function DetailEkycManagementContent() {
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);
  const dispatch = useAppDispatch();
  const { code } = useParams();
  const [dataDetailEkyc, setdataDetailEkyc] = useState<DataDetailEkycType>();
  const [checkBtnLoadingApproval, setcheckBtnLoadingApproval] = useState(false);
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
          url: '/customers/loan',
        },
        {
          name: 'Danh sách Khoản vay ',
        },
        {
          name: `${code}`,
        },
      ]),
    );

    dispatch(setBreadcrumbTitle(`${code}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(`${code}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchEkycManagement, dispatch]);

  function callApiDetailEkyc() {
    if (code) {
      getDetailEkyc(code)
        .then((res) => {
          setdataDetailEkyc(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    callApiDetailEkyc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, dispatchEkycManagement]);

  function openModalReject() {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalReject: true }));
  }

  function openModalApproval() {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalApproval: true }));
  }

  function handleOkConfirm() {
    setcheckBtnLoadingApproval(true);
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalApproval: false }));
    if (dataDetailEkyc) {
      approvalDetailEkycApi({
        code: dataDetailEkyc.code,
      })
        .then((res) => {
          if (!res?.error) {
            setcheckBtnLoadingApproval(false);

            notificationController.success({ message: `Duyệt thành công` });
          }
        })
        .catch((err) => {
          setcheckBtnLoadingApproval(false);

          console.log('err', err);
        });
    }
  }

  function handleCancelConfirm() {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalApproval: false }));
  }

  return (
    <GS.WrapperOverrideInput>
      <S.ContainerDetailEkyc>
        <div className="content-body">
          {dataDetailEkyc && dataDetailEkyc?.status == 'WAIT_FOR_APPROVE' ? (
            <div className="section-reject-approval">
              <div className="box-btn">
                <Button onClick={openModalReject}>Từ chối</Button>
                <BtnCreateStyle onClick={openModalApproval} haveNotIcon={true} label="Duyệt"></BtnCreateStyle>
              </div>
            </div>
          ) : null}

          <SectionInforGeneralEkyc dataDetail={dataDetailEkyc && dataDetailEkyc}></SectionInforGeneralEkyc>
          <InforPaper dataDetail={dataDetailEkyc && dataDetailEkyc}></InforPaper>
          <SectionImage dataDetail={dataDetailEkyc}></SectionImage>
        </div>
        {dataDetailEkyc && <ModalReject callApiDetailEkyc={callApiDetailEkyc} dataDetailEkyc={dataDetailEkyc}></ModalReject>}
        {dataDetailEkyc && <ModalEditInforPaper callApiDetailEkyc={callApiDetailEkyc} dataDetailEkyc={dataDetailEkyc}></ModalEditInforPaper>}
        {dataDetailEkyc && (
          <GS.StyleModalConfirm
            title="Bạn có chắc muốn duyệt xác minh tài khoản?"
            footer={null}
            open={stateEkycManagement?.stateModal?.stateModalApproval}
            onOk={handleOkConfirm}
            onCancel={handleCancelConfirm}
          >
            <div>
              <div className="text-confirm">{'Bạn có chắc muốn duyệt xác minh tài khoản?'} </div>
              <div className="btn-change">
                <Button className="btn-cancel" onClick={handleCancelConfirm}>
                  Huỷ
                </Button>
                <Button className="btn-yes" loading={checkBtnLoadingApproval} onClick={handleOkConfirm}>
                  Xác nhận
                </Button>
              </div>
            </div>
          </GS.StyleModalConfirm>
        )}
      </S.ContainerDetailEkyc>
    </GS.WrapperOverrideInput>
  );
}

export default function DetailEkycManagement() {
  return (
    <EkycManagementProvider>
      <DetailEkycManagementContent></DetailEkycManagementContent>
    </EkycManagementProvider>
  );
}
