import React, { useEffect, useState } from 'react';
import { PartnerProvider } from '../Context';
import ModalEditPartner from '../Component/ModalEditPartner';
import * as S from '../Partner.style';
import * as GS from '@app/styles/GlobalStyle';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import SectionTableProduct from './TablePartnerProduct/SectionTableProduct';
import { DataTablePartner } from '../interface';
import { getDetailPartnerApi } from '../Partner.api';
import { useParams } from 'react-router-dom';
import SectionInforPartner from './SectionInforPartner';

type Props = {
  data?: any;
};

export default function DetailPartner({}: Props) {
  const [dataDetail, setdataDetail] = useState<DataTablePartner>();
  const dispatch = useAppDispatch();
  const { uuid } = useParams();

  function callApiDetailPartner() {
    if (uuid) {
      getDetailPartnerApi(uuid)
        .then((res) => {
          setdataDetail(res);
        })
        .catch((err) => {
          console.log(err);
          setdataDetail(undefined);
        });
    }
  }

  useEffect(() => {
    if (uuid) {
      callApiDetailPartner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý đối tác',
          url: '/partner/management',
        },

        {
          name: dataDetail && dataDetail.name,
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`${dataDetail && dataDetail.name}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataDetail]);
  return (
    <GS.WrapperOverrideInput>
      <PartnerProvider>
        <S.ContainerDetailPartner>
          <div className="content-body">
            <div className="section-btn-action">
              <div className="box-btn"></div>
            </div>
            <SectionInforPartner dataDetail={dataDetail}></SectionInforPartner>
            <SectionTableProduct></SectionTableProduct>
          </div>
        </S.ContainerDetailPartner>
        <ModalEditPartner callApiDetailPartner={callApiDetailPartner} dataDetail={dataDetail}></ModalEditPartner>
      </PartnerProvider>
    </GS.WrapperOverrideInput>
  );
}
