import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { PartnerContext, PartnerProvider } from './Context';
import TablePartner from './Component/TablePartner';

export default function PartnerManagement() {
  const { dispatchPartner } = useContext(PartnerContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý đối tác',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Quản lý đối tác`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchPartner, dispatch]);
  return (
    <PartnerProvider>
      <PageTitle>Quản lý đối tác</PageTitle>

      <div>
        <TablePartner></TablePartner>
      </div>
    </PartnerProvider>
  );
}
