import React, { useContext } from 'react';
import { DatePicker, DatePickerProps, Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S2 from '../EkycManagement.styles';
import { AiOutlineSearch } from 'react-icons/ai';
const { RangePicker } = DatePicker;

import BtnExportExportExcel from '@app/components/common/BtnExportExcel/BtnExportExportExcel';
import { RangePickerProps } from 'antd/lib/date-picker';
import { EkycManagementContext } from '../Context';
import { updateFiltersEkycManagement } from '../Action';

export const FormSearchEkycManagement = () => {
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);

  function ExportExcelFunc() {
    console.log('export CSV');
  }

  function onChangeTimeCreate(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, createAtFrom: dateString[0], createAtTo: dateString[1], pageIndex: 1 }));
    } else {
      dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, createAtFrom: '', createAtTo: '', pageIndex: 1 }));
    }
  }

  function onChangeTimeApproval(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, approveAtFrom: dateString[0], approveAtTo: dateString[1], pageIndex: 1 }));
    } else {
      dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, approveAtFrom: '', approveAtTo: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, key: e.target.value, pageIndex: 1 }));
    }
  }

  function onChangeSelectStatus(e: string) {
    dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, status: e, pageIndex: 1 }));
  }

  function onChangeSelectCardType(e: string) {
    dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, cardIdType: e, pageIndex: 1 }));
  }
  function onChangeSelectBillType(e: string) {
    dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, billingStatus: e, pageIndex: 1 }));
  }

  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S2.DflexStartEnd>
          <S2.Dflex>
            <Form.Item name={'keyword'}>
              <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Tìm kiếm Tên, sđt,...'} />
            </Form.Item>

            {/* <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select placeholder={'Nguồn giải ngân'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={'Gau'}>Gấu</Select.Option>
                    <Select.Option value={'Vin'}>Vin</Select.Option>
                  </Select>
                </Form.Item>
              </div> */}
            <div className={'filter-item'} style={{ marginLeft: '8px' }}>
              <Form.Item name={'statuses'}>
                <Select onChange={onChangeSelectStatus} className="style-select-partner" placeholder={'Trạng thái'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất cả</Select.Option>
                  <Select.Option value={'SUCCESS '}>Đã xác thực</Select.Option>
                  <Select.Option value={'WAIT_FOR_APPROVE '}>Chờ duyệt</Select.Option>
                  <Select.Option value={'FAILED '}>Thất bại</Select.Option>

                  <Select.Option value={'CANCELED'}>Từ chối</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className={'filter-item'} style={{ marginLeft: '8px' }}>
              <Form.Item name={'statuses'}>
                <Select onChange={onChangeSelectCardType} className="style-select-partner" placeholder={'Loại giấy tờ'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất cả</Select.Option>
                  <Select.Option value={'CMT'}>CMT</Select.Option>
                  <Select.Option value={'CCCD'}>CCCD</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className={'filter-item'} style={{ marginLeft: '8px', marginRight: '8px' }}>
              <Form.Item name={'statuses'}>
                <Select onChange={onChangeSelectBillType} className="style-select-partner" placeholder={'Loại'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất cả</Select.Option>
                  <Select.Option value={'CHARGED'}>Tính tiền</Select.Option>

                  <Select.Option value={'UNCHARGED'}>Không tính tiền</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <RangePicker onChange={onChangeTimeCreate} placeholder={['Ngày tạo', 'Ngày tạo']} format="DD-MM-YYYY" />
              </Form.Item>
            </div>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <RangePicker onChange={onChangeTimeApproval} placeholder={['Ngày duyệt', 'Ngày duyệt']} format="DD-MM-YYYY" />
              </Form.Item>
            </div>
          </S2.Dflex>

          <div style={{ display: 'flex' }}>
            <Form.Item>
              <BtnExportExportExcel onClick={ExportExcelFunc}></BtnExportExportExcel>
            </Form.Item>
          </div>
        </S2.DflexStartEnd>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
