import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReconciliationLoanListContext, ReconciliationLoanListProvider } from '../Context';
import Table from './SectionDetail/Table';

export default function DetailReconciliationLoan() {
  const { dispatchReconciliationLoanList } = useContext(ReconciliationLoanListContext);
  const dispatch = useAppDispatch();

  const { date } = useParams();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Đối soát',
          url: `/reconciliation/payment/${date}`,
        },
        {
          name: 'Khoản vay',
        },
        {
          name: `${date}`,
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Đối soát ngày ${date}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchReconciliationLoanList, dispatch]);
  return (
    <ReconciliationLoanListProvider>
      <PageTitle>Đối soát khoản vay</PageTitle>

      <div>
        <Table></Table>
      </div>
    </ReconciliationLoanListProvider>
  );
}
