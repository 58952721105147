import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { EkycManagementContext, EkycManagementProvider } from './Context';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import TableEkycManagement from './Component/TableEkycManagement';

export default function EkycManagement() {
  const { dispatchEkycManagement } = useContext(EkycManagementContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý Ekyc',
          url: '/customers/EkycManagement',
        },
        {
          name: 'Danh sách Ekyc',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Danh sách Ekyc`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchEkycManagement, dispatch]);
  return (
    <EkycManagementProvider>
      <PageTitle>Danh sách Ekyc </PageTitle>

      <div>
        {/* <S.H2>Danh sách giải ngân</S.H2> */}

        <TableEkycManagement></TableEkycManagement>
      </div>
    </EkycManagementProvider>
  );
}
