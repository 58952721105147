import axios from 'axios';
import _ from 'lodash';

export interface ErrorResponseType {
  message?: string;
  code?: string;
}

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
  validateStatus: function () {
    return true;
  },
});

httpApi.interceptors.request.use((config) => {
  if (_.get(config, 'secured', false)) {
    config.headers = { ...config.headers, Authorization: `Bearer ...` };
  }

  config.headers = {
    ...config.headers,
    'Content-Type': 'application/json',
  };

  return config;
});

httpApi.interceptors.response.use(
  (resp) => {
    if (_.includes([200, 201], resp.status) && typeof resp.data !== 'undefined') {
      return resp.data;
    } else {
      return {
        error: {
          data: resp.data ?? null,
          message: resp?.data?.message ?? null,
          status: resp.status,
          code: resp?.data?.code ?? -1,
        },
      };
    }
  },
  (error) => error,
);
