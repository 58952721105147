import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { DataTypeTableFee } from '../interface';
import Avatar from '@app/assets/icons/avatar.svg';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
export const columnsConfigFee: ColumnsType<DataTypeTableFee> = [
  {
    width: '310px',
    title: 'ID giao dịch',
    key: 'code',
    dataIndex: 'code',
    render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
  },
  {
    width: '220px',
    title: 'ID khoản vay',
    key: 'loanCode',
    dataIndex: 'loanCode',
    render: (value: string) => <a className="text-paymentcode">{value ? value : ''}</a>,
  },

  {
    width: '197px',
    title: 'Tên khách hàng',
    key: 'customerName',
    dataIndex: 'customerName',
    render: (value: string) => {
      return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
    },
  },
  {
    width: '203px',
    title: 'Thời gian thực hiện',
    key: 'createDate',
    dataIndex: 'createDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    align: 'right',
    width: '180px',
    title: 'Loại phí',
    render: (value: DataTypeTableFee) => (
      <div>
        {(value.type && value.type == 'LOAN_FEE' ? 'Phí' : '') ||
          (value.type && value.type == 'REFUND_FEE_AFTER_DISBURSEMENT' ? 'Hoàn phí (Sau GN )' : '') ||
          (value.type && value.type == 'OVERDUE_FEE' ? 'Phí quá hạn' : '') ||
          (value.type && value.type == 'REFUND_FEE_BEFORE_DISBURSEMENT' ? 'Hoàn phí(Trước GN)' : '-')}
      </div>
    ),
  },
  {
    align: 'right',

    title: 'Tỉ lệ phí',
    key: 'rate',
    dataIndex: 'rate',
    render: (value: string) => <div>{value ? value + '%' : 0}</div>,
  },
  {
    align: 'right',
    title: 'Tiền tính phí',
    key: 'principalAmount',
    dataIndex: 'principalAmount',
    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },

  {
    align: 'right',
    title: 'Tiền phí',
    key: 'amount',
    dataIndex: 'amount',
    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },

  {
    width: '160px',
    title: 'Người thực hiện',
    key: 'createdBy',
    dataIndex: 'createdBy',
    render: (value: string) => <div>{value ? value : ''}</div>,
  },
];
