import { CheckDataOriginalType, FilterReconciliationPaymentListType, filtersReconciliationPaymentDetail, ReducerType } from './interface';

export const UPDATE_FILTERS_RECONCILIATION_PAYMENT = 'UPDATE_FILTERS_RECONCILIATION_PAYMENT';
export const UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_PAYMENT = 'UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_PAYMENT';
export const UPDATE_FILTERS_RECONCILIATION_PAYMENT_DETAIL = 'UPDATE_FILTERS_RECONCILIATION_PAYMENT_DETAIL';
export const updateFiltersReconciliationPaymentList = (PaymentReconciliationPaymentList: FilterReconciliationPaymentListType): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_PAYMENT,
    filtersReconciliationPaymentList: PaymentReconciliationPaymentList,
  };
};
export const updateFiltersReconciliationPaymentDetail = (PaymentReconciliationPaymentDetail: filtersReconciliationPaymentDetail): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_PAYMENT_DETAIL,
    filtersReconciliationPaymentDetail: PaymentReconciliationPaymentDetail,
  };
};

export const updateCheckDataOriginal = (checkDataOriginal: CheckDataOriginalType): ReducerType => {
  return {
    type: UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_PAYMENT,
    checkDataOriginal: checkDataOriginal,
  };
};
