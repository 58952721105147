import { FilterDebtType, filtersWaitForApproveType, ModalsType, ReducerType } from './interface';

export const UPDATE_FILTERS_DEBTLIST = 'UPDATE_FILTERS_DEBTLIST';
export const UPDATE_FILTERS_DEBTLIST_WAIT_FOR_APPROVE = 'UPDATE_FILTERS_DEBTLIST_WAIT_FOR_APPROVE';
export const UPDATE_MODAL_DEBTLIST = 'UPDATE_MODAL_DEBTLIST';

export const updateFiltersDebtList = (filtersDebtList: FilterDebtType): ReducerType => {
  return {
    type: UPDATE_FILTERS_DEBTLIST,
    filtersDebtList: filtersDebtList,
  };
};

export const updateFiltersDebtListWaitForApprove = (filtersWaitForApprove: filtersWaitForApproveType): ReducerType => {
  return {
    type: UPDATE_FILTERS_DEBTLIST_WAIT_FOR_APPROVE,
    filtersWaitForApprove: filtersWaitForApprove,
  };
};

export const updateModals = (modals: ModalsType): ReducerType => {
  return {
    type: UPDATE_MODAL_DEBTLIST,
    modals: modals,
  };
};
