import { formatNumberWithCommas } from '@app/utils/utils';
import { Col, Form, Input, Row, TabsProps, Timeline } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { updateModals } from '../Action';
import { getCapitalDetailHistoryApi } from '../Capital.api';
import * as S from '../Capital.styles';
import { CapitalContext } from '../Context';
import { ResponseApiDetail, ResponseApiHistoryDetail } from '../interface';
type Props = {
  dataDetail: ResponseApiDetail | null;
  form: any;
};
export default function ContentMoneyTab(props: Props) {
  const [dataHistory, setdataHistory] = useState<ResponseApiHistoryDetail[]>();
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);
  // const [amountCashOut, setamountCashOut] = useState<number | string>();
  // const [amountCashIn, setamountCashIn] = useState<number | string>();

  const callApiHistoryDetail = async () => {
    if (props?.dataDetail) {
      getCapitalDetailHistoryApi(props?.dataDetail?.code)
        .then((res) => {
          setdataHistory(res);
        })
        .catch(() => {
          console.log('err');
          setdataHistory([]);
        });
    }
  };
  useEffect(() => {
    callApiHistoryDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataDetail]);

  function onChangeCashout(e: any) {
    dispatchCapital(updateModals({ ...stateCapital.modals, saveAmountStore: e.target.value }));
  }
  function onChangeCashin(e: any) {
    dispatchCapital(updateModals({ ...stateCapital.modals, saveAmountStore: e.target.value }));
  }
  const itemsTabInside: TabsProps['items'] = [
    {
      key: '1',
      label: `Nạp nguồn vốn`,
      children: (
        <div>
          <Row className="label-add-capital">Thêm tiền kí quỹ</Row>
          <Form.Item name={'amountCashIn'} rules={[{ type: 'string' }]}>
            <Input onChange={(e) => onChangeCashin(e)} type="number" size={'small'} className={'style-input-create'} placeholder={'Nhập số tiền'} />
          </Form.Item>
        </div>
      ),
    },
    {
      key: '2',
      label: `Rút nguồn vốn`,
      children: (
        <div>
          <Row className="label-add-capital">Rút tiền kí quỹ</Row>
          <Form.Item
            name={'amountCashOut'}
            rules={[
              {
                type: 'string',
                validator(_, value) {
                  if (props?.dataDetail) {
                    if (value > props?.dataDetail?.balance) {
                      return Promise.reject(new Error('Số tiền rút vượt quá hạn mức'));
                    }

                    if (value < 0) {
                      return Promise.reject(new Error('số tiền phải > 0'));
                    }
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input onChange={(e) => onChangeCashout(e)} type="number" size={'small'} className={'style-input-create'} placeholder={'Nhập số tiền'} />
          </Form.Item>
          <div className="text-description">Số dư hiện tại có thể rút {props?.dataDetail ? formatNumberWithCommas(props?.dataDetail?.balance) : 0}</div>
        </div>
      ),
    },
  ];

  const onChangeTab = (key: string) => {
    if (key == '1') {
      dispatchCapital(updateModals({ ...stateCapital.modals, isCashIn: true }));
      props?.form.setFieldsValue({
        amountCashOut: '',
      });
    }
    if (key == '2') {
      dispatchCapital(updateModals({ ...stateCapital.modals, isCashIn: false }));
      props?.form.setFieldsValue({
        amountCashIn: '',
      });
    }
    dispatchCapital(updateModals({ ...stateCapital.modals, tabsModals: key }));
  };

  return (
    <S.StyleTabMoney>
      <Col className="col1">
        <Row className="title">Nạp, rút nguồn vốn</Row>
        <Row className="text-2">Thêm số tiền kí quỹ và rút số tiền dư hiện tại trong quỹ</Row>
        <S.TabStyleSectionMoney onChange={onChangeTab} defaultActiveKey="1" items={itemsTabInside} />
      </Col>
      <Col className="col2">
        <Row className="label-col2">Lịch sử nạp rút nguồn vốn</Row>
        <div className="box-timeline">
          <S.CssTimeline>
            {dataHistory && dataHistory.length > 0 ? (
              dataHistory?.map((item: ResponseApiHistoryDetail, index: number) =>
                item && item?.status == 'CASH_OUT' ? (
                  <Timeline.Item key={index} color="red">
                    Rút {formatNumberWithCommas(item?.amount)}đ vào ký quỹ bởi {item?.createdBy} vào <p>{item?.createdAt}</p>
                  </Timeline.Item>
                ) : (
                  <Timeline.Item key={index}>
                    Nạp thêm {formatNumberWithCommas(item?.amount)}đ từ nguồn vốn bởi {item?.createdBy} vào <p>{item?.createdAt}</p>
                  </Timeline.Item>
                ),
              )
            ) : (
              <Timeline.Item color="#1a1f25">Không có lịch sử giao dịch nguồn vốn</Timeline.Item>
            )}
          </S.CssTimeline>
        </div>
      </Col>
    </S.StyleTabMoney>
  );
}
