import { FilterDisbursementType, FiltersTabWaitForDisbursementDetailDayType, FiltersTabWaitForDisbursementType, ReducerType, StateModal } from './interface';

export const UPDATE_FILTERS_DISBURSEMENT = 'UPDATE_FILTERS_DISBURSEMENT';
export const UPDATE_ARR_ROW_CHECKBOX = 'UPDATE_ARR_ROW_CHECKBOX';
export const UPDATE_DATA_ERR_DETAIL = 'UPDATE_DATA_ERR_DETAIL';

export const UPDATE_STATE_MODAL_DISBURSEMENT = 'UPDATE_STATE_MODAL_DISBURSEMENT';
export const UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT = 'UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT';
export const UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT_DAY = 'UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT_DAY';

export const updateFiltersDisbursement = (filtersDisbursement: FilterDisbursementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_DISBURSEMENT,
    filtersDisbursementList: filtersDisbursement,
  };
};

export const updateArrRowCheckBox = (arrRowCheckBoxSave: any): ReducerType => {
  return {
    type: UPDATE_ARR_ROW_CHECKBOX,
    arrRowCheckBoxSave: arrRowCheckBoxSave,
  };
};

export const updateDataErrDetail = (dataErrDetail: any): ReducerType => {
  return {
    type: UPDATE_DATA_ERR_DETAIL,
    dataErrDetail: dataErrDetail,
  };
};

export const updateFiltersWaitForDisbursement = (filtersTabWaitForDisbursement: FiltersTabWaitForDisbursementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT,
    filtersTabWaitForDisbursement: filtersTabWaitForDisbursement,
  };
};

export const updateFiltersWaitForDisbursementDetailDay = (filtersTabWaitForDisbursementDetail: FiltersTabWaitForDisbursementDetailDayType): ReducerType => {
  return {
    type: UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT_DAY,
    filtersTabWaitForDisbursementDetailDay: filtersTabWaitForDisbursementDetail,
  };
};

export const updateStateModalDisbursement = (stateModal: StateModal): ReducerType => {
  return {
    type: UPDATE_STATE_MODAL_DISBURSEMENT,
    stateModal: stateModal,
  };
};
