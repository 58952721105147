/* eslint-disable prettier/prettier */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Loan/DebtList.styles';

import { DebtListContext, DebtListProvider } from './Context';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import TableDebtList from './ComponentDebtList/TableDebtList';
import TableWaitForApproval from './ComponentDebtList/TableWaitForApproval';

export default function DebtList() {
  const { dispatchDebtList } = useContext(DebtListContext);
  const dispatch = useAppDispatch();
  const [ToggleState, setToggleState] = useState<number>(1);
  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  function TabMenu() {
    const getActiveClass = (index: number, className: string) => (ToggleState === index ? className : '');

    return (
      <S.TabsTable>
        <div className="box-content">
          <div
            onClick={() => {
              toggleTab(1);
            }}
            className={`item ${getActiveClass(1, 'active-tabs')}`}
          >
            Quản lý giao dịch
          </div>
          <div
            onClick={() => {
              toggleTab(2);
            }}
            className={`item ${getActiveClass(2, 'active-tabs')}`}
          >
            Cần xác nhận
          </div>
        </div>
      </S.TabsTable>
    );
  }

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý thanh toán',
          url: '/customers/debtList',
        },
        {
          name: 'Danh sách thanh toán',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Quản lý thanh toán `));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchDebtList, dispatch]);
  return (
    <DebtListProvider>
      <PageTitle>Quản lý thanh toán</PageTitle>

      <div>
        {/* <S.H2>Quản lý thanh toán </S.H2> */}
        <TabMenu></TabMenu>
        {ToggleState && ToggleState == 1 ? <TableDebtList></TableDebtList> : null}
        {ToggleState && ToggleState == 2 ? <TableWaitForApproval></TableWaitForApproval> : null}
      </div>
    </DebtListProvider>
  );
}
