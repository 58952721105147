import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { Modal, Steps, Tabs } from 'antd';

export const Menu = styled.div`
  display: flex;
`;
export const H2 = styled.div`
  padding-left: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const DflexStartEnd = styled.div`
  .ant-select-selector {
    min-width: 160px;
  }
  /* padding-top: 30px; */
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    margin-right: 8px;
  }
  .ant-picker.ant-picker-range {
    width: 316px;
    margin-right: 8px;
  }
  margin-top: 15px;
  .ant-form-item {
    margin: 0px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 10px;
`;
export const DflexStartEndFormSearchWaitForDisbursement = styled.div`
  .style-disabled {
    background-color: #9ea6ac;
    color: #d9d9d9 !important;
  }
  .box-btn {
    display: flex;
    .btn-create {
      margin-right: 10px;
    }
  }
  margin-bottom: 15px;
  .ant-select-selector {
    min-width: 247px;
  }
  /* padding-top: 30px; */
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    margin-right: 8px;
  }
  .ant-picker.ant-picker-range {
    width: 316px;
    margin-right: 8px;
  }
  margin-top: 5px;
  .ant-form-item {
    margin: 0px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
`;

export const DflexFlexEnd = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: End;
`;
export const Dflex = styled.div`
  display: flex;
  width: 49%;
`;
export const StyledButtonA = styled(Button)`
  color: red;
  background-color: yellow;
`;
export const StyledButtonB = styled(Button)`
  color: pink;
  background-color: green;
`;
export const TabsLoan = styled(Tabs)`
  color: pink;
  background-color: green;
`;
export const boxCircle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
export const circleStyle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const TimelineStyle = styled.div`
  padding: 24px;
  width: 100%;
  padding-bottom: 20px;
  .section-timeline {
    padding: 40px 60px 30px 0px;
  }
`;
export const StyleSteps = styled(Steps)`
  .description-timeline {
    min-width: 250px;
  }
  .ant-steps-item-title {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f0f0f0;
    padding-right: 20px;
    padding-left: 10px;
  }
  .ant-steps-item-description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c !important;
    padding-left: 10px;
  }
  .ant-steps-item-title::after {
    color: #40a9ff;
  }
  span.anticon.anticon-check.ant-steps-finish-icon {
    color: #40a9ff;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: #40a9ff !important;
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: #40a9ff;
  }
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description,
  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: #8c8c8c;
  }
`;

export const ModalDetailPolicy = styled(Modal)`
  .item-fil-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    background: #272d35;
    border: 1px solid #3c4450;
  }
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;

    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalAddImage = styled(Modal)`
  .item-fil-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    background: #272d35;
    border: 1px solid #3c4450;
  }
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .section-footer {
    border-top: 1px solid #272d35;
  }
  .content-body {
    padding-bottom: 20px;
    height: 600px;
    width: 100%;
    .box-content-img {
      height: 100%;
    }
    .section-title {
      .label {
        color: var(--primary-01, #f2c015);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      width: 96%;
      display: flex;
      justify-content: space-between;
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 100%;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;
export const ModalShowPdfPayment = styled(Modal)`
  .item-fil-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    background: #272d35;
    border: 1px solid #3c4450;
  }
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .section-footer{
    padding-top: 20px;
    border-top: 1px solid #272d35;
    display: flex;
    justify-content: end;

  }
  .content-body {
 
    /* height: 600px; */
    padding: 20px 30px 50px 30px;
    .box-content-img{
      padding-top:20px;
      .save-image{
        height: 600px;
      }
    }
    } 
    .section-title {
      .name-img {
        color: #f2c015;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 250px;
      }
      .label {
        color: var(--primary-01, #f2c015);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      width: 96%;
      display: flex;
      justify-content: space-between;
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 100%;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;
export const ModalShowPdf = styled(Modal)`
  .item-fil-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    background: #272d35;
    border: 1px solid #3c4450;
  }
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .section-footer{
    padding-top: 20px;
    border-top: 1px solid #272d35;
    display: flex;
    justify-content: end;

  }
  .content-body {
 
    /* height: 600px; */
    padding: 20px 30px 50px 30px;
    .box-content-img{
      padding-top:20px;
      .save-image{
        height: 600px;
      }
    }
    } 
    .section-title {
      .name-img {
        color: #f2c015;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 250px;
      }
      .label {
        color: var(--primary-01, #f2c015);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      width: 96%;
      display: flex;
      justify-content: space-between;
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 100%;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalDetailImage = styled(Modal)`
  .item-fil-detail {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 3px 5px;
    border-radius: 2px;
    background: #272d35;
    border: 1px solid #3c4450;
  }
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .section-footer{
    border-top: 1px solid #272d35;

  }
  .content-body {
    padding-top: 20px;

    padding-bottom: 20px;
    /* height: 600px; */
    padding: 20px 30px;
    .box-content-img{
      padding-top:20px;
      .save-image{
        height: 600px;
      }
    }
    } 
    .section-title {
      .name-img {
        color: #f2c015;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 250px;
      }
      .label {
        color: var(--primary-01, #f2c015);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
      }
      width: 96%;
      display: flex;
      justify-content: space-between;
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 100%;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const LayoutInfor = styled.div`
  .cursor {
    cursor: pointer;
  }
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  .ant-steps.ant-steps-horizontal.ant-steps-label-horizontal {
  }
  .value-yellow {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f2c015 !important;
  }
  .value-blue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff !important;
  }
  .value-green {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a !important;
  }

  .layout-left {
    /* width: 68.5%; */
    padding-right: 24px;
    .section-userinfor {
      margin-bottom: 24px;
      background-color: #1e232b;
      width: 100%;
      .title-userinfor {
        padding: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #ffffff;
        border-bottom: 1px solid #3c4450;
      }
      .content {
        padding: 24px;
        display: flex;
        justify-content: space-between;

        .avatar-user {
          width: 15%;
        }
        .column-content {
          width: 22%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            margin-bottom: 5px;
          }
          .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
            margin-bottom: 20px;
            min-height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
    .section-image-borrow-loan {
      margin-bottom: 24px;
      background-color: #1e232b;
      width: 100%;
      padding: 24px;
      border-bottom: 1px solid #3c4450;
      display: flex;
      justify-content: space-between;

      .label {
        color: #fff;
        font-size: 16px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
      .proof {
        display: flex;
        cursor: pointer;
        .name-evidence {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 250px;
        }
      }
    }
    .section-disbursement {
      .anticon svg {
        color: unset !important;
      }
      margin-bottom: 24px;
      background-color: #1e232b;
      width: 100%;
      .css-icon-swap {
        color: #8796a1;
        margin-top: 20px;
      }
      .title-disbursement {
        padding: 16px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: #ffffff;
        border-bottom: 1px solid #3c4450;
      }
      .content {
        .css-icon-caret {
          color: #85a5ff;
          margin-top: 20px;
        }
        .box-item-disbursement {
          max-height: 389px;
          overflow: auto;
        }
        .box-not-payment {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 50px;
          border-bottom: 1px solid #3c4450;
          color: #8796a1;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          .box-img {
            display: flex;
            justify-content: center;
            margin-bottom: 20px;
          }
        }
        .line-transaction {
          display: flex;
          border-bottom: 1px solid #3c4450;
          padding: 24px 24px 24px 24px;
          .css-icon-pdf {
            margin-right: 5px;
          }
          .css-icon {
            display: flex;
            align-items: center;
          }
          .box-item:hover {
            /* overflow: visible; */
          }
          .box-item {
            margin-left: 14px;
            margin-right: 14px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            .box-label {
              width: 100%;
              height: 50%;
              background-color: yellow;
              display: flex;
              margin-bottom: 10px;
              .label-item {
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #8c8c8c;
              }
              .css-icon-swap-right {
                color: #8796a1;
              }
            }
            .box-value {
              width: 100%;
              height: 50%;
              background-color: red;
            }
            .label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #8c8c8c;
              margin-bottom: 15px;
            }
            .value {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f5f5f5;
              min-height: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: flex;
            }

            .style-maxwidth-date {
              max-width: 100px;
            }
          }
          .break-word-date {
            white-space: break-spaces;
          }
          .amount-disbursement {
            margin-left: 30px;
          }
          .style-code {
            width: 128px;
          }
          .style-partner {
            width: 68px;
          }
          .style-fund {
            width: 60px;
          }
          .style-amount {
            width: 108px;
          }
          .style-pdf {
            width: 71px;
            margin-left: 18px;
            margin-right: 0px;
          }
          .css-date-disbursement {
            width: 89px;
            margin-left: 30px;
          }
          .css-amount {
            margin-left: 20px;
          }
        }
      }
    }
    .section-debt {
      .anticon svg {
        color: unset !important;
      }
      .box-item-debt {
        max-height: 389px;
        overflow: auto;
      }
      .box-not-payment {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px;
        border-bottom: 1px solid #3c4450;
        color: #8796a1;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        .box-img {
          display: flex;
          justify-content: center;
          margin-bottom: 20px;
        }
      }
    }
  }
  .layout-right {
    width: 30%;
    .section-infor-payment-general {
      background-color: #1e232b;

      .title-inforgeneral {
        padding: 16px 24px;
        border-bottom: 1px solid #3c4450;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .content {
        padding: 14px;
        .box-content-item-result {
          padding: 11px;
          border-radius: 4px;
        }
        .box-content-item {
          padding: 11px;
          border-radius: 4px;
          background-color: #222931;
        }
        .background-color-none {
          background-color: none !important;
        }
        .margin-top-8 {
          margin-top: 8px;
        }
        .border-botom-payment-section {
          border-bottom: 1px solid #8796a1;
        }
        .padding-bottom-12 {
          padding-bottom: 12px;
        }
        .padding-bottom {
          padding-bottom: 24px;
        }
        .border-botom {
          border-bottom: 1px solid #3c4450;
        }
        .margin-bottom {
          margin-bottom: 24px;
        }
        .margin-top {
          margin-top: 24px;
        }
        .box-line {
          display: flex;
          justify-content: space-between;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #bfbfbf;
          }
          .label-bold {
            font-weight: 700;
          }
          .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
          }
        }
      }
    }
    .section-inforgeneral {
      background-color: #1e232b;

      .title-inforgeneral {
        padding: 16px 24px;
        border-bottom: 1px solid #3c4450;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
      .content {
        padding: 24px;

        .box-content-loan {
        }
        .padding-bottom {
          padding-bottom: 24px;
        }
        .border-botom {
          border-bottom: 1px solid #3c4450;
        }
        .margin-bottom {
          margin-bottom: 24px;
        }
        .margin-top {
          margin-top: 24px;
        }
        .box-line {
          display: flex;
          justify-content: space-between;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #bfbfbf;
          }
          .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 60%;
            text-align: end;
          }
        }
      }
    }
  }
`;

export const ModalUpdateLoan = styled(Modal)`
  .ant-col.ant-form-item-label {
    display: none;
  }
  .ant-select-selector {
    border: 1px solid var(--character-stroke, #3c4450) !important;
  }
  span.ant-picker-suffix {
    display: none;
  }
  input {
    border-radius: 2px;
    border: 1px solid var(--character-stroke, #3c4450);
    background: var(--character-input, #272d35);
    padding: 4px 12px;
    font-size: 14px;
  }

  input#basic_loanValueRatio {
    border-radius: 2px;
    border: 1px solid var(--character-stroke, #3c4450);
    background: var(--character-input, #272d35);
    padding: 4px 6px;
    font-size: 14px;
  }

  &input#basic_overdueDate {
    background-color: none !important;
    border: none !important;
  }
  .box-content-create-loan {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #3c4450;
    .input-default-value {
      color: '#8C8C8C';
      padding-left:5px;
      border-radius: 2px;
      border: 1px solid var(--character-stroke, #3c4450);
      background: var(--character-input, #272d35);
      width: 100%;
      font-size: 14px;
      color: var(--neutral-5, #d9d9d9);
      font-family: Be Vietnam Pro;
      font-style: normal;
      font-weight: 700;
      line-height: 29px;
    }
    .content-left {
      padding: 24px 32px;
      height: 530px;
      overflow: auto;
      width: 50%;
      border-right: 1px solid #3c4450;
      .title {
        color: var(--neutral-5, #d9d9d9);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        margin-bottom: 10px;
      }
      .box-form {
        .input-default-value {
          border-radius: 2px;
          border: 1px solid var(--character-stroke, #3c4450);
          background: var(--character-input, #272d35);
          width: 100%;
        }
        .label {
          color: #bfbfbf;
          font-size: 14px;
          line-height: 22px;
          span {
            color: #f5222d;
          }
        }
      }
    }
    .content-right {
      padding: 24px 32px;
      width: 50%;
      .title {
        color: var(--neutral-5, #d9d9d9);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        font-style: normal;
        font-weight: 700;
        line-height: 29px;
        margin-left: 10px;
      }
      .section-checkbox {
        display: flex;
        margin-bottom: 3px;
      }

      }
      .label {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 22px;
        span {
          color: #f5222d;
        }
      }
      .section-total-money-borrow {
        margin-top: 20px;
        .item {
          margin-top: 15px;
          justify-content: space-between;
          display: flex;
          .label {
            color: var(--neutral-6, #bfbfbf);
            font-size: 14px;
            font-family: Be Vietnam Pro;
            line-height: 22px;
          }
          .value {
            color: var(--neutral-3, #f5f5f5);
            font-size: 14px;
            font-family: Be Vietnam Pro;
            font-weight: 700;
            line-height: 22px;
            text-align: right;
          }
        }
      }
    }
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 900px;
    background: #1a1f25;
    border-radius: 4px;
  }

  .ant-select-selector {
    background-color: #272d35 !important;
    padding: 0px 5px !important;
    font-size: 14px;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const ContainerLoan = styled.div`
  background-color: #12161c;
  padding: 24px 24px 24px 24px;
  .box-content {
    background-color: #1a1f25;
  }
  /* .ant-table-header.ant-table-sticky-holder {
    top: 70px !important;
  } */
`;
export const StyleFooterModalCreate = styled.div`
  display: flex;
  justify-content: end;
  padding: 24px;
  border-top: 1px solid #3c4450;
`;
export const styleTextAction = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #85a5ff;
  cursor: pointer;
`;
export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;
  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 180px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const columnDateReviewStyle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #f2c015;
  .box-date-review {
  }
  span {
    margin-right: 13px;
    margin-top: 2px;
  }
`;
export const BoxLoanList = styled.div`
  /* .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .cursor-pointer-none {
    cursor: unset;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
  .ant-picker.ant-picker-range {
    height: 32px !important;
  }
  .action-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .action-approval {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .text-false {
    color: #ff4d4f;
  }
`;

export const BoxDetailWaitForDisbursement = styled.div`
  /* .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .cursor-pointer-none {
    cursor: unset;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
  .ant-picker.ant-picker-range {
    height: 32px !important;
  }
  .action-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .action-approval {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .text-false {
    color: #ff4d4f;
  }
`;
