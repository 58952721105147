import React from 'react';
import { initCustomerManagement, useCustomerManagementReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const CustomerManagementContext = React.createContext({ ...initCustomerManagement });
export const CustomerManagementProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateCustomerManagement, dispatchCustomerManagement] = useCustomerManagementReducer();

  const wrap = {
    stateCustomerManagement,
    dispatchCustomerManagement,
  };

  return <CustomerManagementContext.Provider value={wrap}>{children}</CustomerManagementContext.Provider>;
};
