import React from 'react';
import { initPartner, usePartnerReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const PartnerContext = React.createContext({ ...initPartner });
export const PartnerProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [statePartner, dispatchPartner] = usePartnerReducer();

  const wrap = {
    statePartner,
    dispatchPartner,
  };

  return <PartnerContext.Provider value={wrap}>{children}</PartnerContext.Provider>;
};
