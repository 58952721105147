/* eslint-disable prettier/prettier */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useContext, useEffect } from 'react';
// import * as GS from '@app/styles/GlobalStyle';

import { LoanListContext, LoanListProvider } from './Context';
import TableLoan from './ComponentLoan/TableLoan';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
// import { updateFiltersLoanList } from './Action';
// import TableLoanList from './ComponentLoan/TableWaitForDisbursementLoan';

// type labelLoanTabType = 'Tất cả' | 'Chờ giải ngân';

export default function Loan() {
  const { dispatchLoanList } = useContext(LoanListContext);
  // const [ToggleState, setToggleState] = useState(1);
  // const [LabelTab, setLabelTab] = useState<labelLoanTabType>('Tất cả');

  // const toggleTab = (index: number) => {
  //   if (index == 1) {
  //     setLabelTab('Tất cả');
  //   }
  //   if (index == 2) {
  //     setLabelTab('Chờ giải ngân');
  //   }
  //   setToggleState(index);
  // };

  // function TabMenu() {
  //   const getActiveClass = (index: number, className: string) => (ToggleState === index ? className : '');
  //   return (
  //     <GS.TabsTable>
  //       <div className="box-content" style={{ width: '180px' }}>
  //         <div
  //           onClick={() => {
  //             toggleTab(1), dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, status: '' }));
  //           }}
  //           className={`item ${getActiveClass(1, 'active-tabs')}`}
  //         >
  //           Tất cả
  //         </div>
  //         <div
  //           onClick={() => {
  //             toggleTab(2), dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, status: 'WAIT_FOR_DISBURSEMENT' }));
  //           }}
  //           className={`item ${getActiveClass(2, 'active-tabs')}`}
  //         >
  //           Chờ giải ngân
  //         </div>
  //       </div>
  //     </GS.TabsTable>
  //   );
  // }
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
          url: '/customers/loan',
        },
        {
          name: 'Danh sách khoản vay',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Danh sách khoản vay`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchLoanList, dispatch]);
  return (
    <LoanListProvider>
      <PageTitle>Danh sách khoản vay</PageTitle>

      <div>
        {/* <TabMenu></TabMenu> */}
        {/* {LabelTab == 'Tất cả' ? <TableLoan></TableLoan> : null} */}
        <TableLoan></TableLoan>
        {/* {LabelTab == 'Chờ giải ngân' ? <TableLoanList></TableLoanList> : null} */}
      </div>
    </LoanListProvider>
  );
}
