import React from 'react';
import * as GS from '@app/styles/GlobalStyle';
import { Button } from 'antd';

type Props = {
  isModalOpen: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  labelTitle: string;
  loading?: boolean;
};

export default function ModalConfirm(props: Props) {
  return (
    <GS.StyleModalConfirm title="Basic Modal" footer={null} open={props?.isModalOpen} onOk={props?.handleOk} onCancel={props?.handleCancel}>
      <div>
        <div className="text-confirm">{props?.labelTitle}</div>
        <div className="btn-change">
          <Button className="btn-cancel" onClick={props?.handleCancel}>
            Huỷ
          </Button>
          <Button className="btn-yes" loading={props?.loading} onClick={props?.handleOk}>
            Chắc chắn
          </Button>
        </div>
      </div>
    </GS.StyleModalConfirm>
  );
}
