import { formatNumberWithCommas } from '@app/utils/utils';
import React from 'react';

type Props = {
  data?: any;
};

export default function SectionTotalMoneyBorrow(props: Props) {
  return (
    <div className="section-total-money-borrow">
      <div className="item">
        <div className="label">Tổng tiền vay</div>
        <div className="value">{props?.data?.loanAmount ? formatNumberWithCommas(props?.data?.loanAmount) : 0}</div>
      </div>
      <div className="item">
        <div className="label">Tỷ lệ phí</div>
        <div className="value">{props?.data?.feeRate ? props?.data?.feeRate : 0}%</div>
      </div>
      <div className="item">
        <div className="label">Phí tạm tính</div>
        <div className="value">{props?.data?.feeAmount ? formatNumberWithCommas(props?.data?.feeAmount) : 0}</div>
      </div>
      <div className="item">
        <div className="label">Tỷ lệ lãi</div>
        <div className="value">{props?.data?.interestRate ? props?.data?.interestRate : 0}%</div>
      </div>
      <div className="item">
        <div className="label">Lãi tạm tính</div>
        <div className="value">{props?.data?.interestAmount ? formatNumberWithCommas(props?.data?.interestAmount) : 0}</div>
      </div>
      <div className="item">
        <div className="label">Phí khác</div>
        <div className="value">{props?.data?.otherFee ? formatNumberWithCommas(props?.data?.otherFee) : 0}</div>
      </div>
      <div className="item">
        <div className="label">TỔNG THANH TOÁN (TẠM TÍNH)</div>
        <div className="value">{props?.data?.totalAmountPaid ? formatNumberWithCommas(props?.data?.totalAmountPaid) : 0}</div>
      </div>
    </div>
  );
}
