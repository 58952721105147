import React, { useEffect, useState } from 'react';
import * as S from '../Loan.styles';
import { Steps } from 'antd';
import { DataLoanDetailType } from '../interface';

const { Step } = Steps;
type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
};
export default function TimelineSection(props: Props) {
  const [currentStep, setcurrentStep] = useState<number>();

  useEffect(() => {
    if (props?.dataDetailLoan?.approvalDate) {
      setcurrentStep(1);
      if (props?.dataDetailLoan?.disbursementDate) {
        setcurrentStep(2);
        if (props?.dataDetailLoan?.maturityDate) {
          setcurrentStep(3);
        }
        if (props?.dataDetailLoan?.completeDate) {
          setcurrentStep(5);
        }
      }
    } else setcurrentStep(0);
  }, [props?.dataDetailLoan]);

  return (
    <div className="section-timeline">
      <S.StyleSteps current={currentStep} size="small" status="wait" iconPrefix="wait">
        <Step title="Chờ giải ngân" description={<div className="description-timeline">{props?.dataDetailLoan?.approvalDate && (props?.dataDetailLoan?.approvalDate).toLocaleString()}</div>} />
        <Step title="Đã giải ngân" description={<div className="description-timeline">{props?.dataDetailLoan?.disbursementDate && (props?.dataDetailLoan?.disbursementDate).toLocaleString()}</div>} />
        <Step title="Đến hạn" description={<div className="description-timeline">{props?.dataDetailLoan?.maturityDate && (props?.dataDetailLoan?.maturityDate).toLocaleString()}</div>} />
        <Step title="Quá hạn" />

        <Step title="Đã tất toán" description={<div className="description-timeline">{props?.dataDetailLoan?.completeDate && (props?.dataDetailLoan?.completeDate).toLocaleString()}</div>} />
      </S.StyleSteps>
    </div>
  );
}
