import initAxios from '@app/api/API.v2';
import { ResponseDataUploadFile, TypeUploadFilePackage } from '@app/interfaces/interfaces';
import { AxiosRequestConfig } from 'axios';
const httpApi = initAxios();

interface ConfigFormData extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}

interface Config extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfigFormData: ConfigFormData = {
  ContentType: 'ContentType',
  secured: true,
};

const TokenConfig: Config = {
  secured: true,
};

interface paramsUploadFileType {
  packageType?: string | TypeUploadFilePackage;
  partnerUuid?: string | undefined | null;
}

export const UploadImgApi = (formData: FormData, params?: paramsUploadFileType | undefined | string): Promise<ResponseDataUploadFile> =>
  httpApi.post(`/loans/v1/files/upload?${params}`, formData, { ...TokenConfigFormData });

export const getImg = (params: string): Promise<any> => httpApi.get(`/loans/v1/files?key=${params}`, { ...TokenConfig });
