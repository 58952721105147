import { Modal } from 'antd';
import styled from 'styled-components';
export const BoxRefund = styled.div`
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #597ef7;
    cursor: pointer;
  }
`;
export const FormSearch = styled.div`
  .margin-top-10 {
    margin-top: 10px;
  }
  .ant-select {
    min-width: 197px !important;
  }
  margin-left: 3px;
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 340px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-select-selector {
    width: 247px;
  }
  .ant-picker {
    height: 32px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: space-between;
  }
`;

export const ModalCreateProduct = styled(Modal)`
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;
    padding-bottom: 65px;
    .box-col {
      display: flex;
      justify-content: space-between;
      .item-form {
        .item-line-product {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #bfbfbf;
          }
          .value {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #bfbfbf;
          }
        }
        width: 47.5%;
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalEditProduct = styled(Modal)`
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;
    padding-bottom: 65px;
    .box-col {
      display: flex;
      justify-content: space-between;
      .item-form {
        .item-line-product {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #bfbfbf;
          }
          .value {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            color: #bfbfbf;
          }
        }
        width: 47.5%;
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;
