import { FONT_SIZE, FONT_WEIGHT, media } from '@app/styles/themes/constants';
import styled from 'styled-components';

export const LoginDescription = styled.div`
  margin-bottom: 1.875rem;
  color: #828282;
  font-weight: 400;
  font-size: 14px;

  @media only screen and ${media.xs} {
    margin-bottom: 1.5625rem;
    font-weight: ${FONT_WEIGHT.bold};
  }

  @media only screen and ${media.md} {
    margin-bottom: 1.75rem;
    font-weight: ${FONT_WEIGHT.regular};
  }

  @media only screen and ${media.xl} {
    margin-bottom: 1.875rem;
  }
`;

export const RememberMeText = styled.span`
  color: var(--primary-color);
  font-size: ${FONT_SIZE.xs};
`;

export const ForgotPasswordText = styled.span`
  color: var(--text-light-color);
  font-size: ${FONT_SIZE.xs};
  text-decoration: underline;
`;

export const HeadTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  text-align: center;
`;

export const LoginHeadLine = styled.span`
  color: var(--primary-color);
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-size: 14px;
  border-bottom: 1px solid #494848;
  display: flex;
  margin-bottom: 18px;
  padding-bottom: 12px;
`;
