import React from 'react';
import { DataDetailCusomerType } from '../../interfaces';
import { Tooltip } from 'antd';

type Props = {
  dataDetail: DataDetailCusomerType | undefined;
};

export default function SectionInforPayment(props: Props) {
  return (
    <div className="section-user-infor-payment">
      <div className="title">
        <div className="box-title">Thông tin thanh toán</div>
      </div>
      <div className="content-user">
        <div className="item-column">
          <div className="label">Ngân hàng thụ hưởng</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail?.user?.beneficiaryBank ? props?.dataDetail.user?.beneficiaryBank : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail?.user?.beneficiaryBank ? props?.dataDetail.user?.beneficiaryBank : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Chủ tài khoản</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail?.user?.bankUserName ? props?.dataDetail?.user?.bankUserName : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail?.user?.bankUserName ? props?.dataDetail?.user?.bankUserName : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Số tài khoản</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail?.user?.bankAccount ? props?.dataDetail?.user?.bankAccount : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail?.user?.bankAccount ? props?.dataDetail?.user?.bankAccount : '-'}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
