import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Loan.styles';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import { DatePicker, Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { CheckBox } from '@app/components/header/components/searchDropdown/searchOverlay/SearchFilter/SearchFilter.styles';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { formatNumberWithCommas } from '@app/utils/utils';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { DataLoanDetailType } from '../interface';
import { updateLoanApi } from '../Loan.api';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';

type Props = {
  dataDetailLoan?: DataLoanDetailType | undefined;
  callApiDetailLoan: () => void;
};

export default function ModalUpdateLoan(props: Props) {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [form] = Form.useForm();
  const [checkbox, setcheckbox] = useState<boolean>(false);
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      string: '${label} không hợp lệ!',
    },
  };

  function onFinish(values: any) {
    console.log('finish', values);
    setcheckBtnLoading(true);

    if (values && props?.dataDetailLoan) {
      updateLoanApi(props?.dataDetailLoan?.code, checkbox, {
        status: values?.status,
        referenceId: values?.referenceId,
        maturityDate: values?.maturityDate ? moment(values?.maturityDate).format('DD-MM-YYYY') : null,
        newLoanAmount: values?.newLoanAmount,
        reason: values?.reason,
      })
        .then((res) => {
          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalUpdateLoan: false }));

          setcheckBtnLoading(false);
          form.resetFields();
          form.setFieldsValue({
            status: undefined,
            referenceId: '',
            maturityDate: undefined,
            newLoanAmount: '',
            reason: '',
          });
          if (res?.error) {
          } else {
            notificationController.success({ message: `Cập nhập thành công` });
            props?.callApiDetailLoan();
          }
        })
        .catch(() => {
          setcheckBtnLoading(false);
          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalUpdateLoan: false }));

          form.setFieldsValue({
            status: undefined,
            referenceId: '',
            maturityDate: undefined,
            newLoanAmount: '',
            reason: '',
          });
          console.log('err');
        });
    }
  }

  function onCancel() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalUpdateLoan: false }));

    form.resetFields();
    form.setFieldsValue({
      status: undefined,
      referenceId: '',
      maturityDate: undefined,
      newLoanAmount: '',
      reason: '',
    });
  }

  function onChangeSelectStatus(e: any) {
    console.log('e', e);
  }

  const onChangeCheckbox = (e: CheckboxChangeEvent) => {
    setcheckbox(e.target.checked);
  };

  useEffect(() => {
    if (props?.dataDetailLoan) {
      form.setFieldsValue({
        status: props?.dataDetailLoan?.status,
        referenceId: props?.dataDetailLoan?.referenceId,
        maturityDate: props?.dataDetailLoan?.maturityDate ? moment(props?.dataDetailLoan?.maturityDate, 'DD-MM-YYYY') : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLoanList?.stateModal?.stateModalUpdateLoan, props?.dataDetailLoan]);

  return (
    <S.ModalUpdateLoan title="Cập nhật khoản vay" footer={null} open={stateLoanList?.stateModal?.stateModalUpdateLoan} onCancel={onCancel}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} validateMessages={validateMessages} name="basic" onFinish={onFinish} style={{ width: '100%' }}>
          <div className="box-content-create-loan">
            <div className="content-left">
              <h2 className="title">Thông tin khoản vay</h2>
              <div className="box-form">
                <div className="label">Trạng thái khoản vay</div>
                <Form.Item name={'status'} rules={[{ required: true, type: 'string' }]} label="Trạng thái khoản vay'">
                  <Select defaultValue={'ACTIVE'} onChange={onChangeSelectStatus} className="style-select-partner" placeholder={'Trạng thái khoản vay'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={'ACTIVE'}>Đang hoạt động</Select.Option>
                    <Select.Option value={'CANCELED'}>Đã hủy</Select.Option>
                    {/* <Select.Option value={'DONE'}>Đã tất toán</Select.Option> */}
                  </Select>
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">REF ID</div>
                <Form.Item name={'referenceId'} rules={[{ required: true, type: 'string' }]} label="REF ID">
                  <Input size={'small'} className="form-input-create" placeholder={'Mã đơn hàng'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Ngày đến hạn</div>
                <div className={'filter-item'}>
                  <Form.Item name={'maturityDate'} rules={[{ type: 'date' }]} label="Ngày đến hạn">
                    <DatePicker style={{ background: 'none', border: 'none  ', padding: '0px', width: '100%' }} placeholder={'Ngày đến hạn'} format={'DD-MM-YYYY'} />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="content-right">
              <div className="section-checkbox">
                <CheckBox onChange={onChangeCheckbox}></CheckBox>
                <h2 className="title">Thay đổi giá trị đơn hàng</h2>
              </div>
              {checkbox ? (
                <>
                  <div className="box-form">
                    <div className="label">Số tiền đơn hàng cũ</div>
                    <Form.Item name={'referenceId'} label="Số tiền đơn hàng cũ">
                      <div className="input-default-value" style={{ color: '#8C8C8C' }}>
                        {formatNumberWithCommas(props?.dataDetailLoan?.baseAmount ? props?.dataDetailLoan.baseAmount : 0)}{' '}
                      </div>
                    </Form.Item>
                  </div>
                  <div className="box-form">
                    <div className="label">Số tiền đơn hàng mới</div>
                    <Form.Item
                      name={'newLoanAmount'}
                      rules={[
                        { required: true, type: 'string' },
                        ({}) => ({
                          validator(_, value) {
                            if (value < 0) {
                              return Promise.reject(new Error('Số tiền đơn hàng phải > 0'));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                      label="Số tiền đơn hàng mới"
                    >
                      <Input type="number" size={'small'} className="form-input-create" placeholder={'Số tiền đơn hàng mới'} />
                    </Form.Item>
                  </div>
                  <div className="box-form">
                    <div className="label">
                      Lý do thay đổi giá trị <span>*</span>
                    </div>
                    <Form.Item name={'reason'} rules={[{ required: true, type: 'string' }]} label="Lý do thay đổi giá trị">
                      <Input size={'small'} className="form-input-create" placeholder={'Lý do thay đổi giá trị'} />
                    </Form.Item>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <FooterModal type={'submit'} handleLoading={checkBtnLoading} handleClickCancel={onCancel} labelCancel={'Hủy'} LabelSave={'Cập nhập khoản vay'}></FooterModal>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalUpdateLoan>
  );
}
