import { notificationController } from '@app/controllers/notificationController';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as S from '../../../RequestLoan/RequestLoanManagement.styles';
import { updateModals } from '../Action';
import { DebtListContext } from '../Context';
import { AprrovalStatusPaymentTransaction } from '../DebtList.api';
import { DataTableWaitForApproveType } from '../interface';

type Props = {
  RecordRow: DataTableWaitForApproveType;
  callApiDebtListWaitForAprrove: () => void;
};
export default function ModalApprovalDebt(props: Props) {
  const navigate = useNavigate();
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);
  const [loading, setloading] = useState(false);
  function moveDetailLoan() {
    if (props.RecordRow) {
      navigate(`/customers/loan/${props.RecordRow?.loanCode}`);
    }
  }

  const handleCancelModalAccept = () => {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalAprrove: false }));
  };

  const handleOkModalAccept = async () => {
    setloading(true);
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalAprrove: false }));
    if (props.RecordRow) {
      AprrovalStatusPaymentTransaction(props.RecordRow?.code, {
        bifiinStatus: 'VERIFIED',
      })
        .then((res) => {
          if (res?.error) {
            setloading(false);
          } else {
            notificationController.success({ message: `Xác nhận thành công` });
            props?.callApiDebtListWaitForAprrove();
            setloading(false);
          }
        })
        .catch((err) => {
          setloading(false);
          notificationController.error({ message: _.get(err, `Approval fail`, `${err} err API`) });
        });
    }
  };

  return (
    <S.ModalAccept
      footer={[
        <Button key="back" className="style-btn-back" onClick={handleCancelModalAccept}>
          Huỷ
        </Button>,
        <Button loading={loading} key="submit" className="style-btn-accept" type="primary" onClick={handleOkModalAccept}>
          Xác Nhận
        </Button>,
      ]}
      open={stateDebtList?.modals.modalAprrove}
      onOk={handleOkModalAccept}
      onCancel={handleCancelModalAccept}
    >
      <div className="box-header">
        Xác nhận duyệt thanh toán <span className="color-yellow">{props.RecordRow?.code} </span>{' '}
      </div>

      <div className="box-content-body">
        <S.DflexModalAccept>
          <S.BranchNameStyle>ID khoản vay</S.BranchNameStyle>
          <S.BranchResultStyle>
            <a onClick={moveDetailLoan}>{props.RecordRow ? props.RecordRow?.loanCode : ''}</a>
          </S.BranchResultStyle>
        </S.DflexModalAccept>

        <S.DflexModalAccept style={{ marginTop: '-5px' }}>
          <S.BranchNameStyle>Tên khách hàng</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.partnerName : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Số tiền nhận</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.paymentAmount : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Đối tác liên kết</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.partnerName : ''} </S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Chứng từ</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.authenticationFile : ''} </S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept style={{ borderBottom: '1px solid #3C4450 ', paddingBottom: '30px', marginBottom: '0px' }}>
          <S.BranchNameStyle>Ngày thanh toán</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.paymentDate : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
      </div>
    </S.ModalAccept>
  );
}
