import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { Avatar, Tooltip } from 'antd';
import React from 'react';
import { DataLoanDetailType } from '../interface';

type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
};
export default function UserInforSection(props: Props) {
  return (
    <div className="section-userinfor">
      <div className="title-userinfor">Thông tin khách hàng</div>
      <div className="content">
        <div className="avatar-user">
          <Avatar style={{ width: '94px', height: '94px' }}></Avatar>
        </div>
        <div className="column-content">
          <div className="label">Họ và tên</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.fullName ? props.dataDetailLoan?.user?.fullName : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.fullName ? props.dataDetailLoan?.user?.fullName : '-'}</div>
          </Tooltip>
          <div className="label margin-top-20">Năm sinh</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.birthday ? props.dataDetailLoan?.user?.birthday : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.birthday ? props.dataDetailLoan?.user?.birthday : '-'}</div>
          </Tooltip>

          <div className="label margin-top-20">Trạng thái</div>
          <div className="value">
            <StatusesText status={props?.dataDetailLoan && props?.dataDetailLoan != undefined ? props?.dataDetailLoan?.status : 'ACTIVE'} context="LOAN"></StatusesText>
          </div>
        </div>
        <div className="column-content">
          <div className="label">CMND/CCCD</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.idCard ? props.dataDetailLoan?.user?.idCard : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.idCard ? props.dataDetailLoan?.user?.idCard : '-'}</div>
          </Tooltip>

          <div className="label">Số điện thoại</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.phone ? props.dataDetailLoan?.user?.phone : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.phone ? props.dataDetailLoan?.user?.phone : '-'}</div>
          </Tooltip>

          <div className="label">Điểm</div>
          <div className="value">
            <CreditScoring score={props.dataDetailLoan?.user?.point ? props.dataDetailLoan?.user?.point : 0} />
          </div>
        </div>
        <div className="column-content">
          <div className="label">Email/</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.email ? props.dataDetailLoan?.user?.email : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.email ? props.dataDetailLoan?.user?.email : '-'}</div>
          </Tooltip>

          <div className="label">Level</div>
          <Tooltip placement="top" title={<span>{props.dataDetailLoan?.user?.level ? props.dataDetailLoan?.user?.level : '-'}</span>}>
            <div className="value">{props.dataDetailLoan?.user?.level ? props.dataDetailLoan?.user?.level : '-'}</div>
          </Tooltip>

          <div className="label">Ghi chú</div>
          <div className="value">-</div>
        </div>
      </div>
    </div>
  );
}
