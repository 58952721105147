import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../RequestLoanManagement.styles';
import { formatNumberWithCommas } from '@app/utils/utils';

// import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import { StatusesText } from '@app/components/common/Statuses/Statuses';

import Avatar from '../../../../../../assets/icons/avatar.svg';
import { actionApprovalAndRejectApiVotes, getListVotesApi } from '../RequestLoanManagement.api';

import { DataResponseVotes, DataTableVotes } from '../interface';
import { ColumnsType } from 'antd/lib/table';
import { RequestLoanContext } from '../Context';
import { updateFiltersVotes, updateModalsRequestLoan } from '../Action';
import { queryStringParams } from '@app/controllers/queryStringParams';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import ActionColumn from '@app/components/common/ActionColumn/ActionColumn';
import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import { FormSearchVotes } from './FormSearchVotes';
import { notificationController } from '@app/controllers/notificationController';
import ModalRejectVotes from './ModalRejectVotes';

export default function TableVotes() {
  //   const [filterRequestID, setfilterRequestID] = useState<FilterParamsRequestID>({
  //     code: '',
  //   });

  //   const [RecordRow, setRecordRow] = useState<any>();
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);
  const [checkLoading, setcheckLoading] = useState(false);
  const [saveDataReject, setsaveDataReject] = useState<any>();
  const [dataList, setDataList] = useState<DataResponseVotes>();
  const callApiVotesList = useCallback(async () => {
    setcheckLoading(true);

    await getListVotesApi(queryStringParams(stateRequestLoan.filterVotes))
      .then((res) => {
        res && res?.data && setDataList(res);
        setcheckLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setcheckLoading(false);
      });
  }, [stateRequestLoan.filterVotes]);

  //   const callApiRequestLoanApproval = useCallback(
  //     async (filterRequestID: FilterParamsRequestID) => {
  //       setcheckLoading(true);

  //       await ApprovalRequestLoanManagementApi(filterRequestID)
  //         .then((res) => {
  //           callApiRequestLoan();
  //           if (res?.error) {
  //             setcheckLoading(false);
  //             // notificationController.error({ message: _.get(res?.message, `Approval fail`, `${res?.message}`) });
  //           } else {
  //             notificationController.success({ message: `Duyệt thành công` });
  //             setcheckLoading(false);
  //           }
  //         })
  //         .catch((err) => {
  //           setcheckLoading(false);
  //           notificationController.error({ message: _.get(err, `Approval fail`, `${err} err API`) });
  //         });
  //     },
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     [filterRequestID],
  //   );

  //   const showModalReject = (value: DataTypeTable) => {
  //     dispatchRequestLoan(updateFiltersActionRejectRequestLoan({ ...stateRequestLoan.filterRequestReject, code: value?.code && value?.code }));
  //     setRecordRow(value);
  //     dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: true }));
  //   };

  // const handleOkModalReject = () => {
  //   setIsModalOpenModalReject(false);
  //   // callApiRequestLoanReject(stateRequestLoan?.filterRequestReject);
  // };

  // const handleCancelModalReject = () => {
  //   setIsModalOpenModalReject(false);
  // };

  useEffect(() => {
    callApiVotesList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRequestLoan.filterVotes]);

  function actionAprroval(code: DataTableVotes) {
    actionApprovalAndRejectApiVotes(code?.code, {
      status: 'APPROVED',
    })
      .then((res) => {
        if (res?.error) {
          notificationController.error({ message: `Duyệt thất bại` });
        } else {
          notificationController.success({ message: `Duyệt thành công` });
          callApiVotesList();
        }
      })
      .catch(() => {
        notificationController.error({ message: `Err Api` });
      });
  }

  function actionReject(data: DataTableVotes) {
    data && setsaveDataReject(data);
    dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalRejectVotes: true }));
  }

  const columns: ColumnsType<DataTableVotes> = [
    {
      title: 'ID phiếu chờ duyệt',
      key: 'code',
      width: '270px',
      dataIndex: 'code',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '230px',
      title: 'ID khoản vay',
      key: 'loanCode',
      dataIndex: 'loanCode',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '204px',
      title: 'Tên khách hàng',
      key: 'userName',
      dataIndex: 'userName',
      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      width: '130px',
      title: 'Điểm Tín dụng KH',
      key: 'userScore',
      dataIndex: 'userScore',
      render: (value: any) => {
        return <div>{value ? <CreditScoring score={value && value} /> : ''}</div>;
      },
    },

    {
      width: '92px',
      title: 'Level',
      key: 'Level',

      render: (value: DataTableVotes) => (
        <div>
          {(value.level && value.level == 'LEVEL_0' ? '0' : '') ||
            (value.level && value.level == 'LEVEL_1' ? '1' : '') ||
            (value.level && value.level == 'LEVEL_2' ? '2' : '') ||
            (value.level && value.level == 'LEVEL_3' ? '3' : '') ||
            (value.level && value.level == 'LEVEL_4' ? '4' : '')}
        </div>
      ),
    },

    {
      width: '180px',
      title: 'Thời gian request',
      key: 'createdAt',
      dataIndex: 'createdAt',

      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },

    {
      // width: '150px',
      title: 'Số tiền vay cũ',
      key: 'oldLoanAmount',
      dataIndex: 'oldLoanAmount',
      align: 'right',
      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : 0}</div>;
      },
    },
    {
      // width: '150px',
      title: 'Số tiền vay mới',
      key: 'newLoanAmount',
      dataIndex: 'newLoanAmount',
      align: 'right',
      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : 0}</div>;
      },
    },
    {
      width: '120px',
      title: 'Đối tác liên kết',
      key: 'partner',
      dataIndex: 'partner',
    },

    {
      width: '146px',
      title: 'REFID',
      key: 'refId',
      dataIndex: 'refId',

      render: (value: number) => <a className="cursor-pointer-none">{value ? value : ''}</a>,
    },

    {
      width: '150px',
      title: 'Hình thức vay',
      key: 'product',
      dataIndex: 'product',
    },

    {
      width: '150px',
      title: 'Tỷ lệ phí ứng vốn',
      key: 'feeRate',
      dataIndex: 'feeRate',
      align: 'right',
      render: (value: number) => {
        return <div>{value ? value + '%' : ''}</div>;
      },
    },

    {
      width: '150px',
      title: 'Lãi suất',
      align: 'right',
      key: 'interestRate',
      dataIndex: 'interestRate',
      render: (value: number) => {
        return <div>{value ? value + '%' : 0 || value == 0 ? 0 + '%' : null}</div>;
      },
    },
    {
      width: '200px',
      title: 'Phí dự kiến mới',
      key: 'newFeeAmount',

      render: (value: DataTableVotes) => {
        return <div>{value?.newFeeAmount ? formatNumberWithCommas(value?.newFeeAmount) : 0}</div>;
      },
    },

    {
      width: '155px',
      title: 'Trạng thái',
      key: 'status',
      fixed: 'right',

      dataIndex: 'status',
      render: (value) => {
        return <StatusesText status={value} context="LOAN"></StatusesText>;
      },
    },
    {
      width: '100px',
      title: 'Hành động',
      key: 'requestId',
      fixed: 'right',
      render: (value: DataTableVotes) => {
        return (
          <ActionColumn
            status={value?.status}
            handleApproval={() => actionAprroval(value)}
            handleRejected={() => actionReject(value)}
            actionApproval={true}
            actionReject={true}
            actionWatch={false}
          ></ActionColumn>
        );
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <S.ContainerRequestLoan className="container-request-loan">
        <div className="box-content">
          <FormSearchVotes></FormSearchVotes>
          <Table
            style={{ padding: '16px 24px 24px 24px' }}
            scroll={{ x: 2730 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataList?.data ? dataList?.data : []}
            columns={columns}
            sticky={true}
            rowKey="code"
            loading={checkLoading}
            pagination={{
              current: dataList?.pageIndex,
              pageSize: dataList?.pageSize,
              total: dataList?.total,
              onChange: (page: number, pageSize: number) => {
                dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, pageIndex: page, pageSize: pageSize }));
              },
            }}
          />
        </div>
        {saveDataReject && <ModalRejectVotes callApiListVote={callApiVotesList} dataListVotes={saveDataReject}></ModalRejectVotes>}
      </S.ContainerRequestLoan>
    </GS.WrapperOverrideInput>
  );
}
