import React, { useEffect } from 'react';
import CoffeeErrorAnimation from '@app/assets/animated-json/coffee-error.json';
import lottie from 'lottie-web';
import * as S from './AuthenMeCallback.style';

const AuthenMeError: React.FC = () => {
  const elem = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: CoffeeErrorAnimation,
      autoplay: true,
      loop: true,
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <S.Wrapper>
      <S.BoxError ref={elem} />
    </S.Wrapper>
  );
};

export default AuthenMeError;
