import React, { useEffect } from 'react';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { WithChildrenProps } from '@app/types/generalTypes';

const RequireAuth: React.FC<WithChildrenProps> = ({ children }) => {
  const token = useAppSelector((state) => state.auth.token);

  const oauth2_path = process.env.REACT_APP_OAUTH2_URL;
  const oauth2_state = new Date().valueOf();
  const oauth2_client_id = process.env.REACT_APP_OAUTH2_CLIENT_ID;

  const oauth2_url: string = `${oauth2_path}/oauth2/auth?client_id=${oauth2_client_id}&response_type=code&redirect_uri=${location.href}oauth2/callback&state=${oauth2_state}` as string;

  useEffect(() => {
    if (!token) {
      location.href = oauth2_url;
    }
  }, [token, oauth2_url]);

  return token ? <>{children}</> : null;
};

export default RequireAuth;
