import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { dataFund } from '../../Loan/interface';
import {
  DataDetail,
  DataResponseDisbursement,
  DataResponseTableWaitForDisbursement,
  DataResponseTableWaitForDisbursementDetail,
  ResponseDataUploadFile,
  paramsBodyCreateProofType,
  paramsPendingDisbursementApi,
} from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}
interface ConfigFormData extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}

const TokenConfigFormData: ConfigFormData = {
  ContentType: 'ContentType',
  secured: true,
};

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

// export const getLoan = (params: any): Promise<ResponseCollectionBody> => httpApi.post('/v1/loan', { ...params });
export const getListDisbursementApi = (params: string): Promise<DataResponseDisbursement> => httpApi.get(`/loans/v1/transactions?${params}`, { ...TokenConfig });

export const getListDisbursementCapitalApi = (params: string | undefined): Promise<DataResponseDisbursement> => httpApi.get(`/loans/v1/funds/transaction-collection/${params}`, { ...TokenConfig });

export const getDetailDisbursementApi = (params: string): Promise<DataDetail> => httpApi.get(`/loans/v1/transactions/${params}`, { ...TokenConfig });

export const getImg = (params: string): Promise<any> => httpApi.get(`/loans/v1/files/${params}`, { ...TokenConfig });

export const UploadImgApi = (formData: FormData): Promise<ResponseDataUploadFile> => httpApi.post('/loans/v1/files/upload', formData, { ...TokenConfigFormData });

export const getFundApi = (params: string | undefined): Promise<dataFund[] | any> => httpApi.get(`/loans/v1/funds/active?${params}`, { ...TokenConfig });

export const getListWaitForDisbursementApi = (params: string | undefined): Promise<DataResponseTableWaitForDisbursement> => httpApi.get(`/loans/v1/transaction/pending?${params}`, { ...TokenConfig });

export const getDetailWaitForDisbursementDetailApi = (dateReview: string, params: string | undefined): Promise<DataResponseTableWaitForDisbursementDetail> =>
  httpApi.get(`/loans/v1/transaction/pending/${dateReview}?${params}`, { ...TokenConfig });

export const updatePendingDisbursementApi = (params: paramsPendingDisbursementApi | undefined): Promise<any> => httpApi.put(`/loans/v1/transaction/pending`, params, { ...TokenConfig });

export const CreateEditProofDisbursementApi = (code: string, paramsBody: paramsBodyCreateProofType): Promise<any> =>
  httpApi.post(`/loans/v1/transactions/${code}/receipt`, paramsBody, { ...TokenConfig });

export const ExportExcelDisbursemented = (params: string): Promise<any> => httpApi.get(`/loans/v1/transactions/export?${params}`, { ...TokenConfig });

export const ExportExcelDisbursementDetailInDay = (date: string | Date, params: string): Promise<any> => httpApi.get(`/loans/v1/transaction/pending/${date}/export?${params}`, { ...TokenConfig });
