import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineExport } from 'react-icons/ai';
import * as S from '../CustomerManagement.styles';
import * as GS from '@app/styles/GlobalStyle';
import SectionTabsDetail from './Section/./SectionTabsDetail';
import SectionUserInfor from './Section/./SectionInforUser';
import ModalEditDetail from './Section/ModalEditDetail';
import { DataDetailCusomerType } from '../interfaces';
import { getDetailCustomers } from '../CustomerManagement.api';
import _ from 'lodash';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { CustomerManagementContext, CustomerManagementProvider } from '../context';
import SectionInforPayment from './Section/SectionInforPayment';

type Props = {
  data?: any;
};

export default function DetailCusomer({}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState<DataDetailCusomerType>();
  const { id } = useParams();
  const { stateCustomerManagement } = useContext(CustomerManagementContext);

  const dispatch = useAppDispatch();

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const callApiDetailCustomer = async () => {
    getDetailCustomers(id)
      .then((res: DataDetailCusomerType) => {
        if (_.size(res.error)) {
          // @TODO Hiển thị lỗi lấy dữ liệu danh sách khách hàng
        } else {
          setDataDetail(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setDataDetail(undefined);
      });
  };
  useEffect(() => {
    callApiDetailCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomerManagement, stateCustomerManagement?.modals?.checkRender]);
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khách hàng',
          url: '/customers/management',
        },
        {
          name: 'Danh sách',
        },
        {
          name: dataDetail && dataDetail?.user?.fullName,
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`${dataDetail && dataDetail?.user?.fullName}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataDetail]);

  // const dislaydSectionUserInfor = useMemo(() => {
  //   return dataDetail && <SectionUserInfor dataDetail={dataDetail && dataDetail}></SectionUserInfor>;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [stateCustomerManagement?.modals?.checkRender]);

  return (
    <GS.WrapperOverrideInput>
      <CustomerManagementProvider>
        <S.ContainerDetailCustomer>
          {/* <div className="title-name">{dataDetail?.user?.fullName}</div> */}
          <div className="content-body">
            <div className="section-btn-action">
              <div className="box-btn">
                {/* <Button className="btn-edit" onClick={showModal}>
                Sửa
              </Button> */}
                <Button className="btn-excel" icon={<AiOutlineExport />}>
                  Xuất Excel
                </Button>
              </div>
            </div>
            <SectionUserInfor callApiDetailCustomer={callApiDetailCustomer} dataDetail={dataDetail}></SectionUserInfor>

            <SectionInforPayment dataDetail={dataDetail && dataDetail}></SectionInforPayment>
            {/* {dislaydSectionUserInfor} */}
            <SectionTabsDetail dataDetail={dataDetail && dataDetail}></SectionTabsDetail>
            {/* <div className="text-note">Sửa lần cuối bởi Nguyen Ngoc Duong | 23-09-2023 - 12:11 am</div> */}
          </div>
          <ModalEditDetail isModalOpen={isModalOpen} handleOk={handleOk} handleCancel={handleCancel}></ModalEditDetail>
        </S.ContainerDetailCustomer>
      </CustomerManagementProvider>
    </GS.WrapperOverrideInput>
  );
}
