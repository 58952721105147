import { CheckDataOriginalType, FilterReconciliationLoanListType, filtersReconciliationLoanDetail, ReducerType } from './interface';

export const UPDATE_FILTERS_RECONCILIATION_LOAN = 'UPDATE_FILTERS_RECONCILIATION_LOAN';
export const UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_LOAN = 'UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_LOAN';
export const UPDATE_FILTERS_RECONCILIATION_LOAN_DETAIL = 'UPDATE_FILTERS_RECONCILIATION_LOAN_DETAIL';
export const updateFiltersReconciliationLoanList = (filtersReconciliationLoanList: FilterReconciliationLoanListType): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_LOAN,
    filtersReconciliationLoanList: filtersReconciliationLoanList,
  };
};
export const updateFiltersReconciliationLoanDetail = (filtersReconciliationLoanDetail: filtersReconciliationLoanDetail): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_LOAN_DETAIL,
    filtersReconciliationLoanDetail: filtersReconciliationLoanDetail,
  };
};

export const updateCheckDataOriginal = (checkDataOriginal: CheckDataOriginalType): ReducerType => {
  return {
    type: UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_LOAN,
    checkDataOriginal: checkDataOriginal,
  };
};
