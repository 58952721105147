import { ColumnsType } from 'antd/lib/table';
import { DataTypeTable } from '../../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

export const columnsConfigOriginal: ColumnsType<DataTypeTable> = [
  {
    width: '175px',
    title: 'Thời gian đối soát',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value && value}></TableColumnTime>,
  },
  {
    width: '123px',
    title: 'ID khoản vay',
    key: 'loanCodePartner',
    dataIndex: 'loanCodePartner',
    render: (value: string) => <a className="cursor-pointer-none">{value ? value : ''}</a>,
  },
  {
    width: '166px',
    title: 'Số tiền vay',
    key: 'loanAmountPartner',
    dataIndex: 'loanAmountPartner',
    align: 'right',

    render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value) : ''}</div>,
  },
  {
    width: '177px',
    title: 'Tổng tiền đơn hàng ',
    key: 'baseAmountPartner',
    dataIndex: 'baseAmountPartner',
    align: 'right',

    render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value) : ''}</div>,
  },
  {
    width: '131px',
    title: 'Hình thức vay ',
    key: 'loanTypePartner',
    dataIndex: 'loanTypePartner',

    render: (value: string) => <div className="">{value ? value : ''}</div>,
  },
  {
    width: '224px',
    title: 'Thời gian tạo khoản vay ',
    key: 'approvalDatePartner',
    dataIndex: 'approvalDatePartner',

    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    width: '131px',
    title: 'REF ID ',
    key: 'referenceIdPartner',
    dataIndex: 'referenceIdPartner',

    render: (value: string) => <div className="text-loancode">{value ? value : ''}</div>,
  },
];
