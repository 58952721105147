import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/v2/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/Main';
import SettingsPage from '@app/pages/Settings/Settings';

import { CapitalRoutes, CustomerManagementRoutes, PartnerRoutes, PolicyRoutes, ProductRoutes, ReconciliationLoanRoutes } from '@app/modules/Backline/Customers/routes';
import { OAuth2Routes } from '@app/modules/Oauth2/routes';

const Logout = React.lazy(() => import('./Logout'));

export const DASHBOARD_PATH = '/';

const Dashboard = withLoading(DashboardPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />
          <Route path={'settings'} element={<SettingsPage />} />
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
        {CustomerManagementRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/customers'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}
        {CapitalRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/capital'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}
        {ProductRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/product'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}
        {PolicyRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/policy'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}
        {PartnerRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/partner'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}

        {ReconciliationLoanRoutes.map((props) => {
          return (
            <Route key={''} element={protectedLayout}>
              <Route key={''} path={'/reconciliation'}>
                <Route path={props.path} element={props.element} />
              </Route>
            </Route>
          );
        })}
        {OAuth2Routes.map((props) => {
          return (
            <Route key={''} path={'/oauth2'}>
              <Route path={props.path} element={props.element} />
            </Route>
          );
        })}
      </Routes>
    </BrowserRouter>
  );
};
