import { FilterLoanListType, filtersDetailWaitForDisbursementType, filtersWaitForDisbursementType, ReducerType, StateModal } from './interface';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_DATA_LOANLIST = 'UPDATE_DATA_LOANLIST';
export const UPDATE_FILTERS_LOANLIST = 'UPDATE_FILTERS_LOANLIST';
export const UPDATE_PAGINATION_META_CUSTOMER = 'UPDATE_PAGINATION_META_CUSTOMER';
export const UPDATE_STATE_MODAL_DISBURSEMENT = 'UPDATE_STATE_MODAL_DISBURSEMENT';
export const UPDATE_FILTERS_WAIT_FOR_DISBURSEMENT = 'UPDATE_FILTERS_WAIT_FOR_DISBURSEMENT';
export const UPDATE_FILTERS_DETAIL_WAIT_FOR_DISBURSEMENT = 'UPDATE_FILTERS_DETAIL_WAIT_FOR_DISBURSEMENT';
export const updateForm = (form: any): ReducerType => {
  return {
    type: UPDATE_FORM,
    form: form,
  };
};

export const updateFiltersLoanList = (filtersLoanList: FilterLoanListType): ReducerType => {
  return {
    type: UPDATE_FILTERS_LOANLIST,
    filtersLoanList: filtersLoanList,
  };
};

export const updateFiltersWaitForDisbursement = (filtersWaitForDisbursement: filtersWaitForDisbursementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_WAIT_FOR_DISBURSEMENT,
    filtersWaitForDisbursement: filtersWaitForDisbursement,
  };
};

export const updateFiltersDetailWaitForDisbursement = (filtersDetailWaitForDisbursement: filtersDetailWaitForDisbursementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_DETAIL_WAIT_FOR_DISBURSEMENT,
    filtersDetailWaitForDisbursement: filtersDetailWaitForDisbursement,
  };
};

export const updateStateModalDisbursement = (stateModal: StateModal): ReducerType => {
  return {
    type: UPDATE_STATE_MODAL_DISBURSEMENT,
    stateModal: stateModal,
  };
};
