import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../Management/CustomerManagement.styles';
import * as S2 from '../Capital.styles';
import { AiOutlineSearch } from 'react-icons/ai';

import { CapitalContext } from '../Context';
import { updateFiltersCapital, updateModals } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
export const FormSearchCapitalList = () => {
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);

  const showModalCreate = () => {
    dispatchCapital(updateModals({ ...stateCapital.modals, modalCreate: true }));
  };

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchCapital(updateFiltersCapital({ ...stateCapital?.filters, key: e.target.value, pageIndex: 1 }));
    }
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd>
            <S2.Dflex>
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Search'} />
              </Form.Item>
            </S2.Dflex>
            <div style={{ display: 'flex' }}>
              <Form.Item>
                <BtnCreateStyle onClick={showModalCreate} label={'Thêm nguồn vốn'}></BtnCreateStyle>
              </Form.Item>
            </div>
          </S2.DflexStartEnd>
          {/* <ModalCreateCapital></ModalCreateCapital> */}
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
