import { Dispatch, useReducer } from 'react';
import { UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_LOAN, UPDATE_FILTERS_RECONCILIATION_LOAN, UPDATE_FILTERS_RECONCILIATION_LOAN_DETAIL } from './Action';
import { ReducerType } from './interface';

export const initReconciliationLoan = {
  stateReconciliationLoan: {
    form: null,
    filtersReconciliationLoanList: {
      date: '',
      pageSize: 10,
      pageIndex: 1,
    },
    filtersReconciliationLoanDetail: {
      day: '',
      key: '',
      typeData: '',
      status: '',
      pageSize: 10,
      pageIndex: 1,
    },
    checkDataOriginal: false,
  },
  dispatchReconciliationLoanList: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_RECONCILIATION_LOAN:
      return {
        ...state,
        filtersReconciliationLoanList: action.filtersReconciliationLoanList,
      };
    case UPDATE_FILTERS_RECONCILIATION_LOAN_DETAIL:
      return {
        ...state,
        filtersReconciliationLoanDetail: action.filtersReconciliationLoanDetail,
      };
    case UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_LOAN:
      return {
        ...state,
        checkDataOriginal: action.checkDataOriginal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useReconciliationLoanListReducer = () => {
  return useReducer(reducer, initReconciliationLoan.stateReconciliationLoan);
};
