import React from 'react';
import * as S from './ActionColumn.style';
import IconActionRequestDisable from '@app/assets/icons/icon-action-request-disable.svg';
import IconActionRequestAvailable from '@app/assets/icons/icon-action-request-available.svg';
import { StatusesTypo } from '@app/constants/statuses';
type Props = {
  actionWatch?: boolean;
  actionApproval?: boolean;
  actionReject?: boolean;
  ActionBF?: boolean;
  status?: StatusesTypo;
  handleWatch?: () => void;
  handleApproval?: () => void;
  handleRejected?: () => void;
  handleBF?: () => void;
};

export default function ActionColumn(props: Props) {
  return (
    <S.StyleColumnAction>
      {props?.status == 'WAIT_FOR_APPROVE' ? (
        <S.PopoverStyle
          overlayInnerStyle={{ backgroundColor: '#212830', borderRadius: '0px', minWidth: '100px' }}
          placement="bottomRight"
          content={
            <S.PopoverStyle>
              {props.actionWatch ? (
                <p onClick={props.handleWatch} className="text-watch">
                  Xem
                </p>
              ) : null}
              {props.actionApproval ? (
                <p onClick={props.handleApproval} className="style-text-action-approval">
                  Duyệt
                </p>
              ) : null}
              {props.actionReject ? (
                <p onClick={props.handleRejected} className="style-text-action-reject">
                  Huỷ
                </p>
              ) : null}

              {props.ActionBF ? (
                <p onClick={props.handleBF} className="style-text-action-BF">
                  Bifiin đã nhận
                </p>
              ) : null}
            </S.PopoverStyle>
          }
          trigger="click"
        >
          <div className="box-action-available">
            <img src={IconActionRequestAvailable} alt="" />
          </div>
        </S.PopoverStyle>
      ) : (
        <div className="box-action">
          <img src={IconActionRequestDisable} alt="" />
        </div>
      )}
    </S.StyleColumnAction>
  );
}
