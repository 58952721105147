import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../ReconciliationPayment.style';
const { RangePicker } = DatePicker;

import { DataResponseReconciliationPayment } from '../interface';
import { ReconciliationPaymentListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getReconciliationPaymentListApi } from '../ReconciliationPayment.api';
import { updateFiltersReconciliationPaymentList } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';
import { columnsConfig } from './columnsConfig';

export default function TableReconciliationPaymentList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataResponseReconciliationPayment>();
  const { stateReconciliationPayment, dispatchReconciliationPaymentList } = useContext(ReconciliationPaymentListContext);
  const callApiListDisbursement = async () => {
    setcheckLoading(true);
    await getReconciliationPaymentListApi(queryStringParams(stateReconciliationPayment?.filtersReconciliationPaymentList))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        setData(res); /// fake
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiListDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReconciliationPayment?.filtersReconciliationPaymentList]);

  function onChangeDate(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    dispatchReconciliationPaymentList(
      updateFiltersReconciliationPaymentList({ ...stateReconciliationPayment?.filtersReconciliationPaymentList, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }),
    );
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxReconciliationPayment>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <RangePicker format="YYYY-MM-DD" onChange={onChangeDate} />
              </Form.Item>
            </div>
            <Table
              scroll={{ x: 1180 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchReconciliationPaymentList(updateFiltersReconciliationPaymentList({ ...stateReconciliationPayment?.filtersReconciliationPaymentList, pageIndex: page }));
                },
              }}
            />
          </S.BoxReconciliationPayment>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
