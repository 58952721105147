import initAxios from '@app/api/API.v2';
import { ResponseDataUploadFile } from '@app/interfaces/interfaces';
import { AxiosRequestConfig } from 'axios';
import { CallApiErrType, DataLoanDetailType, DataResponseDetailWaitForDisbursement, DataResponseType, paramsBodyUpdateLoanType } from './interface';
import { DataTablePolicy } from '../Policy/interface';
const httpApi = initAxios();
interface Config extends AxiosRequestConfig {
  secured: boolean;
}
interface ConfigFormData extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}

const TokenConfig: Config = {
  secured: true,
};
const TokenConfigFormData: ConfigFormData = {
  ContentType: 'ContentType',
  secured: true,
};
export const getLoanListApi = (params: string): Promise<DataResponseType> => httpApi.get(`/loans/v1?${params}`, { ...TokenConfig });

export const getListWaitForDisbursementApi = (params: string): Promise<DataResponseType> => httpApi.get(`/loans/v1/disbursement/pending?${params}`, { ...TokenConfig });

export const getDetailWaitForDisbursementApi = (dateReview: string, params: string): Promise<DataResponseDetailWaitForDisbursement> =>
  httpApi.get(`/loans/v1/disbursement/pending/${dateReview}?${params}`, { ...TokenConfig });

export const getLoanListCapitalApi = (params: string | undefined): Promise<DataResponseType> => httpApi.get(`/loans/v1/funds/loan-collection/${params}`, { ...TokenConfig });

export const createDisbursementApi = (params: FormData): Promise<DataResponseType | CallApiErrType> => httpApi.post('/loans/v1/transactions', params, { ...TokenConfigFormData });

export const detailLoanApi = (id: string | undefined): Promise<DataLoanDetailType> => httpApi.get(`/loans/v1/${id}`, { ...TokenConfig });

export const UploadImgApi = (formData: FormData): Promise<ResponseDataUploadFile> => httpApi.post('/loans/v1/files/upload', formData, { ...TokenConfigFormData });

export const updatePendingDisbursement = (params: string | undefined): Promise<any> => httpApi.put(`/loans/v1/disbursement/pending/${params}`, undefined, { ...TokenConfig });

export const getDetailPolicy = (policyCode: string): Promise<DataTablePolicy> => httpApi.get(`/loans/v1/policy/${policyCode}`, { ...TokenConfig });

export const updateLoanApi = (code: string | undefined, isChangeLoanAmount: boolean, body: paramsBodyUpdateLoanType): Promise<any> =>
  httpApi.put(`/loans/v1/request/manual/${code}?isChangeLoanAmount=${isChangeLoanAmount}`, body, { ...TokenConfig });

export const createimageEvidenceApi = (code: string, evidence: any): Promise<any> => httpApi.post(`/loans/v1/${code}/evidence`, evidence, { ...TokenConfig });

export const ExportExcelLoan = (params: string): Promise<any> => httpApi.get(`/loans/v1/export?${params}`, { ...TokenConfig });
