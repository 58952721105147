import React from 'react';
import { initLoanList, useLoanListReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const LoanListContext = React.createContext({ ...initLoanList });
export const LoanListProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateLoanList, dispatchLoanList] = useLoanListReducer();

  const wrap = {
    stateLoanList,
    dispatchLoanList,
  };

  return <LoanListContext.Provider value={wrap}>{children}</LoanListContext.Provider>;
};
