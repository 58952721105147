import React, { useContext, useEffect, useState } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Form } from 'antd';
import { CustomerManagementContext, CustomerManagementProvider } from '@app/modules/Backline/Customers/pages/Management/context';
import { Card } from '@app/components/common/Card/Card';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import * as S from './CustomerManagement.styles';
import { FormSearch } from './ComponentManagementCustomer/FormSearch';
import { TableList } from './ComponentManagementCustomer/Table';
import _ from 'lodash';
import { DataResponseCustomerType } from '@app/modules/Backline/Customers/pages/Management/interfaces';
import { getCustomers } from '@app/modules/Backline/Customers/pages/Management/CustomerManagement.api';
import { queryStringParams } from '@app/controllers/queryStringParams';

interface MainProps {
  form: any;
}

const Main: React.FC<MainProps> = ({ form }) => {
  const { stateCustomerManagement, dispatchCustomerManagement } = useContext(CustomerManagementContext);
  const [data, setData] = useState<DataResponseCustomerType>();
  const [checkLoading, setcheckLoading] = useState(false);
  const callApiDataTableCustomer = async () => {
    setcheckLoading(true);

    getCustomers(queryStringParams(stateCustomerManagement.filters))
      .then((res: DataResponseCustomerType) => {
        if (_.size(res.error)) {
          // @TODO Hiển thị lỗi lấy dữ liệu danh sách khách hàng
          setcheckLoading(false);
        } else {
          setData(res);
          setcheckLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };
  useEffect(() => {
    callApiDataTableCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCustomerManagement.filters]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khách hàng',
          url: '/customers/management',
        },
        {
          name: 'Danh sách',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle('Danh sách khách hàng'));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchCustomerManagement, dispatch, form]);

  return (
    <Card padding={'24px'}>
      <S.ContentBody>
        <Form form={form}>
          <FormSearch form={form} />
          <TableList checkLoading={checkLoading} data={data} />
        </Form>
      </S.ContentBody>
    </Card>
  );
};

const CustomerManagement: React.FC = () => {
  const [form] = Form.useForm();
  return (
    <CustomerManagementProvider>
      <PageTitle>Danh sách khách hàng</PageTitle>
      <Main form={form} />
    </CustomerManagementProvider>
  );
};

export default CustomerManagement;
