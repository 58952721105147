import { Modal } from 'antd';
import styled from 'styled-components';
export const BoxRefund = styled.div`
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #597ef7;
    cursor: pointer;
  }
`;
export const FormSearch = styled.div`
  .margin-top-10 {
    margin-top: 10px;
  }
  .ant-select {
    min-width: 197px !important;
  }
  margin-left: 3px;
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 340px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-select-selector {
    width: 247px;
  }
  .ant-picker {
    height: 32px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: space-between;
  }
`;

export const ModalEditPartner = styled(Modal)`
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;

    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
  .content-body-default-policy {
    border-bottom: 1px solid #272d35;

    .box-col {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      .item-form {
        width: 47.5%;

        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
        .item-line-product {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalDetailCampaign = styled(Modal)`
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;

    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
  .content-body-default-policy {
    border-bottom: 1px solid #272d35;

    .box-col {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      .item-form {
        width: 47.5%;

        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
        .item-line-product {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalCreateCampaign = styled(Modal)`
  /* input#basic_time {
    background: none !important;
    border: none !important;
  } */
  .ant-picker.ant-picker-range {
    width: 100% !important;
  }
  input {
    /* background: #272d35; */
    /* border: 1px solid #3c4450; */
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;

    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
  .content-body-default-policy {
    border-bottom: 1px solid #272d35;

    .box-col {
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;
      .item-form {
        width: 47.5%;

        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
        .item-line-product {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;
        }
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ModalCreatePartner = styled(Modal)`
  input {
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  .content-header {
    border-bottom: 1px solid #272d35;
    display: flex;
    justify-content: space-between;
    .item-form {
      width: 47.5%;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;
    padding-top: 20px;
    padding-bottom: 30px;
    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
  .title-modal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .ant-modal-content {
    width: 904px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    .box-img {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        object-fit: contain;
        height: 100%;
      }
    }
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }
`;

export const ContainerDetailPartner = styled.div`
  .title-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #f2f2f2;
    padding: 0px 0px 24px 24px;
  }
  .content-body {
    background: #12161c;
    width: 100%;
    padding: 24px;
    .text-note {
      padding-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .section-btn-action {
      display: flex;
      justify-content: end;

      .box-btn {
        display: flex;
        justify-content: end;
        .btn-excel {
          height: 32px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #d9d9d9;
          margin-left: 10px;
        }
        .btn-edit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #f2c015;
          border: 1px solid #f2c015;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          color: #1f1f1f;
        }
      }
    }

    .section-user-infor {
      background-color: #1a1f25;
      margin-top: 24px;
      .title {
        border-bottom: 1px solid #3c4450;
        .box-title {
          padding: 16px 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-user {
        padding: 24px 75px 24px 24px;
        display: flex;
        justify-content: space-between;
        .avatar-column {
          width: 94px;
          img {
            width: 100%;
          }
        }
        .item-column {
          width: 88%;
          padding-top: 20px;
          .text-watch {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #85a5ff !important;
            cursor: pointer;
          }
          .value {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #bfbfbf;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .font-bold {
            font-weight: 700;
          }
          .box-value-infor {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #8c8c8c;
              margin-bottom: 8px;
            }

            .redirect-style {
              max-width: 290px;
            }
            .profile-style {
              max-width: 340px;
            }
            .box-edit-customer {
              display: flex;
              .edit-customer {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #f2c015;
                cursor: pointer;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }

    .section-partner-product {
      background-color: #1a1f25;
      margin-top: 24px;
      padding: 24px 50px 24px 24px;

      display: flex;
      justify-content: space-between;
      .avatar-column {
        width: 94px;
        height: 94px;
        img {
          width: 94px;
          height: 94px;
        }
      }
      .column-content {
        margin-top: 5px;
        width: 89%;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #bfbfbf;
        .value {
          font-weight: 700;
        }
        .row-1 {
        }
        .row-2 {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          .item-code {
            max-width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-profile {
            max-width: 320px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .item-redirect {
            max-width: 280px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .title {
        .sub-title {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
          margin-top: 10px;
        }
      }
      .box-input-code {
        width: 323px;
        input {
          background: #272d35;
        }
      }
      .text-watch {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #85a5ff;
        cursor: pointer;
        margin-right: 24px;
        margin-left: 15px;
      }
    }
    .section-tabs-detail {
      margin-top: 24px;
      background: #1a1f25;
      .title {
        .box-title {
          padding: 16px 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-verify {
        .title-verify-face {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f5f5f5;
        }
        .image-verify-face {
          display: flex;
          margin-top: 24px;
          margin-bottom: 24px;
          .box-img {
            width: 140px;
            height: 159px;
            box-sizing: border-box;
            left: 24px;
            top: 118px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
        .CCCD-verify-face {
          display: flex;
          margin-top: 24px;
          .box-img {
            width: 333px;
            box-sizing: border-box;
            height: 194px;
            left: 24px;
            top: 347px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
      }
    }
  }
`;

export const StyleSectionFormDetail = styled.div`
  .style-edit-text {
    margin-left: 8px;
    margin-top: 12px;
  }
  display: flex;
  justify-content: space-between;
  .box-start {
    width: 30%;
    display: flex;
    input {
      width: 323px;
      background-color: #272d35;
    }
  }
  .content-body {
    border-bottom: 1px solid #272d35;
    .title {
      .sub-title {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
        margin-top: 10px;
      }
    }
    .box-input-code {
      width: 323px;
      input {
        background: #272d35;
      }
    }
    .text-watch {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #85a5ff;
      cursor: pointer;
      margin-right: 24px;
      margin-left: 15px;
    }
    .item-line {
      display: flex;
      justify-content: space-between;
      .item-form {
        width: 47.5%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #bfbfbf;
        }
      }
    }
  }
`;

export const StyleDetailPartnerProduct = styled.div`
  .title {
    .box-title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #f0f0f0;
    }
    .sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #bfbfbf;
      margin-top: 14px;
    }
  }
`;
