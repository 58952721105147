import React from 'react';
import { AiOutlineDashboard, AiOutlineDollarCircle, AiOutlineSetting, AiOutlineShop } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DatabaseOutlined, MoneyCollectOutlined, PullRequestOutlined, ReconciliationOutlined, ShoppingOutlined, UsergroupAddOutlined } from '@ant-design/icons';

export interface SidebarNavigationItem {
  title?: string;
  key: string;
  label?: React.ReactElement | string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
  onClick?(): void;
  onTitleClick?(): void;
  className?: string;
}

interface TitleTranslationProps {
  translationKey: string;
  url?: string;
}

const MenuTitleTranslation: React.FC<TitleTranslationProps> = ({ translationKey, url }) => {
  const { t } = useTranslation();
  return (
    <>
      <Link to={url || ''}>{t(translationKey)}</Link>
    </>
  );
};

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    key: 'dashboard',
    icon: <AiOutlineDashboard />,
    label: <MenuTitleTranslation translationKey={'common.siderMenu.dashboard'} />,
    className: 'd-md-none',
  },

  {
    label: <MenuTitleTranslation translationKey={'common.siderMenu.requestLoan'} />,
    key: 'user_management',
    icon: <PullRequestOutlined style={{ width: '16px' }} />,
    className: 'd-md-none',

    children: [
      {
        title: 'Khoản vay chờ duyệt',
        label: 'Khoản vay chờ duyệt',
        key: 'requestLoan',
        onClick: function () {
          location.href = '/customers/request-loan-management';
        },
      },
      {
        title: 'Phiếu chờ duyệt',
        label: 'Phiếu chờ duyệt',
        key: 'votesWaitForAprroval',
        onClick: function () {
          location.href = '/customers/votes-wait-for-approval';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'common.siderMenu.customer'} />,
    key: 'customer',
    icon: <AiOutlineShop />,
    children: [
      {
        title: 'Danh sách khách hàng',
        label: 'Danh sách khách hàng',
        key: 'feed',
        onClick: function () {
          location.href = '/customers/management';
        },
      },
      {
        title: 'EKYC',
        label: 'EKYC',
        key: 'EKYC',
        onClick: function () {
          location.href = '/customers/ekyc-management';
        },
      },
    ],
  },

  {
    label: <MenuTitleTranslation translationKey={'common.siderMenu.loan'} />,
    key: 'loan',
    icon: <AiOutlineDollarCircle />,
    className: 'd-md-none',
    children: [
      {
        title: 'Danh sách khoản vay',
        label: 'Danh sách khoản vay',
        key: 'LoanList',
        onClick: function () {
          location.href = '/customers/loan';
        },
      },

      {
        title: 'RequestLoanManagement',
        label: 'Quản lý giải ngân ',
        key: 'Disbursement',
        onClick: function () {
          location.href = '/customers/disbursement';
        },
      },
      {
        title: 'DebtList',
        label: 'Quản lý thanh toán',
        key: 'DebtList',
        onClick: function () {
          location.href = '/customers/debtList';
        },
      },
      {
        title: 'InterestAndFee',
        label: 'Lịch sử phí và lãi',
        key: 'InterestAndFee',
        onClick: function () {
          location.href = '/customers/interest-and-fee';
        },
      },
      {
        title: 'Refund',
        label: 'Lịch sử hoàn gốc',
        key: 'Refund',
        onClick: function () {
          location.href = '/customers/refund';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'common.siderMenu.reconciliation'} />,
    key: 'reconciliation',
    icon: <ReconciliationOutlined />,
    className: 'd-md-none',

    children: [
      {
        title: 'Đối soát khoản vay',
        label: 'Đối soát khoản vay',
        key: 'ReconciliationLoan',
        onClick: function () {
          location.href = '/reconciliation/loan';
        },
      },
      {
        title: 'Đối soát giải ngân',
        label: 'Đối soát giải ngân',
        key: 'ReconciliationDisbursement',
        onClick: function () {
          location.href = '/reconciliation/disbursement';
        },
      },
      {
        title: 'Đối soát thanh toán',
        label: 'Đối soát thanh toán',
        key: 'ReconciliationPayment',
        onClick: function () {
          location.href = '/reconciliation/payment';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'QL nguồn vốn'} />,
    key: 'capital',
    icon: <MoneyCollectOutlined />,
    children: [
      {
        title: 'Quản lý nguồn vốn',
        label: 'Quản lý nguồn vốn',
        key: 'capital',
        onClick: function () {
          location.href = '/capital/management';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'QL chính sách'} />,
    key: 'policy',
    icon: <DatabaseOutlined />,
    children: [
      {
        title: 'Quản lý chính sách',
        label: 'Quản lý chính sách',
        key: 'policy',
        onClick: function () {
          location.href = '/policy/management';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'QL sản phẩm'} />,
    key: 'product',
    icon: <ShoppingOutlined />,
    children: [
      {
        title: 'Quản lý sản phẩm',
        label: 'Quản lý sản phẩm',
        key: 'product',
        onClick: function () {
          location.href = '/product/management';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'QL đối tác'} />,
    key: 'partner',
    icon: <UsergroupAddOutlined />,
    children: [
      {
        title: 'Quản lý đối tác',
        label: 'Quản lý đối tác',
        key: 'partner',
        onClick: function () {
          location.href = '/partner/management';
        },
      },
    ],
  },
  {
    label: <MenuTitleTranslation translationKey={'common.siderMenu.setting'} />,
    key: 'setting',
    icon: <AiOutlineSetting />,
    // url: '/settings',
    onClick: function () {
      location.href = '/settings';

      return false;
    },
  },
];
