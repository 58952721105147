import React, { useContext, useEffect, useState } from 'react';
import * as S from '../../Disbursement.styles';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';

import * as GS from '@app/styles/GlobalStyle';
import IconImg from '@app/assets/icons/icon-pin-img.svg';

import { DisbursementContext } from '../../Context';
import { updateStateModalDisbursement } from '../../Action';
import { DataDetail, DataTableWaitForDisbursementDetailType } from '../../interface';
import { CreateEditProofDisbursementApi, getDetailDisbursementApi } from '../../Disbursement.api';
import { useNavigate } from 'react-router-dom';
import { UploadImgApi, getImg } from '@app/api/UploadFIle.api';
import { Button, Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { notificationController } from '@app/controllers/notificationController';
import { DeleteOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';
import { formatNumberWithCommas } from '@app/utils/utils';

type Props = {
  data: DataTableWaitForDisbursementDetailType | undefined;
  callApiListDisbursement?: () => void;
};
export default function ModalShowDetailProof(props: Props) {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [dataDetail, setdataDetail] = useState<DataDetail>();
  const [saveImg, setsaveImg] = useState();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  //   const [checkDelete, setcheckDelete] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>(props?.data?.proofKey && props?.data?.proofKey);

  //   function handleDelteImg() {
  //     setcheckDelete(true);
  //     setsaveStringImg('');
  //   }
  const [checkDelete, setcheckDelete] = useState(false);
  useEffect(() => {
    setcheckDelete(false);
  }, [stateDisbursement?.stateModal?.stateModalShowEditDetail]);

  function handleDelteImg() {
    setcheckDelete(true);
    setsaveStringImg('');
  }
  function handleCancelModalCreate() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalShowEditDetail: false }));
    // setcheckDelete(false);
  }

  function handleOkModalCreate() {
    setloading(true);

    if (props?.data) {
      CreateEditProofDisbursementApi(props?.data?.code, {
        proof: saveStringImg,
      })
        .then((res) => {
          if (res?.error) {
            setloading(false);
            // setcheckDelete(false);
            props?.callApiListDisbursement && props?.callApiListDisbursement();

            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalShowEditDetail: false }));
          } else {
            setloading(false);
            props?.callApiListDisbursement && props?.callApiListDisbursement();

            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalShowEditDetail: false }));
            // setcheckDelete(false);
            notificationController.success({ message: `Tạo thành công` });
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          }
        })
        .catch(() => {
          setloading(false);
          dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalShowEditDetail: false }));
          //   setcheckDelete(false);

          notificationController.error({ message: `err API` });
        });
    }
  }

  const callApiDetail = async () => {
    if (props?.data) {
      await getDetailDisbursementApi(props?.data?.code)
        .then((res) => {
          setdataDetail(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const callApiGetImg = async () => {
    if (props?.data) {
      await getImg(props?.data?.keyProof)
        .then((res) => {
          setsaveImg(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  function moveDetailLoan() {
    if (dataDetail) {
      navigate(`/customers/loan/${dataDetail?.loanCode}`);
    }
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;

  const propsUpdate: UploadProps = {
    name: 'file',
    multiple: true,
    listType: 'picture',
    onPreview: onPreview,
    maxCount: 1,

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status == 'removed') {
        setsaveStringImg('');
      }
      if (status !== 'uploading' && status !== 'removed') {
        const formData = new FormData();

        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'TRANSACTION',
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  useEffect(() => {
    if (stateDisbursement?.stateModal?.stateModalShowEditDetail == true && props?.data) {
      callApiDetail();
      callApiGetImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, stateDisbursement?.stateModal?.stateModalShowEditDetail]);

  return (
    <S.ModalCreateProof title="Chứng từ khoản vay" open={stateDisbursement?.stateModal?.stateModalShowEditDetail} onCancel={handleCancelModalCreate} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
        <div className="content">
          <div className="section-img">
            <div className="title-section-img">
              {checkDelete ? null : (
                <div className="d-flex">
                  <img src={IconImg} alt="" />
                  <Tooltip placement="top" title={<span>{props?.data?.keyProof ? props?.data?.keyProof : ''}</span>}>
                    <div className="name-img">{props?.data?.keyProof && props?.data?.keyProof}</div>
                  </Tooltip>
                </div>
              )}
              <div>{checkDelete ? null : <DeleteOutlined onClick={handleDelteImg} />}</div>
            </div>
            {checkDelete ? (
              <div className="box-content-img">
                <div className="save-image">
                  <Dragger {...propsUpdate}>
                    <p className="ant-upload-drag-icon">
                      <IconUploadFile />
                    </p>
                    <p className="ant-upload-text">
                      Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                    </p>
                  </Dragger>
                </div>
              </div>
            ) : (
              <div className="box-content-img">
                <div className="box-img">
                  <img
                    src={
                      saveImg && saveImg
                        ? saveImg
                        : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                    }
                    alt=""
                  />
                </div>
              </div>
            )}
            <GS.StyleFooterModalCreate style={{ borderTop: 'none', marginTop: `${saveStringImg ? '75px' : '0px'}` }}>
              <Button key="back" onClick={handleCancelModalCreate}>
                Huỷ
              </Button>

              <Button className="btn-create-submit" loading={loading} onClick={handleOkModalCreate} key="submit" htmlType="submit" style={{ marginLeft: '10px' }}>
                Xác nhận
              </Button>
            </GS.StyleFooterModalCreate>
          </div>
          <div className="section-infor">
            <div className="title">Chứng từ khoản vay</div>
            <div className="infor-content">
              <div className="box-line">
                <div className="label">Mã giải ngân</div>
                <a className="value color-id">{dataDetail ? dataDetail?.code : ''}</a>
              </div>
              <div className="box-line">
                <div className="label">ID khoản vay</div>
                <a className="value color-id" onClick={moveDetailLoan}>
                  {dataDetail ? dataDetail?.loanCode : ''}
                </a>
              </div>
              <div className="box-line">
                <div className="label">Tên khách hàng</div>
                <div className="value">
                  {/* <UserNameAvatarColumn avatar={Avatar} name={dataDetail ? dataDetail?.customerName : ''}></UserNameAvatarColumn> */}
                  {dataDetail ? dataDetail?.customerName : ''}
                </div>
              </div>
              <div className="box-line">
                <div className="label">Đối tác liên kết</div>
                <div className="value">{dataDetail ? dataDetail?.partner : ''}</div>
              </div>
              <div className="box-line">
                <div className="label">Ngày giải ngân</div>
                <div className="value">{dataDetail?.transDate ? dataDetail?.transDate : '-'}</div>
              </div>
              <div className="box-line">
                <div className="label">Số tiền</div>
                <div className="value">{dataDetail ? formatNumberWithCommas(dataDetail?.amount) : ''}</div>
              </div>
              <div className="box-line">
                <div className="label">Nguồn giải ngân</div>
                <div className="value">{dataDetail ? dataDetail?.fund : '-'}</div>
              </div>
              <div className="box-line">
                <div className="label">TK nhận giải ngân</div>
                {dataDetail?.accountReceiveMoney && (
                  <Tooltip placement="top" title={<span>{dataDetail ? dataDetail?.accountReceiveMoney : null}</span>}>
                    <div className="value value-special">{dataDetail ? dataDetail?.accountReceiveMoney : '-'}</div>
                  </Tooltip>
                )}
              </div>
              <div className="box-line">
                <div className="label">Người thực hiện</div>
                <div className="value">{dataDetail?.executor ? dataDetail?.executor : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      </GS.WrapperOverrideInput>
    </S.ModalCreateProof>
  );
}
