import { Button } from 'antd';
import React from 'react';
import * as GS from '@app/styles/GlobalStyle';

type Props = {
  openModals: boolean;
  handleCancel?: (e: any) => void;
  handleOk?: (e: any) => void;
  label?: string;
};

export default function ModalConfirm(props: Props) {
  return (
    <GS.StyleModalConfirm title="Basic Modal" footer={null} open={props?.openModals} onCancel={props?.handleCancel}>
      <div>
        <div className="text-confirm">{props?.label} </div>
        <div className="btn-change">
          <Button className="btn-cancel" onClick={props?.handleCancel}>
            Huỷ
          </Button>
          <Button onClick={props?.handleOk} className="btn-yes">
            Xác nhận
          </Button>
        </div>
      </div>
    </GS.StyleModalConfirm>
  );
}
