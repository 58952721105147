import React from 'react';
// import AvatarVerify from '@app/assets/icons/AvatarVerify.svg';
// import CCCDVerify from '@app/assets/icons/CCCDVerify.svg';

type Props = {
  data?: any;
};

export default function TabDataVerify({}: Props) {
  return (
    <div className="content-verify">
      {/* <div className="title-verify-face">Xác minh khuôn mặt</div>
      <div className="image-verify-face">
        <div className="box-img">
          <img src={AvatarVerify} alt="" />
        </div>
        <div className="box-img">
          <img src={AvatarVerify} alt="" />
        </div>
      </div>
      <div className="title-verify-face">CMND/CCCD</div>
      <div className="CCCD-verify-face">
        <div className="box-img">
          <img src={CCCDVerify} alt="" />
        </div>
        <div className="box-img">
          <img src={CCCDVerify} alt="" />
        </div>
      </div> */}
      Không có dữ liệu
    </div>
  );
}
