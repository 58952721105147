import React from 'react';
import TableDetailProduct from './TableDetailProduct';

export default function SectionTableProduct() {
  // const { statePartner, dispatchPartner } = useContext(PartnerContext);
  // const [dataList, setdataList] = useState<any>();
  // const { uuid } = useParams();

  // function callListTableProductInDetailPartner() {
  //   if (uuid) {
  //     getListTableProductInDetailPartner(uuid)
  //       .then((res) => {
  //         setdataList(res);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         setdataList(undefined);
  //       });
  //   }
  // }

  // useEffect(() => {
  //   if (uuid) {
  //     callListTableProductInDetailPartner();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [uuid]);

  return (
    <div className="section-user-infor">
      <TableDetailProduct></TableDetailProduct>
    </div>
  );
}
