import { Divider } from 'antd';
import React from 'react';
import * as S from './TableColumnTime.style';

type Props = {
  value: string | any;
};

export default function TableColumnTime(props: Props) {
  return (
    <S.StyleColumnTime>
      {props?.value ? props.value.split(' ')[0] : ''}
      {props?.value ? <Divider type="vertical" /> : ''}
      {props?.value ? props.value.split(' ')[1] : ''}
    </S.StyleColumnTime>
  );
}
