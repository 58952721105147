import React, { useContext, useState } from 'react';
import { Form, Input, DatePicker, DatePickerProps } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../InterestAndFee.style';
const { RangePicker } = DatePicker;
import { AiOutlineExport, AiOutlineSearch } from 'react-icons/ai';
import { RangePickerProps } from 'antd/lib/date-picker';
import { updateFiltersInterest } from '../Action';
import { InterestAndFeeListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { notificationController } from '@app/controllers/notificationController';
import { ExportExcelInterest } from '../InterestAndFee.api';

export const FormSearchInterest = () => {
  const { stateInterestAndFeeList, dispatchInterestAndFeeList } = useContext(InterestAndFeeListContext);
  const [checkLoading, setcheckLoading] = useState(false);

  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchInterestAndFeeList(updateFiltersInterest({ ...stateInterestAndFeeList?.filtersInterest, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchInterestAndFeeList(updateFiltersInterest({ ...stateInterestAndFeeList?.filtersInterest, fromDate: '', toDate: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchInterestAndFeeList(updateFiltersInterest({ ...stateInterestAndFeeList?.filtersInterest, key: e.target.value, pageIndex: 1 }));
    }
  }

  function ExportExcelFunc() {
    setcheckLoading(true);
    ExportExcelInterest(
      queryStringParams({
        key: stateInterestAndFeeList?.filtersFee?.key,
        fromDate: stateInterestAndFeeList?.filtersFee?.fromDate,
        toDate: stateInterestAndFeeList?.filtersFee?.toDate,
      }),
    )
      .then((res) => {
        if (!res?.error) {
          setcheckLoading(false);
          notificationController.success({ message: `Xuất file thành công , vui lòng check mail` });
        } else {
          setcheckLoading(false);
        }
      })
      .catch(() => {
        setcheckLoading(false);
        console.log('err');
      });
  }
  // function onChangeSelect(e: string) {
  //   dispatchInterestAndFeeList(updateFiltersInterestAndFeeList({ ...stateInterestAndFeeList?.filters, transactionLogType: e, pageIndex: 1 }));
  // }
  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <div className="d-flex">
            <Form.Item name={'keyword'}>
              <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID giao dịch, ID khoản vay, Khách hàng '} />
            </Form.Item>

            <div className={'filter-item'} style={{ marginLeft: '10px' }}>
              <Form.Item name={'statuses'}>
                <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
              </Form.Item>
            </div>
          </div>
          <GS.StyleBtnExcel style={{ marginTop: '5px' }} loading={checkLoading} onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
            Xuất Excel
          </GS.StyleBtnExcel>
          {/* <div className={'filter-item'} style={{ marginLeft: '10px' }}>
            <Form.Item name={'statuses'}>
              <Select onChange={onChangeSelect} placeholder={'Loại'} size={'small'} dropdownMatchSelectWidth={200}>
                <Select.Option value={''}>Tất cả</Select.Option>
                <Select.Option value={'INTEREST'}>Lãi</Select.Option>
                <Select.Option value={'FEE'}>Phí</Select.Option>
              </Select>
            </Form.Item>
          </div> */}
        </div>
      </S2.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
