import React, { useEffect } from 'react';
import { PartnerProvider } from '../Context';
import ModalEditPartner from '../Component/ModalEditPartner';
import * as S from '../Partner.style';
import * as GS from '@app/styles/GlobalStyle';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import TableDetailPartnerProduct from './TablePartnerProduct/TablePolicyDetail';
import { useParams } from 'react-router-dom';

type Props = {
  data?: any;
};

export default function DetailPartnerProduct({}: Props) {
  const dispatch = useAppDispatch();
  const { productName } = useParams();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý đối tác',
          url: '/partner/management',
        },

        {
          name: productName && productName,
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`${productName}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <GS.WrapperOverrideInput>
      <PartnerProvider>
        <S.ContainerDetailPartner>
          <TableDetailPartnerProduct></TableDetailPartnerProduct>
        </S.ContainerDetailPartner>
        <ModalEditPartner></ModalEditPartner>
      </PartnerProvider>
    </GS.WrapperOverrideInput>
  );
}
