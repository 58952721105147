import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { RequestLoanContext } from '../Context';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../RequestLoanManagement.styles';
import { LoanListProvider } from '../../Loan/Context';
import InforRequestLoanSection from '../ComponentLoan/InforRequestLoanSection';

export default function DetailRequestLoan() {
  const { dispatchRequestLoan } = useContext(RequestLoanContext);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý yêu cầu khoản vay ',
          url: '/customers/management',
        },

        {
          name: 'Ronald Richards',
        },
      ]),
    );

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle('Ronald Richards'));
    };
  }, [dispatchRequestLoan, dispatch]);

  return (
    <LoanListProvider>
      <GS.WrapperOverrideInput>
        <S.DflexEnd>
          <S.BtnReject>Từ chối </S.BtnReject>
          <S.BtnApproved>Phê Duyệt</S.BtnApproved>
        </S.DflexEnd>
        <InforRequestLoanSection></InforRequestLoanSection>
      </GS.WrapperOverrideInput>
    </LoanListProvider>
  );
}
