import { Button } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Capital.styles';
import * as GS from '@app/styles/GlobalStyle';
import SectionTabsDetail from './Section/SectionTabsDetail';
// import ModalEditDetail from './Section/ModalEditDetail';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { useParams } from 'react-router-dom';
import { getCapitalDetailApi } from '../Capital.api';
import { ResponseApiDetail } from '../interface';
import SectionCapitalInfor from './Section/SectionInforUser';
import { updateModals } from '../Action';
import { CapitalContext, CapitalProvider } from '../Context';
import ModalEditCapital from '../Component/ModalEdit';

type Props = {
  data?: any;
};

function DetailCapital({}: Props) {
  const [dataDetail, setDataDetail] = useState<ResponseApiDetail | null>();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);

  const callApiDetailCapital = async () => {
    getCapitalDetailApi(id)
      .then((res: ResponseApiDetail) => {
        if (res?.error) {
          setDataDetail(null);
        } else {
          res && setDataDetail(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setDataDetail(null);
      });
  };

  function openEdit() {
    dispatchCapital(updateModals({ ...stateCapital.modals, modalEdit: true }));
  }

  useEffect(() => {
    callApiDetailCapital();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý nguồn vốn',
          url: '/capital/management',
        },
        {
          name: 'Danh sách',
        },
        {
          name: dataDetail ? dataDetail?.name : '',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`${dataDetail ? dataDetail?.name : ''}`));
    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataDetail]);
  return (
    <GS.WrapperOverrideInput>
      <S.ContainerDetailCapital>
        {/* <div className="title-name">{dataDetail?.user?.fullName}</div> */}
        <div className="content-body">
          <div className="section-btn-action">
            <div className="box-btn">
              <Button onClick={openEdit} className="btn-edit">
                Sửa
              </Button>
            </div>
          </div>
          <SectionCapitalInfor dataDetail={dataDetail ? dataDetail : null}></SectionCapitalInfor>
          <SectionTabsDetail dataDetail={dataDetail && dataDetail}></SectionTabsDetail>
        </div>
        {<ModalEditCapital callApiDetailCapital={callApiDetailCapital} dataDetail={dataDetail ? dataDetail : null}></ModalEditCapital>}
      </S.ContainerDetailCapital>
    </GS.WrapperOverrideInput>
  );
}

export default function BoxDetailCapital() {
  return (
    <CapitalProvider>
      <DetailCapital></DetailCapital>
    </CapitalProvider>
  );
}
