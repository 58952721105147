import { FilterType, ModalsType, ReducerType } from './interface';

export const UPDATE_FILTERS_CAPITAL = 'UPDATE_FILTERS_CAPITAL';
export const UPDATE_FILTERS_CAPITAL_WAIT_FOR_APPROVE = 'UPDATE_FILTERS_CAPITAL_WAIT_FOR_APPROVE';
export const UPDATE_MODAL_CAPITAL = 'UPDATE_MODAL_CAPITAL';

export const updateFiltersCapital = (filters: FilterType): ReducerType => {
  return {
    type: UPDATE_FILTERS_CAPITAL,
    filters: filters,
  };
};

export const updateModals = (modals: ModalsType): ReducerType => {
  return {
    type: UPDATE_MODAL_CAPITAL,
    modals: modals,
  };
};
