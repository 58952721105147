import React from 'react';
import { initPolicy, usePolicyReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const PolicyContext = React.createContext({ ...initPolicy });
export const PolicyProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [statePolicy, dispatchPolicy] = usePolicyReducer();

  const wrap = {
    statePolicy,
    dispatchPolicy,
  };

  return <PolicyContext.Provider value={wrap}>{children}</PolicyContext.Provider>;
};
