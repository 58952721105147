import React from 'react';
import * as S from './ActionColumn.style';
import IconActionRequestDisable from '@app/assets/icons/icon-action-request-disable.svg';
import IconActionRequestAvailable from '@app/assets/icons/icon-action-request-available.svg';
import { StatusRefund, TypeRefund } from '@app/interfaces/interfaces';
type Props = {
  actionWatch?: boolean;
  status?: StatusRefund;
  handleCollect?: () => void;
  typeRefund: TypeRefund;
};

export default function ActionColumnRefund(props: Props) {
  return (
    <S.StyleColumnAction>
      {props?.typeRefund == 'AFTER_DISBURSED' ? (
        <S.PopoverStyle
          overlayInnerStyle={{ backgroundColor: '#212830', borderRadius: '0px', minWidth: '100px' }}
          placement="bottomRight"
          content={
            <S.PopoverStyle>
              {props.actionWatch ? (
                <p onClick={props.handleCollect} style={{ color: '#85A5FF', cursor: 'pointer' }} className="text-watch style-text-action-watch">
                  {props?.status == 'COLLECTED_MONEY' ? 'Chưa thu' : 'Đã thu'}
                </p>
              ) : null}
            </S.PopoverStyle>
          }
          trigger="click"
        >
          <div className="box-action-available">
            <img src={IconActionRequestAvailable} alt="" />
          </div>
        </S.PopoverStyle>
      ) : (
        <div className="box-action">
          <img src={IconActionRequestDisable} alt="" />
        </div>
      )}
    </S.StyleColumnAction>
  );
}
