import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../DebtList.styles';

import { ColumnsType } from 'antd/lib/table';
import { DataTypeTable } from '../interface';
import Avatar from '@app/assets/icons/avatar.svg';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import { FormSearchDebtList } from './FormSearchLoan';

import { DataResponseDebt } from '../interface';
import { DebtListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { AprrovalStatusPaymentTransaction, getDebtListApi } from '../DebtList.api';
import { updateFiltersDebtList, updateModals } from '../Action';
import ActionColumn from '@app/components/common/ActionColumn/ActionColumn';
import ModalDetailPayment from './ModalDetailPayment';
import { useNavigate } from 'react-router-dom';
import ModalConfirm from '@app/components/ModalConfirm/ModalConfirm';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';

export default function TableDebtList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataResponseDebt>();
  const navigate = useNavigate();
  const [openModalHandleBF, setopenModalHandleBF] = useState(false);
  const [saveRecordRow, setsaveRecordRow] = useState<DataTypeTable>();
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);
  const [saveIDPayment, setsaveIDPayment] = useState<string>();
  function handleCancel() {
    setopenModalHandleBF(false);
  }

  const callApiListDisbursement = async () => {
    setcheckLoading(true);
    await getDebtListApi(queryStringParams(stateDebtList?.filtersDebtList))
      .then((res) => {
        setData(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  function handleWatch(record: DataTypeTable) {
    setsaveRecordRow(record);
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalDetailPayment: true }));
  }

  function handdleOpenConfirmActionBF(record: DataTypeTable) {
    setopenModalHandleBF(true);
    setsaveIDPayment(record?.code);
  }

  function handleOkChangeStatus() {
    setcheckLoading(true);
    setopenModalHandleBF(false);
    if (saveIDPayment) {
      AprrovalStatusPaymentTransaction(saveIDPayment, {
        bifiinStatus: 'DONE',
      })
        .then((res) => {
          if (res?.error) {
            setcheckLoading(false);
          } else {
            notificationController.success({ message: `Xác nhận thành công` });
            callApiListDisbursement();
            setcheckLoading(false);
          }
        })
        .catch((err) => {
          setcheckLoading(false);
          notificationController.error({ message: _.get(err, `Approval fail`, `${err} err API`) });
        });
    }
  }
  function moveDetailLoan(record: DataTypeTable) {
    navigate(`/customers/loan/${record?.loanCode}`);
  }

  useEffect(() => {
    callApiListDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDebtList?.filtersDebtList]);

  const columnsConfig: ColumnsType<DataTypeTable> = [
    {
      title: 'Mã thanh toán',
      key: 'code',
      fixed: 'left',

      dataIndex: 'code',
      render: (value: string) => <div className="text-paymentcode">{value ? value : ''}</div>,
    },
    {
      title: 'ID khoản vay',
      key: 'loanCode',
      fixed: 'left',
      width: '250px',
      render: (record: DataTypeTable) => <a onClick={() => moveDetailLoan(record)}>{record ? record?.loanCode : ''}</a>,
    },
    {
      width: '170px',
      title: 'Tên khách hàng',
      key: 'customerName',
      dataIndex: 'customerName',

      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      align: 'right',
      width: '150px',
      title: 'Số tiền nhận',
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      align: 'right',
      width: '150px',
      title: 'Thu gốc',
      key: 'principalPaidAmount',
      dataIndex: 'principalPaidAmount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      align: 'right',
      width: '150px',
      title: 'Thu lãi',
      key: 'interestPaidAmount',
      dataIndex: 'interestPaidAmount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      align: 'right',
      width: '150px',
      title: 'Thu phí',
      key: 'feePaidAmount',
      dataIndex: 'feePaidAmount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      width: '180px',
      title: 'Đối tác liên kết',
      key: 'partnerName',
      dataIndex: 'partnerName',
    },
    {
      width: '250px',
      title: 'Ngày Thanh Toán',
      key: 'paymentDate',
      dataIndex: 'paymentDate',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },
    {
      width: '244px',
      title: 'Người thực hiện',
      key: 'executioner',
      dataIndex: 'executioner',
      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      width: '148px',
      title: 'Chứng từ',
      key: 'proofOfPayment',
      dataIndex: 'proofOfPayment',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '185px',
      title: 'Trạng thái đối tác',
      key: 'partnerStatus',
      render: (value: DataTypeTable) => (value.partnerStatus == 'DONE' ? <StatusesText status={value && value?.partnerStatus} context="PAYMENT_TRANSACTION_PARTNER"></StatusesText> : ''),
    },
    {
      width: '155px',
      title: 'Trạng thái BF',
      key: 'status',
      render: (value: DataTypeTable) => (value.status == 'DONE' ? <StatusesText status={value && value?.status} context="PAYMENT_TRANSACTION_BF"></StatusesText> : ''),
    },
    {
      width: '89px',
      title: 'Hành động',
      key: 'action',
      render: (record: DataTypeTable) => (
        <ActionColumn
          handleWatch={() => handleWatch(record)}
          actionWatch={true}
          handleBF={() => handdleOpenConfirmActionBF(record)}
          ActionBF={record?.partnerStatus == 'DONE' ? true : false}
          status={'WAIT_FOR_APPROVE'}
        ></ActionColumn>
      ),
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxDebt>
            <FormSearchDebtList></FormSearchDebtList>
            <Table
              scroll={{ x: 2430 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchDebtList(updateFiltersDebtList({ ...stateDebtList?.filtersDebtList, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxDebt>
        </div>
        {saveRecordRow && <ModalDetailPayment RecordRow={saveRecordRow && saveRecordRow}></ModalDetailPayment>}
      </GS.ContainerTable>
      <ModalConfirm
        openModals={openModalHandleBF}
        handleCancel={handleCancel}
        handleOk={handleOkChangeStatus}
        label={'Bạn có chắc chắn muốn thay đổi trạng thái của giao dịch này không?'}
      ></ModalConfirm>
    </GS.WrapperOverrideInput>
  );
}
