import { FiltersPolicyType, ModalsPolicyType, ReducerType } from './interface';

export const UPDATE_FILTERS_POLICY = 'UPDATE_FILTERS_POLICY';
export const UPDATE_MODALS_POLICY = 'UPDATE_MODALS_POLICY';

export const updateFiltersPolicy = (filtersPolicy: FiltersPolicyType): ReducerType => {
  return {
    type: UPDATE_FILTERS_POLICY,
    filtersPolicy: filtersPolicy,
  };
};

export const updateModalsPolicy = (modalsPolicy: ModalsPolicyType): ReducerType => {
  return {
    type: UPDATE_MODALS_POLICY,
    modalsPolicy: modalsPolicy,
  };
};
