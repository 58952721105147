import React from 'react';
import * as S from './CreditScoring.style';
import { CreditScoringTypo, NumericRange, CreateArrayWithLengthX, CreditScoringDefinitionEnum } from '@app/constants/creditScoring';
import * as GS from '@app/styles/GlobalStyle';

const ScoringLevel = (number: number): CreditScoringTypo => {
  switch (true) {
    case number <= 20:
      return 'lowest';
    case number > 20 && number <= 40:
      return 'low';
    case number > 40 && number <= 60:
      return 'medium';
    case number > 60 && number <= 80:
      return 'high';
    case number > 80 && number <= 100:
      return 'highest';
    default:
      return 'low';
  }
};

export interface CreditScoringProps {
  score: NumericRange<CreateArrayWithLengthX<0>, 100>;
}

export const CreditScoring: React.FC<CreditScoringProps> = ({ score: score }) => {
  const definition = CreditScoringDefinitionEnum[ScoringLevel(score)];

  return (
    <GS.ContentTableCenter>
      <S.Tag color={definition?.bg} txt_color={definition?.text ?? ''}>
        {score}
      </S.Tag>
    </GS.ContentTableCenter>
  );
};
