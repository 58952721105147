import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../Partner.style';
import { AiOutlineSearch } from 'react-icons/ai';
import { PartnerContext } from '../Context';
import { updateFiltersPartner, updateModalsPartner } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';

export const FormSearchPartner = () => {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchPartner(updateFiltersPartner({ ...statePartner?.filtersPartner, name: e.target.value, pageIndex: 1 }));
    }
  }

  function handleOpenModalCreate() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalCreate: true }));
  }

  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <Form.Item name={'keyword'}>
            <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Tìm kiếm đối tác'} />
          </Form.Item>
          <div className="margin-top-10">
            <BtnCreateStyle onClick={handleOpenModalCreate} label="Thêm đối tác"></BtnCreateStyle>
          </div>
        </div>
      </S2.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
