import React from 'react';
import { useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';

interface SiderContentProps {
  setCollapsed?: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce((result: SidebarNavigationItem[], current) => result.concat(current.children && current.children.length > 0 ? current.children : current), []);

const SiderMenu: React.FC<SiderContentProps> = ({}) => {
  const location = useLocation();

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === location.pathname);
  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu = sidebarNavigation.find(({ children }) => children?.some(({ url }) => url === location.pathname));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];
  // items={sidebarNavigation}

  return (
    <S.Menu mode="inline" triggerSubMenuAction={'hover'} defaultSelectedKeys={defaultSelectedKeys} defaultOpenKeys={defaultOpenKeys} items={sidebarNavigation}>
      {/*{sidebarNavigation.map((nav) => {*/}
      {/*  if (nav.children && nav.children.length) {*/}
      {/*    return (*/}
      {/*      <Menu.SubMenu key={nav.key} title={t(nav.title ?? '')} icon={nav.icon}>*/}
      {/*        /!* popupClassName="d-none"*!/*/}
      {/*        {nav.children.map((childNav) => {*/}
      {/*          return (*/}
      {/*            <Menu.Item key={`${nav.key}-${childNav.key}`} title="">*/}
      {/*              <Link to={childNav.url || ''}>{t(childNav?.title ?? '')}</Link>*/}
      {/*            </Menu.Item>*/}
      {/*          );*/}
      {/*        })}*/}
      {/*      </Menu.SubMenu>*/}
      {/*    );*/}
      {/*  }*/}

      {/*  return (*/}
      {/*    <Menu.Item key={nav.key} title="" icon={nav.icon}>*/}
      {/*      <Link to={nav.url || ''}>{t(nav.title ?? '')}</Link>*/}
      {/*    </Menu.Item>*/}
      {/*  );*/}
      {/*})}*/}
    </S.Menu>
  );
};

export default SiderMenu;
