import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
// import { FormSearchLoan } from './FormSearchLoan';
import { DataResponseType, DataTypeTableLoan } from '../interface';
import { getLoanListCapitalApi } from '../Loan.api';
import { LoanListContext } from '../Context';

import { updateFiltersLoanList } from '../Action';
import { columns } from './ColumnConfig';

type Props = {
  idCapital?: string;
};

export default function TableLoanSectionDetail(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataLoanList, setDataLoanList] = useState<DataTypeTableLoan[]>();
  const [dataResponse, setDataResponse] = useState<DataResponseType>();

  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [checkLoading, setcheckLoading] = useState(false);

  const callApiGetLoanList = async () => {
    setcheckLoading(true);
    await getLoanListCapitalApi(props?.idCapital)
      .then((res) => {
        setDataResponse(res);
        setcheckLoading(false);

        setDataLoanList(res?.data);
      })
      .catch((error) => {
        setcheckLoading(false);
        console.log(error);
        setDataLoanList(undefined);
      });
  };
  useEffect(() => {
    if (props?.idCapital) {
      callApiGetLoanList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.idCapital]);
  return (
    <GS.WrapperOverrideInput>
      <div className="box-content">
        {/* <FormSearchLoan checkSection={true}></FormSearchLoan> */}
        <Table
          style={{ paddingTop: '20px' }}
          scroll={{ x: 3250 }}
          rowSelection={{ type: 'checkbox' }}
          dataSource={dataLoanList && dataLoanList?.length > 0 ? dataLoanList : []}
          loading={checkLoading}
          rowKey="id"
          columns={columns}
          sticky={true}
          pagination={{
            current: dataResponse?.pageIndex,
            pageSize: dataResponse?.pageSize,
            total: dataResponse?.total,
            onChange: (page: number, pageSize: number) => {
              dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, pageIndex: page, pageSize: pageSize }));
            },
          }}
        />
      </div>
    </GS.WrapperOverrideInput>
  );
}
