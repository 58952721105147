import { DataResponseCustomerType, FilterCustomerType, modalsType, PaginationMetaTye, ReducerType } from '@app/modules/Backline/Customers/pages/Management/interfaces';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_DATA_CUSTOMERS = 'UPDATE_DATA_CUSTOMERS';
export const UPDATE_FILTERS_CUSTOMERS = 'UPDATE_FILTERS_CUSTOMERS';
export const UPDATE_PAGINATION_META_CUSTOMER = 'UPDATE_PAGINATION_META_CUSTOMER';
export const UPDATE_MODALS = 'UPDATE_MODALS';

export const updateForm = (form: any): ReducerType => {
  return {
    type: UPDATE_FORM,
    form: form,
  };
};

export const updateDataCustomers = (dataCustomer: DataResponseCustomerType): ReducerType => {
  return {
    type: UPDATE_DATA_CUSTOMERS,
    dataCustomer: dataCustomer,
  };
};

export const updateModals = (modals: modalsType): ReducerType => {
  return {
    type: UPDATE_MODALS,
    modals: modals,
  };
};

export const updateFiltersCustomer = (filtersCustomer: FilterCustomerType): ReducerType => {
  return {
    type: UPDATE_FILTERS_CUSTOMERS,
    filtersCustomer: filtersCustomer,
  };
};

export const updatePaginationMetaCustomer = (metaCustomerData: PaginationMetaTye): ReducerType => {
  return {
    type: UPDATE_PAGINATION_META_CUSTOMER,
    metaCustomerData: metaCustomerData,
  };
};
