import { Dispatch, useReducer } from 'react';
import { UPDATE_DATA_REQUESTLOAN, UPDATE_FILTERS_ACTION_REJECT_REQUESTLOAN, UPDATE_FILTERS_REQUESTLOAN, UPDATE_FILTERS_VOTES, UPDATE_FORM, UPDATE_MODALS_REQUESTLOAN } from './Action';
import { ReducerType } from './interface';

export const initRequestLoan = {
  stateRequestLoan: {
    form: null,
    filters: {
      status: '',
      pageSize: 20,
      pageIndex: 1,
      text: '',
      createdDate: '',
      createdFromDate: '',
      createdToDate: '',
    },
    filterRequestReject: {
      code: '',
      reason: '',
      description: '',
    },
    filterVotes: {
      status: '',
      pageSize: 20,
      pageIndex: 1,
      key: '',
      fromDate: '',
      toDate: '',
    },
    modals: {
      isModalOpenModalReject: false,
      isModalOpenModalRejectVotes: false,

      modalCreateLoan: false,
    },
    touching: new Date(),
    data: {
      customer: {
        collection: [],
        meta: {
          pageSize: 1,
          current: 1,
          total: 0,
        },
      },
    },
  },
  dispatchRequestLoan: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        form: action.form,
      };
    case UPDATE_DATA_REQUESTLOAN:
      return {
        ...state,
        data: {
          ...state.data,
          customer: action.dataRequestLoan,
        },
      };
    case UPDATE_FILTERS_REQUESTLOAN:
      return {
        ...state,
        filters: action.filtersRequestLoan,
      };
    case UPDATE_FILTERS_VOTES:
      return {
        ...state,
        filterVotes: action.filterVotes,
      };
    case UPDATE_MODALS_REQUESTLOAN:
      return {
        ...state,
        modals: action.modals,
      };
    case UPDATE_FILTERS_ACTION_REJECT_REQUESTLOAN:
      return {
        ...state,
        filterRequestReject: action.filterRequestReject,
      };
    // case UPDATE_PAGINATION_META_REQUESTLOAN:
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       customer: {
    //         ...state.data.customer,
    //         meta: {
    //           ...state.data.customer.meta,
    //           ...action.metaRequestLoanData,
    //         },
    //       },
    //     },
    //   };
    // case UPDATE_FORCE_DATA_LISTING:
    //   return {
    //     ...state,
    //     touching: new Date(),
    //   };
    default:
      return {
        ...state,
      };
  }
};

export const useRequestLoanReducer = () => {
  return useReducer(reducer, initRequestLoan.stateRequestLoan);
};
