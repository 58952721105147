import React, { useContext, useState } from 'react';
import { Form, Select, Button, DatePickerProps, DatePicker } from 'antd';
import * as GS from '@app/styles/GlobalStyle';

import * as S from '../../../Management/CustomerManagement.styles';
import * as S2 from '../DebtList.styles';

import { AiOutlineExport } from 'react-icons/ai';
import ModalCreateDebt from './ModalCreateDebt';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { DebtListContext } from '../Context';
import { updateFiltersDebtListWaitForApprove, updateModals } from '../Action';

export const FormSearchTableWaitForApproval = () => {
  const [isModalOpenModalCreate, setIsModalOpenModalCreate] = useState(false);
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);

  const showModalCreate = () => {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalCreate: true }));
  };

  const handleCancelModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  const handleOkModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  const datafake = [
    ['velacorp1', 'velacorp2', 'velacorp3'],
    ['ubox1', 'ubox2', 'ubox3'],
    ['hanoi1', 'hanoi2', 'hanoi3'],
  ];

  function ExportExcelFunc() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    datafake.forEach(function (infoArray, index) {
      const dataString = infoArray.join(',');
      csvContent += index < datafake.length ? dataString + '\n' : dataString;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Ubox_Synv.fintech.csv');
    document.body.appendChild(link);
    link.click();
  }

  const onChangeTime: DatePickerProps['onChange'] = (date, dateString) => {
    if (dateString) {
      dispatchDebtList(updateFiltersDebtListWaitForApprove({ ...stateDebtList?.filtersWaitForApprove, createdDate: dateString, pageIndex: 1 }));
    } else {
      dispatchDebtList(updateFiltersDebtListWaitForApprove({ ...stateDebtList?.filtersWaitForApprove, createdDate: '', pageIndex: 1 }));
    }
  };

  function onChangeSelectStatus(e: string) {
    dispatchDebtList(updateFiltersDebtListWaitForApprove({ ...stateDebtList?.filtersWaitForApprove, status: e, pageIndex: 1 }));
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd>
            <S2.Dflex>
              <div className={'filter-item'}>
                <Form.Item name={'createdDate'}>
                  <DatePicker placeholder={'Ngày thanh toán'} format="DD-MM-YYYY" onChange={onChangeTime} />
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectStatus} className="style-select-status" placeholder={'Trạng Thái BF'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>
                    <Select.Option value={'WAIT'}>Chờ duyệt</Select.Option>
                    <Select.Option value={'VERIFIED'}>Xác nhận</Select.Option>
                    <Select.Option value={'REJECTED'}>Từ chối</Select.Option>
                  </Select>
                </Form.Item>
              </div>

              {/* <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select className="style-select-partner" placeholder={'Đối tác liên kết'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>

                    <Select.Option value={'Gau'}>Gấu</Select.Option>
                    <Select.Option value={'Vin'}>Vin</Select.Option>
                  </Select>
                </Form.Item>
              </div> */}
            </S2.Dflex>
            <div style={{ display: 'flex' }}>
              <Form.Item>
                <Button onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
                  Xuất Excel
                </Button>
              </Form.Item>
              <Form.Item>
                <BtnCreateStyle onClick={showModalCreate} label={'Phiếu thu nợ'}></BtnCreateStyle>
              </Form.Item>
            </div>
          </S2.DflexStartEnd>
          <ModalCreateDebt isModalOpenModalCreate={isModalOpenModalCreate} handleOkModalCreate={handleOkModalCreate} handleCancelModalCreate={handleCancelModalCreate}></ModalCreateDebt>
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
