export type CreateArrayWithLengthX<LENGTH extends number, ACC extends unknown[] = []> = ACC['length'] extends LENGTH ? ACC : CreateArrayWithLengthX<LENGTH, [...ACC, 1]>;

export type NumericRange<START_ARR extends number[], END extends number, ACC extends number = never> = START_ARR['length'] extends END
  ? ACC | END
  : NumericRange<[...START_ARR, 1], END, ACC | START_ARR['length']>;

export type RGB = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}` | string;
export type CreditScoringTypo = 'lowest' | 'low' | 'medium' | 'high' | 'highest';
export type CreditScoringEnumType = {
  [key in CreditScoringTypo]?: {
    bg: RGB;
    text: HEX | 'string';
  };
};

export const CreditScoringDefinitionEnum: CreditScoringEnumType = {
  lowest: {
    bg: 'rgba(255, 255, 255, 0.12)',
    text: '#D9D9D9',
  },
  low: {
    bg: 'rgba(255, 57, 57, 0.12)',
    text: '#F5222D',
  },
  medium: {
    bg: 'rgba(250, 140, 22, 0.12)',
    text: '#FA8C16',
  },
  high: {
    bg: 'rgba(24, 144, 255, 0.12)',
    text: '#1890FF',
  },
  highest: {
    bg: 'rgba(82, 196, 26, 0.12)',
    text: '#52C41A',
  },
};

export const OptionLabels = [
  { label: 'Rất cao', value: 'highest' },
  { label: 'Cao', value: 'high' },
  { label: 'Trung bình', value: 'medium' },
  { label: 'Thấp', value: 'low' },
  { label: 'Rất thấp', value: 'lowest' },
];
