import React from 'react';
import { initInterestAndFeeList, useInterestAndFeeListReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const InterestAndFeeListContext = React.createContext({ ...initInterestAndFeeList });
export const InterestAndFeeListProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateInterestAndFeeList, dispatchInterestAndFeeList] = useInterestAndFeeListReducer();

  const wrap = {
    stateInterestAndFeeList,
    dispatchInterestAndFeeList,
  };

  return <InterestAndFeeListContext.Provider value={wrap}>{children}</InterestAndFeeListContext.Provider>;
};
