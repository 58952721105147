import React from 'react';
import * as S from '../RequestLoanManagement.styles';

export default function InforRequestLoanSection() {
  return (
    <S.InforRequestLoan>
      <div className="title-inforRequestLoan">Thông tin đơn đề nghị vay</div>
    </S.InforRequestLoan>
  );
}
