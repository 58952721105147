import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { Modal, Popover, Tabs } from 'antd';

// export const Loan = styled.div`
// `;
export const Menu = styled.div`
  display: flex;
`;
export const DivNamePhone = styled.div`
  margin-top: -25px;
  z-index: 10;
  position: absolute;
  text-align: end;
  top: 60px;
  right: 15px;
`;

export const ModalCreateLoan = styled(Modal)`
  .ant-col.ant-form-item-label {
    display: none;
  }
  .ant-select-selector {
    border: 1px solid var(--character-stroke, #3c4450) !important;
  }
  input {
    border-radius: 2px;
    border: 1px solid var(--character-stroke, #3c4450);
    background: var(--character-input, #272d35);
    padding: 4px 12px;
    font-size: 14px;
  }
  input#basic_loanValueRatio {
    border-radius: 2px;
    border: 1px solid var(--character-stroke, #3c4450);
    background: var(--character-input, #272d35);
    padding: 4px 6px;
    font-size: 14px;
  }
  .box-content-create-loan {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #3c4450;
    padding-bottom: 32px;
    .content-left {
      padding: 24px 32px;
      height: 750px;
      overflow: auto;
      width: 50%;
      border-right: 1px solid #3c4450;
      .box-form {
        .label {
          color: #bfbfbf;
          font-size: 14px;
          line-height: 22px;
          span {
            color: #f5222d;
          }
        }
      }
    }
    .content-right {
      padding: 24px 32px;
      width: 50%;
      .label {
        color: #bfbfbf;
        font-size: 14px;
        line-height: 22px;
        span {
          color: #f5222d;
        }
      }
      .section-total-money-borrow {
        margin-top: 20px;
        .item {
          margin-top: 15px;
          justify-content: space-between;
          display: flex;
          .label {
            color: var(--neutral-6, #bfbfbf);
            font-size: 14px;
            font-family: Be Vietnam Pro;
            line-height: 22px;
          }
          .value {
            color: var(--neutral-3, #f5f5f5);
            font-size: 14px;
            font-family: Be Vietnam Pro;
            font-weight: 700;
            line-height: 22px;
            text-align: right;
          }
        }
      }
    }
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 900px;
    background: #1a1f25;
    border-radius: 4px;
  }

  .ant-select-selector {
    background-color: #272d35 !important;
    padding: 0px 5px !important;
    font-size: 14px;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const H2 = styled.div`
  padding-left: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const DflexStartEnd = styled.div`
  .box-end {
    display: flex;
    justify-content: end;
  }
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-right: 24px;
  .ant-form-item {
    margin: 0;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    margin-right: 8px;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    margin-right: 8px;
  }
  .ant-picker {
    width: 247px;
    margin-right: 8px;
  }
  .ant-picker-header button {
    color: #fff !important;
  }
  .ant-select-selector {
    min-width: 103px;
  }
`;
export const Dflex = styled.div`
  display: flex;
  width: 49%;
`;

export const Header = styled.div`
  padding: 24px 32px;
`;
export const StyledButtonA = styled(Button)`
  color: red;
  background-color: yellow;
`;
export const StyledButtonB = styled(Button)`
  color: pink;
  background-color: green;
`;

export const ModalAccept = styled(Modal)`
  .style-btn-back {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #d9d9d9;
  }
  .style-btn-accept {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #161a1e;
  }
  .button.ant-btn.ant-btn-default {
    border-radius: 2px;
  }
  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    border-radius: 2px;
  }
  .ant-btn {
    padding: 4px 15px;
    height: unset;
    border-radius: 2px;
  }
  .box-header {
    padding: 24px 32px;
    border-bottom: 1px solid #3c4450;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    display: grid;
    color: #f0f0f0;
    .color-yellow {
      color: #f2c015;
    }
  }
  .box-content-body {
    padding: 30px;
  }
  .ant-modal-content {
    width: 517px;
  }
  .ant-modal-footer {
    padding: 0px 30px 30px 30px;
    border-top: none;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 20px;
  }
  .ant-modal-body {
    padding: 0;
  }
`;
export const ModalReject = styled(Modal)`
  .btn-save-change {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    gap: 10px;
    width: 117px;
    height: 32px;
    background: #9ea6ac;
    border: 1px solid #9ea6ac;
    border-radius: 2px;
  }
  .ant-modal-content {
    width: 591px;
  }
  .ant-modal-footer {
    padding: 0px 32px 32px 32px;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 12px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    display: none;
  }
`;
export const TabsLoan = styled(Tabs)`
  color: pink;
  background-color: green;
`;
export const boxCircle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
export const circleStyle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const DflexModalAccept = styled.div`
  margin-bottom: 26px;
  display: flex;
  justify-content: space-between;
`;
export const BranchNameStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
`;
export const BranchResultStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f5f5f5;
`;
export const DflexPara = styled.div`
  display: flex;
`;
export const StyleStar = styled.div`
  color: #ff4d4f;
  font-size: 14;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const StylePara = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const StyleInput = styled.div`
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }
  .ant-select-selector {
    background: #272d35 !important;
  }
`;
export const InputPara = styled.div`
  input#para {
    background: #272d35 !important;
    margin-top: 15px;
  }
`;
export const DflexEnd = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: end;
`;

export const BtnReject = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  background: #161a1e;
  border: 1px solid #9ea6ac;
  border-radius: 2px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #9ea6ac;
  margin-right: 10px;
`;

export const BtnApproved = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  background: #9ea6ac;
  border: 1px solid #9ea6ac;
  border-radius: 2px;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #d9d9d9;
`;

export const InforRequestLoan = styled.div`
  background: #1a1f25;
  .title-inforRequestLoan {
    padding: 16px 24px;
  }
`;

export const StyleMessage = styled.div`
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
`;
export const PopoverStyle = styled(Popover)`
  .ant-popover-inner-content {
    width: 100px;
  }
  .ant-popover-inner {
    background: red !important;
  }
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
  .ant-popover-inner-content {
    background: #212830;
  }
  .style-text-action-approval {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .style-text-action-reject {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    margin-bottom: 0;
    cursor: pointer;
  }
  .text-normal {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #d9d9d9;
  }
`;

export const ContainerRequestLoan = styled.div`
  background-color: #12161c;
  padding: 0px 24px 24px 24px;
  .box-content {
    background-color: #1a1f25;
  }
  .ant-table-header.ant-table-sticky-holder {
    top: 71px !important;
  }
`;
export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;
  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 300px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const TabsTableVotes = styled.div`
  border-bottom: 24px solid #12161c;
  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 380px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const StyleColumnAction = styled.div`
  .ant-popover-inner {
    background: red !important;
  }
  .box-action {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .box-action-available {
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }
  .action-style-availble {
    width: 20px;
    background: #212830;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
  display: flex;
  max-width: 200px;
  .style-text-action-watch {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .style-text-action-approval {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .style-text-action-reject {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    cursor: pointer;
  }
  .style-text-action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .style-text-action-reject-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
`;
