import { Button, Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import * as S from '../../CustomerManagement.styles';

type Props = {
  data?: any;
  isModalOpen?: boolean;
  handleOk?: () => void;
  handleCancel?: () => void;
};

export default function ModalEditDetail(props: Props) {
  const [action, setAction] = useState<string>('Đang hoạt động');
  const [checked, setchecked] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    if (!checked) {
      setchecked(true);
      setAction('Đang hoạt động');
      setIsModalOpen(false);
    } else {
      setAction('Ngưng hoạt động');
      setchecked(false);

      setIsModalOpen(false);
    }
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function handdleSwitch() {
    setIsModalOpen(true);
  }

  return (
    <S.StyleModalEditDetail footer={null} open={props.isModalOpen} onOk={props.handleOk} onCancel={props.handleCancel}>
      <div className="box-title-modal">
        <div className="title-modal">Sửa thông tin cá nhân Ronald Richards</div>
      </div>
      <div className="content-body">
        <div className="label">Trạng thái</div>
        <div className="value">
          <S.SwitchStyle onClick={handdleSwitch} defaultChecked checked={checked} />
          <div className="text-action">{action}</div>
        </div>
        <div className="label-note">Ghi chú</div>
        <div className="value">
          <S.InputPara>
            <Form.Item name={'para'} style={{ marginTop: '20px' }}>
              <TextArea rows={4}></TextArea>
            </Form.Item>
          </S.InputPara>
        </div>
        <div className="section-btn">
          <Button className="btn-cancel">Hủy</Button>

          <Button className="btn-change">Lưu thay đổi</Button>
        </div>
      </div>
      <S.StyleModalConfirm title="Basic Modal" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div>
          <div className="text-confirm">Xác nhận Ngưng hoạt động người dùng “Ronald Richards” ? </div>
          <div className="btn-change">
            <Button className="btn-cancel" onClick={handleCancel}>
              Huỷ
            </Button>
            <Button className="btn-yes" onClick={handleOk}>
              Xác nhận
            </Button>
          </div>
        </div>
      </S.StyleModalConfirm>
    </S.StyleModalEditDetail>
  );
}
