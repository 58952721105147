import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../ReconciliationDisbursement.style';
import { ColumnsType } from 'antd/lib/table';
import { DataResponseReconciliationDisbursementDetail, DataTypeTableDetail } from '../../interface';
import { StatusesText } from '@app/components/common/Statuses/Statuses';

import { ReconciliationDisbursementContext } from '../../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getReconciliationDisbursementDetailApi } from '../../ReconciliationDisbursement.api';
import { updateFiltersReconciliationDisbursementDetail } from '../../Action';
import { FormSearch } from './FormSearch';
import { columnsConfigOriginal } from './columnConfigOriginal';
import TableColumnReconciliation from '@app/components/common/TableColumnTime/TableColumnReconciliation';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import { useParams } from 'react-router-dom';

export default function TableReconciliationDisbursementList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const { date } = useParams();
  const [data, setData] = useState<DataResponseReconciliationDisbursementDetail>();
  const { stateReconciliationDisbursement, dispatchReconciliationDisbursement } = useContext(ReconciliationDisbursementContext);

  const callApiDisbursementDetail = async () => {
    setcheckLoading(true);
    if (stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail.day && stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail.day != '') {
      await getReconciliationDisbursementDetailApi(queryStringParams(stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          setData(res);
          setcheckLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setcheckLoading(false);
        });
    }
  };

  const columnsConfig: ColumnsType<any> = [
    {
      width: '185px',
      title: 'Thời gian đối soát',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) => a.age - b.age,
      render: (value: string) => <TableColumnTime value={value && value}></TableColumnTime>,
    },
    {
      title: 'ID giải ngân (BG/BF)',
      key: 'loanCode',

      render: (value: DataTypeTableDetail) => (
        <a className="cursor-pointer-none">
          <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.transactionCodePartner} valueBF={value?.transactionCodeBifiin}></TableColumnReconciliation>
        </a>
      ),
    },
    {
      title: 'ID khoản vay (BG/BF)',
      key: 'loanCode',

      render: (value: DataTypeTableDetail) => (
        <a className="cursor-pointer-none">
          <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.loanCodePartner} valueBF={value?.loanCodeBifiin}></TableColumnReconciliation>
        </a>
      ),
    },
    {
      width: '317px',
      title: 'Thời gian giải ngân (BG/BF)',
      key: 'approvalDate',
      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.disbursementDatePartner} valueBF={value?.disbursementDateBifiin}></TableColumnReconciliation>
      ),
    },
    {
      width: '265px',
      title: 'Số tiền giải ngân (BG/BF)',
      key: 'amount',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="number" status={value ? value?.status : ''} valuePartner={value?.amountPartner} valueBF={value?.amountBifiin}></TableColumnReconciliation>
      ),
    },
    {
      width: '175px',
      title: 'Nguồn giải ngân',
      key: 'loanType',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.fundNamePartner} valueBF={value?.fundNameBifiin}></TableColumnReconciliation>
      ),
    },

    {
      width: '181px',
      title: 'Ghi chú',
      key: 'note',
      dataIndex: 'note',

      render: (value: string) => <div className="text-loancode">{value ? value : ''}</div>,
    },
    {
      width: '134px',
      title: 'Trạng thái đối soát',
      key: 'status',
      dataIndex: 'status',

      render: (value) => <StatusesText context="RECONCILIATION" status={value}></StatusesText>,
    },
    {
      width: '111px',

      title: 'Hành động',
      key: 'action',
      render: (value: DataTypeTableDetail) => {
        return value && value?.status == 'SUCCESS' ? <div className="action-approval-disable">Duyệt</div> : <div className="action-approval">Duyệt</div>;
      },
    },
  ];

  useEffect(() => {
    dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, pageIndex: 1, day: date }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    callApiDisbursementDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxReconciliationDisbursement>
            <FormSearch></FormSearch>
            <Table
              scroll={{ x: stateReconciliationDisbursement?.checkDataOriginal ? 1184 : 2160 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={stateReconciliationDisbursement?.checkDataOriginal ? columnsConfigOriginal : columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementDetail({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementDetail, pageIndex: page }));
                },
              }}
            />
          </S.BoxReconciliationDisbursement>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
