import styled from 'styled-components';
import { Badge as ABadge, Tag as ATag } from 'antd';

export const Badge = styled(ABadge)`
  font-weight: 400;
  font-size: 14px;
`;

export const Tag = styled(ATag)`
  font-size: 12px;
`;
