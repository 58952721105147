/* eslint-disable prettier/prettier */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useContext, useEffect, useState } from 'react';

import * as S from '../RequestLoanManagement.styles';
import { RequestLoanContext, RequestLoanProvider } from '../Context';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { updateFiltersVotes } from '../Action';
import TableVotes from './TableVotes';

function TabMenu() {
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);
  const [ToggleState, setToggleState] = useState(1);

  const toggleTab = (index: number) => {
    setToggleState(index);
  };
  const getActiveClass = (index: number, className: string) => (ToggleState === index ? className : '');

  return (
    <S.TabsTableVotes>
      <div className="box-content">
        <div
          onClick={() => {
            toggleTab(1), dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, status: '', pageIndex: 1 }));
          }}
          className={`item ${getActiveClass(1, 'active-tabs')}`}
        >
          Tất cả
        </div>
        <div
          onClick={() => {
            toggleTab(2), dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, status: 'WAIT_FOR_APPROVE', pageIndex: 1 }));
          }}
          className={`item ${getActiveClass(2, 'active-tabs')}`}
        >
          Chờ duyệt
        </div>
        <div
          onClick={() => {
            toggleTab(3), dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, status: 'APPROVED', pageIndex: 1 }));
          }}
          className={`item ${getActiveClass(3, 'active-tabs')}`}
        >
          Đã duyệt
        </div>
        <div
          onClick={() => {
            toggleTab(4), dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, status: 'REJECTED', pageIndex: 1 }));
          }}
          className={`item ${getActiveClass(4, 'active-tabs')}`}
        >
          Từ chối
        </div>
        <div
          onClick={() => {
            toggleTab(5), dispatchRequestLoan(updateFiltersVotes({ ...stateRequestLoan?.filterVotes, status: 'CANCELLED' }));
          }}
          className={`item ${getActiveClass(5, 'active-tabs')}`}
        >
          Hủy
        </div>
      </div>
    </S.TabsTableVotes>
  );
}

export default function VotesWaitForApproval() {
  const { dispatchRequestLoan } = useContext(RequestLoanContext);
  const dispatch = useAppDispatch();

  //sticky window

  //
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Phiếu chờ duyệt',
          // url: '/customers/request-loan-management',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Phiếu chờ duyệt`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchRequestLoan, dispatch]);

  return (
    <RequestLoanProvider>
      <PageTitle>Phiếu chờ duyệt</PageTitle>

      <div>
        {/* <S.H2>Quản lý đơn đề nghị vay</S.H2> */}
        <TabMenu></TabMenu>
        <TableVotes></TableVotes>
      </div>
    </RequestLoanProvider>
  );
}
