import React from 'react';
import TableLoanSectionDetail from '../../../Loan/ComponentLoan/TableLoanSectionDetail';
import { LoanListProvider } from '../../../Loan/Context';
// import Table from 'antd/lib/table';
import * as S from '../../Capital.styles';
import { ResponseApiDetail } from '../../interface';

// import { columns } from '../../../Loan/ComponentLoan/ColumnConfig';
// import { FormSearchLoan } from './FormSearchLoan';

type Props = {
  dataDetail?: ResponseApiDetail | undefined | null;
};
export default function TableLoan(props: Props) {
  return (
    <LoanListProvider>
      <S.StyleTableDetailTab>
        {/* <FormSearchLoan></FormSearchLoan>

      <Table scroll={{ x: 1200 }} dataSource={[]} columns={columns} sticky={true} rowKey="id" pagination={false} /> */}
        <TableLoanSectionDetail idCapital={props?.dataDetail?.code}></TableLoanSectionDetail>
        <div className="watch-all">
          <div className="text-all">Xem tất cả</div>
        </div>
      </S.StyleTableDetailTab>
    </LoanListProvider>
  );
}
