import React, { useContext, useEffect, useState } from 'react';
import * as S from '../EkycManagement.styles';
import { EkycManagementContext } from '../Context';
import * as GS from '@app/styles/GlobalStyle';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import * as S2 from '../../Loan/Loan.styles';
import { DataDetailEkycType, ParamsEditEkycApiType } from '../interface';
import { updateStateModalEkycManagement } from '../Action';
import { EditEkycApi } from '../EkycManagement.api';
import { notificationController } from '@app/controllers/notificationController';
import moment from 'moment';

type Props = {
  dataDetailEkyc?: DataDetailEkycType | undefined;
  callApiDetailEkyc: () => void;
};

export default function ModalEditInforPaper(props: Props) {
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancelModalEdit() {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalEditPaperEkyc: false }));
    form.setFieldsValue({
      cardIdType: props?.dataDetailEkyc?.cardIdType ? props?.dataDetailEkyc?.cardIdType : undefined,
      dateOfCardId: props?.dataDetailEkyc?.dateOfCardId ? moment(props?.dataDetailEkyc?.dateOfCardId) : undefined,
      expirationDateOfCardId: props?.dataDetailEkyc?.expirationDateOfCardId ? moment(props?.dataDetailEkyc?.expirationDateOfCardId) : undefined,
      placeOfCardId: props?.dataDetailEkyc?.placeOfCardId ? props?.dataDetailEkyc?.placeOfCardId : undefined,
      dob: props?.dataDetailEkyc?.dob ? moment(props?.dataDetailEkyc?.dob) : undefined,
      fullName: props?.dataDetailEkyc?.fullName ? props?.dataDetailEkyc?.fullName : undefined,
      cardCode: props?.dataDetailEkyc?.cardCode ? props?.dataDetailEkyc?.cardCode : undefined,
      gender: props?.dataDetailEkyc?.gender ? props?.dataDetailEkyc?.gender : undefined,
      nationality: props?.dataDetailEkyc?.nationality ? props?.dataDetailEkyc?.nationality : undefined,
      address: props?.dataDetailEkyc?.address ? props?.dataDetailEkyc?.address : undefined,
      resident: props?.dataDetailEkyc?.resident ? props?.dataDetailEkyc?.resident : undefined,
    });
  }

  const onFinish = async (values: ParamsEditEkycApiType) => {
    if (values && props?.dataDetailEkyc) {
      setloading(true);

      await EditEkycApi(props?.dataDetailEkyc.code, {
        cardIdType: values?.cardIdType,
        dateOfCardId: moment(values?.dateOfCardId).format('YYYY-MM-DD'),
        expirationDateOfCardId: moment(values?.expirationDateOfCardId).format('YYYY-MM-DD'),
        placeOfCardId: values?.placeOfCardId,
        dob: moment(values?.dob).format('YYYY-MM-DD'),
        fullName: values?.fullName,
        cardCode: values?.cardCode,
        gender: values?.gender,
        nationality: values?.nationality,
        address: values?.address,
        resident: values?.resident,
      })
        .then((res) => {
          dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalEditPaperEkyc: false }));

          if (res?.error) {
            setloading(false);
            form.setFieldsValue({
              cardIdType: props?.dataDetailEkyc?.cardIdType ? props?.dataDetailEkyc?.cardIdType : undefined,
              dateOfCardId: props?.dataDetailEkyc?.dateOfCardId ? moment(props?.dataDetailEkyc?.dateOfCardId) : undefined,
              expirationDateOfCardId: props?.dataDetailEkyc?.expirationDateOfCardId ? moment(props?.dataDetailEkyc?.expirationDateOfCardId) : undefined,
              placeOfCardId: props?.dataDetailEkyc?.placeOfCardId ? props?.dataDetailEkyc?.placeOfCardId : undefined,
              dob: props?.dataDetailEkyc?.dob ? moment(props?.dataDetailEkyc?.dob) : undefined,
              fullName: props?.dataDetailEkyc?.fullName ? props?.dataDetailEkyc?.fullName : undefined,
              cardCode: props?.dataDetailEkyc?.cardCode ? props?.dataDetailEkyc?.cardCode : undefined,
              gender: props?.dataDetailEkyc?.gender ? props?.dataDetailEkyc?.gender : undefined,
              nationality: props?.dataDetailEkyc?.nationality ? props?.dataDetailEkyc?.nationality : undefined,
              address: props?.dataDetailEkyc?.address ? props?.dataDetailEkyc?.address : undefined,
              resident: props?.dataDetailEkyc?.resident ? props?.dataDetailEkyc?.resident : undefined,
            });
          } else {
            setloading(false);
            props?.callApiDetailEkyc();
            notificationController.success({ message: `Sửa thành công` });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);

          dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalEditPaperEkyc: false }));
        });
    }
  };

  useEffect(() => {
    if (props?.dataDetailEkyc) {
      form.setFieldsValue({
        cardIdType: props?.dataDetailEkyc?.cardIdType ? props?.dataDetailEkyc?.cardIdType : undefined,
        dateOfCardId: props?.dataDetailEkyc?.dateOfCardId ? moment(props?.dataDetailEkyc?.dateOfCardId) : undefined,
        expirationDateOfCardId: props?.dataDetailEkyc?.expirationDateOfCardId ? moment(props?.dataDetailEkyc?.expirationDateOfCardId) : undefined,
        placeOfCardId: props?.dataDetailEkyc?.placeOfCardId ? props?.dataDetailEkyc?.placeOfCardId : undefined,
        dob: props?.dataDetailEkyc?.dob ? moment(props?.dataDetailEkyc?.dob) : undefined,
        fullName: props?.dataDetailEkyc?.fullName ? props?.dataDetailEkyc?.fullName : undefined,
        cardCode: props?.dataDetailEkyc?.cardCode ? props?.dataDetailEkyc?.cardCode : undefined,
        gender: props?.dataDetailEkyc?.gender ? props?.dataDetailEkyc?.gender : undefined,
        nationality: props?.dataDetailEkyc?.nationality ? props?.dataDetailEkyc?.nationality : undefined,
        address: props?.dataDetailEkyc?.address ? props?.dataDetailEkyc?.address : undefined,
        resident: props?.dataDetailEkyc?.resident ? props?.dataDetailEkyc?.resident : undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataDetailEkyc]);

  return (
    <S.ModalEditEkyc title="Sửa thông tin giấy tờ" open={stateEkycManagement.stateModal.stateModalEditPaperEkyc} onCancel={handleCancelModalEdit} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} validateMessages={validateMessages} name="basic" onFinish={onFinish} style={{ width: '100%' }}>
          <div className="box-body-form">
            <div className="form-create">
              <div className="box-content-left">
                <div className="box-form">
                  <div className="label">Loại giấy tờ</div>
                  <Form.Item name={'cardIdType'} label="Loại giấy tờ" rules={[{ required: true, type: 'string' }]}>
                    <Select className="style-select-partner" placeholder={'Chọn loại giấy tờ'} size={'small'} dropdownMatchSelectWidth={200}>
                      <Select.Option value={'CMT'}>CMT</Select.Option>
                      <Select.Option value={'CCCD'}>CCCD</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Số giấy tờ</div>
                  <Form.Item name={'cardCode'} label="Số giấy tờ" rules={[{ required: true, type: 'string' }]}>
                    <Input size={'small'} className="form-input-create" placeholder={'Nhập Số giấy tờ'} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Tên khách hàng</div>
                  <Form.Item name={'fullName'} label="Tên khách hàng" rules={[{ required: true, type: 'string' }]}>
                    <Input size={'small'} className="form-input-create" placeholder={'Nhập Tên khách hàng'} />
                  </Form.Item>
                </div>
                {/* <S.BoxFormDob className="box-form">
                  <div className="item-dob-form">
                    <div className="label">Ngày sinh</div>
                    <Form.Item name={'code'} rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className="form-input-create" placeholder={'Nhập ngày'} />
                    </Form.Item>
                  </div>
                  <div className="item-dob-form">
                    <div className="label">Tháng sinh</div>
                    <Form.Item name={'code'} rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className="form-input-create" placeholder={'Nhập tháng'} />
                    </Form.Item>
                  </div>
                  <div className="item-dob-form">
                    <div className="label">Năm sinh</div>
                    <Form.Item name={'code'} rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className="form-input-create" placeholder={'Nhập năm'} />
                    </Form.Item>
                  </div>
                </S.BoxFormDob> */}
                <div className="box-form">
                  <div className="label">Ngày tháng năm sinh</div>
                  <Form.Item name={'dob'} label="Ngày tháng năm sinh" rules={[{ required: true }]}>
                    {/* <Input size={'small'} className="form-input-create" placeholder={'Nhập giới tính'} /> */}
                    <DatePicker format={'YYYY-MM-DD'} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Giới tính</div>
                  <Form.Item name={'gender'} label="Giới tính" rules={[{ type: 'string' }]}>
                    <Select className="style-select-partner" placeholder={'Chọn giới tính'} size={'small'} dropdownMatchSelectWidth={200}>
                      <Select.Option value={'male'}>Nam</Select.Option>
                      <Select.Option value={'female'}>Nữ</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Nơi thường chú</div>
                  <Form.Item name={'address'} label="Nơi thường chú" rules={[{ required: true, type: 'string' }]}>
                    <Input size={'small'} className="form-input-create" placeholder={'Nhập nơi thường chú'} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Quốc tịch</div>
                  <Form.Item name={'nationality'} label="Quốc tịch" rules={[{ type: 'string' }]}>
                    <Input size={'small'} className="form-input-create" placeholder={'Nhập quốc tịch'} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Ngày cấp</div>
                  <Form.Item label="Ngày cấp" name={'dateOfCardId'} rules={[{ required: true }]}>
                    <DatePicker />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Nơi cấp</div>
                  <Form.Item label="Nơi cấp" name={'placeOfCardId'} rules={[{ required: true, type: 'string' }]}>
                    <Input size={'small'} className="form-input-create" placeholder={'Nhập nơi cấp'} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">Ngày hết hạn</div>
                  <Form.Item label="Ngày hết hạn" name={'expirationDateOfCardId'}>
                    <DatePicker />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="information">
              <div className="box-content-right">
                <div className="infor-title">Ảnh giấy tờ</div>
                <div className="infor-content">
                  <div className="box-branch">
                    <div className="label">Mặt trước</div>
                  </div>
                  <div className="img-form-edit">
                    <img src={props?.dataDetailEkyc?.imageOfFrontCard && props?.dataDetailEkyc?.imageOfFrontCard} alt="" />
                  </div>

                  <div className="box-branch margin-top-20">
                    <div className="label">Mặt sau</div>
                  </div>
                  <div className="img-form-edit">
                    <img src={props?.dataDetailEkyc?.imageOfBehindCard && props?.dataDetailEkyc?.imageOfBehindCard} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <S2.StyleFooterModalCreate>
            <Button key="back" onClick={handleCancelModalEdit}>
              Huỷ
            </Button>
            <Button className="btn-create-submit" loading={loading} key="submit" htmlType="submit">
              Xác nhận
            </Button>
          </S2.StyleFooterModalCreate>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalEditEkyc>
  );
}
