import React from 'react';
import { initProduct, useProductReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const ProductContext = React.createContext({ ...initProduct });
export const ProductProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateProduct, dispatchProduct] = useProductReducer();

  const wrap = {
    stateProduct,
    dispatchProduct,
  };

  return <ProductContext.Provider value={wrap}>{children}</ProductContext.Provider>;
};
