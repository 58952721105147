import { createSlice, createAction, PrepareAction } from '@reduxjs/toolkit';
import { BreadcrumbInterface, BreadcrumbType } from '@app/interfaces/interfaces';

const initialState: BreadcrumbInterface = {
  items: [],
  title: '',
};

export const setBreadcrumb = createAction<PrepareAction<BreadcrumbType[]>>('breadcrumb/setBreadcrumb', (items: BreadcrumbType[]) => {
  return {
    payload: items,
  };
});

export const setBreadcrumbTitle = createAction<PrepareAction<string>>('breadcrumb/setBreadcrumbTitle', (title: string) => {
  return {
    payload: title,
  };
});

export const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setBreadcrumb, (state, action) => {
      state.items = action.payload;
    });
    builder.addCase(setBreadcrumbTitle, (state, action) => {
      state.title = action.payload;
    });
  },
});

export default breadcrumbSlice.reducer;
