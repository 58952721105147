import React from 'react';
import { Col, Row } from 'antd';
import { Dropdown } from '@app/components/common/Dropdown/Dropdown';
import { H6 } from '@app/components/common/typography/H6/H6';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';

import { readUserProfile } from '@app/services/localStorage.service';
export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const user = readUserProfile();
  return user ? (
    <Dropdown overlay={<ProfileOverlay />} trigger={['click']}>
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>{/* <Avatar src={user.imgUrl} alt="User" shape="circle" size={23} /> */}</Col>
        {isTablet && (
          <Col>
            <H6>{user && user?.name}</H6>
          </Col>
        )}
      </S.ProfileDropdownHeader>
    </Dropdown>
  ) : null;
};
