import React from 'react';
import { initReconciliationPayment, useReconciliationPaymentListReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const ReconciliationPaymentListContext = React.createContext({ ...initReconciliationPayment });
export const ReconciliationPaymentListProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateReconciliationPayment, dispatchReconciliationPaymentList] = useReconciliationPaymentListReducer();

  const wrap = {
    stateReconciliationPayment,
    dispatchReconciliationPaymentList,
  };

  return <ReconciliationPaymentListContext.Provider value={wrap}>{children}</ReconciliationPaymentListContext.Provider>;
};
