import styled from 'styled-components';
export const BoxReconciliationDisbursement = styled.div`
  /* .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .ant-picker.ant-picker-range {
    height: 32px !important;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
  .action-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .action-approval {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
  }
  .action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .text-false {
    color: #ff4d4f;
  }
  .cursor-pointer-none {
    cursor: unset;
  }
`;
export const H2 = styled.div`
  padding-left: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;

export const FormSearch = styled.div`
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 281px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .filter-item .ant-select {
    width: 212px;
  }
  .ant-picker {
    height: 32px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: space-between;
    .box-startend {
      display: flex;
      margin-left: 10px;
    }
    .box-flexend {
      margin-top: 10px;
    }
  }
`;
