import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import TableReconciliationPaymentList from './Component/Table';
import { ReconciliationPaymentListContext, ReconciliationPaymentListProvider } from './Context';

export default function ReconciliationPayment() {
  const { dispatchReconciliationPaymentList } = useContext(ReconciliationPaymentListContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Đối soát',
          url: '/reconciliation/payment',
        },
        {
          name: 'Thanh toán',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Thanh Toán`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchReconciliationPaymentList, dispatch]);
  return (
    <ReconciliationPaymentListProvider>
      <PageTitle>Đối soát khoản vay</PageTitle>

      <div>
        {/* <GS.H2>Khoản vay</GS.H2> */}
        <TableReconciliationPaymentList></TableReconciliationPaymentList>
      </div>
    </ReconciliationPaymentListProvider>
  );
}
