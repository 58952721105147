import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../EkycManagement.styles';

import { ColumnsType } from 'antd/es/table';
// import { FormSearchEkycManagement } from './FormSearchLoan';
import { EkycManagementContext } from '../Context';
import { DataResponseEkycManagement, DataTable } from '../interface';
import { updateFiltersEkycManagement } from '../Action';
import { getListEkycManagementApi } from '../EkycManagement.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { BillingStatusEkycType } from '@app/interfaces/interfaces';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { FormSearchEkycManagement } from './FormSearchEkycManagement';

export default function TableEkycManagement() {
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setdata] = useState<DataResponseEkycManagement>();
  const callApiListEkycManagement = async () => {
    setcheckLoading(true);
    await getListEkycManagementApi(queryStringParams(stateEkycManagement.filtersEkycManagement))
      .then((res) => {
        setdata(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setdata(undefined);

        setcheckLoading(false);
      });
  };

  console.log('first', data);
  //   function moveDetailLoan(value: DataTable) {
  //     navigate(`/customers/loan/${value?.loanCode}`);
  //   }

  useEffect(() => {
    callApiListEkycManagement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEkycManagement?.filtersEkycManagement]);

  function MoveDetailEkyc(value: DataTable) {
    location.href = `/customers/ekyc-management/${value.code}`;
  }

  const columns: ColumnsType<DataTable> = [
    {
      width: '120px',
      title: 'ID',
      key: 'requestId',
      dataIndex: 'requestId',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '150px',
      title: 'Tên khách hàng',
      key: 'fullName',
      dataIndex: 'fullName',
    },
    {
      width: '150px',
      title: 'Loại giấy tờ',
      key: 'cardIdType',
      dataIndex: 'cardIdType',
    },
    {
      width: '150px',
      title: 'Số giấy tờ',
      key: 'cardCode',
      dataIndex: 'cardCode',
    },
    {
      width: '150px',
      title: 'Ngày cấp',
      key: 'dateOfCardId',
      dataIndex: 'dateOfCardId',
    },
    {
      width: '150px',
      title: 'Thời gian tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      width: '150px',
      title: 'Thời gian duyệt',
      key: 'approveAt',
      dataIndex: 'approveAt',
    },
    {
      width: '150px',
      title: 'Loại',
      key: 'billingStatus',
      dataIndex: 'billingStatus',
      render: (type: BillingStatusEkycType) => <div>{type && type == 'CHARGED' ? 'Tính tiền' : 'Không tính tiền'}</div>,
    },

    {
      width: '150px',
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      render: (value: any) => {
        return <StatusesText status={value} context="EKYC"></StatusesText>;
      },
    },

    {
      width: '100px',
      title: 'Hành động',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      render: (value: DataTable) => (
        <GS.WrapperOverrideInput>
          <div onClick={() => MoveDetailEkyc(value)} className="text-watch">
            Xem
          </div>
        </GS.WrapperOverrideInput>
      ),
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxEkycManagement>
            <FormSearchEkycManagement></FormSearchEkycManagement>
            <Table
              style={{ paddingTop: '20px' }}
              className="padding-table"
              scroll={{ x: 1900 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              sticky={true}
              columns={columns}
              loading={checkLoading}
              rowKey="code"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchEkycManagement(updateFiltersEkycManagement({ ...stateEkycManagement?.filtersEkycManagement, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxEkycManagement>
        </div>
      </GS.ContainerTable>
      {/* <ModalDetail data={dataCode && dataCode}></ModalDetail> */}
    </GS.WrapperOverrideInput>
  );
}
