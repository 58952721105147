import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { Modal, Tabs } from 'antd';

export const Menu = styled.div`
  display: flex;
`;

export const H2 = styled.div`
  padding-left: 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const DflexStartEnd = styled.div`
  .ant-select {
    min-width: 163px !important;
  }
  .ant-picker {
    min-width: 240px;
  }
  /* padding-top: 30px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 13px;
`;

export const Dflex = styled.div`
  display: flex;
  width: 49%;
`;
export const StyledButtonA = styled(Button)`
  color: red;
  background-color: yellow;
`;
export const StyledButtonB = styled(Button)`
  color: pink;
  background-color: green;
`;
export const TabsLoan = styled(Tabs)`
  color: pink;
  background-color: green;
`;
export const boxCircle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
export const circleStyle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const StyleBtnCreate = styled(Button)`
  .style-modal-create {
    color: #161a1e;
  }
  background-color: #f2c015;
  color: black !important;
  border: none !important;
  :hover {
    color: #fff !important;
  }
`;
export const StyleInput = styled.div`
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }
  .ant-select-selector {
    background: #272d35 !important;
  }
`;
export const StyleStar = styled.div`
  color: #ff4d4f;
  font-size: 14;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const StylePara = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;
export const DflexPara = styled.div`
  display: flex;
`;
export const InputPara = styled.div`
  input#para {
    background: #272d35 !important;
    margin-top: 15px;
  }
`;
export const DflexEnd = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: end;
`;
export const ModalSelectFund = styled(Modal)`
  .btn-save-change {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    gap: 10px;
    width: 117px;
    height: 32px;
    background: #9ea6ac;
    border: 1px solid #9ea6ac;
    border-radius: 2px;
  }
  .ant-modal-content {
    width: 591px;
  }
  .ant-modal-footer {
    padding: 0px 32px 32px 32px;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 12px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const ModalDetail = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    width: 1085px;
    margin-left: -180px;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    margin-right: 20px;
  }
  .content {
    display: flex;
    width: 100%;
    .section-img {
      width: 65%;
      padding: 24px;
      .title-section-img {
        display: flex;
        justify-content: space-between;
        .d-flex {
          display: flex;
        }
        .name-img {
          display: flex;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f2c015;
          margin-left: 5px;
          max-width: 559px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .box-content-img {
        width: 100%;
        height: 574px;

        margin-top: 50px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        .save-image {
          height: 574px;
          width: 100%;
          background-color: #272d35;
        }

        .box-img {
          width: 378px;
          height: 100%;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .section-infor {
      width: 35%;
      border-left: 1px solid #3c4450;
      .title {
        border-bottom: 1px solid #3c4450;
        padding: 24px 32px 17px 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #f0f0f0;
      }
      .infor-content {
        padding: 0px 32px 0px 32px;
        .box-line {
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #bfbfbf;
          }
          .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #d9d9d9;
            width: 60%;
            text-align: right;
          }
          .value-special {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .color-id {
            color: #f2c015;
          }
        }
      }
    }
  }
`;
export const ModalListErrDetail = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
    padding: 24px;
  }
  .ant-modal-content {
    width: 800px;
    margin-left: -180px;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    margin-right: 20px;
  }
  .box-content {
    padding: 0px 24px 24px 24px;
    .section-btn {
      display: flex;
      justify-content: end;
      margin-top: 30px;
    }
    .line-label {
      display: flex;
      color: var(--neutral-3, #f5f5f5);
      font-size: 14px;
      font-family: Be Vietnam Pro;
      font-weight: 500;
      line-height: 22px;
      background: #21272f;
      .col-1 {
        width: 40%;
        height: 100%;
        border-bottom: 1px solid var(--line, #3c4450);

        padding: 16px;
      }

      .col-3 {
        width: 60%;
        border-bottom: 1px solid var(--line, #3c4450);

        height: 100%;
        padding: 16px;
      }
    }
    .line-item {
      display: flex;
      width: 100%;
      .col-1 {
        width: 40%;
        height: 100%;
        border-bottom: 1px solid var(--line, #3c4450);
        color: var(--primary-yellow-01, #f2c015);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        line-height: 22px;
        padding: 16px;
      }

      .col-3 {
        width: 60%;
        border-bottom: 1px solid var(--line, #3c4450);
        color: var(--neutral-5, #d9d9d9);
        font-size: 14px;
        font-family: Be Vietnam Pro;
        line-height: 22px;
        height: 100%;
        padding: 16px;
      }
    }
  }
`;

export const ModalCreateProof = styled(Modal)`
  .ant-modal-body {
    padding: 0;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-content {
    width: 1085px;
    margin-left: -180px;
  }
  span.anticon.anticon-close.ant-modal-close-icon {
    margin-right: 20px;
  }
  .content {
    display: flex;
    width: 100%;
    .section-img {
      width: 65%;
      padding: 24px;
      .title-section-img {
        display: flex;
        justify-content: space-between;
        .d-flex {
          display: flex;
        }
        .name-img {
          display: flex;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f2c015;
          margin-left: 5px;
          max-width: 559px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      .box-content-img {
        width: 100%;
        height: 574px;
        margin-top: 50px;
        margin-bottom: 10px;
        display: flex;
        justify-content: center;
        .save-image {
          /* height: 244px; */
          width: 100%;
          background-color: #272d35;
        }

        .box-img {
          width: 378px;
          height: 100%;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .section-infor {
      width: 35%;
      border-left: 1px solid #3c4450;
      .title {
        border-bottom: 1px solid #3c4450;
        padding: 24px 32px 17px 32px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #f0f0f0;
      }
      .infor-content {
        padding: 0px 32px 0px 32px;
        .box-line {
          display: flex;
          justify-content: space-between;
          padding-top: 16px;
          padding-bottom: 16px;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #bfbfbf;
          }
          .value {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #d9d9d9;
            width: 60%;
            text-align: right;
          }
          .value-special {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .color-id {
            color: #f2c015;
          }
        }
      }
    }
  }
`;
export const columnDateReviewStyle = styled.div`
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  line-height: 22px;
  color: #f2c015;
  .box-date-review {
  }
  span {
    margin-right: 13px;
    margin-top: 2px;
  }
`;

export const BoxDetailWaitForDisbursement = styled.div`
  /* .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .cursor-pointer-none {
    cursor: unset;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
  .ant-picker.ant-picker-range {
    height: 32px !important;
  }
  .action-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .action-approval {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .text-false {
    color: #ff4d4f;
  }
`;

export const BoxDisbursementList = styled.div`
  /* .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px;
  } */
  .cursor-pointer-none {
    cursor: unset;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
  .ant-picker.ant-picker-range {
    height: 32px !important;
  }
  .action-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .action-approval {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .text-false {
    color: #ff4d4f;
  }
`;
export const ModalCreate = styled(Modal)`
  .style-label-img {
    margin-bottom: 10px;
    margin-top: 20px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .err-text-select {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
    margin-bottom: -15px;
  }
  .ant-form-item-explain-error {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    margin-top: -5px;
  }
  label.ant-form-item-required {
    display: none;
  }
  .ant-modal-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #f0f0f0;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 818px;
    background: #1a1f25;
    border-radius: 4px;
  }
  .ant-select-selector {
    background-color: #272d35 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 100px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const StyleMessage = styled.div`
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
`;

export const BoxDisbursement = styled.div`
  .margin-top-5 {
    margin-top: 5px;
  }
  .d-flex {
    display: flex;
  }
  .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }

  /* background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 16px 24px 24px;
  } */
  .padding-table {
  }
  .style-text-pdf {
    margin-left: 10px;
    margin-bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
  }
`;
export const ColumnCodeStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2c015;
`;
export const ColumnLoanCodeStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2c015;
  display: flex;
  .margin-left {
    margin-left: 10px;
  }
`;
export const ColumnCustomerNameStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #d9d9d9;
  display: flex;
  .margin-left {
    margin-left: 10px;
  }
`;
