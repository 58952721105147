import React, { useContext, useEffect, useState } from 'react';
import * as S from '../RequestLoanManagement.styles';
import { RequestLoanContext } from '../Context';
import { updateModalsRequestLoan } from '../Action';
import * as GS from '@app/styles/GlobalStyle';
import { Form, Input, Modal, Select, Upload, UploadFile, UploadProps, message } from 'antd';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import { RcFile } from 'antd/lib/upload';
import { UploadImgApi } from '@app/api/UploadFIle.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { getListTablePolicyDefault, getListTableProductInDetailPartner, getPartnerApi } from '../../Partner/Partner.api';
import { CalCuLateApi, CheckPhoneApi, CreateLoanApi } from '../RequestLoanManagement.api';
import { ParamsBodyCreateLoanType, ResponseCheckPhoneCreateLoanType } from '../interface';
import useDebounce from '@app/hooks/useDebounce';
import SectionTotalMoneyBorrow from './ComponentModalCreate/SectionTotalMoneyBorrow';
import { DataResponseApiPartnerList, DataResponseApiPartnerListProduct } from '../../Partner/interface';
import { getPolicyApi } from '../../Policy/Policy.api';
import { notificationController } from '@app/controllers/notificationController';

type Props = {
  data?: any;
  callApiRequestLoan: () => void;
};

export default function ModalCreateLoan(props: Props) {
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);
  const [form] = Form.useForm();
  const [dataListProduct, setdataListProduct] = useState<DataResponseApiPartnerListProduct>();
  const [dataListPolicy, setdataListPolicy] = useState<any>();
  const [dataListPartner, setdataListPartner] = useState<DataResponseApiPartnerList>();
  const [disabledProduct, setdisabledProduct] = useState(true);
  const [saveUuidPartner, setsaveUuidPartner] = useState<string>();
  const [disabledPolicy, setdisabledPolicy] = useState(true);
  const [disableMoneyBorrow, setdisableMoneyBorrow] = useState(true);
  const [defaultValuePolicy, setdefaultValuePolicy] = useState<any>();
  const [dataCalculate, setdataCalculate] = useState<any>();
  const [saveBaseAmount, setsaveBaseAmount] = useState(undefined);
  const [saveRatio, setsaveRatio] = useState(undefined);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>();
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  const [valuePhone, setvaluePhone] = useState();
  const [checkValidatePhone, setcheckValidatePhone] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(valuePhone, 300);
  const debouncedSearchTermRatio = useDebounce(saveRatio, 600);
  const [checkFile, setcheckFile] = useState(true);
  const [saveMaximumLoanRate, setsaveMaximumLoanRate] = useState<number>();
  const [checkName, setcheckName] = useState<boolean>();
  const [saveDataApiCheckPhone, setsaveDataApiCheckPhone] = useState<ResponseCheckPhoneCreateLoanType>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      string: '${label} không hợp lệ!',
    },
  };

  useEffect(() => {
    setcheckFile(false);
    setsaveStringImg('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRequestLoan?.modals?.modalCreateLoan]);

  useEffect(() => {
    setTimeout(() => {
      setcheckFile(true);
    }, 500);
    setcheckFile(false);
    // setsaveStringImg('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRequestLoan?.modals?.modalCreateLoan]);

  const callApiListProduct = async (uuid: string) => {
    await getListTableProductInDetailPartner(uuid, queryStringParams({ pageSize: 1000000000 }))
      .then((res: DataResponseApiPartnerListProduct) => {
        if (res?.error) {
          setdataListProduct(undefined);
        } else {
          setdataListProduct(res);
        }
      })
      .catch(() => {
        console.log('err');
        setdataListProduct(undefined);
      });
  };

  const getPolicyCall = async () => {
    await getPolicyApi(queryStringParams({ pageSize: 1000000000 }))
      .then((res) => {
        if (!res.error) {
          setdataListPolicy(res);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPartnerCall = async () => {
    await getPartnerApi(queryStringParams({ pageSize: 1000000000 }))
      .then((res) => {
        setdataListPartner(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPartnerCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPolicyCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleChangePartner(e: any) {
    if (e) {
      callApiListProduct(e);
      // setdisabledPolicy(false);
      setdisabledProduct(false);
      setsaveUuidPartner(e);
    } else {
      // setdisabledPolicy(true);
      setdisabledProduct(true);
    }
  }

  function callApiDetailPolicy(e: any, saveUuidPartner: string) {
    getListTablePolicyDefault(saveUuidPartner, e)
      .then((res) => {
        if (res?.error) {
          setdefaultValuePolicy(undefined);
        } else {
          setdefaultValuePolicy(res?.policyDefault?.code);
          form.setFieldsValue({ policyCode: res?.policyDefault?.code });
          setdisabledPolicy(false);
          setsaveMaximumLoanRate(res?.policyDefault?.maximumLoanRate);
          setdisableMoneyBorrow(false);
        }
      })
      .catch(() => {
        console.log('err');
      });
  }
  const handleChangeProduct = async (e: any) => {
    if (e && saveUuidPartner) {
      callApiDetailPolicy(e, saveUuidPartner);
    } else {
      setdisabledPolicy(true);
    }
  };

  function handleChangePolicy(e: any) {
    if (e && saveUuidPartner) {
      setsaveMaximumLoanRate(e.split(',')[1]);
    } else {
      setdisabledPolicy(true);
    }
  }

  function handleChangeBaseAmount(e: any) {
    if (e) {
      setsaveBaseAmount(e);
    } else {
      setsaveBaseAmount(undefined);
    }
  }

  function handleChangeRatio(e: any) {
    if (e) {
      setsaveRatio(e);
    } else {
      setsaveRatio(undefined);
    }
  }

  function handleCallApiCaculate() {
    CalCuLateApi({
      baseAmount: form.getFieldValue('baseAmount'),
      uuidPartner: form.getFieldValue('uuidPartner'),
      loanProductCode: form.getFieldValue('loanProductCode'),
      policyCode: form.getFieldValue('policyCode').split(',')[0],
      loanValueRatio: form.getFieldValue('loanValueRatio'),
    })
      .then((res) => {
        if (res?.error) {
          setdataCalculate(undefined);
        } else {
          setdataCalculate(res);
        }
      })
      .catch(() => {
        console.log('err');
      });
  }

  useEffect(() => {
    if (saveBaseAmount && saveRatio && debouncedSearchTermRatio) {
      handleCallApiCaculate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTermRatio]);

  function onFinish(values: ParamsBodyCreateLoanType) {
    setcheckBtnLoading(true);

    if (values) {
      CreateLoanApi({
        userPhone: values?.userPhone,
        referenceId: values?.referenceId,
        referenceType: values?.referenceType,
        baseAmount: values?.baseAmount,
        purpose: values?.purpose,
        uuidPartner: values?.uuidPartner,
        loanProductCode: values?.loanProductCode,
        policyCode: values?.policyCode.split(',')[0],
        loanValueRatio: values?.loanValueRatio,
        loanEvidence: saveStringImg ? saveStringImg : '',
        note: values?.note,
      })
        .then((res) => {
          setcheckBtnLoading(false);
          setdefaultValuePolicy(undefined);
          form.resetFields();
          dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan?.modals, modalCreateLoan: false }));
          form.setFieldsValue({
            userPhone: '',
            referenceId: '',
            referenceType: undefined,
            baseAmount: '',
            purpose: '',
            uuidPartner: undefined,
            loanProductCode: undefined,
            policyCode: undefined,
            loanValueRatio: '',
            loanEvidence: '',
            note: '',
          });
          setcheckName(false);
          if (res?.error) {
          } else {
            notificationController.success({ message: `Tạo thành công` });
            props?.callApiRequestLoan();
          }
        })
        .catch(() => {
          setcheckBtnLoading(false);
          setcheckName(false);

          dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan?.modals, modalCreateLoan: false }));
          form.setFieldsValue({
            userPhone: '',
            referenceId: '',
            referenceType: undefined,
            baseAmount: '',
            purpose: '',
            uuidPartner: undefined,
            loanProductCode: undefined,
            policyCode: undefined,
            loanValueRatio: '',
            loanEvidence: '',
            note: '',
          });
          console.log('err');
        });
    }
  }

  function handleChangePhone(e: any) {
    setvaluePhone(e.target.value);
  }

  function handleCancelModal() {
    setdefaultValuePolicy(undefined);
    form.resetFields();
    setcheckValidatePhone(false);
    setcheckName(false);
    form.setFieldsValue({
      userPhone: '',
      referenceId: '',
      referenceType: undefined,
      baseAmount: '',
      purpose: '',
      uuidPartner: undefined,
      loanProductCode: undefined,
      policyCode: undefined,
      loanValueRatio: '',
      loanEvidence: '',
      note: '',
    });
    dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan?.modals, modalCreateLoan: false }));
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };

  function handleCancel() {
    setPreviewOpen(false);
  }

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    name: 'file',
    multiple: true,
    maxCount: 1,
    listType: 'picture',
    onPreview: handlePreview,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status == 'removed') {
        setsaveStringImg('');
      }
      if (status !== 'uploading' && status !== 'removed') {
        const formData = new FormData();

        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'LOAN',
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  const callApiCheckPhone = async () => {
    if (valuePhone) {
      await CheckPhoneApi(valuePhone)
        .then((res) => {
          if (res?.error) {
            setcheckValidatePhone(true);
            setcheckName(false);
          } else {
            setcheckValidatePhone(false);
            setcheckName(true);
            setsaveDataApiCheckPhone(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      callApiCheckPhone();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  function onChangeSearch() {
    // if (e.key == 'Enter') {
    callApiCheckPhone();
    // }
  }

  return (
    <S.ModalCreateLoan title="Thêm khoản vay" open={stateRequestLoan?.modals?.modalCreateLoan} footer={null} onCancel={handleCancelModal}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} validateMessages={validateMessages} name="basic" onFinish={onFinish} style={{ width: '100%' }}>
          <div className="box-content-create-loan">
            <div className="content-left">
              <div className="box-form" style={{ position: 'relative' }}>
                <div className="label">
                  Khách hàng <span> *</span>
                </div>
                <Form.Item name="userPhone" rules={[{ required: true }]} label="số điện thoại">
                  <Input type="number" onChange={(e) => handleChangePhone(e)} onBlur={() => onChangeSearch()} size={'small'} className="form-input-create" placeholder={'Nhập SĐT'} />
                </Form.Item>
                {checkName ? <S.DivNamePhone>{saveDataApiCheckPhone?.fullName && saveDataApiCheckPhone?.fullName}</S.DivNamePhone> : null}

                <div className="err-text" style={{ marginLeft: '0px' }}>
                  {checkValidatePhone ? 'Số điện thoại khách hàng không tồn tại' : ''}
                </div>
              </div>
              <div className="box-form">
                <div className="label">
                  Ref ID (Mã đơn hàng) <span> *</span>
                </div>
                <Form.Item name={'referenceId'} rules={[{ required: true, type: 'string' }]} label="Mã đơn hàng">
                  <Input size={'small'} className="form-input-create" placeholder={'Nhập mã đơn hàng'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Loại mã liên kết <span> *</span>
                </div>
                <Form.Item name={'referenceType'} rules={[{ required: true, type: 'string' }]} label="Mã liên kết">
                  <Select className="style-select-partner" placeholder={'Chọn loại mã liên kết'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={'ORDER'}>ORDER</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Mục đích vay <span> *</span>
                </div>
                <Form.Item name={'purpose'} rules={[{ required: true, type: 'string' }]} label="Mục đích vay">
                  <Input size={'small'} className="form-input-create" placeholder={'Nhập mục đích vay'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Note</div>
                <Form.Item name={'note'} rules={[{ type: 'string' }]} label="note">
                  <Input size={'small'} className="form-input-create" placeholder={' note'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Đối tác <span> *</span>
                </div>
                <Form.Item name={'uuidPartner'} rules={[{ required: true, type: 'string' }]} label="Đối tác">
                  <Select onChange={(e) => handleChangePartner(e)} placeholder={'Chọn đối tác'} size={'small'} dropdownMatchSelectWidth={200}>
                    {dataListPartner &&
                      dataListPartner?.data?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item?.uuid}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Loại sản phẩm <span> *</span>
                </div>
                <Form.Item name={'loanProductCode'} rules={[{ required: true, type: 'string' }]} label="Loại sản phẩm">
                  <Select onChange={(e) => handleChangeProduct(e)} disabled={disabledProduct} placeholder={'Chọn loại sản phẩm'} size={'small'} dropdownMatchSelectWidth={200}>
                    {dataListProduct &&
                      dataListProduct?.data?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item?.productName}`}>
                          {item?.productName}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Mã chính sách <span> *</span>
                </div>
                <Form.Item name={'policyCode'} rules={[{ required: true, type: 'string' }]} label="Chính sách">
                  {/* {defaultValuePolicy ? ( */}
                  <Select
                    onChange={(e) => handleChangePolicy(e)}
                    disabled={disabledPolicy}
                    defaultValue={defaultValuePolicy}
                    placeholder={'Chọn chính sách'}
                    size={'small'}
                    dropdownMatchSelectWidth={200}
                  >
                    {dataListPolicy &&
                      dataListPolicy?.data?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item.code},${item.maximumLoanRate}`}>
                          {item?.code}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Chứng từ</div>
                {checkFile ? (
                  <div className="save-image">
                    <Dragger {...propsUpdate}>
                      <p className="ant-upload-drag-icon">
                        <IconUploadFile />
                      </p>
                      <p className="ant-upload-text">
                        Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                      </p>
                    </Dragger>
                  </div>
                ) : null}
              </div>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
            </div>
            {/* <ContentRightModalCreate></ContentRightModalCreate> */}

            <div className="content-right">
              <div className="box-form">
                <div className="label">
                  Số tiền đơn hàng(vnđ) <span> *</span>
                </div>
                <Form.Item name={'baseAmount'} rules={[{ required: true, type: 'string' }]} label="Số tiền đơn hàng">
                  <Input onChange={(e) => handleChangeBaseAmount(e)} size={'small'} className="form-input-create" placeholder={'Nhập số tiền đơn hàng'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">
                  Tỷ lệ cho vay(%) <span> *</span>
                </div>
                <Form.Item
                  name={'loanValueRatio'}
                  rules={[
                    { required: true, type: 'string' },
                    ({}) => ({
                      validator(_, value) {
                        if (saveMaximumLoanRate) {
                          if (value > saveMaximumLoanRate) {
                            return Promise.reject(new Error(`Không được vượt quá tỷ lệ cho vay tối đa ${saveMaximumLoanRate} %`));
                          }
                          if (!form.getFieldValue('baseAmount')) {
                            return Promise.reject(new Error(`Hãy nhập số tiền đơn hàng trước để tính được tiền thanh toán`));
                          }
                          if (value == 0) {
                            return Promise.reject(new Error('Tỷ lệ cho vay phải > 0'));
                          }
                          if (value < 0) {
                            return Promise.reject(new Error('Tỷ lệ cho vay phải > 0'));
                          }
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                  label="tỉ lệ cho vay"
                >
                  <Input onChange={(e) => handleChangeRatio(e)} disabled={disableMoneyBorrow} size={'small'} className="form-input-create" placeholder={'Nhập tỉ lệ cho vay'} />
                </Form.Item>
              </div>
              <SectionTotalMoneyBorrow data={dataCalculate && dataCalculate}></SectionTotalMoneyBorrow>
            </div>
          </div>
          <FooterModal type={'submit'} handleLoading={checkBtnLoading} handleClickCancel={handleCancelModal} labelCancel={'Hủy'} LabelSave={'Thêm khoản vay'}></FooterModal>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreateLoan>
  );
}
