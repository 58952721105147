import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';

export const StyleBtnCreate = styled(Button)`
  background-color: #f2c015;
  color: black !important;
  border: none !important;
  margin-left: 10px;
  /* .ant-btn:focus {
    text-decoration: none;
    background-color: rgb(242, 192, 21) !important;
  } */
  :hover {
    color: #fff !important;
  }
`;
