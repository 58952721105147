import { FiltersProductType, ModalsProductType, ReducerType } from './interface';

export const UPDATE_FILTERS_PRODUCT = 'UPDATE_FILTERS_PRODUCT';
export const UPDATE_MODALS_PRODUCT = 'UPDATE_MODALS_PRODUCT';

export const updateFiltersProduct = (filtersProduct: FiltersProductType): ReducerType => {
  return {
    type: UPDATE_FILTERS_PRODUCT,
    filtersProduct: filtersProduct,
  };
};
export const updateModalsProduct = (modalsProduct: ModalsProductType): ReducerType => {
  return {
    type: UPDATE_MODALS_PRODUCT,
    modalsProduct: modalsProduct,
  };
};
