import { Button, Form, Input, message, Select, Upload, UploadProps } from 'antd';
// import React, { useContext } from 'react';
import * as S from '../Disbursement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import * as S2 from '../../../Loan/Loan.styles';
import { createDisbursementApi, detailLoanApi } from '../../../Loan/Loan.api';
import { dataFund, DataLoanDetailType } from '../../../Loan/interface';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import DisableUploadImg from '@app/assets/icons/disabledUpload.png';

import { useContext, useEffect, useState } from 'react';
// import { LoanListContext } from '../../../Loan/Context';
import { updateStateModalDisbursement } from '../../../Loan/Action';
import { DisbursementContext } from '../Context';
import useDebounce from '@app/hooks/useDebounce';
import { getFundApi } from '../Disbursement.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { formatNumberWithCommas } from '@app/utils/utils';
import { UploadImgApi } from '@app/api/UploadFIle.api';

export default function ModalCreateDisbursement() {
  const [dataDetail, setdataDetail] = useState<DataLoanDetailType | null>();
  const [dataFund, setdataFund] = useState<dataFund[] | null>();
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>();
  const [form] = Form.useForm();
  const [checkDisableUpload, setcheckDisableUpload] = useState(true);

  const [loading, setloading] = useState(false);
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [fieldValue, setFieldValue] = useState('');
  const [check, setCheck] = useState<boolean>();
  const [checkValidateSelect, setcheckValidateSelect] = useState<boolean>(false);
  const [checkCallApiEnter, setCheckCallApiEnter] = useState<boolean>();
  const [labelSelect, setlabelSelect] = useState('Chọn nguồn tiền giải ngân');
  const debouncedSearchTerm = useDebounce(fieldValue, 200);
  function handleOkModalCreate() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalDisbursement: false }));
  }
  function handleCancelModalCreate() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalDisbursement: false }));
    form.setFieldsValue({
      loanCode: '',
      amount: '',
      fund: 'Chọn nguồn tiền giải ngân',
    });
    setlabelSelect('Chọn nguồn tiền giải ngân');
    setcheckValidateSelect(false);

    setdataFund(null);
    setdataDetail(null);
    setCheck(false);
  }

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    name: 'file',
    maxCount: 1,

    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'TRANSACTION',
            partnerUuid: dataDetail && dataDetail?.partnerUuid,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              // setobject({ ...object, imageKey: res?.key });
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              // setobject({ ...object, imageKey: '' });
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  function onchangeFund(e: string) {
    if (e && e != 'Chọn nguồn tiền giải ngân') {
      setcheckValidateSelect(false);
    }
  }

  const onFinish = async (values: any) => {
    const formData = new FormData();
    formData.append('amount', values?.amount);
    formData.append('loanCode', values?.loanCode);
    formData.append('fundCode', values?.fund);
    if (saveStringImg) {
      formData.append('imageUrl', saveStringImg);
    }
    if (values?.fund != 'Chọn nguồn tiền giải ngân') {
      setloading(true);
      await createDisbursementApi(formData)
        .then((res) => {
          if (res?.error) {
            handleOkModalCreate();
            setloading(false);
            setdataDetail(null);
          } else {
            handleOkModalCreate();
            setloading(false);
            setdataDetail(null);
            notificationController.success({ message: `Tạo thành công` });
            form.setFieldsValue({
              loanCode: '',
              amount: '',
              fund: 'Chọn nguồn tiền giải ngân',
            });
            setlabelSelect('Chọn nguồn tiền giải ngân');
            setcheckValidateSelect(false);
          }
        })
        .catch((err) => {
          setloading(false);
          setdataDetail(null);
          handleOkModalCreate();
          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
        });
    } else {
      setcheckValidateSelect(true);
    }
  };

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeID = (e: any) => {
    if ((e.key = 'Enter')) {
      setCheckCallApiEnter(!checkCallApiEnter);
    }
    setFieldValue(e.target.value);
  };

  const getUserCall = async () => {
    await detailLoanApi(fieldValue)
      .then((res) => {
        if (res.error) {
          setdataDetail(null);
          setCheck(true);
          setcheckDisableUpload(true);
        } else {
          setcheckDisableUpload(false);

          setdataDetail(res);
          setCheck(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdataDetail(null);
        setdataFund(null);
        setCheck(true);
      });
  };

  const getFundCall = async () => {
    if (dataDetail) {
      await getFundApi(queryStringParams({ product: dataDetail?.loanProduct?.code }))
        .then((res) => {
          setdataFund(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (debouncedSearchTerm) {
      getUserCall();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldValue, debouncedSearchTerm, checkCallApiEnter]);

  useEffect(() => {
    getFundCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetail]);

  return (
    <S.ModalCreate footer={null} title="Tạo phiếu giải ngân " open={stateDisbursement?.stateModal.stateModalDisbursement} onCancel={handleCancelModalCreate}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages} onFinishFailed={onFinishFailed}>
          <div>
            <div style={{ display: 'flex' }}>
              <div className="form-create">
                <div className="label">ID khoản vay</div>
                <Form.Item name="loanCode" label="ID khoản vay" rules={[{ required: true, type: 'string' }]}>
                  <Input onKeyUp={(e) => onChangeID(e)} size={'small'} className="form-input-create" placeholder={'Nhập mã khoản vay '} />
                </Form.Item>
                <div className="err-text">{check ? 'Khoản vay không tồn tại' : ''}</div>
                <div className="box-form">
                  <div className="label" style={{ marginTop: `${check ? '0px' : '30px'}` }}>
                    Số tiền giải ngân
                  </div>
                  <Form.Item
                    name="amount"
                    label="Số tiền giải ngân"
                    rules={[
                      { required: true },
                      ({}) => ({
                        validator(_, value) {
                          if (dataDetail?.amount) {
                            if (value > dataDetail?.amount - dataDetail?.disbursedAmount) {
                              return Promise.reject(new Error('số tiền (giải ngân + đã giải ngân) <= số tiền vay'));
                            }
                            if (value == 0) {
                              return Promise.reject(new Error('số tiền phải > 0'));
                            }
                            if (value < 0) {
                              return Promise.reject(new Error('số tiền phải > 0'));
                            }
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input size={'small'} type="number" className="form-input-create" placeholder={'Nhập số tiền giải ngân '} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">nguồn tiền giải ngân</div>
                  <div className={'filter-item input-form-css-create'} style={{ marginBottom: `${checkValidateSelect ? '0px' : '30px'}` }}>
                    <Form.Item name="fund">
                      <Select placeholder={labelSelect} size={'small'} dropdownMatchSelectWidth={200} onChange={(e: string) => onchangeFund(e)}>
                        {dataFund && dataFund.length > 0
                          ? dataFund.map((item, index) => (
                              <Select.Option key={index} value={`${item?.code}`}>
                                {item?.name}
                              </Select.Option>
                            ))
                          : ''}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="err-text-select">{checkValidateSelect ? 'Hãy chọn nguồn tiền giải ngân' : ''}</div>
                <div className="label style-label-img">Chứng từ</div>
                <div className="save-image">
                  {checkDisableUpload ? (
                    <img src={DisableUploadImg} alt="" />
                  ) : (
                    <Dragger {...propsUpdate}>
                      <p className="ant-upload-drag-icon">
                        <IconUploadFile />
                      </p>
                      <p className="ant-upload-text">
                        Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                      </p>
                    </Dragger>
                  )}
                </div>
              </div>
              <div className="information">
                <div className="infor-title">Thông tin khoản vay {dataDetail ? dataDetail?.code : ''} </div>
                <div className="infor-content">
                  <div className="box-branch">
                    <div className="label">Tên khách hàng</div>
                    <div>{dataDetail ? dataDetail?.user?.fullName : ''}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Gốc hoàn (trước GN)</div>
                    <div>{dataDetail?.refundFeeAmount ? formatNumberWithCommas(dataDetail?.refundFeeAmount) : 0}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Số tiền cần giải ngân</div>
                    <div>{dataDetail?.amount ? formatNumberWithCommas(dataDetail?.amount - dataDetail?.refundFeeAmount) : 0}</div>
                  </div>

                  <div className="box-branch">
                    <div className="label">Đã giải ngân</div>
                    <div>{dataDetail?.disbursedAmount ? formatNumberWithCommas(dataDetail?.disbursedAmount) : 0}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Chưa giải ngân</div>
                    <div>{dataDetail ? (dataDetail?.amount | dataDetail?.disbursedAmount ? dataDetail?.amount - dataDetail?.refundFeeAmount - dataDetail?.disbursedAmount : 0) : null}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <S2.StyleFooterModalCreate>
            <Button key="back" onClick={handleCancelModalCreate}>
              Huỷ
            </Button>

            <Button className="btn-create-submit" loading={loading} key="submit" htmlType="submit" style={{ marginLeft: '10px' }}>
              Xác nhận giải ngân
            </Button>
          </S2.StyleFooterModalCreate>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreate>
  );
}
