import styled from 'styled-components';
import { Tag as ATag } from 'antd';

export const Tag = styled(ATag)<{ txt_color: string }>`
  color: ${(props) => props?.txt_color};
  padding: 4px 23px;
  font-weight: 400;
  font-size: 14px;
  border-radius: 20px;
`;
