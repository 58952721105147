import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import React, { useContext } from 'react';
import Avatar from '@app/assets/icons/avatar.svg';
import { DataDetailCusomerType } from '../../interfaces';
import { CustomerManagementContext } from '../../context';
import { updateModals } from '../../actions';
import ModalEditLevelCustomer from './ModalEditLevelCustomer';
import { Tooltip } from 'antd';

type Props = {
  dataDetail?: DataDetailCusomerType;
  callApiDetailCustomer: () => void;
};

export default function SectionUserInfor(props: Props) {
  const { stateCustomerManagement, dispatchCustomerManagement } = useContext(CustomerManagementContext);

  function showModalEditCustomer() {
    dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalsEditCustomer: true, modalConfirm: false }));
  }
  return (
    <div className="section-user-infor">
      <div className="title">
        <div className="box-title"> Thông tin khách hàng</div>
      </div>
      <div className="content-user">
        <div className="avatar-column">
          <img src={Avatar} alt="" />
        </div>
        <div className="item-column">
          <div className="label">Họ và tên</div>
          <div className="value">{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.fullName : '-'}</div>
          <div className="label">Trạng thái</div>
          <div className="value">
            <StatusesText context="CUSTOMER" status={props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.status : ''}></StatusesText>
          </div>
          <div className="label">Năm sinh</div>
          <div className="value">{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.birthday : '-'}</div>
        </div>
        <div className="item-column">
          <div className="label">CMND/CCCD</div>
          <div className="value">{props?.dataDetail && props?.dataDetail?.user?.idCard ? props?.dataDetail?.user?.idCard : '-'}</div>
          <div className="label">Số điện thoại</div>
          <div className="value">{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.phone : '-'}</div>
        </div>
        <div className="item-column">
          <div className="label">Email</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.email : '-'}</span>}>
            <div className="value">{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.email : '-'}</div>
          </Tooltip>
          <div className="label">Điểm</div>
          <div className="value" style={{ width: '60px' }}>
            <CreditScoring score={props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.point : ''} />
          </div>
        </div>
        <div className="item-column">
          <div className="label">Level</div>
          <div className="value">
            <div className="box-edit-customer">
              <div>{props?.dataDetail && props?.dataDetail?.user ? props?.dataDetail?.user?.level : ''}</div>
              <div className="edit-customer" onClick={showModalEditCustomer}>
                Sửa
              </div>
            </div>
          </div>
          <div className="label">Ghi chú</div>
          <div className="value">-</div>
        </div>
      </div>
      {props?.dataDetail && <ModalEditLevelCustomer callApiDetailCustomer={props?.callApiDetailCustomer} dataDetail={props?.dataDetail && props?.dataDetail}></ModalEditLevelCustomer>}
    </div>
  );
}
