import React, { useContext } from 'react';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../Policy.style';
import { AiOutlineSearch } from 'react-icons/ai';
import { PolicyContext } from '../Context';
import { updateFiltersPolicy, updateModalsPolicy } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import ModalCreatePolicy from './ModalCreatePolicy';

export const FormSearchPolicy = () => {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchPolicy(updateFiltersPolicy({ ...statePolicy?.filtersPolicy, key: e.target.value, pageIndex: 1 }));
    }
  }

  function handleOpenModalCreate() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: true }));
  }

  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <Form.Item name={'keyword'}>
            <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Mã chính sách'} />
          </Form.Item>
          <div className="margin-top-10">
            <BtnCreateStyle onClick={handleOpenModalCreate} label="Thêm chính sách"></BtnCreateStyle>
          </div>
        </div>
      </S2.FormSearch>
      <ModalCreatePolicy></ModalCreatePolicy>
    </GS.WrapperOverrideInput>
  );
};
