import { CheckDataOriginalType, FilterReconciliationDisbursementType, filtersReconciliationDisbursementDetail, ReducerType } from './interface';

export const UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT = 'UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT';
export const UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_DISBURSEMENT = 'UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_DISBURSEMENT';
export const UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT_DETAIL = 'UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT_DETAIL';
export const updateFiltersReconciliationDisbursementList = (filtersReconciliationDisbursement: FilterReconciliationDisbursementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT,
    filtersReconciliationDisbursement: filtersReconciliationDisbursement,
  };
};
export const updateFiltersReconciliationDisbursementDetail = (filtersReconciliationDisbursementDetail: filtersReconciliationDisbursementDetail): ReducerType => {
  return {
    type: UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT_DETAIL,
    filtersReconciliationDisbursementDetail: filtersReconciliationDisbursementDetail,
  };
};

export const updateCheckDataOriginal = (checkDataOriginal: CheckDataOriginalType): ReducerType => {
  return {
    type: UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_DISBURSEMENT,
    checkDataOriginal: checkDataOriginal,
  };
};
