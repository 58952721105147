import React from 'react';

import * as S2 from '../../Capital.styles';
// import { FormSearchDisbursement } from './FormSearchDisbursement';
// import { ColumnsType } from 'antd/lib/table';
// import { formatNumberWithCommas } from '@app/utils/utils';
// import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
// import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
// import { DataTable } from '../../../Disbursement/Loan/interface';
// import * as S from '../../../Disbursement/Loan/Disbursement.styles';
// import Avatar from '@app/assets/icons/avatar.svg';
// import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
// import { useNavigate } from 'react-router-dom';

import TableDisbursementSectionDetail from '../../../Disbursement/Loan/ComponentDisbursement/TableDisbursementSectionDetail';
import { DisbursementProvider } from '../../../Disbursement/Loan/Context';
import { ResponseApiDetail } from '../../interface';
type Props = {
  dataDetail?: ResponseApiDetail | undefined | null;
};
export default function TableDisbursementSection(props: Props) {
  // const navigate = useNavigate();

  // function showModalDetail(value: DataTable) {
  //   console.log('detailmodal', value);
  // }

  // function moveDetailLoan(value: DataTable) {
  //   navigate(`/customers/loan/${value?.loanCode}`);
  // }

  return (
    <DisbursementProvider>
      <S2.StyleTableDetailTab>
        <TableDisbursementSectionDetail idCapital={props?.dataDetail?.code && props?.dataDetail?.code}></TableDisbursementSectionDetail>

        <div className="watch-all">
          <div className="text-all">Xem tất cả</div>
        </div>
      </S2.StyleTableDetailTab>
    </DisbursementProvider>
  );
}
