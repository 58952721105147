import { Popover } from 'antd';
import styled from 'styled-components';

export const PopoverStyle = styled(Popover)`
  .ant-popover-inner-content {
    width: 100px;
  }
  .ant-popover-inner {
    background: red !important;
  }
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
  .ant-popover-inner-content {
    background: #212830;
  }
  .style-text-action-approval {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .style-text-action-reject {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    margin-bottom: 0;
    cursor: pointer;
  }
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;

    color: #597ef7;
    cursor: pointer;
  }
  .style-text-action-BF {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
    cursor: pointer;
  }
`;

export const StyleColumnAction = styled.div`
  .ant-popover-inner {
    background: red !important;
  }
  .box-action {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .box-action-available {
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
  }
  .action-style-availble {
    width: 20px;
    background: #212830;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding-bottom: 5px;
    margin-bottom: 0;
  }
  display: flex;
  max-width: 200px;
  .style-text-action-watch {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .style-text-action-approval {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
    cursor: pointer;
  }
  .style-text-action-reject {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    cursor: pointer;
  }
  .style-text-action-approval-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
  .style-text-action-reject-disable {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #586678;
  }
`;
