import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { ProductContext, ProductProvider } from './Context';
import TableProduct from './Component/TableProduct';

export default function ProductManagement() {
  const { dispatchProduct } = useContext(ProductContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý sản phẩm',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Quản lý sản phẩm`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchProduct, dispatch]);
  return (
    <ProductProvider>
      <PageTitle>Quản lý sản phẩm</PageTitle>

      <div>
        <TableProduct></TableProduct>
      </div>
    </ProductProvider>
  );
}
