import React, { useContext, useEffect, useState } from 'react';
// import { Form, Button } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../../../Management/CustomerManagement.styles';
import * as S2 from '../../../../Loan/Loan.styles';
import { Form, Select } from 'antd';
import { AiOutlineExport } from 'react-icons/ai';
import { updateFiltersWaitForDisbursementDetailDay, updateStateModalDisbursement } from '../../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
// import { DataTableDetailWaitForDisbursement } from '../interface';
// import { updatePendingDisbursement } from '../Loan.api';
import _ from 'lodash';
import { DisbursementContext } from '../../Context';
import { DataTableDetailWaitForDisbursement } from '../../interface';
import { DataResponseApiPartnerList } from '../../../../Partner/interface';
import { getPartnerApi } from '../../../../Partner/Partner.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { ExportExcelDisbursementDetailInDay, updatePendingDisbursementApi } from '../../Disbursement.api';
import { getProductApi } from '../../../../Product/Product.api';
import { notificationController } from '@app/controllers/notificationController';
import ModalSelectFund from './ModalSelectFund';
import ModalListErrDetail from './ModalListErrDetail';
import { useParams } from 'react-router-dom';

type Props = {
  dataDetailWaitForDisbursement?: DataTableDetailWaitForDisbursement[] | undefined;
  callApiLoanDetailWaitForDisbursement?: () => void;
  dateReview?: string | undefined;
};

export const FormSearchDetailWaitForDisbursement = (props: Props) => {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [dataPartner, setdataPartner] = useState<DataResponseApiPartnerList>();
  const [dataListProduct, setdataListProduct] = useState<any>();
  const { dateReview }: any = useParams();
  const [checkLoading, setcheckLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkDisabled, setcheckDisabled] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [classNameDisabled, setclassNameDisabled] = useState<string>('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  //   function onChangeSelectPartner(e: string) {
  //     dispatchDisbursement(
  //       updateFiltersDetailWaitForDisbursement({
  //         ...stateDisbursement?.filters,
  //         partner: e,
  //         pageIndex: 1,
  //         pageSize: stateDisbursement?.filtersTabWaitForDisbursementDetailDay?.pageSize && stateDisbursement?.filtersTabWaitForDisbursementDetailDay?.pageSize,
  //       }),
  //     );
  //   }
  //   useEffect(() => {
  //     if (props?.dataDetailWaitForDisbursement) {
  //       if (props?.dataDetailWaitForDisbursement?.[0].approvalDisbursementTime) {
  //         setcheckDisabled(true);
  //         setclassNameDisabled('style-disabled');
  //       } else {
  //         setcheckDisabled(false);
  //         setclassNameDisabled('');
  //       }
  //     }
  //   }, [props?.dataDetailWaitForDisbursement, stateDisbursement?.filtersDetailWaitForDisbursement]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleOk() {
    setcheckBtnLoading(true);

    updatePendingDisbursementApi(undefined)
      .then((res) => {
        console.log(res);
        if (res?.error) {
          setcheckBtnLoading(false);
          dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement?.stateModal, stateModalConfirm: false }));
        } else {
          setcheckBtnLoading(false);
          dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement?.stateModal, stateModalConfirm: false }));

          // props?.callApiLoanDetailWaitForDisbursement();
          notificationController.success({ message: `Xác nhận giải ngân thành công` });
        }
      })
      .catch((err) => {
        setcheckBtnLoading(false);
        dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement?.stateModal, stateModalConfirm: false }));

        console.log(err);
        notificationController.error({ message: _.get(`Something went wrong Api`, `Something went wrong Api`, `Something went wrong Api`) });
      });
  }

  const callApiListPartner = async () => {
    await getPartnerApi(
      queryStringParams({
        name: '',
        pageSize: 100000,
        pageIndex: 1,
      }),
    )
      .then((res) => {
        setdataPartner(res);
      })
      .catch((err) => {
        console.log(err);
        setdataPartner(undefined);
      });
  };
  useEffect(() => {
    callApiListPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callApiListProduct = async () => {
    await getProductApi()
      .then((res) => {
        if (res?.error) {
          setdataListProduct(undefined);
        } else {
          setdataListProduct(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setdataListProduct(undefined);
      });
  };

  useEffect(() => {
    callApiListProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onChangePartnerSelect(e: string) {
    dispatchDisbursement(updateFiltersWaitForDisbursementDetailDay({ ...stateDisbursement?.filtersTabWaitForDisbursementDetailDay, partner: e, pageIndex: 1 }));
  }

  function onChangeFundSelect(e: string) {
    dispatchDisbursement(updateFiltersWaitForDisbursementDetailDay({ ...stateDisbursement?.filtersTabWaitForDisbursementDetailDay, product: e, pageIndex: 1 }));
  }

  function onChangeSelectStatus(e: string) {
    dispatchDisbursement(updateFiltersWaitForDisbursementDetailDay({ ...stateDisbursement?.filtersTabWaitForDisbursementDetailDay, status: e, pageIndex: 1 }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function handleCancelConfirm() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement?.stateModal, stateModalConfirm: false }));
  }

  function ConfirmDisbursement() {
    // if (checkDisabled == false) {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement?.stateModal, stateModalSelectFund: true }));
    // }
  }

  useEffect(() => {
    if (stateDisbursement?.stateModal?.checkStateModalListErrDetail == true) {
      dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalListErrDetail: true }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement?.stateModal?.checkStateModalListErrDetail]);

  function ExportExcelFunc() {
    setcheckLoading(true);
    ExportExcelDisbursementDetailInDay(
      dateReview,
      queryStringParams({
        product: stateDisbursement?.filtersTabWaitForDisbursementDetailDay.product,
        status: stateDisbursement?.filtersTabWaitForDisbursementDetailDay.status,
        partner: stateDisbursement?.filtersTabWaitForDisbursementDetailDay.partner,
      }),
    )
      .then((res) => {
        if (!res?.error) {
          setcheckLoading(false);
          notificationController.success({ message: `Xuất file thành công , vui lòng check mail` });
        } else {
          setcheckLoading(false);
        }
      })
      .catch(() => {
        setcheckLoading(false);
        console.log('err');
      });
  }

  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEndFormSearchWaitForDisbursement>
            <S2.Dflex>
              <div className={'filter-item'}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangePartnerSelect} className="style-select-partner" placeholder={'Đối tác'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value="">Tất cả</Select.Option>

                    {dataPartner?.data &&
                      dataPartner?.data?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item?.code}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '8px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeFundSelect} placeholder={'Loại sản phẩm'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value="">Tất cả</Select.Option>

                    {dataListProduct &&
                      dataListProduct?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item?.name}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '8px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectStatus} className="style-select-partner" placeholder={'Trạng thái'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>
                    <Select.Option value={'IN_REVIEW'}>Chờ giải ngân</Select.Option>

                    <Select.Option value={'COMPLETED'}>Đã giải ngân</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </S2.Dflex>
            <div className="box-btn">
              <Form.Item className="btn-create">
                <BtnCreateStyle className={classNameDisabled && classNameDisabled} disabled={checkDisabled} onClick={ConfirmDisbursement} label={'Đã giải ngân'}></BtnCreateStyle>
              </Form.Item>
              <Form.Item>
                <GS.StyleBtnExcel loading={checkLoading} onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
                  Xuất Excel
                </GS.StyleBtnExcel>
              </Form.Item>
            </div>
          </S2.DflexStartEndFormSearchWaitForDisbursement>
        </S.WrapperFilters>

        <ModalSelectFund
          callApiLoanDetailWaitForDisbursement={props?.callApiLoanDetailWaitForDisbursement && props?.callApiLoanDetailWaitForDisbursement}
          dateReview={props?.dateReview}
        ></ModalSelectFund>
        {stateDisbursement?.stateModal?.checkStateModalListErrDetail == true ? <ModalListErrDetail></ModalListErrDetail> : null}
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
