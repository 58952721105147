import { StatusesConciliation } from '@app/interfaces/interfaces';
import { formatNumberWithCommas } from '@app/utils/utils';
import { Divider } from 'antd';
import React, { useEffect, useState } from 'react';
import * as S from './TableColumnTime.style';

type Props = {
  valuePartner: number | string | undefined | any;
  valueBF: number | string | undefined | any;
  status: StatusesConciliation | string;
  type: 'string' | 'number';
};

export default function TableColumnReconciliation(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [varPartner, setvarPartner] = useState<number | string | undefined>();
  const [check, setcheck] = useState<boolean>();

  const [varBF, setvarBF] = useState<number | string | undefined>();

  const renderColumn = () => {
    return (
      <>
        <div className={props.valueBF == props.valuePartner ? '' : 'err-text'}>
          {/* {props && props.valuePartner ? props.valuePartner : 'N/A'}
          {props && props.valueBF && props.valuePartner && props.valueBF == props.valuePartner ? '' : <Divider type="vertical" />}
          {props && props.valueBF == props.valuePartner ? tet : props.valueBF} */}
          {varPartner}
          {check ? <Divider type="vertical" /> : ''}
          {varBF}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (props) {
      setcheck(true);
      if (props.valuePartner) {
        setvarBF('N/A');
        if (props.type == 'number') {
          setvarPartner(formatNumberWithCommas(props.valuePartner));
        } else {
          setvarPartner(props.valuePartner);
        }
      }
      if (props.valueBF) {
        setvarPartner('N/A');
        if (props.type == 'number') {
          setvarBF(formatNumberWithCommas(props.valueBF));
        } else {
          setvarBF(props.valueBF);
        }
      }
      if (props.valueBF && props.valuePartner) {
        setvarPartner(props.valuePartner);

        if (props.valueBF == props.valuePartner) {
          setvarBF('');
          setcheck(false);
          if (props.type == 'number') {
            setvarPartner(formatNumberWithCommas(props.valuePartner));
          }
        } else {
          setvarBF(props.valueBF);
          if (props.type == 'number') {
            setvarPartner(formatNumberWithCommas(props.valuePartner));
            setvarBF(formatNumberWithCommas(props.valueBF));
          }
        }
      }
    }
    renderColumn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <S.StyleColumnTime>
      {props && props?.status == 'INVALID_FROM_BIFIIN' ? (
        <div className="err-text">
          {props && varPartner}
          <Divider type="vertical" />
          N/A
        </div>
      ) : '' || (props && props?.status == 'INVALID_FROM_PARTNER') ? (
        <div className="err-text">
          N/A
          <Divider type="vertical" />
          {props && varBF}
        </div>
      ) : '' || (props && props?.status == 'SUCCESS') ? (
        props.valuePartner ? (
          varPartner
        ) : (
          varBF
        )
      ) : null || (props && props?.status == 'FAILED') ? (
        renderColumn()
      ) : null}
    </S.StyleColumnTime>
  );
}
