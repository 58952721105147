import { PlusOutlined } from '@ant-design/icons';
import React from 'react';
import * as S from './BtnCreateStyle.style';
type Props = {
  onClick?: () => void;
  label: string;
  haveNotIcon?: boolean;
  loading?: boolean;
  htmlType?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  disabled?: boolean;
};

export default function BtnCreateStyle(props: Props) {
  return (
    <S.StyleBtnCreate
      className={props?.className}
      htmlType={props?.htmlType}
      loading={props?.loading}
      disabled={props?.disabled}
      onClick={props.onClick}
      icon={props?.haveNotIcon ? null : <PlusOutlined style={{ color: 'black' }} />}
    >
      {props.label}
    </S.StyleBtnCreate>
  );
}
