import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_DEBTLIST, UPDATE_FILTERS_DEBTLIST_WAIT_FOR_APPROVE, UPDATE_MODAL_DEBTLIST } from './Action';
import { ReducerType } from './interface';

export const initDebtList = {
  stateDebtList: {
    form: null,
    filtersDebtList: {
      pageSize: 20,
      pageIndex: 1,
      partner: '',
      status: '',
      createdDate: '',
      text: '',
      fromDate: '',
      toDate: '',
    },
    filtersWaitForApprove: {
      pageSize: 20,
      pageIndex: 1,
      status: '',
      createdDate: '',
      partnerName: '',
    },
    modals: {
      modalAprrove: false,
      modalDetailPayment: false,
      modalRejectPayment: false,
      modalCreate: false,
    },
  },
  dispatchDebtList: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_DEBTLIST:
      return {
        ...state,
        filtersDebtList: action.filtersDebtList,
      };
    case UPDATE_FILTERS_DEBTLIST_WAIT_FOR_APPROVE:
      return {
        ...state,
        filtersWaitForApprove: action.filtersWaitForApprove,
      };
    case UPDATE_MODAL_DEBTLIST:
      return {
        ...state,
        modals: action.modals,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useDebtListReducer = () => {
  return useReducer(reducer, initDebtList.stateDebtList);
};
