import { HEX } from '@app/constants/creditScoring';

export type StatusesTypo =
  | 'DONE'
  | 'WAIT_FOR_DISBURSEMENT'
  | 'DRAFT'
  | 'OVERDUE'
  | 'ACTIVE'
  | 'RECEIVE'
  | 'REJECTED'
  | 'APPROVED'
  | 'WAIT_FOR_APPROVE'
  | 'INACTIVE'
  | 'OVERDUE'
  | 'WAIT_FOR_OUTGOING'
  | 'TO_DUE_SOON'
  | 'CANCELED'
  | 'COMPLETED'
  | 'WAIT_FOR_VERIFY_OTP'
  | 'IN_ACTIVE'
  | 'SUCCESS'
  | 'FAILED'
  | 'VERIFIED'
  | 'INVALID_FROM_PARTNER'
  | 'INVALID_FROM_BIFIIN'
  | 'WAIT'
  | 'DISABLED'
  | 'DEFAULT'
  | 'CANCELLED'
  | 'ACTIVE'
  | 'NORMAL'
  | 'EXPIRE'
  | 'RECALL'
  | 'IN_REVIEW'
  | 'SETTLEMENT';
export type StatusesContextType = 'CUSTOMER' | 'LOAN' | 'RECONCILIATION' | 'PAYMENT' | 'PAYMENT_TRANSACTION_BF' | 'CAPITAL' | 'PAYMENT_TRANSACTION_PARTNER' | 'POLICES' | 'EKYC' | 'DISBURSEMENT';
export type StatusAntCode = 'success' | 'error' | 'default' | 'processing' | 'warning' | 'gold' | HEX;

type StatusEnumType = {
  [key in StatusesContextType]?: {
    [key in StatusesTypo]?: {
      colorOrigin: StatusAntCode;
      text: string;
      ts?: string; // optional for use translation, in the case passed a ts field, will be use this field instead text
      color?: string | ''; // optional for force use color as defining instead colorOrigin
    };
  };
};

export const StatusDefinitionEnum: StatusEnumType = {
  RECONCILIATION: {
    SUCCESS: {
      colorOrigin: '#73D13D',
      text: 'Đã đối soát',
      color: '#73D13D',
    },
    FAILED: {
      colorOrigin: '#FA8C16',
      text: 'Cần xử lý',
      color: '#FA8C16',
    },
    INVALID_FROM_PARTNER: {
      colorOrigin: '#FA8C16',
      color: '#FA8C16',
      text: 'Cần xử lý',
    },
    INVALID_FROM_BIFIIN: {
      colorOrigin: '#FA8C16',
      color: '#FA8C16',
      text: 'Cần xử lý',
    },
  },
  EKYC: {
    SUCCESS: {
      colorOrigin: '#73D13D',
      text: 'Đã xác thực',
      color: '#73D13D',
    },
    WAIT_FOR_APPROVE: {
      colorOrigin: '#FA8C16',
      text: 'Chờ duyệt',
      color: '#FA8C16',
    },
    FAILED: {
      colorOrigin: '#FF4D4F',
      text: 'Thất bại',
      color: '#FF4D4F',
    },
    CANCELED: {
      colorOrigin: '#D9D9D9',
      text: 'Từ chối',
      color: '#D9D9D9',
    },
  },
  POLICES: {
    ACTIVE: {
      colorOrigin: '#73D13D',
      text: 'Đang hoạt động',
      color: '#73D13D',
    },
    NORMAL: {
      colorOrigin: '#F2C015',
      text: 'Chưa hoạt động',
      color: '#F2C015',
    },
    EXPIRE: {
      colorOrigin: '#F5222D',
      color: '#F5222D',
      text: 'Hết hạn',
    },
  },
  CUSTOMER: {
    ACTIVE: {
      colorOrigin: 'success',
      text: 'Hoạt động',
      color: '#73D13D',
    },
    IN_ACTIVE: {
      colorOrigin: 'success',
      text: 'Ngưng HĐ',
      color: '#595959',
    },
    WAIT_FOR_VERIFY_OTP: {
      colorOrigin: 'success',
      color: '#FA8C16',
      text: 'Chưa xác thực',
    },
  },
  CAPITAL: {
    ACTIVE: {
      colorOrigin: 'success',
      text: 'Hoạt động',
      color: '#73D13D',
    },
    DISABLED: {
      colorOrigin: 'failed',
      text: 'Ngừng hoạt động',
      color: '#595959',
    },
  },
  DISBURSEMENT: {
    IN_REVIEW: {
      colorOrigin: '#FF7A45',
      text: 'Chờ giải ngân',
      color: '#FF7A45',
    },
    COMPLETED: {
      colorOrigin: '#73D13D',
      text: 'Đã giải ngân',
      color: '#73D13D',
    },
  },
  PAYMENT: {
    WAIT: {
      colorOrigin: 'success',
      text: 'Chờ duyệt',
      color: '#FA8C16',
    },
    VERIFIED: {
      colorOrigin: 'success',
      text: 'Đã xác nhận',
      color: '#73D13D',
    },
    REJECTED: {
      colorOrigin: 'success',
      color: '#FF4D4F',
      text: 'Từ chối',
    },
  },
  PAYMENT_TRANSACTION_BF: {
    DONE: {
      colorOrigin: 'success',
      color: '#40A9FF',
      text: 'BF đã nhận',
    },
  },
  PAYMENT_TRANSACTION_PARTNER: {
    DONE: {
      colorOrigin: 'success',
      color: '#73D13D',
      text: 'Partner đã nhận',
    },
  },
  LOAN: {
    RECALL: {
      color: '#FF4D4F',
      colorOrigin: '#FF4D4F',
      text: 'Thu hồi',
    },
    DRAFT: {
      color: '#D9D9D9',
      colorOrigin: '#D9D9D9',
      text: 'Nháp',
    },
    WAIT_FOR_APPROVE: {
      color: '#FA8C16',
      colorOrigin: 'processing',
      text: 'Chờ duyệt',
    },
    APPROVED: {
      color: '#52C41A',
      colorOrigin: 'processing',
      text: 'Đã duyệt',
    },

    REJECTED: {
      colorOrigin: 'default',
      color: '#FF4D4F',
      text: 'Từ chối',
    },
    WAIT_FOR_DISBURSEMENT: {
      color: '#9254DE',
      colorOrigin: '#9254DE',
      text: 'Chờ giải ngân',
    },
    OVERDUE: {
      colorOrigin: '#FF4D4F',
      text: 'Quá hạn',
      color: '#FF4D4F',
    },
    DONE: {
      colorOrigin: '#597EF7',
      color: '#597EF7',
      text: 'Đã tất toán',
    },
    CANCELED: {
      colorOrigin: '#595959',
      text: 'Đã hủy',
      color: '#595959',
    },
    ACTIVE: {
      colorOrigin: '#73D13D',
      text: 'Hoạt động',
      color: '#73D13D',
    },
    CANCELLED: {
      colorOrigin: '#595959',
      text: 'Đã hủy',
      color: '#595959',
    },
  },
};
