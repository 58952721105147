import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { Modal, Tabs, Timeline } from 'antd';

// export const Loan = styled.div`
// `;
export const Menu = styled.div`
  display: flex;
`;
export const H2 = styled.div`
  padding-left: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const DflexStartEnd = styled.div`
  .style-select-date-payment {
    min-width: 162px;
  }
  .style-select-partner {
    min-width: 152px;
  }
  .style-select-status {
    min-width: 143px;
  }
  /* padding-top: 30px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
`;
export const Dflex = styled.div`
  display: flex;
  width: 49%;
`;
export const StyledButtonA = styled(Button)`
  color: red;
  background-color: yellow;
`;
export const StyledButtonB = styled(Button)`
  color: pink;
  background-color: green;
`;

export const styleBoxImg = styled.div``;

export const ModalCreate = styled(Modal)`
  .margin-bottom-20 {
    margin-bottom: 10px;
  }
  .css-btn-yellow {
    background: #f2c015 !important;
    color: black !important;
    height: 32px;
    border: 1px solid #f2c015 !important;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 818px;
    background: #1a1f25;
    border-radius: 4px;
  }
  .ant-select-selector {
    background-color: #272d35 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
  .style-input-create {
    background: #272d35;
    border-radius: 2px;
    padding-left: 12px;
  }
  .ant-modal-content {
    width: 907px;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm.ant-input-affix-wrapper-status-success {
    width: 100%;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  input#keyword {
    background: #272d35;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    background: #272d35;
    width: 100%;
  }
  .content {
    width: 906px;
    background-color: #1a1f25;
    .box-content-body-take-border-bottom {
      .ant-checkbox-wrapper + .ant-checkbox-wrapper {
        margin-left: 0px !important;
      }
      label.ant-checkbox-wrapper.ant-checkbox-group-item {
        width: 47% !important;
      }
      display: flex;
      padding-bottom: 20px;
      justify-content: space-between;
      .item-checkbox {
        width: 47%;
      }
      border-bottom: 1px solid #3c4450;
      padding: 10px;
      .box-content-body {
        display: flex;
        padding: 16px 32px;
        justify-content: space-between;
        .col-1 {
          width: 47.5%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
          }
          .row-checkbox {
            margin-bottom: 10px;
            .title-checkbox {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #f0f0f0;
            }
          }
          .row-checkbox-type {
            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
              margin-left: 0px !important;
            }
            label.ant-checkbox-wrapper.ant-checkbox-group-item {
              width: 47% !important;
            }
            display: flex;
            padding-bottom: 20px;
            justify-content: space-between;
            .item-checkbox {
              width: 47%;
            }
            .title-checkbox {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #f0f0f0;
            }
          }
        }
        .col-2 {
          width: 47.5%;

          .box-img-content {
            width: 100%;
            height: 574px;
            margin-top: 50px;
            margin-bottom: 10px;
            display: flex;
            justify-content: center;

            .box-img {
              width: 100%;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
              .style-img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
          }
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            .icon-special {
              color: #ff4d4f;
              margin-right: 5px;
              font-size: 14px;
              margin-top: 3px;
            }
          }
          .box-img {
            width: 100%;
            height: 244px;
            border: 1.5px dashed #3c4450;
            border-radius: 4px;
            margin-top: 6px;
          }
        }
      }
    }
    .footer {
      padding: 24px 42px;
      display: flex;
      justify-content: end;
      .box-btn {
        display: flex;
        .btn-cancel {
          margin-right: 12px;
          background: #1a1f25;
          border: 1px solid #d9d9d9;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 2px;
          box-sizing: border-box;
        }
        .btn-save {
          background: #9ea6ac;
          box-sizing: border-box;
          border: 1px solid #9ea6ac;
          border-radius: 2px;
        }
      }
    }
  }
`;

export const StyleCheckboxCapital = styled.div`
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
  }
  label.ant-checkbox-wrapper.ant-checkbox-group-item {
    width: 47% !important;
  }
  display: flex;
  padding-bottom: 20px;
  justify-content: space-between;
  .item-checkbox {
    width: 47%;
  }
  .title-checkbox {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #f0f0f0;
  }
`;

export const ModalEdit = styled(Modal)`
  .label-type-loan {
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .margin-top-10 {
    margin-top: 10px;
  }
  .text-action {
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 818px;
    background: #1a1f25;
    border-radius: 4px;
  }
  .ant-select-selector {
    background-color: #272d35 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    background-color: #1a1f25;
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
    border-bottom: none;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
  .style-input-create {
    background: #272d35;
    border-radius: 2px;
    padding-left: 12px;
  }
  .ant-modal-content {
    width: 907px;
  }
  .ant-tabs-content-holder {
    border-bottom: 1px solid #3c4450;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm.ant-input-affix-wrapper-status-success {
    width: 100%;
    border: 1px solid #3c4450;
    border-radius: 2px;
  }
  input#keyword {
    background: #272d35;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    background: #272d35;
    width: 100%;
  }
  .content {
    width: 906px;
    background-color: #1a1f25;

    .box-content-body-take-border-bottom {
      .box-content-body {
        display: flex;
        padding-bottom: 32px;

        justify-content: space-between;
        .col-1 {
          width: 47.5%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
          }
          .row-checkbox {
            margin-bottom: 10px;
            .title-checkbox {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #f0f0f0;
            }
          }
          .row-checkbox-type {
            display: flex;
            padding-bottom: 20px;
            .title-checkbox {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #f0f0f0;
            }
          }
        }
        .col-2 {
          width: 47.5%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            .icon-special {
              color: #ff4d4f;
              margin-right: 5px;
              font-size: 14px;
              margin-top: 3px;
            }
          }
          .box-img {
            width: 100%;
            height: 244px;
            border: 1.5px dashed #3c4450;
            border-radius: 4px;
            margin-top: 6px;
          }
          .title-section-img {
            display: flex;
            margin-top: 12px;
            justify-content: space-between;
            .box-startend {
              display: flex;
              width: 60%;
            }
            .name-img {
              display: flex;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f2c015;
              margin-left: 5px;
            }
          }
        }
      }
    }
    .footer {
      padding: 24px 42px;
      display: flex;
      justify-content: end;
      .box-btn {
        display: flex;
        .btn-cancel {
          margin-right: 12px;
          background: #1a1f25;
          border: 1px solid #d9d9d9;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
          border-radius: 2px;
          box-sizing: border-box;
        }
        .btn-save {
          background: #9ea6ac;
          box-sizing: border-box;
          border: 1px solid #9ea6ac;
          border-radius: 2px;
        }
      }
    }
  }
`;
export const TabStyle = styled(Tabs)`
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #3c4450;
    padding-left: 32px;
    padding-right: 32px;
  }
  .ant-tabs-content.ant-tabs-content-top {
    padding: 24px 32px 32px 32px;
  }
`;
export const TabStyleSectionMoney = styled(Tabs)`
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #3c4450;
    padding-left: 0px;
    padding-right: 0px;
  }

  .ant-tabs-tab {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #d9d9d9;
  }

  .label-add-capital {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #8c8c8c;
    margin-top: 24px;
  }
  .text-description {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #bfbfbf;
  }
  input#basic_name {
    border: 1px solid #686868;
    border-radius: 2px;
  }
  .ljEjZQ .ant-tabs-content.ant-tabs-content-top {
    padding: 0px !important;
  }
`;
export const StyleTabMoney = styled.div`
  display: flex;
  .ant-tabs-content.ant-tabs-content-top {
    padding: 0px !important;
  }

  .col1 {
    width: 50%;
    border-right: 1px solid #3c4450;
    padding-right: 30px;
    padding-bottom: 70px;

    .title {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
    }
    .text-2 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #8c8c8c;
      margin-top: 10px;
    }
  }
  .col2 {
    width: 50%;
    .label-col2 {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #f0f0f0;
      padding-left: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid #3c4450;
    }
  }
  .ljEjZQ .ant-tabs-content.ant-tabs-content-top {
    padding: 0px !important;
  }
  .ant-tabs-content-holder {
    border-bottom: 1px solid #1a1f25;
  }
  .box-timeline {
    padding: 24px;
  }
`;

export const CssTimeline = styled(Timeline)`
  color: #bfbfbf !important;
  ul.ant-timeline {
    color: #bfbfbf !important;
  }
  .ant-timeline-item-head.ant-timeline-item-head-blue {
    border: 2px solid #85a5ff;
  }
  .ant-timeline-item-tail {
    border: 1px solid #3c4450;
  }
  .ant-timeline-item-content {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 27px;
    color: #bfbfbf;
  }
`;
export const DflexPara = styled.div`
  display: flex;
`;
export const StyleStar = styled.div`
  color: #ff4d4f;
  font-size: 14;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 4px;
`;

export const StylePara = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;

  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 250px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const StyleInput = styled.div`
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }
  .ant-select-selector {
    background: #272d35 !important;
  }
`;
export const InputPara = styled.div`
  input#para {
    background: #272d35 !important;
    margin-top: 15px;
  }
`;
export const DflexEnd = styled.div`
  display: flex;
  justify-content: end;
`;
export const StyleMessage = styled.div`
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
`;
export const StyleBtnCreate = styled(Button)`
  .style-modal-create {
    color: #161a1e;
  }
  background-color: #f2c015;
  color: black !important;
  margin-left: 10px;
  border: none !important;
  :hover {
    color: #fff !important;
  }
`;

export const ModalReject = styled(Modal)`
  .btn-save-change {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    gap: 10px;
    width: 117px;
    height: 32px;
    background: #9ea6ac;
    border: 1px solid #9ea6ac;
    border-radius: 2px;
  }
  .ant-modal-content {
    width: 591px;
  }
  .ant-modal-footer {
    padding: 0px 32px 32px 32px;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 12px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const StyleTextLoanCode = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2c015;
`;
export const StyleCustomerName = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #d9d9d9;
`;
export const BoxCapital = styled.div`
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .ant-table-header.ant-table-sticky-holder {
    top: 0px !important;
  }
  .action-debt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
  }
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px 24px 24px;
  }
  .text-loancode {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f2c015;
  }
  .text-paymentcode {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f2c015;
  }
  .box-avatar {
    display: flex;
    .text-customer-name {
      margin-left: 10px;
    }
  }
`;

export const StyleTableDetailTab = styled.div`
  .watch-all {
    display: flex;
    margin-top: 20px;
    justify-content: end;
    .text-all {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #f2c015;
      cursor: pointer;
    }
  }
`;
export const ContainerDetailCapital = styled.div`
  .section-user-infor {
    background-color: #1a1f25;
    margin-top: 24px;
    .title {
      border-bottom: 1px solid #3c4450;
      .box-title {
        padding: 16px 24px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #ffffff;
      }
    }
    .content-user {
      padding: 24px 72px 24px 72px;
      display: flex;
      justify-content: space-between;
      .avatar-column {
        width: 94px;
        img {
          width: 100%;
        }
      }
      .item-column {
        width: 17%;
        .label {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #8c8c8c;
          margin-bottom: 8px;
        }
        .value {
          margin-bottom: 20px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f5f5f5;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .title-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #f2f2f2;
    padding: 0px 0px 24px 24px;
  }
  .content-body {
    background: #12161c;
    width: 100%;
    padding: 24px;
    .text-note {
      padding-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .section-btn-action {
      display: flex;
      justify-content: end;

      .box-btn {
        display: flex;
        justify-content: end;
        .btn-excel {
          height: 32px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #d9d9d9;
          margin-left: 10px;
        }
        .btn-edit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #f2c015;
          border: 1px solid #f2c015;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          color: #1f1f1f;
        }
      }
    }
    .section-user-infor {
      background-color: #1a1f25;
      margin-top: 24px;
      .title {
        border-bottom: 1px solid #3c4450;
        .box-title {
          padding: 16px 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-user {
        padding: 24px 72px 24px 72px;
        display: flex;
        justify-content: space-between;
        .avatar-column {
          width: 94px;
          img {
            width: 100%;
          }
        }
        .item-column {
          width: 17%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            margin-bottom: 8px;
            min-height: 40px;
          }
          .value {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
          }
        }
      }
    }
    .section-tabs-detail {
      margin-top: 24px;
      background: #1a1f25;
      .title {
        .box-title {
          padding: 16px 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-verify {
        .title-verify-face {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f5f5f5;
        }
        .image-verify-face {
          display: flex;
          margin-top: 24px;
          margin-bottom: 24px;
          .box-img {
            width: 140px;
            height: 159px;
            box-sizing: border-box;
            left: 24px;
            top: 118px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
        .CCCD-verify-face {
          display: flex;
          margin-top: 24px;
          .box-img {
            width: 333px;
            box-sizing: border-box;
            height: 194px;
            left: 24px;
            top: 347px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
      }
    }
  }
`;
