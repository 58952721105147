import React from 'react';
import { AppRouterInterface } from '@app/interfaces/interfaces';
import AuthenMeCallback from '@app/modules/Oauth2/AuthenMe/AuthenMeCallback';
import AuthenMeError from '@app/modules/Oauth2/AuthenMe/AuthenMeError';

export const OAuth2Routes: AppRouterInterface[] = [
  {
    path: 'callback',
    element: <AuthenMeCallback />,
  },
  {
    path: 'error',
    element: <AuthenMeError />,
  },
];
