import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { formatNumberWithCommas } from '@app/utils/utils';
import { ProductContext } from '../Context';
import { FormSearchProduct } from './FormSearchProduct';
import { getProductApi } from '../Product.api';
import { DataResponseApiProductList } from '../interface';
import ModalCreateProduct from './ModaCreateProduct';
import ModalEditProduct from './ModalEditProduct';
import { updateModalsProduct } from '../Action';
import { getPolicyApi } from '../../Policy/Policy.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { DataResponseApiPolicyList } from '../../Policy/interface';
import { PolicyContext } from '../../Policy/Context';
import ModalDetailProduct from './ModalDetailProduct';

export default function TableProduct() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataProduct, setDataProduct] = useState<DataResponseApiProductList[]>();
  const [dataListPolicy, setdataListPolicy] = useState<DataResponseApiPolicyList>();
  const { statePolicy } = useContext(PolicyContext);
  const [saveDataRecord, setsaveDataRecord] = useState<DataResponseApiProductList>();
  const { stateProduct, dispatchProduct } = useContext(ProductContext);

  const callApiListProduct = async () => {
    setcheckLoading(true);
    await getProductApi()
      .then((res) => {
        if (res?.error) {
          setDataProduct(undefined);
          setcheckLoading(false);
        } else {
          setDataProduct(res);
          setcheckLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setDataProduct(undefined);
        setcheckLoading(false);
      });
  };

  const callApiListPolicy = async () => {
    setcheckLoading(true);

    await getPolicyApi(
      queryStringParams({
        key: '',
        pageSize: 100000,
        pageIndex: 1,
      }),
    )
      .then((res) => {
        if (res?.error) {
          setcheckLoading(false);
        } else {
          setdataListPolicy(res);
          setcheckLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setdataListPolicy(undefined);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiListProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateProduct?.filtersProduct]);

  function handleOpenModalEdit(value: DataResponseApiProductList) {
    value && setsaveDataRecord(value);
    dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalEdit: true }));
  }

  function handleMoveDetail(value: DataResponseApiProductList) {
    value && setsaveDataRecord(value);
    dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalDetail: true }));
  }

  useEffect(() => {
    callApiListPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePolicy?.filtersPolicy]);

  const columnsConfig: ColumnsType<any> = [
    {
      width: '200px',
      title: 'Sản phẩm',
      key: 'name',
      fixed: 'left',
      dataIndex: 'name',
      render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
    },
    {
      width: '200px',
      title: 'Mã chính sách sử dụng',
      key: 'code',
      dataIndex: ['policy', 'code'],
      render: (value: string) => <div className="text-loancode">{value ? value : ''}</div>,
    },
    {
      width: '110px',
      title: 'Tỉ lệ cho vay / ĐH',
      align: 'right',
      key: 'maximumLoanRate',
      dataIndex: ['policy', 'maximumLoanRate'],
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '160px',
      title: 'Số tiền cho vay tối đa / KV',
      key: 'maximumLoanAmount',
      align: 'right',

      dataIndex: ['policy', 'maximumLoanAmount'],
      render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value ? value : 0) : ''}</div>,
    },
    {
      width: '130px',
      title: 'Điểm tín dụng tối thiểu/ĐH',
      key: 'creditScore',
      align: 'right',

      dataIndex: ['policy', 'creditScore'],
      render: (value: number) => <div className="text-loancode">{value ? value : 0}</div>,
    },
    {
      width: '160px',
      title: 'Số tiền duyệt tự động',
      align: 'right',

      key: 'loanAmountAutoApprove',
      dataIndex: ['policy', 'loanAmountAutoApprove'],
      render: (value: number) => <div className="text-loancode">{value ? '≤ ' + formatNumberWithCommas(value ? value : 0) : 0}</div>,
    },
    {
      width: '100px',
      title: 'Lãi/ năm',
      align: 'right',

      key: 'interestRate',
      dataIndex: ['policy', 'interestRate'],
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Phí',
      key: 'feeRate',
      align: 'right',

      dataIndex: ['policy', 'feeRate'],
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '130px',
      title: 'Ngày ân hạn',
      key: 'gracePeriod',
      dataIndex: ['policy', 'gracePeriod'],
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : ''}</div>,
    },
    {
      width: '140px',
      title: 'Số ngày/ kỳ',
      key: 'dayPerPeriod',
      dataIndex: ['policy', 'dayPerPeriod'],
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : ''}</div>,
    },
    {
      width: '120px',
      title: 'Phí quá hạn kỳ 1',
      key: 'lateFeeFirstTerm',
      align: 'right',

      dataIndex: ['policy', 'lateFeeFirstTerm'],
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '140px',
      title: 'Phí tăng sau mỗi kỳ (%)',
      key: 'increasingFeePerPeriod',
      align: 'right',

      dataIndex: ['policy', 'increasingFeePerPeriod'],
      render: (value: string) => <div className="text-loancode">{value ? value + '%' : 0}</div>,
    },
    {
      width: '120px',
      title: 'Số ngày bị thu hồi',
      key: 'dayRecall',
      dataIndex: ['policy', 'dayRecall'],
      render: (value: string) => <div className="text-loancode">{value ? value + ' Ngày' : ''}</div>,
    },

    {
      width: '134px',
      title: 'Hành động',
      render: (value: any) => {
        return (
          <div className="text-watch">
            <span onClick={() => handleMoveDetail(value)}>Chi tiết</span>
            <span style={{ marginLeft: '10px' }} onClick={() => handleOpenModalEdit(value ? value : undefined)}>
              Sửa
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <FormSearchProduct></FormSearchProduct>
          <Table
            scroll={{ x: 1750 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataProduct ? dataProduct : []}
            columns={columnsConfig && columnsConfig}
            loading={checkLoading}
            sticky={true}
            rowKey="name"
          />
        </div>
        {dataProduct ? <ModalCreateProduct dataListPolicy={dataListPolicy && dataListPolicy} dataProduct={dataProduct && dataProduct}></ModalCreateProduct> : null}
        {saveDataRecord && dataProduct ? (
          <ModalEditProduct dataListPolicy={dataListPolicy} dataProduct={dataProduct && dataProduct} saveDataRecord={saveDataRecord && saveDataRecord}></ModalEditProduct>
        ) : null}
        {saveDataRecord && dataProduct ? (
          <ModalDetailProduct dataListPolicy={dataListPolicy} dataProduct={dataProduct && dataProduct} saveDataRecord={saveDataRecord && saveDataRecord}></ModalDetailProduct>
        ) : null}
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
