import React from 'react';
import { initDebtList, useDebtListReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const DebtListContext = React.createContext({ ...initDebtList });
export const DebtListProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateDebtList, dispatchDebtList] = useDebtListReducer();

  const wrap = {
    stateDebtList,
    dispatchDebtList,
  };

  return <DebtListContext.Provider value={wrap}>{children}</DebtListContext.Provider>;
};
