import { ThemeType, CollapseType } from '@app/interfaces/interfaces';
import { createSlice, createAction, PrepareAction } from '@reduxjs/toolkit';

interface ThemeState {
  theme: ThemeType;
  isSiderCollapsed: CollapseType;
}

export const defaultTheme = (localStorage.getItem('theme') as ThemeType) || 'dark';
export const defaultCollapsed = (localStorage.getItem('isSiderCollapsed') as CollapseType) || 'collapsed';

localStorage.setItem('theme', defaultTheme);
localStorage.setItem('isSiderCollapsed', defaultCollapsed);

const initialState: ThemeState = {
  theme: defaultTheme,
  isSiderCollapsed: defaultCollapsed,
};

export const setTheme = createAction<PrepareAction<ThemeType>>('theme/setTheme', (theme: ThemeType) => {
  localStorage.setItem('theme', theme);
  return {
    payload: theme,
  };
});

export const setCollapse = createAction<PrepareAction<CollapseType>>('theme/setCollapse', (value: CollapseType) => {
  localStorage.setItem('isSiderCollapsed', value);
  return {
    payload: value,
  };
});

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setTheme, (state, action) => {
      state.theme = action.payload;
    });
    builder.addCase(setCollapse, (state, action) => {
      state.isSiderCollapsed = action.payload;
    });
  },
});

export default themeSlice.reducer;
