import React from 'react';
import * as S from '../../Capital.styles';
import TableDisbursementSection from './TableDisbursement';
import TableLoanSection from './TableLoan';
import { ResponseApiDetail } from '../../interface';

type Props = {
  dataDetail?: ResponseApiDetail | undefined | null;
};

export default function SectionTabsDetail(props: Props) {
  const onChange = () => {};

  const itemsContent = [
    {
      label: `Giải ngân`,
      key: '1',
      children: <TableDisbursementSection dataDetail={props?.dataDetail && props?.dataDetail}></TableDisbursementSection>,
    },
    {
      label: `Khoản vay`,
      key: '2',
      children: <TableLoanSection dataDetail={props?.dataDetail && props?.dataDetail}></TableLoanSection>,
    },
  ];
  return (
    <div className="section-tabs-detail">
      <div className="title">
        <div className="box-title">
          <S.TabStyle defaultActiveKey="1" onChange={onChange} items={itemsContent} />
        </div>
      </div>
    </div>
  );
}
