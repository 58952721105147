import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Row, Col } from 'antd';
import { Card } from '@app/components/common/Card/Card';
import { ValidationForm } from '@app/components/forms/ValidationForm/ValidationForm';

const SettingsMain: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <PageTitle>{t('common.settings')}</PageTitle>
      <Row gutter={[30, 30]}>
        <Col xs={24} sm={24} xl={10}>
          <Card id="validation form" title={t('forms.validationForm')} padding="1.25rem">
            <ValidationForm />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SettingsMain;
