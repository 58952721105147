import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { EditPolicyApi } from '../Policy.api';
import { notificationController } from '@app/controllers/notificationController';
import { DataDetailPolicy } from '../interface';

type Props = {
  dataPolicy?: DataDetailPolicy | undefined;
  callApiListPolicy: () => void;
};

export default function ModalEditPolicy(props: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
    form.resetFields();
  }

  const onFinish = async (values: any) => {
    setloading(true);
    if (values && props?.dataPolicy) {
      EditPolicyApi(props?.dataPolicy?.code, {
        name: values?.name ? values?.name : props?.dataPolicy?.name,
        maximumLoanRate: values?.maximumLoanRate ? values?.maximumLoanRate : props?.dataPolicy?.maximumLoanRate,
        maximumLoanAmount: values?.maximumLoanAmount ? values?.maximumLoanAmount : props?.dataPolicy?.maximumLoanAmount,
        loanAmountAutoApprove: values?.loanAmountAutoApprove ? values?.loanAmountAutoApprove : props?.dataPolicy?.loanAmountAutoApprove,
        gracePeriod: values?.gracePeriod ? values?.gracePeriod : props?.dataPolicy?.gracePeriod,
        dayPerPeriod: values?.dayPerPeriod ? values?.dayPerPeriod : props?.dataPolicy?.dayPerPeriod,
        lateFeeFirstTerm: values?.lateFeeFirstTerm ? values?.lateFeeFirstTerm : props?.dataPolicy?.lateFeeFirstTerm,
        increasingFeePerPeriod: values?.increasingFeePerPeriod ? values?.increasingFeePerPeriod : props?.dataPolicy?.increasingFeePerPeriod,
        dayRecall: values?.dayRecall ? values?.dayRecall : props?.dataPolicy?.dayRecall,
        creditScore: values?.creditScore ? values?.creditScore : props?.dataPolicy?.creditScore,
        policySettingList: [
          {
            id: props?.dataPolicy?.policySettings[0]?.id,
            lowerLoanRate: 0,
            higherLoanRate: 10,
            feeRate: values?.feeRate_range_0_10 ? values?.feeRate_range_0_10 : 0,
            interestRate: values?.interestRate_range_0_10 ? values?.interestRate_range_0_10 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[1]?.id,
            lowerLoanRate: 10,
            higherLoanRate: 20,
            feeRate: values?.feeRate_range_10_20 ? values?.feeRate_range_10_20 : 0,
            interestRate: values?.interestRate_range_10_20 ? values?.interestRate_range_10_20 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[2]?.id,
            lowerLoanRate: 20,
            higherLoanRate: 30,
            feeRate: values?.feeRate_range_20_30 ? values?.feeRate_range_20_30 : 0,
            interestRate: values?.interestRate_range_20_30 ? values?.interestRate_range_20_30 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[3]?.id,
            lowerLoanRate: 30,
            higherLoanRate: 40,
            feeRate: values?.feeRate_range_30_40 ? values?.feeRate_range_30_40 : 0,
            interestRate: values?.interestRate_range_30_40 ? values?.interestRate_range_30_40 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[4]?.id,
            lowerLoanRate: 40,
            higherLoanRate: 50,
            feeRate: values?.feeRate_range_40_50 ? values?.feeRate_range_40_50 : 0,
            interestRate: values?.interestRate_range_40_50 ? values?.interestRate_range_40_50 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[5]?.id,
            lowerLoanRate: 50,
            higherLoanRate: 60,
            feeRate: values?.feeRate_range_50_60 ? values?.feeRate_range_50_60 : 0,
            interestRate: values?.interestRate_range_50_60 ? values?.interestRate_range_50_60 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[6]?.id,
            lowerLoanRate: 60,
            higherLoanRate: 70,
            feeRate: values?.feeRate_range_60_70 ? values?.feeRate_range_60_70 : 0,
            interestRate: values?.interestRate_range_60_70 ? values?.interestRate_range_60_70 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[7]?.id,
            lowerLoanRate: 70,
            higherLoanRate: 80,
            feeRate: values?.feeRate_range_70_80 ? values?.feeRate_range_70_80 : 0,
            interestRate: values?.interestRate_range_70_80 ? values?.interestRate_range_70_80 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[8]?.id,
            lowerLoanRate: 80,
            higherLoanRate: 90,
            feeRate: values?.feeRate_range_80_90 ? values?.feeRate_range_80_90 : 0,
            interestRate: values?.interestRate_range_80_90 ? values?.interestRate_range_80_90 : 0,
          },
          {
            id: props?.dataPolicy?.policySettings[9]?.id,
            lowerLoanRate: 90,
            higherLoanRate: 100,
            feeRate: values?.feeRate_range_90_100 ? values?.feeRate_range_90_100 : 0,
            interestRate: values?.interestRate_range_90_100 ? values?.interestRate_range_90_100 : 0,
          },
        ],
      })
        .then((res) => {
          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
          if (res?.error) {
            setloading(false);
            notificationController.error({ message: res?.message });
          } else {
            setloading(false);
            props?.callApiListPolicy();
            notificationController.success({ message: `Sửa thành công` });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);

          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalEdit: false }));
        });
    }
  };

  useEffect(() => {
    if (props?.dataPolicy) {
      form.setFieldsValue({
        name: props?.dataPolicy?.name,
        maximumLoanRate: String(props?.dataPolicy?.maximumLoanRate),
        maximumLoanAmount: String(props?.dataPolicy?.maximumLoanAmount),
        loanAmountAutoApprove: String(props?.dataPolicy?.loanAmountAutoApprove),
        gracePeriod: String(props?.dataPolicy?.gracePeriod),
        dayPerPeriod: String(props?.dataPolicy?.dayPerPeriod),
        lateFeeFirstTerm: String(props?.dataPolicy?.lateFeeFirstTerm),
        increasingFeePerPeriod: String(props?.dataPolicy?.increasingFeePerPeriod),
        dayRecall: String(props?.dataPolicy?.dayRecall),
        creditScore: String(props?.dataPolicy?.creditScore),
        feeRate_range_0_10: String(props?.dataPolicy?.policySettings[0]?.feeRate),
        interestRate_range_0_10: String(props?.dataPolicy?.policySettings[0]?.interestRate),
        feeRate_range_10_20: String(props?.dataPolicy?.policySettings[1]?.feeRate),
        interestRate_range_10_20: String(props?.dataPolicy?.policySettings[1]?.interestRate),
        feeRate_range_20_30: String(props?.dataPolicy?.policySettings[2]?.feeRate),
        interestRate_range_20_30: String(props?.dataPolicy?.policySettings[2]?.interestRate),
        feeRate_range_30_40: String(props?.dataPolicy?.policySettings[3]?.feeRate),
        interestRate_range_30_40: String(props?.dataPolicy?.policySettings[3]?.interestRate),
        feeRate_range_40_50: String(props?.dataPolicy?.policySettings[4]?.feeRate),
        interestRate_range_40_50: String(props?.dataPolicy?.policySettings[4]?.interestRate),
        feeRate_range_50_60: String(props?.dataPolicy?.policySettings[5]?.feeRate),
        interestRate_range_50_60: String(props?.dataPolicy?.policySettings[5]?.interestRate),
        feeRate_range_60_70: String(props?.dataPolicy?.policySettings[6]?.feeRate),
        interestRate_range_60_70: String(props?.dataPolicy?.policySettings[6]?.interestRate),
        feeRate_range_70_80: String(props?.dataPolicy?.policySettings[7]?.feeRate),
        interestRate_range_70_80: String(props?.dataPolicy?.policySettings[7]?.interestRate),
        feeRate_range_80_90: String(props?.dataPolicy?.policySettings[8]?.feeRate),
        interestRate_range_80_90: String(props?.dataPolicy?.policySettings[8]?.interestRate),
        feeRate_range_90_100: String(props?.dataPolicy?.policySettings[9]?.feeRate),
        interestRate_range_90_100: String(props?.dataPolicy?.policySettings[9]?.interestRate),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.dataPolicy, statePolicy?.modalsPolicy]);

  return (
    <S.ModalEditPolicy title={'Sửa chính sách'} open={statePolicy?.modalsPolicy?.modalEdit && statePolicy?.modalsPolicy?.modalEdit} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <Form.Item name={'name'} label="Tên chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>
            <h2 className="title-modal">Tỷ lệ phí và lãi theo tỷ lệ cho vay</h2>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay mặc định (%)</div>
                  <Form.Item name={'maximumLoanRate'} label="Tỉ lệ cho vay mặc định (%)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay(đ)</div>
                  <Form.Item name={'maximumLoanAmount'} label="Số tiền cho vay tối đa trên khoản vay(đ)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;0 và &lt;=10%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_0_10'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_0_10'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động(đ)</div>
                  <Form.Item name={'loanAmountAutoApprove'} label="Số tiền duyệt tự động(đ)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;10 và &lt;=20%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_10_20'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_10_20'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng(điểm)</div>
                  <Form.Item name={'creditScore'} label="Điểm tín dụng tối thiểu của đơn hàng(điểm)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;20 và &lt;=30%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_20_30'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_20_30'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn(Ngày)</div>
                  <Form.Item name={'gracePeriod'} label="Ngày ân hạn(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;30 và &lt;=40%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_30_40'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_30_40'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ(Ngày)</div>
                  <Form.Item name={'dayPerPeriod'} label="Số ngày/ kỳ(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;40 và &lt;=50%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_40_50'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_40_50'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ(%)</div>
                  <Form.Item name={'increasingFeePerPeriod'} label="" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;50 và &lt;=60%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_50_60'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_50_60'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi(Ngày)</div>
                  <Form.Item name={'dayRecall'} label="" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;60 và &lt;=70%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_60_70'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_60_70'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;70 và &lt;=80%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_70_80'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_70_80'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;80 và &lt;=90%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_80_90'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_80_90'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;90 và &lt;=100%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_90_100'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_90_100'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalEditPolicy>
  );
}
