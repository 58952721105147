import React, { useCallback, useState } from 'react';
import { Header } from '../../../header/Header';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import { MainHeader } from '../MainHeader/MainHeader';
import * as S from './MainLayout.styles';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Breadcrumb, BreadcrumbItem } from '@app/components/common/Breadcrumb/Breadcrumb';
// import { HomeOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { setCollapse } from '@app/store/slices/themeSlice';

const MainLayout: React.FC = () => {
  const dispatch = useAppDispatch();
  const { isSiderCollapsed } = useAppSelector((state) => state.theme);

  const [isTwoColumnsLayout] = useState(false);
  const [siderCollapsed, setSiderCollapsed] = useState(isSiderCollapsed === 'collapsed');

  const toggleSider = useCallback(() => {
    dispatch(setCollapse(isSiderCollapsed === 'collapsed' ? 'expanded' : 'collapsed'));
    setSiderCollapsed(!(isSiderCollapsed == 'collapsed'));

    // @TODO Dirty fix issue freeze browse with StrictMode
    location.reload();
  }, [dispatch, isSiderCollapsed]);

  const breadcrumbs = useAppSelector((state) => state.breadcrumb);
  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} toggleSider={toggleSider} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <S.BreadcrumbBox>
              <Breadcrumb>
                <BreadcrumbItem href={'/'}>
                  <Space>
                    {/* <HomeOutlined /> */}
                    <span className="style-title-text-dashboard">Trang chủ</span>
                  </Space>
                </BreadcrumbItem>
                {breadcrumbs.items.map((breadcrumb) => {
                  return (
                    <React.Fragment key={breadcrumb.name}>
                      <BreadcrumbItem href={breadcrumb.url ?? null}>
                        <Space>
                          {breadcrumb.icon ?? ''}
                          <span className="style-pathname">{breadcrumb.name}</span>
                        </Space>
                      </BreadcrumbItem>
                    </React.Fragment>
                  );
                })}
              </Breadcrumb>
              <h2 className={'title'}>{breadcrumbs.title ?? ''}</h2>
            </S.BreadcrumbBox>
            <Outlet />
          </div>
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
