import React, { useContext, useEffect, useState } from 'react';
import * as S from '../../Loan.styles';
import * as GS from '@app/styles/GlobalStyle';
import { Button } from 'antd';
import { getImg } from '@app/api/UploadFIle.api';
import { LoanListContext } from '../../Context';
import { updateStateModalDisbursement } from '../../Action';

type Props = {
  dataKey?: string | undefined;
  callApiDetailLoan?: () => void;
};

export default function ModalShowDisbursementPdf(props: Props) {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [saveImg, setsaveImg] = useState<string | undefined>(props?.dataKey);

  function handleCancelModal() {
    setsaveImg('');
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, modalShowPdf: false }));
  }

  const callApiGetImg = async () => {
    setsaveImg(
      'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI',
    );

    if (props?.dataKey && props?.dataKey) {
      await getImg(props?.dataKey)
        .then((res) => {
          setsaveImg(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    callApiGetImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLoanList.stateModal.modalShowPdf]);

  return (
    <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
      <S.ModalShowPdf footer={null} title="Chi tiết ảnh chứng từ" onCancel={handleCancelModal} open={stateLoanList?.stateModal?.modalShowPdf}>
        <div className="content-body">
          <div className="section-title"></div>

          <div className="box-content-img">
            <div className="box-img">
              <img
                style={{ width: '100%' }}
                src={
                  saveImg && saveImg
                    ? saveImg
                    : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                }
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="section-footer">
          <Button style={{ borderRadius: '2px' }} onClick={handleCancelModal}>
            Đóng
          </Button>
        </div>
      </S.ModalShowPdf>
    </GS.WrapperOverrideInput>
  );
}
