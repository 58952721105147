import React, { useMemo } from 'react';
import Overlay from '../../../../common/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import * as S from './MainSider.styles';
import { SiderLogo } from '../SiderLogo';
import SiderMenu from '../SiderMenu/SiderMenu';
import { AiOutlineMenuFold } from 'react-icons/ai';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
  toggleSider: () => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, toggleSider, ...props }) => {
  const { mobileOnly, tabletOnly } = useResponsive();

  const isCollapsible = useMemo(() => mobileOnly && tabletOnly, [mobileOnly, tabletOnly]);

  return (
    <>
      <S.Sider trigger={null} collapsed={isCollapsed} collapsedWidth={tabletOnly ? 80 : 0} collapsible={isCollapsible} width={260} {...props}>
        <SiderLogo isSiderCollapsed={isCollapsed} toggleSider={toggleSider} />
        <S.FakerSpaceLogo />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />
        </S.SiderContent>
        <S.MenuFolding onClick={toggleSider}>
          <AiOutlineMenuFold size={20} />
        </S.MenuFolding>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={!isCollapsed} />}
    </>
  );
};

export default MainSider;
