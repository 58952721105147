import React from 'react';
import { DataDetailEkycType } from '../interface';
import { Tooltip } from 'antd';

type Props = {
  dataDetail: DataDetailEkycType | undefined;
};

export default function SectionImage(props: Props) {
  // const [imgCardFront, setimgCardFront] = useState();
  // const [imgCardBack, setimgCardBack] = useState();
  // const [imgCardFace, setimgCardFace] = useState();
  // //   const [listImgFace, setlistImgFace] = useState([]);
  // function getimgFront() {
  //   if (props?.dataDetail && props?.dataDetail?.imageOfFrontCard != '' && props?.dataDetail?.imageOfFrontCard != undefined) {
  //     getImg(props?.dataDetail?.imageOfFrontCard)
  //       .then((res) => {
  //         res && setimgCardFront(res);
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  // }

  // function getimgBack() {
  //   if (props?.dataDetail && props?.dataDetail?.imageOfBehindCard != '' && props?.dataDetail?.imageOfBehindCard != undefined) {
  //     getImg(props?.dataDetail?.imageOfBehindCard)
  //       .then((res) => {
  //         res && setimgCardBack(res);
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  // }

  // function getimgFace() {
  //   if (props?.dataDetail && props?.dataDetail?.imageOfFace != '' && props?.dataDetail?.imageOfFace != undefined) {
  //     getImg(props?.dataDetail?.imageOfFace)
  //       .then((res) => {
  //         res && setimgCardFace(res);
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  // }

  // const [takeArrLinkImg, settakeArrLinkImg] = useState<any>();
  // const [objectSamelistAfterChange, setObjectSamelistAfterChange] = useState<DataDetailEkycType>();
  // const getimgFaceSameList = async () => {
  //   // eslint-disable-next-line prefer-const
  //   let arr: any = [];

  //   if (props?.dataDetail && props?.dataDetail.sameListEkyc && props?.dataDetail.sameListEkyc.length > 0) {
  //     Promise.all(
  //       props?.dataDetail.sameListEkyc.map((item) => {
  //         getImg(item?.imageOfFace)
  //           .then((res) => {
  //             res && arr.push(res);
  //           })
  //           .catch((err) => {
  //             console.log('err', err);
  //           });
  //         settakeArrLinkImg(arr);
  //       }),
  //     );
  //   }
  // };

  // function getSameListAfterChange() {
  //   // eslint-disable-next-line prefer-const
  //   let arrAfterChange: any = [];
  //   if (props?.dataDetail && props?.dataDetail.sameListEkyc && props?.dataDetail.sameListEkyc.length > 0 && takeArrLinkImg.length > 0) {
  //     props?.dataDetail.sameListEkyc.forEach((item: any) => {
  //       takeArrLinkImg.forEach((element: any) => {
  //         arrAfterChange.push({ ...item, linkOfImgFaceSameList: element });
  //         setObjectSamelistAfterChange(arrAfterChange);
  //       });
  //     });
  //   }
  // }
  // console.log('objectSamelistAfterChange', objectSamelistAfterChange);

  // useEffect(() => {
  //   if (props?.dataDetail && props?.dataDetail.sameListEkyc && props?.dataDetail.sameListEkyc.length > 0 && takeArrLinkImg && takeArrLinkImg.length > 0) {
  //     getSameListAfterChange();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [takeArrLinkImg, props?.dataDetail]);

  // useEffect(() => {
  //   getimgFaceSameList();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.dataDetail]);

  // useEffect(() => {
  //   getimgFront();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.dataDetail]);
  // useEffect(() => {
  //   getimgBack();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.dataDetail]);
  // useEffect(() => {
  //   getimgFace();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [props?.dataDetail]);
  // const getImgSameList = (code: string) => {
  //   // eslint-disable-next-line prefer-const
  //   let takeLink: any = [];
  //   if (code) {
  //     getImg(code)
  //       .then((res) => {
  //         if (res) {
  //           takeLink.push(res);
  //           console.log('takeLink1', takeLink);
  //         }
  //       })
  //       .catch((err) => {
  //         console.log('err', err);
  //       });
  //   }
  //   if (takeLink && takeLink.length > 0) {
  //     return <img className="style-face-samelist" src={takeLink && takeLink.length > 0 ? takeLink[0] : undefined} alt="" />;
  //   }
  // };
  return (
    <div className="section-user-infor">
      <div className="title">
        <div className="box-title">Dữ liệu</div>
      </div>
      <div className="content-user">
        <div className="item-column">
          <div className="label">CMND/CCCD</div>
        </div>
      </div>
      <div className="content-user">
        <div className="item-column ">
          <div className="label">1.Mặt trước</div>
          <div className="box-img-card">
            {props?.dataDetail?.imageOfFrontCard ? <img src={props?.dataDetail?.imageOfFrontCard && props?.dataDetail?.imageOfFrontCard} alt="" style={{ width: '100%' }} /> : 'Không có ảnh mặt trước'}
          </div>
          {props?.dataDetail && props?.dataDetail?.frontCardDescription ? (
            <div className="text-err-detail-ekyc"> {props?.dataDetail?.frontCardDescription ? `* ${props?.dataDetail?.frontCardDescription}` : ''}</div>
          ) : null}
        </div>
        <div className="item-column margin-left-40">
          <div className="label">2.Mặt sau</div>
          <div className="box-img-card">
            <div className="box-img-card">
              {props?.dataDetail?.imageOfBehindCard ? <img src={props?.dataDetail?.imageOfBehindCard && props?.dataDetail?.imageOfBehindCard} alt="" style={{ width: '100%' }} /> : null}
            </div>
          </div>
          {props?.dataDetail && props?.dataDetail?.behindCardDescription ? (
            <div className="text-err-detail-ekyc"> {props?.dataDetail?.behindCardDescription ? `* ${props?.dataDetail?.behindCardDescription}` : ''}</div>
          ) : null}
        </div>
        <div className="item-column margin-left-40">
          <div className="label">3.Chân dung</div>
          <div className="box-img-card">
            <div className="box-img-card">
              {props?.dataDetail?.imageOfFace ? <img className="style-of-face" src={props?.dataDetail?.imageOfFace && props?.dataDetail?.imageOfFace} alt="" style={{ width: '100%' }} /> : null}
            </div>
          </div>
          {props?.dataDetail && props?.dataDetail?.imageOfFaceDescription ? (
            <div className="text-err-detail-ekyc"> {props?.dataDetail?.imageOfFaceDescription ? `* ${props?.dataDetail?.imageOfFaceDescription}` : ''}</div>
          ) : null}
        </div>
      </div>
      {/* content-samelist */}
      {props?.dataDetail && props?.dataDetail.sameListEkyc && props?.dataDetail.sameListEkyc.length > 0 ? (
        <div className="content-same-list-ekyc">
          <div className="title">
            <div className="box-title">Chân dung trùng khớp và thông tin giấy tờ tương ứng</div>
          </div>
          {props?.dataDetail.sameListEkyc.map((item, index) => (
            <div className="box-content" key={index}>
              <div className="item">
                <div className="label">Loại giấy tờ</div>
                <Tooltip placement="top" title={<span>{item ? item.cardIdType : '-'}</span>}>
                  <div className="value">{item && item?.cardIdType ? item?.cardIdType : '-'}</div>
                </Tooltip>
                <div className="label">Số giấy tờ</div>
                <Tooltip placement="top" title={<span>{item ? item.cardCode : '-'}</span>}>
                  <div className="value">{item && item?.cardCode ? item?.cardCode : '-'}</div>
                </Tooltip>

                <div className="label">Tên khách hàng</div>
                <Tooltip placement="top" title={<span>{item ? item.fullName : '-'}</span>}>
                  <div className="value">{item && item?.fullName ? item?.fullName : '-'}</div>
                </Tooltip>
              </div>
              <div className="item">
                <div className="label">Ngày sinh</div>
                <Tooltip placement="top" title={<span>{item ? item.dob : '-'}</span>}>
                  <div className="value">{item && item?.dob ? item?.dob : '-'}</div>
                </Tooltip>

                <div className="label">Giới tính</div>
                <Tooltip placement="top" title={<span>{item ? item.gender : '-'}</span>}>
                  <div className="value">{item && item?.gender ? item?.gender : '-'}</div>
                </Tooltip>

                <div className="label">Ngày cấp</div>
                <Tooltip placement="top" title={<span>{item ? item.dateOfCardId : '-'}</span>}>
                  <div className="value">{item && item?.dateOfCardId ? item?.dateOfCardId : '-'}</div>
                </Tooltip>
              </div>
              <div className="item">
                <div className="label">Nơi cấp</div>
                <Tooltip placement="top" title={<span>{item ? item.placeOfCardId : '-'}</span>}>
                  <div className="value">{item && item?.placeOfCardId ? item?.placeOfCardId : '-'}</div>
                </Tooltip>

                <div className="label">Quốc tịch</div>
                <Tooltip placement="top" title={<span>{item ? item.nationality : '-'}</span>}>
                  <div className="value">{item && item?.nationality ? item?.nationality : '-'}</div>
                </Tooltip>

                <div className="label">Ngày hết hạn</div>
                <Tooltip placement="top" title={<span>{item ? item.expirationDateOfCardId : '-'}</span>}>
                  <div className="value">{item && item?.expirationDateOfCardId ? item?.expirationDateOfCardId : '-'}</div>
                </Tooltip>
              </div>
              <div className="item">
                <div className="label">Nơi thường trú</div>
                <Tooltip placement="top" title={<span>{item ? item.address : '-'}</span>}>
                  <div className="value">{item && item?.address ? item?.address : '-'}</div>
                </Tooltip>

                <div className="label">Quê quán</div>
                <Tooltip placement="top" title={<span>{item ? item.resident : '-'}</span>}>
                  <div className="value">{item && item?.resident ? item?.resident : '-'}</div>
                </Tooltip>
              </div>
              <div className="item">
                <div className="box-img-same-list">
                  <div className="value">Chân dung</div>
                  {/* {listImgFace && <img className="style-face-samelist" src={listImgFace && listImgFace[0]} alt="" />}

                  {listImgFace && <img className="style-face-samelist" src={listImgFace && listImgFace[1]} alt="" />}

                  {listImgFace && <img className="style-face-samelist" src={listImgFace && listImgFace[2]} alt="" />} */}
                  <img className="style-face-samelist" src={item?.imageOfFace} alt="" />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
