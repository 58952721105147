import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseType } from '../../Loan/interface';
import { CallApiErrType, DataPaymentDetailType, DataResponseDebt, DataResponseDebtWaitForApprove, ParamsApiAprrovalPayment, ParamsRejectApi, ResponseApiAprrove } from './interface';
const httpApi = initAxios();

interface CreateDebtConfig extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}
interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}
export interface CustomGrantTokenConfig extends AxiosRequestConfig {
  headers: {
    Authorization?: string;
  };
}
const TokenConfig: TokenConfig = {
  secured: true,
};

const TokenConfigPut: TokenConfig = {
  secured: true,
  method: 'PUT',
};

const TokenConfigFormData: CreateDebtConfig = {
  secured: true,
  ContentType: 'multipart/form-data',
};

export const createDebtApi = (formData: FormData): Promise<DataResponseType | CallApiErrType> => httpApi.post('loans/v1/debts-payment', formData, { ...TokenConfigFormData });

export const getDebtListApi = (params: string): Promise<DataResponseDebt> => httpApi.get(`/loans/v1/debts-payment?${params}`, { ...TokenConfig });

export const getDebtListWaitForApproveApi = (params: string): Promise<DataResponseDebtWaitForApprove> => httpApi.get(`/loans/v1/debts-payment/in-process?${params}`, { ...TokenConfig });

export const getDebtListAprroveApi = (id: string): Promise<ResponseApiAprrove> => httpApi.put(`/loans/v1/debts-payment/${id}`, undefined, { ...TokenConfigPut });

export const getDebtListRejectApi = (params: ParamsRejectApi): Promise<ResponseApiAprrove> => httpApi.put('/loans/v1/payments/cancel', params, { ...TokenConfigPut });

export const getDetailPaymentApi = (id: string): Promise<DataPaymentDetailType> => httpApi.get(`loans/v1/debts-payment/${id}`, { ...TokenConfig });

export const getImg = (params: string): Promise<any> => httpApi.get(`/loans/v1/files/${params}`, { ...TokenConfig });

export const AprrovalStatusPaymentTransaction = (id: string, params: ParamsApiAprrovalPayment): Promise<any> => httpApi.put(`/loans/v1/debts-payment/${id}`, params, { ...TokenConfig });
