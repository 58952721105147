import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_CAPITAL, UPDATE_MODAL_CAPITAL } from './Action';
import { ReducerType } from './interface';

export const initCapital = {
  stateCapital: {
    form: null,
    filters: {
      // pageSize: 20,
      // pageIndex: 1,
      status: '',
      key: '',
    },

    modals: {
      modalCreate: false,
      modalEdit: false,
      ModalOpenSaveInfor: false,
      checkedActive: null,
      tabsModals: undefined,
      saveCashin: null,
      saveCashout: null,
      saveAmountStore: null,
      isCashIn: true,
    },
  },
  dispatchCapital: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_CAPITAL:
      return {
        ...state,
        filters: action.filters,
      };

    case UPDATE_MODAL_CAPITAL:
      return {
        ...state,
        modals: action.modals,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useCapitalReducer = () => {
  return useReducer(reducer, initCapital.stateCapital);
};
