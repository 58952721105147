import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_EKYCMANAGEMENT, UPDATE_STATE_MODAL_EKYCMANAGEMENT } from './Action';
import { ReducerType } from './interface';

export const initEkycManagement = {
  stateEkycManagement: {
    filtersEkycManagement: {
      pageSize: 10,
      pageIndex: 1,
      key: '',
      status: '',
      cardIdType: '',
      createAtFrom: '',
      createAtTo: '',
      approveAtTo: '',
      billingStatus: '',
    },
    stateModal: {
      stateModalEkycManagement: false,
      stateModalDetail: false,
      stateModalReject: false,
      stateModalApproval: false,
      stateModalEditPaperEkyc: false,
    },
  },
  dispatchEkycManagement: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_EKYCMANAGEMENT:
      return {
        ...state,
        filtersEkycManagement: action.filtersEkycManagement,
      };
    case UPDATE_STATE_MODAL_EKYCMANAGEMENT:
      return {
        ...state,
        stateModal: action.stateModal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useEkycManagementReducer = () => {
  return useReducer(reducer, initEkycManagement.stateEkycManagement);
};
