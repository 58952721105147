import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { DataTypeTableInterest } from '../interface';
import Avatar from '@app/assets/icons/avatar.svg';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
export const columnsConfigInterest: ColumnsType<DataTypeTableInterest> = [
  {
    width: '310px',
    title: 'ID giao dịch',
    key: 'code',
    dataIndex: 'code',
    render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
  },
  {
    width: '220px',
    title: 'ID khoản vay',
    key: 'loanCode',
    dataIndex: 'loanCode',
    render: (value: string) => <a className="text-paymentcode">{value ? value : ''}</a>,
  },

  {
    width: '197px',
    title: 'Tên khách hàng',
    key: 'customerName',
    dataIndex: 'customerName',

    render: (value: string) => {
      return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
    },
  },
  {
    width: '203px',
    title: 'Thời gian thực hiện',
    key: 'createDate',
    dataIndex: 'createDate',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    align: 'right',
    title: 'Loại phí',
    width: '180px',
    key: 'type',
    render: (value: DataTypeTableInterest) => (
      <div>
        {value && value?.type == 'DAILY_INTEREST'
          ? 'Lãi hàng ngày'
          : '-' || (value && value?.type == 'REFUND_INTEREST_AFTER_DISBURSEMENT')
          ? 'Lãi hoàn(Trước GN)'
          : '-' || (value && value?.type == 'REFUND_INTEREST_BEFORE_DISBURSEMENT')
          ? 'Lãi hoàn(Sau GN)'
          : '-'}
      </div>
    ),
  },
  {
    align: 'right',
    title: 'Tỉ lệ lãi',
    key: 'rate',
    dataIndex: 'rate',
    render: (value: string) => <div>{value ? value + '%/ năm' : 0 + '%/ năm'}</div>,
  },
  {
    align: 'right',
    title: 'Tiền tính lãi',
    key: 'principalAmount',
    dataIndex: 'principalAmount',
    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },

  {
    align: 'right',
    title: 'Tiền lãi',
    key: 'amount',
    dataIndex: 'amount',
    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },

  {
    width: '160px',
    title: 'Người thực hiện',
    key: 'createdBy',
    dataIndex: 'createdBy',
    render: (value: string) => <div>{value ? value : ''}</div>,
  },
];
