import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import { DataTableRefund } from '../interface';
import { StatusRefund, TypeRefund } from '@app/interfaces/interfaces';
import ActionColumnRefund from '@app/components/common/ActionColumn/ActionColumnRefund';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { updateFiltersRefund } from '../Action';
import { RefundContext } from '../Context';
import { FormSearchRefund } from './FormSearchRefund';
import { changeStatusRefundApi, getRefundApi } from '../Refund.api';
import { DataResponseApiRefundList } from '../interface';
import { notificationController } from '@app/controllers/notificationController';
import ModalConfirm from '@app/components/common/ModalConfirm/ModalConfirm';

export default function TableRefund() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [checkLoadingBtn, setcheckLoadingBtn] = useState(false);
  const [openModalConfirm, setopenModalConfirm] = useState(false);
  const [dataRefund, setDataRefund] = useState<DataResponseApiRefundList>();
  const [saveDataRecord, setsaveDataRecord] = useState<DataTableRefund>();
  const { stateRefund, dispatchRefund } = useContext(RefundContext);

  function moveDetailLoan(value: string) {
    location.href = `/customers/loan/${value}`;
  }

  const callApiListRefund = async () => {
    setcheckLoading(true);
    await getRefundApi(queryStringParams(stateRefund?.filtersRefund))
      .then((res) => {
        setDataRefund(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataRefund(undefined);

        setcheckLoading(false);
      });
  };

  function handleCollect(value: DataTableRefund) {
    setopenModalConfirm(true);
    value && setsaveDataRecord(value);
  }

  function handleConfirm() {
    setcheckLoadingBtn(true);

    if (saveDataRecord) {
      changeStatusRefundApi(saveDataRecord?.code, {
        status: saveDataRecord && saveDataRecord?.status == 'NOT_YET_COLLECTED_MONEY' ? 'COLLECTED_MONEY' : 'NOT_YET_COLLECTED_MONEY',
      })
        .then((res) => {
          if (res?.error) {
            notificationController.error({ message: res?.error?.message ? res?.error?.message : `Change failed` });
            setopenModalConfirm(false);
            setcheckLoadingBtn(false);
          } else {
            notificationController.success({ message: `Thay đổi trạng thái thành công` });
            callApiListRefund();
            setopenModalConfirm(false);
            setcheckLoadingBtn(false);
          }
        })
        .catch((err) => {
          console.log(err);
          notificationController.error({ message: `Something Went wrong api ` });
          setopenModalConfirm(false);
          setcheckLoadingBtn(false);
        });
    }
  }

  function handleCancel() {
    setopenModalConfirm(false);
  }

  useEffect(() => {
    callApiListRefund();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateRefund?.filtersRefund]);

  const columnsConfig: ColumnsType<DataTableRefund> = [
    {
      width: '240px',
      title: 'ID giao dịch',
      key: 'code',
      dataIndex: 'code',
      render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
    },
    {
      width: '220px',
      title: 'ID khoản vay',
      key: 'loanCode',
      dataIndex: 'loanCode',
      render: (value: string) => (
        <a className="text-paymentcode" onClick={() => moveDetailLoan(value)}>
          {value ? value : ''}
        </a>
      ),
    },

    {
      width: '184px',
      title: 'Loại hoàn gốc',
      key: 'type',
      dataIndex: 'type',

      render: (type: TypeRefund) => {
        return <div>{type == 'BEFORE_DISBURSED' ? 'Trước giải ngân' : null || type == 'AFTER_DISBURSED' ? 'Sau giải ngân' : null}</div>;
      },
    },
    {
      width: '203px',
      title: 'Thời gian tạo',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },
    {
      width: '141px',
      title: 'REF ID',
      key: 'referenceId',
      dataIndex: 'referenceId',

      render: (value: string) => {
        return <div>{value ? value : ''}</div>;
      },
    },
    {
      title: 'Số tiền vay cũ',
      key: 'oldLoanAmount',
      dataIndex: 'oldLoanAmount',

      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      title: 'Số tiền vay mới',
      key: 'newLoanAmount',
      dataIndex: 'newLoanAmount',

      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      title: 'Hoàn gốc',
      key: 'refundAmount',
      dataIndex: 'refundAmount',

      render: (value: number) => {
        return <div>{value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      width: '164px',
      title: 'Trạng thái thu tiền',
      key: 'status',
      dataIndex: 'status',

      render: (status: StatusRefund) => {
        return <div>{status == 'NOT_YET_COLLECTED_MONEY' ? 'Chưa thu' : null || status == 'COLLECTED_MONEY' ? 'Đã thu' : null || status == 'NO_COLLECTED_MONEY' ? 'Không thu' : null}</div>;
      },
    },
    {
      width: '96px',
      title: 'Hành động',
      fixed: 'right',
      render: (value: DataTableRefund) => {
        return <ActionColumnRefund handleCollect={() => handleCollect(value)} typeRefund={value?.type} actionWatch={true} status={value?.status}></ActionColumnRefund>;
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <FormSearchRefund></FormSearchRefund>
          <Table
            scroll={{ x: 1740 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataRefund && dataRefund?.data}
            columns={columnsConfig}
            // showSizeChanger={true}
            loading={checkLoading}
            sticky={true}
            rowKey="code"
            pagination={{
              current: dataRefund?.pageIndex,
              pageSize: dataRefund?.pageSize,
              total: dataRefund?.total,
              onChange: (page: number, pageSize: number) => {
                {
                  dispatchRefund(updateFiltersRefund({ ...stateRefund?.filtersRefund, pageIndex: page, pageSize: pageSize }));
                }
              },
            }}
          />
        </div>
        <ModalConfirm handleCancel={handleCancel} isModalOpen={openModalConfirm} labelTitle="Bạn có chắc muốn thay đổi trạng thái" handleOk={handleConfirm} loading={checkLoadingBtn}></ModalConfirm>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
