import { Dispatch, useReducer } from 'react';
import { UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_PAYMENT, UPDATE_FILTERS_RECONCILIATION_PAYMENT, UPDATE_FILTERS_RECONCILIATION_PAYMENT_DETAIL } from './Action';
import { ReducerType } from './interface';

export const initReconciliationPayment = {
  stateReconciliationPayment: {
    form: null,
    filtersReconciliationPaymentList: {
      date: '',
      pageSize: 10,
      pageIndex: 1,
    },
    filtersReconciliationPaymentDetail: {
      key: '',
      day: '',
      typeData: '',
      status: '',
      pageSize: 10,
      pageIndex: 1,
    },
    checkDataOriginal: false,
  },
  dispatchReconciliationPaymentList: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_RECONCILIATION_PAYMENT:
      return {
        ...state,
        filtersReconciliationPaymentList: action.filtersReconciliationPaymentList,
      };
    case UPDATE_FILTERS_RECONCILIATION_PAYMENT_DETAIL:
      return {
        ...state,
        filtersReconciliationPaymentDetail: action.filtersReconciliationPaymentDetail,
      };
    case UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_PAYMENT:
      return {
        ...state,
        checkDataOriginal: action.checkDataOriginal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useReconciliationPaymentListReducer = () => {
  return useReducer(reducer, initReconciliationPayment.stateReconciliationPayment);
};
