import { Form, Input } from 'antd';
// import React, { useContext } from 'react';
import * as S from '../DebtList.styles';
import * as GS from '@app/styles/GlobalStyle';
// import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import { useContext, useEffect, useState } from 'react';
import { DataLoanDetailType } from '../../../Loan/interface';
import { DebtListContext } from '../Context';
import { updateModals } from '../Action';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import Avatar from '@app/assets/icons/avatar.svg';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { DataPaymentDetailType, DataTypeTable } from '../interface';
import { getDetailPaymentApi, getImg } from '../DebtList.api';
import { detailLoanApi } from '../../../Loan/Loan.api';
import { formatNumberWithCommas } from '@app/utils/utils';

type Props = {
  RecordRow: DataTypeTable | undefined;
};
export default function ModalDetailPayment(props: Props) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);
  const [form] = Form.useForm();
  const [dataDetailLoan, setdataDetailLoan] = useState<DataLoanDetailType | undefined>(undefined);
  const [dataDetailPayment, setdataDetailPayment] = useState<DataPaymentDetailType | undefined>(undefined);
  const [saveImg, setsaveImg] = useState();
  // const [values, setValues] = useState<any>({
  //   fee: '',
  //   interest: '',
  //   principal: '',
  // });
  // const [form] = Form.useForm(values);
  const callApiGetDetailPayment = async () => {
    if (props?.RecordRow) {
      await getDetailPaymentApi(props?.RecordRow?.code)
        .then((res) => {
          setdataDetailPayment(res);
          console.log('res', res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const callApiGetDetailLoan = async () => {
    if (props?.RecordRow) {
      await detailLoanApi(props?.RecordRow?.loanCode)
        .then((res) => {
          setdataDetailLoan(res);
          console.log('res', res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const callApiGetImg = async () => {
    if (props?.RecordRow) {
      await getImg(props?.RecordRow?.proofOfPayment)
        .then((res) => {
          setsaveImg(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    callApiGetImg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.RecordRow]);

  useEffect(() => {
    callApiGetDetailLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.RecordRow?.loanCode]);

  useEffect(() => {
    callApiGetDetailPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.RecordRow]);

  function handleOkModalCreate() {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalDetailPayment: false }));
  }

  function handleCancelModalCreate() {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalDetailPayment: false }));
  }
  useEffect(() => {
    if (dataDetailPayment) {
      form.setFieldsValue({
        code: dataDetailPayment?.code,
        principalPaidAmount: dataDetailPayment?.principalPaidAmount,
        paymentAmount: dataDetailPayment?.paymentAmount,
        feePaidAmount: dataDetailPayment?.feePaidAmount,
        interestPaidAmount: dataDetailPayment?.interestPaidAmount,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailPayment]);
  return (
    <S.ModalCreate title="Chi tiết thanh toán" open={stateDebtList?.modals?.modalDetailPayment} onOk={handleOkModalCreate} onCancel={handleCancelModalCreate} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} name="basic" style={{ width: '100%' }}>
          <div className="box-body-form">
            <div className="form-create">
              <div className="label">ID thanh toán</div>
              <Form.Item name={'code'}>
                <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập mã khoản vay '} />
              </Form.Item>
              <div className="box-form">
                <div className="label">Tổng số tiền thu</div>

                <Form.Item name={'paymentAmount'}>
                  <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập tổng số tiền thu'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Số tiền phí thu</div>

                <Form.Item name={'feePaidAmount'}>
                  <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập Số tiền phí thu'} />
                </Form.Item>
              </div>

              <div className="box-form">
                <div className="label">Số tiền lãi thu</div>

                <Form.Item name={'interestPaidAmount'}>
                  <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập Số tiền lãi thu'} />
                </Form.Item>
              </div>
              <div className="box-form">
                <div className="label">Số tiền gốc thu</div>

                <Form.Item name={'principalPaidAmount'}>
                  <Input disabled size={'small'} className="form-input-create" placeholder={'Nhập số tiền gốc thu'} />
                </Form.Item>
              </div>
              <div className="label">Chứng từ</div>

              <div className="save-image">
                <img
                  src={
                    saveImg && saveImg
                      ? saveImg
                      : 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.freepik.com%2Ffree-photos-vectors%2Floading-screen&psig=AOvVaw0A7H7rBe68YOeNx2IeudOj&ust=1677059194591000&source=images&cd=vfe&ved=0CBAQjRxqFwoTCJD27sGqpv0CFQAAAAAdAAAAABAI'
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="information">
              <div className="infor-title">Thông tin khoản vay </div>
              <div className="infor-content">
                <div className="box-branch">
                  <div className="label">ID khoản vay</div>
                  <a>{dataDetailLoan?.code}</a>
                </div>
                <div className="box-branch">
                  <div className="label">Tên khách hàng</div>
                  <UserNameAvatarColumn avatar={Avatar} name={dataDetailLoan?.user.fullName ? dataDetailLoan?.user.fullName : ''}></UserNameAvatarColumn>
                </div>
                <div className="box-branch">
                  <div className="label">Trạng thái BF</div>
                  <StatusesText context="PAYMENT_TRANSACTION_BF" status={'DONE'}></StatusesText>
                </div>
                <div className="box-branch">
                  <div className="label">Số tiền vay</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.amount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc đã thanh toán</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.disbursedAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc đã thu</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.principalPaidAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi luỹ kế</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.accruedInterestAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi đã thu</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.interestPaidAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Phí luỹ kế</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.accruedFeeAmount) : ''}</div>
                </div>
                <div className="box-branch" style={{ borderBottom: '1px solid #3C4450', paddingBottom: '15px' }}>
                  <div className="label">Phí đã thu </div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.feePaidAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Gốc còn lại</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.remainingPrincipalAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Lãi còn lại</div>
                  <div>{dataDetailLoan ? formatNumberWithCommas(dataDetailLoan?.accruedInterestAmount - dataDetailLoan?.interestPaidAmount) : ''}</div>
                </div>
                <div className="box-branch">
                  <div className="label">Phí còn lại</div>
                  <div>{dataDetailLoan && dataDetailLoan != undefined ? formatNumberWithCommas(dataDetailLoan?.accruedFeeAmount - dataDetailLoan?.feePaidAmount) : ''}</div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreate>
  );
}
