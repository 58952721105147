import { FilterEkycManagementType, ReducerType, StateModal } from './interface';

export const UPDATE_FILTERS_EKYCMANAGEMENT = 'UPDATE_FILTERS_EKYCMANAGEMENT';
export const UPDATE_STATE_MODAL_EKYCMANAGEMENT = 'UPDATE_STATE_MODAL_EKYCMANAGEMENT';

export const updateFiltersEkycManagement = (filtersEkycManagement: FilterEkycManagementType): ReducerType => {
  return {
    type: UPDATE_FILTERS_EKYCMANAGEMENT,
    filtersEkycManagement: filtersEkycManagement,
  };
};
export const updateStateModalEkycManagement = (stateModal: StateModal): ReducerType => {
  return {
    type: UPDATE_STATE_MODAL_EKYCMANAGEMENT,
    stateModal: stateModal,
  };
};
