import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

export const PageTitle: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{children}</title>
      </Helmet>
    </HelmetProvider>
  );
};
