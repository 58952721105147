import React from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { StatusesContextType, StatusesTypo, StatusDefinitionEnum } from '@app/constants/statuses';
import * as S from './Statuses.styles';
import { CloseCircleOutlined } from '@ant-design/icons';

export interface StatusesProps {
  status: StatusesTypo;
  context: StatusesContextType;
}

export const StatusesText: React.FC<StatusesProps> = (props) => {
  const { t } = useTranslation();
  const state: any = _.get(StatusDefinitionEnum, `${props.context}.${props.status}`, {});

  if (!_.size(state)) {
    return (
      <S.Tag color={'red'} icon={<CloseCircleOutlined />}>
        STATE NOT FOUND
      </S.Tag>
    );
  }

  const text = _.get(state, 'ts', false) ? t(state?.ts) : state?.text;

  return <S.Badge status={state?.colorOrigin} text={text} color={state?.color ?? ''} />;
};
