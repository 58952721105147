import React, { useContext, useEffect, useState } from 'react';
import { Table, Tooltip } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../Disbursement.styles';
import { ColumnsType } from 'antd/lib/table';
import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';

import { queryStringParams } from '@app/controllers/queryStringParams';
import { useParams } from 'react-router-dom';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import moment from 'moment';
import { FormSearchDetailWaitForDisbursement } from './FormSearchDetailWaitForDisbursement';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { DisbursementContext, DisbursementProvider } from '../../Context';
import { DataResponseTableWaitForDisbursementDetail, DataTableDetailWaitForDisbursement, DataTableWaitForDisbursementDetailType } from '../../interface';
import { updateArrRowCheckBox, updateFiltersWaitForDisbursementDetailDay, updateStateModalDisbursement } from '../../Action';
import { getDetailWaitForDisbursementDetailApi } from '../../Disbursement.api';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import ModalCreateProofDetail from './ModalCreateEditProofDetail';
import ModalShowDetailProof from './ModalShowDetailProof';

function DetailWaitForDisbursement() {
  const [checkLoading, setcheckLoading] = useState(false);
  const { dateReview } = useParams();
  const [data, setData] = useState<DataResponseTableWaitForDisbursementDetail>();
  const [dataRow, setdataRow] = useState<DataTableWaitForDisbursementDetailType>();
  // const [arrRowCheckBoxSave, setarrRowCheckBoxSave] = useState<any>();
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);

  const dispatch = useAppDispatch();

  const callApiLoanDetailWaitForDisbursement = async () => {
    setcheckLoading(true);
    if (dateReview) {
      await getDetailWaitForDisbursementDetailApi(dateReview, queryStringParams(stateDisbursement?.filtersTabWaitForDisbursementDetailDay))
        .then((res) => {
          setData(res);
          setcheckLoading(false);
        })
        .catch((err) => {
          setData(undefined);
          console.log(err);
          setcheckLoading(false);
        });
    }
  };

  useEffect(() => {
    callApiLoanDetailWaitForDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement.filtersTabWaitForDisbursementDetailDay]);

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý giải ngân',
        },
        {
          name: 'Danh sách giải ngân',
          url: '/customers/disbursement',
        },
        {
          name: `${dateReview}`,
        },
      ]),
    );

    dispatch(setBreadcrumbTitle(`${dateReview}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(`${dateReview}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchDisbursement, dispatch]);

  function showModalShowEditDetail(value: DataTableWaitForDisbursementDetailType) {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalShowEditDetail: true }));
    setdataRow(value);
  }

  function showModalCreateProofDetail(value: DataTableWaitForDisbursementDetailType) {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProofDetail: true }));
    setdataRow(value);
  }

  const columnsConfig: ColumnsType<DataTableWaitForDisbursementDetailType> = [
    {
      title: 'ID giải ngân',
      key: 'code',
      width: '180px',
      dataIndex: 'code',
      render: (value: string) => <a>{value && value}</a>,
    },
    {
      title: 'ID khoản vay',
      width: '200px',
      key: 'loanCode',
      dataIndex: 'loanCode',
      render: (value: string) => <a>{value && value}</a>,
    },
    {
      title: 'Tên khách hàng',
      width: '200px',

      key: 'customerName',
      render: (value: DataTableDetailWaitForDisbursement) => <UserNameAvatarColumn name={value?.customerName ? value?.customerName : ''}></UserNameAvatarColumn>,
    },
    {
      width: '150px',
      title: 'REF ID',
      key: 'referenceId',
      dataIndex: 'referenceId',
    },
    {
      width: '200px',
      title: 'Số tiền vay',
      key: 'loanAmount',
      dataIndex: 'loanAmount',
      align: 'right',
      render: (value: number) => <div>{value ? (value ? formatNumberWithCommas(value) : 0) : null}</div>,
    },
    {
      width: '200px',
      title: 'Số tiền đơn hàng',
      key: 'baseAmount',
      dataIndex: 'baseAmount',
      align: 'right',
      render: (value: number) => <div>{value ? (value ? formatNumberWithCommas(value) : 0) : null}</div>,
    },
    {
      width: '200px',
      title: 'Số tiền hoàn gốc',
      align: 'right',
      render: (value: DataTableWaitForDisbursementDetailType) => <div>{value ? (value.refundPrincipalAmount ? formatNumberWithCommas(value.refundPrincipalAmount) : 0) : null}</div>,
    },
    {
      width: '200px',
      title: 'Số tiền giải ngân',
      align: 'right',
      render: (value: DataTableWaitForDisbursementDetailType) => <div>{value ? (value.totalAmountDisbursement ? formatNumberWithCommas(value.totalAmountDisbursement) : 0) : null}</div>,
    },
    {
      width: '190px',
      title: 'Đối tác liên kết',
      key: 'partner',
      dataIndex: 'partner',
    },
    {
      width: '190px',
      title: 'Loại sản phẩm',
      key: 'productCode',
      dataIndex: 'productCode',
    },
    {
      width: '250px',
      title: 'Note',
      key: 'note',
      dataIndex: 'note',
      render: (value: string) => <div>{value ? value : '-'}</div>,
    },
    {
      width: '190px',
      title: 'TK nhận giải ngân',
      key: 'accountReceiveMoney',
      dataIndex: 'accountReceiveMoney',
    },
    {
      width: '148px',
      title: 'Ngày duyệt',
      key: 'loanType',

      render: (value: DataTableWaitForDisbursementDetailType) => <div>{value?.approvalDate && moment(value?.approvalDate).format('DD-MM-YYYY')}</div>,
    },

    {
      width: '187px',
      title: 'Trạng thái',
      key: 'referenceId',
      render: (value: DataTableWaitForDisbursementDetailType) => (
        <div>
          <StatusesText context="DISBURSEMENT" status={value?.status && value?.status}></StatusesText>
        </div>
      ),
    },
    {
      width: '220px',
      title: 'Chứng từ',
      key: 'keyProof',
      render: (value: DataTableWaitForDisbursementDetailType) => (
        <GS.GSTableRowProof>
          {value?.keyProof ? (
            <a className="d-flex" onClick={() => showModalShowEditDetail(value)}>
              <IconUploadPDF className="style-icon-pdf margin-top-5 "></IconUploadPDF>
              <Tooltip placement="top" title={<span>{value?.keyProof && value?.keyProof ? value?.keyProof : ''}</span>}>
                <a className="style-text-pdf">{value?.keyProof}</a>
              </Tooltip>
            </a>
          ) : (
            <BtnCreateStyle onClick={() => showModalCreateProofDetail(value)} label={'Chứng từ'}></BtnCreateStyle>
          )}
        </GS.GSTableRowProof>
      ),
    },
  ];

  // const dislaydModalCreateProofDetail = useMemo(() => {
  //   return (
  //     <>
  //       <ModalShowDetailProof callApiListDisbursement={callApiLoanDetailWaitForDisbursement} data={dataRow && dataRow}></ModalShowDetailProof>
  //     </>
  //   );
  // }, [dispatchDisbursement]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxDetailWaitForDisbursement>
            <FormSearchDetailWaitForDisbursement callApiLoanDetailWaitForDisbursement={callApiLoanDetailWaitForDisbursement}></FormSearchDetailWaitForDisbursement>
            <Table
              scroll={{ x: 3200 }}
              rowSelection={{
                type: 'checkbox',
                onChange: (record) => {
                  dispatchDisbursement(updateArrRowCheckBox({ ...stateDisbursement.arrRowCheckBoxSave, arrRowCheckBoxSave: record }));
                },
              }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="code"
              pagination={{
                showSizeChanger: true,
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchDisbursement(updateFiltersWaitForDisbursementDetailDay({ ...stateDisbursement?.filtersTabWaitForDisbursementDetailDay, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxDetailWaitForDisbursement>
          {dataRow && <ModalCreateProofDetail callApiListDisbursement={callApiLoanDetailWaitForDisbursement} data={dataRow && dataRow}></ModalCreateProofDetail>}
          {dataRow && <ModalShowDetailProof callApiListDisbursement={callApiLoanDetailWaitForDisbursement} data={dataRow && dataRow}></ModalShowDetailProof>}
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}

export default function TabDisbursementDetailWaitForDisbursementBoxProvider() {
  return (
    <DisbursementProvider>
      <DetailWaitForDisbursement />
    </DisbursementProvider>
  );
}
