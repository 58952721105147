import { FiltersRefundType, ReducerType } from './interface';

export const UPDATE_FILTERS_REFUND = 'UPDATE_FILTERS_REFUND';

export const updateFiltersRefund = (filtersRefund: FiltersRefundType): ReducerType => {
  return {
    type: UPDATE_FILTERS_REFUND,
    filtersRefund: filtersRefund,
  };
};
