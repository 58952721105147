import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { RefundContext, RefundProvider } from './Context';
import TableRefund from './Component/TableFund';

export default function Refund() {
  const { dispatchRefund } = useContext(RefundContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
          url: '/customers/loan',
        },
        {
          name: 'Lịch sử hoàn gốc',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Lịch sử hoàn gốc`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchRefund, dispatch]);
  return (
    <RefundProvider>
      <PageTitle>Lịch sử hoàn gốc</PageTitle>

      <div>
        <TableRefund></TableRefund>
      </div>
    </RefundProvider>
  );
}
