import { FilterFeeType, FilterInterestType, ReducerType } from './interface';

export const UPDATE_FILTERS_INTEREST = 'UPDATE_FILTERS_INTEREST';
export const UPDATE_FILTERS__FEE = 'UPDATE_FILTERS__FEE';

export const updateFiltersInterest = (filtersInterest: FilterInterestType): ReducerType => {
  return {
    type: UPDATE_FILTERS_INTEREST,
    filtersInterest: filtersInterest,
  };
};
export const updateFiltersFee = (filtersFee: FilterFeeType): ReducerType => {
  return {
    type: UPDATE_FILTERS__FEE,
    filtersFee: filtersFee,
  };
};
