import { Modal } from 'antd';
import styled from 'styled-components';

export const Menu = styled.div`
  display: flex;
`;

export const BoxEkycManagement = styled.div`
  .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }

  /* background-color: #12161c;
  padding: 24px;
  .box-content {
    background-color: #1a1f25;
    padding: 16px 16px 24px 24px;
  } */
  .padding-table {
  }
  .style-text-pdf {
    margin-left: 10px;
    margin-bottom: 2px;
  }
`;

export const DflexStartEnd = styled.div`
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 187px !important;
  }
  .style-select-partner {
    min-width: 152px;
  }
  .style-select-status {
    min-width: 143px;
  }
  /* padding-top: 30px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
`;
export const Dflex = styled.div`
  display: flex;
  width: 88%;
  flex-wrap: wrap;
`;
export const DflexPara = styled.div`
  display: flex;
`;
export const InputPara = styled.div`
  input#para {
    background: #272d35 !important;
    margin-top: 15px;
  }
`;
export const DflexEnd = styled.div`
  margin-top: 10px;

  display: flex;
  justify-content: end;
`;
export const StyleInput = styled.div`
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }
  .ant-select-selector {
    background: #272d35 !important;
  }
`;
export const StyleStar = styled.div`
  color: #ff4d4f;
  font-size: 14;
  display: flex;
  align-items: center;
  margin-right: 5px;
`;

export const StylePara = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const BoxFormDob = styled.div`
  display: flex;
  justify-content: space-between;
  .item-dob-form {
    width: 31%;
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #bfbfbf;
    }
  }
`;

export const ModalReject = styled(Modal)`
  .btn-save-change {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    gap: 10px;
    width: 117px;
    height: 32px;
    background: #9ea6ac;
    border: 1px solid #9ea6ac;
    border-radius: 2px;
  }
  .ant-modal-content {
    width: 591px;
  }
  .ant-modal-footer {
    padding: 0px 32px 32px 32px;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 12px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    display: none;
  }
`;
export const ContainerDetailEkyc = styled.div`
  .title-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #f2f2f2;
    padding: 0px 0px 24px 24px;
  }
  .content-body {
    background: #12161c;
    width: 100%;
    padding: 24px;
    .section-reject-approval {
      display: flex;
      justify-content: end;
      .box-btn {
        display: flex;
      }
    }
    .text-note {
      padding-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .section-btn-action {
      display: flex;
      justify-content: end;

      .box-btn {
        display: flex;
        justify-content: end;
        .btn-excel {
          height: 32px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #d9d9d9;
          margin-left: 10px;
        }
        .btn-edit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #f2c015;
          border: 1px solid #f2c015;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          color: #1f1f1f;
        }
      }
    }
    .section-footer-edit {
      display: flex;
      border-top: 1px solid #3c4450;
      padding: 24px;
      justify-content: end;
    }
    .section-user-infor {
      background-color: #1a1f25;
      margin-top: 24px;
      .margin-left-40 {
        margin-left: 40px;
      }
      .margin-bottom-0 {
        margin-bottom: 0;
      }
      .title {
        border-bottom: 1px solid #3c4450;
        padding-bottom: 10px;
        .box-title {
          padding: 16px 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-same-list-ekyc {
        padding: 24px;
        .title {
          border-bottom: 1px solid #3c4450;
          .box-title {
            padding-left: 0px !important;
          }
        }
        .box-content {
          display: flex;
          margin-top: 25px;
          justify-content: space-between;
          border-bottom: 1px solid #3c4450;
          padding-bottom: 20px;
          .item {
            width: 18%;
            .label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #8c8c8c;
              margin-bottom: 10px;
            }
            .value {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f0f0f0;
              margin-bottom: 30px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .style-face-samelist {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .content-user {
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        .item-column {
          width: 23%;
          margin-right: 20px;
          .box-img-card {
            margin-top: 20px;
            img {
              object-fit: contain;
            }
            .style-of-face {
              object-fit: contain;
              background: #fff;
            }
          }
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            margin-bottom: 10px;
          }
          .text-err-detail-ekyc {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #ff4d4f;
            margin-top: 20px;
          }
          .value {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .box-edit-customer {
            display: flex;
            .edit-customer {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f2c015;
              cursor: pointer;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .section-tabs-detail {
      margin-top: 24px;
      background: #1a1f25;
      .title {
        .box-title {
          padding: 16px 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-verify {
        .title-verify-face {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f5f5f5;
        }
        .image-verify-face {
          display: flex;
          margin-top: 24px;
          margin-bottom: 24px;
          .box-img {
            width: 140px;
            height: 159px;
            box-sizing: border-box;
            left: 24px;
            top: 118px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
        .CCCD-verify-face {
          display: flex;
          margin-top: 24px;
          .box-img {
            width: 333px;
            box-sizing: border-box;
            height: 194px;
            left: 24px;
            top: 347px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
      }
    }
  }
`;
export const ModalEditEkyc = styled(Modal)`
  .ant-col.ant-form-item-label {
    display: none;
  }
  .ant-picker {
    width: 100%;
    height: 32px;
    background: #272d35;
    border-radius: 2px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 818px;
    background: #1a1f25;
    border-radius: 4px;
  }
  .ant-select-selector {
    background-color: #272d35 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 55%;
      /* height: 100%; */
      border-right: 1px solid #3c4450;
      max-height: 680px;
      overflow: overlay;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #bfbfbf;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      max-height: 680px;
      overflow: overlay;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
        .margin-top-20 {
          margin-top: 20px;
        }
        .img-form-edit {
          border: 1px solid #3c4450;
          border-radius: 4px;
          width: 100%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
`;
