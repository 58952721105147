import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseReconciliationLoan, DataResponseReconciliationLoanDetail, paramsExcel } from './interface';

const httpApi = initAxios();

interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: TokenConfig = {
  secured: true,
};

export const getReconciliationLoanListApi = (params: string): Promise<DataResponseReconciliationLoan> => httpApi.get(`/loans/v1/reconcile/loan?${params}`, { ...TokenConfig });

export const getReconciliationLoanDetailApi = (params: string): Promise<DataResponseReconciliationLoanDetail> => httpApi.get(`/loans/v1/reconcile/loan/by-day?${params}`, { ...TokenConfig });

export const getReconciliationLoanExportExcel = (params: paramsExcel): Promise<string> => httpApi.post('/loans/v1/reconcile/loan/export', params, { ...TokenConfig });
