import React, { useEffect } from 'react';
import LoadingAnimation from '@app/assets/animated-json/loading-gray-3.json';
import lottie from 'lottie-web';
import * as S from './AuthenMeCallback.style';
import queryString from 'query-string';
import { getAccessToken } from '@app/modules/Oauth2/AuthenMe/AuthenMeCallback.api';
import _ from 'lodash';
import { persistOAuth2Data } from '@app/services/localStorage.service';

const AuthenMeCallback: React.FC = () => {
  const elem = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const completed_anim = lottie.loadAnimation({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      container: elem.current,
      animationData: LoadingAnimation,
      autoplay: true,
      loop: true,
    });

    return () => {
      completed_anim.destroy();
    };
  }, []);

  useEffect(() => {
    const params = queryString.parse(location.search);
    if (!_.isEmpty(params.code)) {
      const authorization_code = params.code as string;
      getAccessToken(authorization_code).then((resp) => {
        if (resp.error !== undefined) {
          location.href = `/oauth2/error?code=${authorization_code}`;
        } else {
          persistOAuth2Data(resp);
          location.href = '/';
        }
      });
    }
  }, []);

  return (
    <S.Wrapper>
      <S.BoxLoading ref={elem} />
    </S.Wrapper>
  );
};

export default AuthenMeCallback;
