import styled from 'styled-components';
export const StyleColumnTime = styled.div`
  .ant-divider-vertical {
    margin-top: 0px;
  }
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
  }
`;
