import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../Loan.styles';
import { ColumnsType } from 'antd/lib/table';

import { queryStringParams } from '@app/controllers/queryStringParams';
import { useParams } from 'react-router-dom';
import { LoanListContext, LoanListProvider } from '../Context';
import { getDetailWaitForDisbursementApi } from '../Loan.api';
import { DataResponseDetailWaitForDisbursement, DataTableDetailWaitForDisbursement } from '../interface';
import { updateFiltersDetailWaitForDisbursement } from '../Action';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import moment from 'moment';
import { FormSearchDetailWaitForDisbursement } from '../ComponentLoan/FormSearchDetailWaitForDisbursement';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
function DetailWaitForDisbursement() {
  const [checkLoading, setcheckLoading] = useState(false);
  const { dateReview } = useParams();
  const [data, setData] = useState<DataResponseDetailWaitForDisbursement>();
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const dispatch = useAppDispatch();

  const callApiLoanDetailWaitForDisbursement = async () => {
    setcheckLoading(true);
    if (dateReview) {
      await getDetailWaitForDisbursementApi(dateReview, queryStringParams(stateLoanList?.filtersDetailWaitForDisbursement))
        .then((res) => {
          setData(res);
          setcheckLoading(false);
        })
        .catch((err) => {
          setData(undefined);

          console.log(err);
          setcheckLoading(false);
        });
    }
  };
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
        },
        {
          name: 'Danh sách Khoản vay ',
          url: '/customers/loan',
        },
        {
          name: `${dateReview}`,
        },
      ]),
    );

    dispatch(setBreadcrumbTitle(`${dateReview}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(`${dateReview}`));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchLoanList, dispatch]);
  const columnsConfig: ColumnsType<DataTableDetailWaitForDisbursement> = [
    {
      title: 'ID khoản vay',
      key: 'code',
      dataIndex: 'code',
      render: (value: string) => <a>{value && value}</a>,
    },
    {
      title: 'Tên khách hàng',
      key: 'customerName',
      render: (value: DataTableDetailWaitForDisbursement) => <UserNameAvatarColumn name={value?.customerName ? value?.customerName : ''}></UserNameAvatarColumn>,
    },
    {
      width: '150px',
      title: 'REF ID',
      key: 'referenceId',
      dataIndex: 'referenceId',
    },
    {
      width: '200px',
      title: 'Số tiền vay',
      align: 'right',
      render: (value: DataTableDetailWaitForDisbursement) => <div>{value ? (value.loanAmount ? formatNumberWithCommas(value.loanAmount) : 0) : null}</div>,
    },
    {
      width: '200px',
      title: 'Số tiền hoàn gốc',
      align: 'right',
      render: (value: DataTableDetailWaitForDisbursement) => <div>{value ? (value.refundPrincipalAmount ? formatNumberWithCommas(value.refundPrincipalAmount) : 0) : null}</div>,
    },
    {
      width: '200px',
      title: 'Số tiền giải ngân',
      align: 'right',
      render: (value: DataTableDetailWaitForDisbursement) => <div>{value ? (value.totalAmountDisbursement ? formatNumberWithCommas(value.totalAmountDisbursement) : 0) : null}</div>,
    },
    {
      width: '148px',
      title: 'Ngày duyệt',
      key: 'loanType',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (value: DataTableDetailWaitForDisbursement) => <div>{value?.approvalDate && moment(value?.approvalDate).format('DD-MM-YYYY')}</div>,
    },
    {
      width: '190px',
      title: 'Đối tác liên kết',
      key: 'partner',
      dataIndex: 'partner',
    },
    {
      width: '187px',
      title: 'Ngày giải ngân',
      key: 'referenceId',
      render: (value: DataTableDetailWaitForDisbursement) => <div>{value?.approvalDisbursementTime ? value?.approvalDisbursementTime : '-'}</div>,
    },
  ];

  useEffect(() => {
    callApiLoanDetailWaitForDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLoanList?.filtersDetailWaitForDisbursement]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxDetailWaitForDisbursement>
            <FormSearchDetailWaitForDisbursement
              callApiLoanDetailWaitForDisbursement={callApiLoanDetailWaitForDisbursement}
              dataDetailWaitForDisbursement={data && data?.data}
              dateReview={dateReview && dateReview}
            ></FormSearchDetailWaitForDisbursement>
            <Table
              scroll={{ x: 1700 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="code"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchLoanList(updateFiltersDetailWaitForDisbursement({ ...stateLoanList?.filtersDetailWaitForDisbursement, pageIndex: page }));
                },
              }}
            />
          </S.BoxDetailWaitForDisbursement>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}

export default function DetailWaitForDisbursementBoxProvider() {
  return (
    <LoanListProvider>
      <DetailWaitForDisbursement />
    </LoanListProvider>
  );
}
