import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../ReconciliationLoan.style';
const { RangePicker } = DatePicker;

import { DataResponseReconciliationLoan } from '../interface';
import { ReconciliationLoanListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getReconciliationLoanListApi } from '../ReconciliationLoan.api';
import { updateFiltersReconciliationLoanList } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';
import { columnsConfig } from './columnsConfig';

export default function TableReconciliationLoanList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataResponseReconciliationLoan>();
  const { stateReconciliationLoan, dispatchReconciliationLoanList } = useContext(ReconciliationLoanListContext);
  const callApiList = async () => {
    setcheckLoading(true);
    await getReconciliationLoanListApi(queryStringParams(stateReconciliationLoan?.filtersReconciliationLoanList))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        setData(res); /// fake
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReconciliationLoan?.filtersReconciliationLoanList]);

  function onChangeDate(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    dispatchReconciliationLoanList(updateFiltersReconciliationLoanList({ ...stateReconciliationLoan?.filtersReconciliationLoanList, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }));
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxReconciliationLoan>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <RangePicker format="YYYY-MM-DD" onChange={onChangeDate} />
              </Form.Item>
            </div>
            <Table
              scroll={{ x: 1080 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="date"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchReconciliationLoanList(updateFiltersReconciliationLoanList({ ...stateReconciliationLoan?.filtersReconciliationLoanList, pageIndex: page }));
                },
              }}
            />
          </S.BoxReconciliationLoan>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
