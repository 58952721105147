import React from 'react';
import { initReconciliationDisbursement, useReconciliationDisbursementReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const ReconciliationDisbursementContext = React.createContext({ ...initReconciliationDisbursement });
export const ReconciliationDisbursementProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateReconciliationDisbursement, dispatchReconciliationDisbursement] = useReconciliationDisbursementReducer();

  const wrap = {
    stateReconciliationDisbursement,
    dispatchReconciliationDisbursement,
  };

  return <ReconciliationDisbursementContext.Provider value={wrap}>{children}</ReconciliationDisbursementContext.Provider>;
};
