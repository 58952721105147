import React, { useContext, useState } from 'react';
import * as S from '../Policy.style';
import { PolicyContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPolicy } from '../Action';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { CreatePolicyApi } from '../Policy.api';
import { notificationController } from '@app/controllers/notificationController';

type Props = {
  data?: any;
};

export default function ModalCreatePolicy({}: Props) {
  const { statePolicy, dispatchPolicy } = useContext(PolicyContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: false }));
    form.resetFields();
    form.setFieldsValue({
      name: '',
      code: '',
      maximumLoanRate: '',
      maximumLoanAmount: '',
      loanAmountAutoApprove: '',
      gracePeriod: '',
      dayPerPeriod: '',
      lateFeeFirstTerm: '',
      increasingFeePerPeriod: '',
      dayRecall: '',
      creditScore: '',
      feeRate_range_0_10: '',
      interestRate_range_0_10: '',
      feeRate_range_10_20: '',
      interestRate_range_10_20: '',
      feeRate_range_20_30: '',
      interestRate_range_20_30: '',
      feeRate_range_30_40: '',
      interestRate_range_30_40: '',
      feeRate_range_40_50: '',
      interestRate_range_40_50: '',
      feeRate_range_50_60: '',
      interestRate_range_50_60: '',
      feeRate_range_60_70: '',
      interestRate_range_60_70: '',
      feeRate_range_70_80: '',
      interestRate_range_70_80: '',
      feeRate_range_80_90: '',
      interestRate_range_80_90: '',
      feeRate_range_90_100: '',
      interestRate_range_90_100: '',
    });
  }

  const onFinish = async (values: any) => {
    console.log('value', values);
    setloading(true);
    if (values) {
      CreatePolicyApi({
        name: values?.name,
        code: values?.code,
        maximumLoanRate: values?.maximumLoanRate,
        maximumLoanAmount: values?.maximumLoanAmount,
        loanAmountAutoApprove: values?.loanAmountAutoApprove,
        gracePeriod: values?.gracePeriod,
        dayPerPeriod: values?.dayPerPeriod,
        lateFeeFirstTerm: values?.lateFeeFirstTerm,
        increasingFeePerPeriod: values?.increasingFeePerPeriod,
        dayRecall: values?.dayRecall,
        creditScore: values?.creditScore,
        policySettingList: [
          {
            lowerLoanRate: 0,
            higherLoanRate: 10,
            feeRate: values?.feeRate_range_0_10 ? values?.feeRate_range_0_10 : 0,
            interestRate: values?.interestRate_range_0_10 ? values?.interestRate_range_0_10 : 0,
          },
          {
            lowerLoanRate: 10,
            higherLoanRate: 20,
            feeRate: values?.feeRate_range_10_20 ? values?.feeRate_range_10_20 : 0,
            interestRate: values?.interestRate_range_10_20 ? values?.interestRate_range_10_20 : 0,
          },
          {
            lowerLoanRate: 20,
            higherLoanRate: 30,
            feeRate: values?.feeRate_range_20_30 ? values?.feeRate_range_20_30 : 0,
            interestRate: values?.interestRate_range_20_30 ? values?.interestRate_range_20_30 : 0,
          },
          {
            lowerLoanRate: 30,
            higherLoanRate: 40,
            feeRate: values?.feeRate_range_30_40 ? values?.feeRate_range_30_40 : 0,
            interestRate: values?.interestRate_range_30_40 ? values?.interestRate_range_30_40 : 0,
          },
          {
            lowerLoanRate: 40,
            higherLoanRate: 50,
            feeRate: values?.feeRate_range_40_50 ? values?.feeRate_range_40_50 : 0,
            interestRate: values?.interestRate_range_40_50 ? values?.interestRate_range_40_50 : 0,
          },
          {
            lowerLoanRate: 50,
            higherLoanRate: 60,
            feeRate: values?.feeRate_range_50_60 ? values?.feeRate_range_50_60 : 0,
            interestRate: values?.interestRate_range_50_60 ? values?.interestRate_range_50_60 : 0,
          },
          {
            lowerLoanRate: 60,
            higherLoanRate: 70,
            feeRate: values?.feeRate_range_60_70 ? values?.feeRate_range_60_70 : 0,
            interestRate: values?.interestRate_range_60_70 ? values?.interestRate_range_60_70 : 0,
          },
          {
            lowerLoanRate: 70,
            higherLoanRate: 80,
            feeRate: values?.feeRate_range_70_80 ? values?.feeRate_range_70_80 : 0,
            interestRate: values?.interestRate_range_70_80 ? values?.interestRate_range_70_80 : 0,
          },
          {
            lowerLoanRate: 80,
            higherLoanRate: 90,
            feeRate: values?.feeRate_range_80_90 ? values?.feeRate_range_80_90 : 0,
            interestRate: values?.interestRate_range_80_90 ? values?.interestRate_range_80_90 : 0,
          },
          {
            lowerLoanRate: 90,
            higherLoanRate: 100,
            feeRate: values?.feeRate_range_90_100 ? values?.feeRate_range_90_100 : 0,
            interestRate: values?.interestRate_range_90_100 ? values?.interestRate_range_90_100 : 0,
          },
        ],
      })
        .then((res) => {
          dispatchPolicy(updateModalsPolicy({ ...statePolicy?.modalsPolicy, modalCreate: false }));

          if (res?.error) {
            setloading(false);
          } else {
            setloading(false);
            notificationController.success({ message: `Tạo thành công` });
            // form.setFieldsValue({
            //   name: '',
            //   code: '',
            //   maximumLoanRate: '',
            //   maximumLoanAmount: '',
            //   loanAmountAutoApprove: '',
            //   gracePeriod: '',
            //   dayPerPeriod: '',
            //   lateFeeFirstTerm: '',
            //   increasingFeePerPeriod: '',
            //   dayRecall: '',
            //   creditScore: '',
            // });
            handleCancel();
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          handleCancel();
        });
    }
  };

  return (
    <S.ModalCreatePolicy title={'Thêm chính sách'} open={statePolicy?.modalsPolicy?.modalCreate} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">Tên chính sách</div>
                <Form.Item name={'name'} label="Tên chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
              <div className="item-form">
                <div className="label">Mã chính sách</div>
                <Form.Item name={'code'} label="Mã chính sách" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>
            <h2 className="title-modal">Tỷ lệ phí và lãi theo tỷ lệ cho vay</h2>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỉ lệ cho vay mặc định (%)</div>
                  <Form.Item name={'maximumLoanRate'} label="Tỉ lệ cho vay mặc định (%)" rules={[{ required: true, type: 'string' }]}>
                    <Input pattern="^\$\d{1,3}(,\d{3})*(\.\d+)?$" type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
                <div className="item-form">
                  <div className="label">Số tiền cho vay tối đa trên khoản vay(đ)</div>
                  <Form.Item name={'maximumLoanAmount'} label="Số tiền cho vay tối đa trên khoản vay(đ)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;0 và &lt;=10%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_0_10'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_0_10'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số tiền duyệt tự động(đ)</div>
                  <Form.Item name={'loanAmountAutoApprove'} label="Số tiền duyệt tự động(đ)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;10 và &lt;=20%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_10_20'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_10_20'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Điểm tín dụng tối thiểu của đơn hàng(điểm)</div>
                  <Form.Item name={'creditScore'} label="Điểm tín dụng tối thiểu của đơn hàng(điểm)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;20 và &lt;=30%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_20_30'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_20_30'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Ngày ân hạn(Ngày)</div>
                  <Form.Item name={'gracePeriod'} label="Ngày ân hạn(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;30 và &lt;=40%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_30_40'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_30_40'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày/ kỳ(Ngày)</div>
                  <Form.Item name={'dayPerPeriod'} label="Số ngày/ kỳ(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;40 và &lt;=50%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_40_50'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_40_50'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Phí quá hạn kỳ 1(%)</div>
                  <Form.Item name={'lateFeeFirstTerm'} label="Số ngày/ kỳ(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;50 và &lt;=60%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_50_60'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_50_60'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Phí tăng sau mỗi kỳ(%)</div>
                  <Form.Item name={'increasingFeePerPeriod'} label="Phí tăng sau mỗi kỳ(%)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;60 và &lt;=70%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_60_70'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_60_70'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
                <div className="item-form">
                  <div className="label">Số ngày bị thu hồi(Ngày)</div>
                  <Form.Item name={'dayRecall'} label="Số ngày bị thu hồi(Ngày)" rules={[{ required: true, type: 'string' }]}>
                    <Input type="number" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;70 và &lt;=80%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_70_80'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_70_80'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;80 và &lt;=90%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_80_90'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_80_90'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
              <div className="item-line">
                <div className="item-form">
                  <div className="label">Tỷ lệ cho vay: &gt;90 và &lt;=100%</div>
                  <S.lineSpecial>
                    <div className="col-1">
                      <div className="label-special">Lãi/Năm(%)</div>
                      <Form.Item name={'interestRate_range_90_100'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                    <div className="col-2">
                      <div className="label-special">Phí(%)</div>
                      <Form.Item name={'feeRate_range_90_100'} label="" rules={[{ required: true, type: 'string' }]}>
                        <Input type="number" size={'small'} className={'style-input-create'} />
                      </Form.Item>
                    </div>
                  </S.lineSpecial>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreatePolicy>
  );
}
