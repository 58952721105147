import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../DebtList.styles';

import { ColumnsType } from 'antd/lib/table';
import { DataResponseDebtWaitForApprove, DataTableWaitForApproveType } from '../interface';
import Avatar from '@app/assets/icons/avatar.svg';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import { formatNumberWithCommas } from '@app/utils/utils';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

import { DebtListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getDebtListWaitForApproveApi } from '../DebtList.api';
import { updateFiltersDebtListWaitForApprove, updateModals } from '../Action';
import ActionColumn from '@app/components/common/ActionColumn/ActionColumn';
import { FormSearchTableWaitForApproval } from './FormSearchTableWaitForApproval';
import ModalApprovalDebt from './ModalApproval';
import { useNavigate } from 'react-router-dom';
import ModalRejectPayment from './ModalRejectPayment';

export default function TableWaitForApproval() {
  const [checkLoading, setcheckLoading] = useState(false);
  const navigate = useNavigate();
  const [saveRecordModalApproval, setsaveRecordModalApproval] = useState<DataTableWaitForApproveType>();
  const [saveRecordModalReject, setsaveRecordModalRejcet] = useState<DataTableWaitForApproveType>();

  const [data, setData] = useState<DataResponseDebtWaitForApprove>();
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);
  const callApiDebtListWaitForAprrove = async () => {
    setcheckLoading(true);
    await getDebtListWaitForApproveApi(queryStringParams(stateDebtList?.filtersWaitForApprove))
      .then((res) => {
        setData(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  const handleShowModalApproval = (record: DataTableWaitForApproveType) => {
    setsaveRecordModalApproval(record);
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalAprrove: true }));
  };

  function moveDetailLoan(value: DataTableWaitForApproveType) {
    navigate(`/customers/loan/${value?.loanCode}`);
  }

  function handleShowRejected(record: DataTableWaitForApproveType) {
    setsaveRecordModalRejcet(record);

    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalRejectPayment: true }));
  }

  useEffect(() => {
    callApiDebtListWaitForAprrove();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDebtList?.filtersWaitForApprove, dispatchDebtList]);
  const columnsConfig: ColumnsType<DataTableWaitForApproveType> = [
    {
      title: 'ID xác nhận thanh toán',
      key: 'code',
      fixed: 'left',

      dataIndex: 'code',
      render: (value: string) => <div className="text-paymentcode">{value ? value : ''}</div>,
    },
    {
      title: 'ID khoản vay',
      key: 'loanCode',
      fixed: 'left',
      width: '250px',

      render: (value: DataTableWaitForApproveType) => (
        <a className="margin-left" onClick={() => moveDetailLoan(value)}>
          {value ? value?.loanCode : ''}
        </a>
      ),
    },
    {
      width: '170px',
      title: 'Tên khách hàng',
      key: 'customerName',
      dataIndex: 'customerName',

      render: (value: string) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      align: 'right',
      width: '150px',
      title: 'Số tiền nhận',
      key: 'paymentAmount',
      dataIndex: 'paymentAmount',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },

    {
      width: '180px',
      title: 'Đối tác liên kết',
      key: 'partnerName',
      dataIndex: 'partnerName',
    },
    {
      width: '250px',
      title: 'Ngày Thanh Toán',
      key: 'paymentDate',
      dataIndex: 'paymentDate',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
    },

    {
      width: '148px',
      title: 'Chứng từ',
      key: 'authenticationFile',
      dataIndex: 'authenticationFile',
      render: (value: string) => <div>{value ? value : ''}</div>,
    },

    {
      width: '155px',
      title: 'Trạng thái BF',
      key: 'status',
      render: (value: DataTableWaitForApproveType) => <StatusesText status={value && value?.status} context="PAYMENT"></StatusesText>,
    },

    {
      width: '148px',
      title: 'Lý do từ chối',
      key: 'reason',
      dataIndex: 'reason',
      render: (value: string) => <div>{value ? value : ''}</div>,
    },
    {
      width: '89px',
      title: 'Hành động',
      key: 'action',
      render: (record: DataTableWaitForApproveType) =>
        record?.status == 'WAIT' ? (
          <ActionColumn
            handleRejected={() => handleShowRejected(record)}
            handleApproval={() => handleShowModalApproval(record)}
            actionReject={true}
            actionApproval={true}
            status={'WAIT_FOR_APPROVE'}
          ></ActionColumn>
        ) : (
          ''
        ),
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxDebt>
            <FormSearchTableWaitForApproval></FormSearchTableWaitForApproval>
            <Table
              scroll={{ x: 1900 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchDebtList(updateFiltersDebtListWaitForApprove({ ...stateDebtList?.filtersWaitForApprove, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxDebt>
        </div>
        {saveRecordModalApproval && (
          <ModalApprovalDebt RecordRow={saveRecordModalApproval && saveRecordModalApproval} callApiDebtListWaitForAprrove={callApiDebtListWaitForAprrove}></ModalApprovalDebt>
        )}
        {saveRecordModalReject && <ModalRejectPayment RecordRow={saveRecordModalReject && saveRecordModalReject} callApiDebtListWaitForAprrove={callApiDebtListWaitForAprrove}></ModalRejectPayment>}
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
