import { Button } from 'antd';
import React from 'react';
import * as S from './Footer.styles';
type Props = {
  labelCancel?: string;
  LabelSave?: string;
  handleClickCancel?: () => void;
  handleClickSave?: () => void;
  type?: 'button' | 'submit' | 'reset' | undefined;
  handleLoading?: boolean;
  className?: string;
  padding?: string;
};

export default function FooterModal(props: Props) {
  return (
    <S.FooterModal>
      <div className="footer" style={{ padding: `${props?.padding}` }}>
        <div className="box-btn">
          <Button onClick={props?.handleClickCancel} className="btn-cancel">
            {props?.labelCancel}
          </Button>
          {props?.type == undefined ? null : (
            <Button loading={props?.handleLoading} onClick={props?.handleClickSave} htmlType={props.type} className={`btn-save  ${props.className}`}>
              {props?.LabelSave}
            </Button>
          )}
        </div>
      </div>
    </S.FooterModal>
  );
}
