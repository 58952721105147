import React from 'react';
import { initReconciliationLoan, useReconciliationLoanListReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const ReconciliationLoanListContext = React.createContext({ ...initReconciliationLoan });
export const ReconciliationLoanListProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateReconciliationLoan, dispatchReconciliationLoanList] = useReconciliationLoanListReducer();

  const wrap = {
    stateReconciliationLoan,
    dispatchReconciliationLoanList,
  };

  return <ReconciliationLoanListContext.Provider value={wrap}>{children}</ReconciliationLoanListContext.Provider>;
};
