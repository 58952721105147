import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Partner.style';
import { PartnerContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPartner } from '../Action';
import { Form, Input } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { DataResponseApiPartnerList, DataTablePolicesType } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataResponseApiPolicyList } from '../../Policy/interface';
import { getPolicySelect } from '../../Product/Product.api';
import { DataResponsePolicySelectApi } from '../../Product/interface';

type Props = {
  saveDataRecord?: DataTablePolicesType | undefined;
  dataPartner?: DataResponseApiPartnerList[] | undefined;
  dataListPolicy?: DataResponseApiPolicyList | undefined;
  codeDefault?: string | undefined;
};

export default function ModalDetailCampaign(props: Props) {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkLoading, setcheckLoading] = useState(false);
  const [form] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setloading] = useState(false);
  const [dataPolicySelect, setdataPolicySelect] = useState<any>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  function handleCancel() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalDetailCampaign: false }));
  }

  useEffect(() => {
    if (props?.saveDataRecord) {
      form.setFieldsValue({
        campaignName: props?.saveDataRecord?.campaignName,
        policyCode: props?.saveDataRecord?.policyCode,
        time: `${props?.saveDataRecord?.beginDate} -> ${props?.saveDataRecord?.endDate}`,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.saveDataRecord]);

  const callApiInforPolicy = async () => {
    if (props?.saveDataRecord) {
      await getPolicySelect(props?.saveDataRecord?.policyCode && props?.saveDataRecord?.policyCode)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
  };

  useEffect(() => {
    callApiInforPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.saveDataRecord]);

  return (
    <S.ModalCreateCampaign title={`Chi tiết chiến dịch`} open={statePartner?.modalsPartner?.ModalDetailCampaign} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <GS.FormSearch>
            <div>
              <div className="content-header">
                <div className="item-form">
                  <div className="label">Thời gian </div>
                  <div className={'filter-item'}>
                    <Form.Item name={'time'} rules={[{ required: true, message: 'Nhập tên' }]}>
                      <Input disabled size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                </div>
                <div className="item-form">
                  <div className="label"> Tên chiến dịch</div>
                  <Form.Item name={'campaignName'} rules={[{ required: true, message: 'Nhập tên' }]}>
                    <Input disabled size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="content-header">
                <div className="item-form">
                  <div className="label"> Chính sách mặc định</div>
                  <Form.Item name={'policyCode'} rules={[{ required: true, message: 'Chọn chính sách mặc địch' }]}>
                    <Form.Item name={'campaignName'} rules={[{ required: true, message: 'Nhập tên' }]}>
                      <Input disabled size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </Form.Item>
                </div>
              </div>

              <div className="content-body-default-policy">
                <h2 className="title-modal">Thông tin chính sách</h2>
                <div className="box-col">
                  <div className="item-form">
                    <div className="item-line-product">
                      <div className="label">Tỉ lệ cho vay trên đơn hàng </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.maximumLoanRate + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số tiền cho vay tối đa/khoản vay </div>
                      <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.maximumLoanAmount ? dataPolicySelect?.maximumLoanAmount : 0) : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số tiền duyệt tự động(≤) </div>
                      <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.loanAmountAutoApprove ? dataPolicySelect?.loanAmountAutoApprove : 0) : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Điểm tín dụng tối thiểu của đơn hàng </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.creditScore : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Lãi/năm </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.interestRate + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.feeRate + ' %' : '-'}</div>
                    </div>
                  </div>
                  <div className="item-form">
                    <div className="item-line-product">
                      <div className="label">Ngày ân hạn </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.gracePeriod + ' Ngày' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số ngày/ kỳ</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.dayPerPeriod + ' Ngày' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí quá hạn kỳ 1</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.lateFeeFirstTerm + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí tăng sau mỗi kỳ</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.increasingFeePerPeriod + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số ngày bị thu hồi</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.dayRecall + ' Ngày' : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterModal handleClickCancel={handleCancel} type={undefined} padding="24px 0px 0px 0px" labelCancel="Đóng"></FooterModal>
            </div>
          </GS.FormSearch>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreateCampaign>
  );
}
