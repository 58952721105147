import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../Disbursement.styles';
const { RangePicker } = DatePicker;
import { ColumnsType } from 'antd/lib/table';

import { DisbursementContext, DisbursementProvider } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { updateFiltersWaitForDisbursement } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DataResponseTableWaitForDisbursement, DataTableWaitForDisbursement } from '../interface';
import { getListWaitForDisbursementApi } from '../Disbursement.api';

export default function TableDisbursementList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataResponseTableWaitForDisbursement>();
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const callApiList = async () => {
    setcheckLoading(true);
    await getListWaitForDisbursementApi(queryStringParams(stateDisbursement?.filtersTabWaitForDisbursement))
      .then((res) => {
        setData(res); /// fake
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement?.filtersTabWaitForDisbursement]);

  function redirectDetail(record: DataTableWaitForDisbursement) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    if (record?.dateReview) {
      location.href = `/customers/disbursement/detail-disbursement/${record?.dateReview}`;
    }
  }

  const columnsConfig: ColumnsType<DataTableWaitForDisbursement> = [
    {
      width: '237px',
      title: 'Ngày',
      sorter: (a: any, b: any) => a.age - b.age,

      render: (record: DataTableWaitForDisbursement) => (
        <S.columnDateReviewStyle className="text-paymentcode box-date-review" onClick={() => redirectDetail(record)}>
          {record.dateReview ? record.dateReview : '-'}
        </S.columnDateReviewStyle>
      ),
    },
    {
      width: '561px',
      title: 'Tổng số giao dịch',
      key: 'total',
      dataIndex: 'total',
    },

    {
      width: '237px',
      title: 'Số khoản vay đã giải ngân',

      render: (value: DataTableWaitForDisbursement) => <div>{`${value?.total - value?.inReviewTotal} / ${value?.total}`}</div>,
    },

    {
      width: '111px',
      title: 'Hành động',
      render: (record: DataTableWaitForDisbursement) => (
        <div className="action-watch" onClick={() => redirectDetail(record)}>
          Xem
        </div>
      ),
    },
  ];

  //   useEffect(() => {
  //     callApiList();
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [stateDisbursement?.filtersWaitForDisbursement]);

  function onChangeDate(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchDisbursement(updateFiltersWaitForDisbursement({ ...stateDisbursement?.filtersTabWaitForDisbursement, reviewFromDate: dateString[0], reviewToDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchDisbursement(updateFiltersWaitForDisbursement({ ...stateDisbursement?.filtersTabWaitForDisbursement, reviewFromDate: '', reviewToDate: '', pageIndex: 1 }));
    }
  }
  return (
    <DisbursementProvider>
      <GS.WrapperOverrideInput>
        <GS.ContainerTable>
          <div className="box-content">
            <S.BoxDisbursementList>
              <div className={'filter-item'}>
                <Form.Item name={'statuses'}>
                  <RangePicker format="DD-MM-YYYY" onChange={onChangeDate} />
                </Form.Item>
              </div>
              <Table
                scroll={{ x: 1080 }}
                // rowSelection={{ type: 'checkbox' }}
                dataSource={data && data?.data}
                columns={columnsConfig}
                loading={checkLoading}
                sticky={true}
                rowKey="code"
                pagination={{
                  current: data?.pageIndex,
                  pageSize: data?.pageSize,
                  total: data?.total,
                  onChange: (page: number) => {
                    dispatchDisbursement(updateFiltersWaitForDisbursement({ ...stateDisbursement?.filtersTabWaitForDisbursement, pageIndex: page }));
                  },
                }}
              />
            </S.BoxDisbursementList>
          </div>
        </GS.ContainerTable>
      </GS.WrapperOverrideInput>
    </DisbursementProvider>
  );
}
