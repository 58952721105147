import { ColumnsType } from 'antd/lib/table';
import { DataTypeTable } from '../interface';

function redirectDetail(record: DataTypeTable) {
  location.href = `disbursement/${record?.date}`;
}

export const columnsConfig: ColumnsType<DataTypeTable> = [
  {
    width: '237px',
    title: 'Ngày',
    key: 'date',
    dataIndex: 'date',
    sorter: (a: any, b: any) => a.age - b.age,
    render: (value: string) => <a className="text-paymentcode">{value ? value : ''}</a>,
  },
  {
    width: '225px',
    title: 'Tổng số giao dịch (BG/BF)',
    key: 'totalBifiinDisbursement',
    render: (value: any) => <div className={value && value?.needProcess > 0 ? 'err-text' : 'text-loancode'}>{value ? `${value?.totalPartnerTransaction} / ${value?.totalBifiinTransaction}` : ''}</div>,
  },
  {
    width: '563px',
    title: 'Giao dịch cần xử lý',
    key: 'needProcess',
    dataIndex: 'needProcess',
    render: (value: string) => {
      return <div>{value ? value : '0'}</div>;
    },
  },
  {
    width: '111px',
    title: 'Hành động',
    render: (record: DataTypeTable) => (
      <div className="action-watch" onClick={() => redirectDetail(record)}>
        Xem
      </div>
    ),
  },
];
