import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Partner.style';
import { PartnerContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPartner } from '../Action';
import { DatePicker, Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { DataResponseApiPartnerList } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataResponseApiPolicyList } from '../../Policy/interface';
import { getPolicySelect } from '../../Product/Product.api';
import { getPolicyApi } from '../../Policy/Policy.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { PolicyContext } from '../../Policy/Context';
import { DataResponsePolicySelectApi } from '../../Product/interface';
import { CreateCampaignApi } from '../Partner.api';
import { notificationController } from '@app/controllers/notificationController';
import { useParams } from 'react-router-dom';
import moment from 'moment';
const { RangePicker } = DatePicker;

type Props = {
  saveDataRecord?: DataResponseApiPartnerList | undefined;
  dataPartner?: DataResponseApiPartnerList[] | undefined;
  dataListPolicy?: DataResponseApiPolicyList | undefined;
  codeDefault?: string | undefined;
};

export default function ModalCreateCampaign(props: Props) {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkLoading, setcheckLoading] = useState(false);
  const { uuid } = useParams();
  const { productName } = useParams();
  const [savePolicyCode, setsavePolicyCode] = useState<string | undefined>(props?.codeDefault && props?.codeDefault);
  const [form] = Form.useForm();
  const [dataListPolicy, setdataListPolicy] = useState<DataResponseApiPolicyList>();
  const { statePolicy } = useContext(PolicyContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setloading] = useState(false);
  const [dataPolicySelect, setdataPolicySelect] = useState<any>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const callApiListPolicy = async () => {
    setcheckLoading(true);

    await getPolicyApi(queryStringParams(statePolicy?.filtersPolicy))
      .then((res) => {
        if (res?.error) {
          setdataListPolicy(undefined);
          setcheckLoading(false);
        } else {
        }
        setdataListPolicy(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setdataListPolicy(undefined);
        setcheckLoading(false);
      });
  };

  function handleCancel() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalCreateCampaign: false }));

    form.setFieldsValue({
      campaignName: '',
      time: undefined,
      policyCode: undefined,
    });
    setdataPolicySelect(undefined);
  }

  function onChangeSelect(e: any) {
    if (e) {
      setsavePolicyCode(e);
    }
  }

  useEffect(() => {
    callApiListPolicy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePolicy?.filtersPolicy]);

  useEffect(() => {
    if (props?.codeDefault) {
      getPolicySelect(props?.codeDefault)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.codeDefault]);

  useEffect(() => {
    if (savePolicyCode) {
      getPolicySelect(savePolicyCode ? savePolicyCode : props?.codeDefault)
        .then((res: DataResponsePolicySelectApi) => {
          res && setdataPolicySelect(res);
        })
        .catch((err) => {
          console.log(err);
          setdataPolicySelect(undefined);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savePolicyCode]);

  useEffect(() => {
    if (props?.codeDefault) {
      form.setFieldsValue({
        code: props?.codeDefault,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.codeDefault]);

  const onFinish = async (values: any) => {
    console.log('values', values);
    setloading(true);
    if (values && uuid && productName) {
      CreateCampaignApi(uuid, productName, {
        campaignName: values?.campaignName,
        beginDate: moment(values?.time[0]).format('YYYY-MM-DD'),
        endDate: moment(values?.time[1]).format('YYYY-MM-DD'),
        policyCode: values?.policyCode,
      })
        .then((res) => {
          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalCreateCampaign: false }));

          if (res?.error) {
            setloading(false);
            form.setFieldsValue({
              campaignName: '',
              time: undefined,
              policyCode: undefined,
            });
          } else {
            setloading(false);
            notificationController.success({ message: `Thêm chiến dịch thành công` });
            form.setFieldsValue({
              campaignName: '',
              time: undefined,
              policyCode: undefined,
            });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          form.setFieldsValue({
            campaignName: '',
            time: undefined,
            policyCode: undefined,
          });
          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, ModalCreateCampaign: false }));
        });
    }
  };

  return (
    <S.ModalCreateCampaign title={`Tạo chiến dịch`} open={statePartner?.modalsPartner?.ModalCreateCampaign} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" validateMessages={validateMessages} onFinish={onFinish}>
        <GS.WrapperOverrideInput>
          <GS.FormSearch>
            <div>
              <div className="content-header">
                <div className="item-form">
                  <div className="label">Thời gian </div>
                  <div className={'filter-item'}>
                    <Form.Item name={'time'} rules={[{ required: true, message: 'Nhập tên' }]}>
                      <RangePicker format="YYYY-MM-DD" />
                    </Form.Item>
                  </div>
                </div>
                <div className="item-form">
                  <div className="label"> Tên chiến dịch</div>
                  <Form.Item name={'campaignName'} rules={[{ required: true, message: 'Nhập tên' }]}>
                    <Input placeholder="Nhập tên chiến dịch" size={'small'} className={'style-input-create'} />
                  </Form.Item>
                </div>
              </div>
              <div className="content-header">
                <div className="item-form">
                  <div className="label"> Chính sách mặc định</div>
                  <Form.Item name={'policyCode'} rules={[{ required: true, message: 'Chọn chính sách mặc địch' }]}>
                    <Select onChange={onChangeSelect} placeholder={'Chọn chính sách mặc địch'} size={'small'} dropdownMatchSelectWidth={200}>
                      {dataListPolicy?.data &&
                        dataListPolicy?.data?.map((item, index) => (
                          <Select.Option key={index} value={`${item?.code}`}>
                            {item?.code}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>

              <div className="content-body-default-policy">
                <h2 className="title-modal">Thông tin chính sách</h2>
                <div className="box-col">
                  <div className="item-form">
                    <div className="item-line-product">
                      <div className="label">Tỉ lệ cho vay trên đơn hàng </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.maximumLoanRate + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số tiền cho vay tối đa/khoản vay </div>
                      <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.maximumLoanAmount ? dataPolicySelect?.maximumLoanAmount : 0) : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số tiền duyệt tự động(≤) </div>
                      <div className="value">{dataPolicySelect ? formatNumberWithCommas(dataPolicySelect?.loanAmountAutoApprove ? dataPolicySelect?.loanAmountAutoApprove : 0) : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Điểm tín dụng tối thiểu của đơn hàng </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.creditScore : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Lãi/năm </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.interestRate + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.feeRate + ' %' : '-'}</div>
                    </div>
                  </div>
                  <div className="item-form">
                    <div className="item-line-product">
                      <div className="label">Ngày ân hạn </div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.gracePeriod + ' Ngày' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số ngày/ kỳ</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.dayPerPeriod + ' Ngày' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí quá hạn kỳ 1</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.lateFeeFirstTerm + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Phí tăng sau mỗi kỳ</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.increasingFeePerPeriod + ' %' : '-'}</div>
                    </div>
                    <div className="item-line-product">
                      <div className="label">Số ngày bị thu hồi</div>
                      <div className="value">{dataPolicySelect ? dataPolicySelect?.dayRecall + ' Ngày' : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
            </div>
          </GS.FormSearch>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreateCampaign>
  );
}
