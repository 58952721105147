import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Disbursement.styles';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';

import * as GS from '@app/styles/GlobalStyle';

import { DisbursementContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import { DataDetail, DataTable } from '../interface';
import { CreateEditProofDisbursementApi, getDetailDisbursementApi } from '../Disbursement.api';
import { useNavigate } from 'react-router-dom';
import { UploadImgApi } from '@app/api/UploadFIle.api';
import { Button, Modal, Tooltip, Upload, UploadFile, UploadProps, message } from 'antd';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { notificationController } from '@app/controllers/notificationController';
import { formatNumberWithCommas } from '@app/utils/utils';
import { RcFile } from 'antd/lib/upload';

type Props = {
  data: DataTable | undefined;
  callApiListDisbursement?: () => void;
};
export default function ModalCreateProof(props: Props) {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [dataDetail, setdataDetail] = useState<DataDetail>();
  //   const [saveImg, setsaveImg] = useState();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  //   const [checkDelete, setcheckDelete] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>('');
  const [checkRender, setcheckRender] = useState<boolean>(true);

  useEffect(() => {
    setcheckRender(false);
    setsaveStringImg('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement?.stateModal?.stateModalCreateProof]);

  useEffect(() => {
    setTimeout(() => {
      setcheckRender(true);
    }, 500);
    setcheckRender(false);
    // setsaveStringImg('');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateDisbursement?.stateModal?.stateModalCreateProof]);
  //   function handleDelteImg() {
  //     setcheckDelete(true);
  //     setsaveStringImg('');
  //   }

  function handleCancelModalCreate() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProof: false }));
    // setcheckDelete(false);
    setcheckRender(false);

    props?.callApiListDisbursement && props?.callApiListDisbursement();
  }

  function handleOkModalCreate() {
    if (props?.data && saveStringImg != '') {
      setloading(true);

      CreateEditProofDisbursementApi(props?.data?.code, {
        proof: saveStringImg,
      })
        .then((res) => {
          if (res?.error) {
            setloading(false);
            // setcheckDelete(false);

            props?.callApiListDisbursement && props?.callApiListDisbursement();
            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProof: false }));
          } else {
            setloading(false);
            props?.callApiListDisbursement && props?.callApiListDisbursement();
            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProof: false }));
            // setcheckDelete(false);
            notificationController.success({ message: `Tạo thành công` });
          }
        })
        .catch(() => {
          setloading(false);
          dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalCreateProof: false }));
          //   setcheckDelete(false);

          notificationController.error({ message: `err API` });
        });
    }
  }

  const callApiDetail = async () => {
    if (props?.data) {
      await getDetailDisbursementApi(props?.data?.code)
        .then((res) => {
          setdataDetail(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //   const callApiGetImg = async () => {
  //     if (props?.data) {
  //       await getImg(props?.data?.proofKey)
  //         .then((res) => {
  //           setsaveImg(res);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     }
  //   };

  function moveDetailLoan() {
    if (dataDetail) {
      navigate(`/customers/loan/${dataDetail?.loanCode}`);
    }
  }

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setPreviewTitle(file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1));
  };
  function handleCancel() {
    setPreviewOpen(false);
  }

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    onPreview: handlePreview,
    name: 'file',
    multiple: true,
    maxCount: 1,
    listType: 'picture',
    // defaultFileList={[...fileList]},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status == 'removed') {
        setsaveStringImg('');
      }
      if (status !== 'uploading' && status !== 'removed') {
        const formData = new FormData();
        console.log('info.file', info.file);

        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'TRANSACTION',
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  useEffect(() => {
    if (stateDisbursement?.stateModal?.stateModalCreateProof == true && props?.data) {
      callApiDetail();
      //   callApiGetImg();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.data, stateDisbursement?.stateModal?.stateModalCreateProof]);

  return (
    <S.ModalCreateProof title="Chứng từ khoản vay " open={stateDisbursement?.stateModal?.stateModalCreateProof} onCancel={handleCancelModalCreate} footer={null}>
      <GS.WrapperOverrideInput style={{ width: '100%', display: 'flex' }}>
        <div className="content">
          {checkRender ? (
            <div className="section-img">
              <div className="box-content-img">
                <div className="save-image">
                  <Dragger {...propsUpdate}>
                    <p className="ant-upload-drag-icon">
                      <IconUploadFile />
                    </p>
                    <p className="ant-upload-text">
                      Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                    </p>
                  </Dragger>
                </div>
              </div>
              <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img alt="example" style={{ width: '100%' }} src={previewImage} />
              </Modal>
              <GS.StyleFooterModalCreate style={{ borderTop: 'none', marginTop: `${saveStringImg ? '75px' : '0px'}` }}>
                <Button key="back" onClick={handleCancelModalCreate}>
                  Huỷ
                </Button>

                <Button className="btn-create-submit" loading={loading} onClick={handleOkModalCreate} key="submit" htmlType="submit" style={{ marginLeft: '10px' }}>
                  Xác nhận
                </Button>
              </GS.StyleFooterModalCreate>
            </div>
          ) : null}
          <div className="section-infor">
            <div className="title">Chứng từ khoản vay</div>
            <div className="infor-content">
              <div className="box-line">
                <div className="label">Mã giải ngân</div>
                <a className="value color-id">{dataDetail ? dataDetail?.code : ''}</a>
              </div>
              <div className="box-line">
                <div className="label">ID khoản vay</div>
                <a className="value color-id" onClick={moveDetailLoan}>
                  {dataDetail ? dataDetail?.loanCode : ''}
                </a>
              </div>
              <div className="box-line">
                <div className="label">Tên khách hàng</div>
                <div className="value">
                  {/* <UserNameAvatarColumn avatar={Avatar} name={dataDetail ? dataDetail?.customerName : ''}></UserNameAvatarColumn> */}
                  {dataDetail ? dataDetail?.customerName : ''}
                </div>
              </div>
              <div className="box-line">
                <div className="label">Đối tác liên kết</div>
                <div className="value">{dataDetail ? dataDetail?.partner : ''}</div>
              </div>
              <div className="box-line">
                <div className="label">Ngày giải ngân</div>
                <div className="value">{dataDetail ? dataDetail?.transDate : ''}</div>
              </div>
              <div className="box-line">
                <div className="label">Số tiền</div>
                <div className="value">{dataDetail ? formatNumberWithCommas(dataDetail?.amount) : ''}</div>
              </div>
              <div className="box-line">
                <div className="label">Nguồn giải ngân</div>
                <div className="value">{dataDetail ? dataDetail?.fund : '-'}</div>
              </div>
              <div className="box-line">
                <div className="label">TK nhận giải ngân</div>
                {dataDetail?.accountReceiveMoney && (
                  <Tooltip placement="top" title={<span>{dataDetail ? dataDetail?.accountReceiveMoney : null}</span>}>
                    <div className="value value-special">{dataDetail ? dataDetail?.accountReceiveMoney : '-'}</div>
                  </Tooltip>
                )}
              </div>
              <div className="box-line">
                <div className="label">Người thực hiện</div>
                <div className="value">{dataDetail ? dataDetail?.executor : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      </GS.WrapperOverrideInput>
    </S.ModalCreateProof>
  );
}
