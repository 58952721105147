import { RateProps } from 'antd';
import * as S from './Rate.styles';
import React from 'react';

interface RatePropExtend extends RateProps {
  index?: number;
}

export const Rate: React.FC<RatePropExtend> = (props) => {
  return <S.Rate {...props} />;
};
