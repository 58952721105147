import { Form, Input, Select } from 'antd';
import React, { useContext, useState } from 'react';
import * as S from '../EkycManagement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { notificationController } from '@app/controllers/notificationController';
import { EkycManagementContext } from '../Context';
import { updateStateModalEkycManagement } from '../Action';
import _ from 'lodash';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { DataDetailEkycType } from '../interface';
import { rejectEkycApi } from '../EkycManagement.api';
type Props = {
  isModalOpenReject?: boolean;
  handleOkReject?: (e: any) => void;
  handleCancelReject?: (e: any) => void;
  dataDetailEkyc?: DataDetailEkycType;
  callApiDetailEkyc?: () => void;
};
export default function ModalReject(props: Props) {
  const [form] = Form.useForm();
  const [checkLoading, setcheckLoading] = useState(false);
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);

  const { TextArea } = Input;

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const onFinish = (values: any) => {
    setcheckLoading(true);
    if (values && props?.dataDetailEkyc) {
      rejectEkycApi({
        code: props?.dataDetailEkyc.code,
        reason: values?.reason ? values?.reason : 'Từ chối',
        description: values?.description ? values?.description : 'Từ chối',
      })
        .then((res) => {
          setcheckLoading(false);
          dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalReject: false }));
          if (res?.error) {
            dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalReject: false }));
          } else {
            notificationController.success({ message: `Từ chối thành công` });
            props?.callApiDetailEkyc && props?.callApiDetailEkyc();
            form.setFieldsValue({
              reason: undefined,
              description: '',
            });
          }
        })
        .catch((err) => {
          notificationController.error({ message: _.get(err, `Reject fail`, `${err} err API`) });
          dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalReject: false }));
        });
    }
  };

  const handleCancelModalReject = () => {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalReject: false }));
    form.setFieldsValue({
      description: '',
      reason: undefined,
    });
  };

  return (
    <S.ModalReject title="Lý do từ chối duyệt E81zNEOeG8aMq" open={stateEkycManagement?.stateModal?.stateModalReject} onCancel={handleCancelModalReject}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <S.DflexPara style={{ display: 'flex' }}>
            <S.StyleStar>*</S.StyleStar>
            <S.StylePara>Chọn lý do từ chối duyệt eKYC</S.StylePara>
          </S.DflexPara>
          <S.StyleInput className={'filter-item'}>
            <Form.Item label="lý do từ chối" name={'reason'} rules={[{ required: true, type: 'string' }]}>
              <Select placeholder={'Chọn lý do từ chối duyệt'} size={'small'} dropdownMatchSelectWidth={200}>
                <Select.Option value={'Khác'}>Khác</Select.Option>
              </Select>
            </Form.Item>
          </S.StyleInput>
          <S.StylePara>Chi tiết lý do</S.StylePara>
          <S.InputPara>
            <Form.Item name={'description'} style={{ marginTop: '20px' }}>
              <TextArea rows={4}></TextArea>
            </Form.Item>
          </S.InputPara>
          <S.DflexEnd style={{ marginTop: '32px' }}>
            <BtnCreateStyle label={'Lưu thay đổi'} loading={checkLoading} htmlType="submit" haveNotIcon={true}></BtnCreateStyle>
          </S.DflexEnd>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalReject>
  );
}
