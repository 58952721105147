import styled from 'styled-components';
import { Typography, Divider } from 'antd';
import { MenuItem as MenuItemBase } from '@app/components/common/Menu/Menu';
import { media } from '@app/styles/themes/constants';

export const Text = styled(Typography.Text)`
  font-size: 12px;
  font-weight: 600;

  & > a {
    display: block;
  }

  @media only screen and ${media.md} {
    font-size: 12px;
  }
  .style-logout {
    width: 43px;
  }
`;

// Dirty code, that because @type/react 18 are not compatibility with styled component
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const MenuItem = styled(MenuItemBase)`
  height: 50px;
`;

export const ItemsDivider = styled(Divider).withConfig({
  shouldForwardProp: (prop) => !['eventKey', 'warnKey'].includes(prop),
})`
  margin: 0;
`;
