import styled from 'styled-components';
import { Breadcrumb as AntBreadcrumb } from 'antd';
export const { Item } = AntBreadcrumb;

export const BreadcrumbItem = styled(Item)``;
export const Breadcrumb = styled(AntBreadcrumb)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #f2f2f2 !important;
`;
