import { CreditScoring } from '@app/components/common/CreditScoring/CreditScoring';
import { formatNumberWithCommas } from '@app/utils/utils';
import { Button } from 'antd';
import React from 'react';
import * as S from '../RequestLoanManagement.styles';

type Props = {
  RecordRow: any;
  isModalOpenAccept: boolean;
  handleOkAccept: (e: any) => void;
  handleCancelAccept: (e: any) => void;
};
export default function ModalAccept(props: Props) {
  return (
    <S.ModalAccept
      footer={[
        <Button key="back" className="style-btn-back" onClick={props.handleCancelAccept}>
          Huỷ
        </Button>,
        <Button key="submit" className="style-btn-accept" type="primary" onClick={props.handleOkAccept}>
          Xác Nhận
        </Button>,
      ]}
      open={props.isModalOpenAccept}
      onOk={props.handleOkAccept}
      onCancel={props.handleCancelAccept}
    >
      <div className="box-header">
        Xác nhận duyệt khoản vay <span className="color-yellow">{props.RecordRow?.code} </span>{' '}
      </div>

      <div className="box-content-body">
        <S.DflexModalAccept>
          <S.BranchNameStyle>Họ và tên</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.userFullName : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Scoreing</S.BranchNameStyle>
          <CreditScoring score={props.RecordRow ? props.RecordRow?.point : ''}></CreditScoring>
        </S.DflexModalAccept>
        <S.DflexModalAccept style={{ marginTop: '-5px' }}>
          <S.BranchNameStyle>Đối tác</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.partnerName : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Mục đích vay</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.purpose : ''}</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept>
          <S.BranchNameStyle>Thời hạn vay</S.BranchNameStyle>
          <S.BranchResultStyle>{props.RecordRow ? props.RecordRow?.duration : ''} days</S.BranchResultStyle>
        </S.DflexModalAccept>
        <S.DflexModalAccept style={{ borderBottom: '1px solid #3C4450 ', paddingBottom: '30px', marginBottom: '0px' }}>
          <S.BranchNameStyle>Số tiền vay </S.BranchNameStyle>
          <S.BranchResultStyle style={{ color: '#85A5FF' }}>{props.RecordRow ? formatNumberWithCommas(props.RecordRow?.loanAmount) : 0}</S.BranchResultStyle>
        </S.DflexModalAccept>
      </div>
    </S.ModalAccept>
  );
}
