import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../InterestAndFee.style';

import { columnsConfigFee } from './columnsConfigFee';
import { InterestAndFeeListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getFeeListApi } from '../InterestAndFee.api';
import { updateFiltersFee } from '../Action';
import { DataResponseFee } from '../interface';
import { FormSearchFee } from './FormSearchFee';
type Props = {
  toggleState: number;
};

export default function TableFee(props: Props) {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataFee, setDataFee] = useState<DataResponseFee>();

  const { stateInterestAndFeeList, dispatchInterestAndFeeList } = useContext(InterestAndFeeListContext);

  const callApiListFee = async () => {
    setcheckLoading(true);
    await getFeeListApi(queryStringParams(stateInterestAndFeeList?.filtersFee))
      .then((res) => {
        setDataFee(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiListFee();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInterestAndFeeList?.filtersFee, props.toggleState]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <S.BoxInterestAndFee>
            <FormSearchFee></FormSearchFee>
            <Table
              scroll={{ x: 1700 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={dataFee?.data}
              columns={columnsConfigFee}
              loading={checkLoading}
              sticky={true}
              rowKey="code"
              pagination={{
                current: dataFee?.pageIndex,
                pageSize: dataFee?.pageSize,
                total: dataFee?.total,
                onChange: (page: number, pageSize: number) => {
                  {
                    dispatchInterestAndFeeList(updateFiltersFee({ ...stateInterestAndFeeList?.filtersFee, pageIndex: page, pageSize: pageSize }));
                  }
                },
              }}
            />
          </S.BoxInterestAndFee>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
