import React, { useContext, useEffect, useState } from 'react';
// import { Form, Button } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../Management/CustomerManagement.styles';
import * as S2 from '../Loan.styles';
import { Button, Form, Select } from 'antd';
import { AiOutlineExport } from 'react-icons/ai';
import { LoanListContext } from '../Context';
import { updateFiltersDetailWaitForDisbursement, updateStateModalDisbursement } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { DataTableDetailWaitForDisbursement } from '../interface';
import { updatePendingDisbursement } from '../Loan.api';
import { useParams } from 'react-router-dom';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';

type Props = {
  dataDetailWaitForDisbursement: DataTableDetailWaitForDisbursement[] | undefined;
  callApiLoanDetailWaitForDisbursement: () => void;
  dateReview: string | undefined;
};

export const FormSearchDetailWaitForDisbursement = (props: Props) => {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [checkDisabled, setcheckDisabled] = useState<boolean>(false);
  const { dateReview } = useParams();
  const [classNameDisabled, setclassNameDisabled] = useState<string>('');
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  function onChangeSelectPartner(e: string) {
    dispatchLoanList(
      updateFiltersDetailWaitForDisbursement({
        ...stateLoanList?.filters,
        partner: e,
        pageIndex: 1,
        pageSize: stateLoanList?.filtersDetailWaitForDisbursement?.pageSize && stateLoanList?.filtersDetailWaitForDisbursement?.pageSize,
      }),
    );
  }
  useEffect(() => {
    if (props?.dataDetailWaitForDisbursement) {
      if (props?.dataDetailWaitForDisbursement?.[0].approvalDisbursementTime) {
        setcheckDisabled(true);
        setclassNameDisabled('style-disabled');
      } else {
        setcheckDisabled(false);
        setclassNameDisabled('');
      }
    }
  }, [props?.dataDetailWaitForDisbursement, stateLoanList?.filtersDetailWaitForDisbursement]);

  function ConfirmDisbursement() {
    if (checkDisabled == false) {
      dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalConfirm: true }));
    }
  }

  function handleOk() {
    setcheckBtnLoading(true);

    updatePendingDisbursement(dateReview)
      .then((res) => {
        console.log(res);
        if (res?.error) {
          setcheckBtnLoading(false);
          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalConfirm: false }));
        } else {
          setcheckBtnLoading(false);
          dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalConfirm: false }));
          props?.callApiLoanDetailWaitForDisbursement();
          notificationController.success({ message: _.get(res?.message, `Xác nhận giải ngân thành công`, `Xác nhận giải ngân thành công`) });
        }
      })
      .catch((err) => {
        setcheckBtnLoading(false);
        dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalConfirm: false }));

        console.log(err);
        notificationController.error({ message: _.get(`Something went wrong Api`, `Something went wrong Api`, `Something went wrong Api`) });
      });
  }

  function handleCancelConfirm() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalConfirm: false }));
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEndFormSearchWaitForDisbursement>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <Select onChange={onChangeSelectPartner} className="style-select-partner" placeholder={'Đối tác'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất cả</Select.Option>

                  <Select.Option value={'BaoGam'}>Báo Gấm</Select.Option>
                </Select>
              </Form.Item>
            </div>

            <div className="box-btn">
              <Form.Item className="btn-create">
                <BtnCreateStyle className={classNameDisabled && classNameDisabled} disabled={checkDisabled} onClick={ConfirmDisbursement} label={'Đã giải ngân'}></BtnCreateStyle>
              </Form.Item>
              <Form.Item>
                <Button icon={<AiOutlineExport />}>Xuất Excel</Button>
              </Form.Item>
            </div>
          </S2.DflexStartEndFormSearchWaitForDisbursement>
        </S.WrapperFilters>

        <GS.StyleModalConfirm title="Basic Modal" footer={null} open={stateLoanList.stateModal.stateModalConfirm} onCancel={handleCancelConfirm}>
          <div>
            <div className="text-confirm">`Bạn có chắc chắn xác nhận giải ngân cho ngày {props?.dateReview} không?`</div>
            <div className="btn-change">
              <Button className="btn-cancel" onClick={handleCancelConfirm}>
                Huỷ
              </Button>
              <Button onClick={handleOk} loading={checkBtnLoading} className="btn-yes">
                Xác nhận
              </Button>
            </div>
          </div>
        </GS.StyleModalConfirm>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
