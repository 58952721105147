import QuestionCircleOutlined from '@ant-design/icons';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

import React, { useContext } from 'react';
// import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import { DataLoanDetailType } from '../interface';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { Tooltip } from 'antd';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import ModalDetailPolicy from './ModalDetailPolicy';

type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
};
export default function InforGeneralSection(props: Props) {
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);

  function OpenModalDetailPolicy() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, openModalDetailPolicy: true }));
  }
  return (
    <div className="section-inforgeneral">
      <div className="title-inforgeneral">Thông tin khoản vay</div>
      <div className="content">
        <div className="box-content-loan">
          <div className="box-line margin-bottom">
            <div className="label">ID khoản vay</div>
            <div className="value">{props?.dataDetailLoan ? props?.dataDetailLoan?.code : ''}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Trạng thái khoản vay</div>
            <div className="value">{props?.dataDetailLoan ? <StatusesText status={props?.dataDetailLoan?.status && props?.dataDetailLoan?.status} context="LOAN"></StatusesText> : null}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Lý do huỷ</div>
            <div className="value">{props?.dataDetailLoan?.cancelReason ? props?.dataDetailLoan?.cancelReason : '-'}</div>
          </div>

          {/* <div className="box-line margin-bottom">
            <div className="label">Ngày ký hợp đồng</div>
            <div className="value">{props?.dataDetailLoan?.approvalDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.approvalDate : '-'}></TableColumnTime> : '-'}</div>
          </div> */}
          {/* <div className="box-line margin-bottom">
            <div className="label">Hợp đồng</div>
            <div className="value">
              <IconUploadPDF style={{ marginRight: '5px' }}></IconUploadPDF>
              LN0814235
            </div>
          </div> */}
          <div className="box-line margin-bottom">
            <div className="label">Từ đối tác</div>
            <div className="value">{props?.dataDetailLoan ? props?.dataDetailLoan?.partnerName : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Mục đích vay</div>
            <Tooltip placement="top" title={<span>{props?.dataDetailLoan ? props?.dataDetailLoan?.purpose : ''}</span>}>
              <div className="value">{props?.dataDetailLoan ? props?.dataDetailLoan?.purpose : '-'}</div>
            </Tooltip>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">
              REF ID
              <span style={{ marginLeft: '5px', fontSize: '10px' }}>
                <QuestionCircleOutlined />
              </span>
            </div>
            <div className="value ">{props?.dataDetailLoan ? props?.dataDetailLoan?.referenceId : '-'}</div>
          </div>
          {/* //date */}
          <div className="box-line margin-bottom">
            <div className="label">Ngày gửi request</div>
            <div className="value">{props?.dataDetailLoan?.requestDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.requestDate : '-'}></TableColumnTime> : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Ngày duyệt</div>
            <div className="value">{props?.dataDetailLoan?.approvalDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.approvalDate : '-'}></TableColumnTime> : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Ngày giải ngân</div>
            <div className="value">
              {props?.dataDetailLoan?.disbursementDate ? <TableColumnTime value={props?.dataDetailLoan ? props?.dataDetailLoan?.disbursementDate : '-'}></TableColumnTime> : '-'}
            </div>
          </div>
          <div className="box-line margin-bottom ">
            <div className="label">Ngày đến hạn</div>
            <div className="value">{props?.dataDetailLoan?.maturityDate ? props?.dataDetailLoan?.maturityDate : '-'}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Số ngày quá hạn</div>
            <div className="value">{props?.dataDetailLoan && props?.dataDetailLoan.numberDayOverdue ? props?.dataDetailLoan?.numberDayOverdue + ' ngày' : '-'}</div>
          </div>
          <div className="box-line margin-bottom ">
            <div className="label">Trạng thái thanh toán</div>
            <div className="value">
              <div>
                {props?.dataDetailLoan && props?.dataDetailLoan.loanPaymentLevel == 'LEVEL_0'
                  ? '-'
                  : '' || (props?.dataDetailLoan && props?.dataDetailLoan.loanPaymentLevel == 'LEVEL_1')
                  ? 'Miễn phí'
                  : '' || (props?.dataDetailLoan && props?.dataDetailLoan.loanPaymentLevel == 'LEVEL_2')
                  ? 'Tính phí'
                  : '' || (props?.dataDetailLoan && props?.dataDetailLoan.loanPaymentLevel == 'LEVEL_3')
                  ? 'Thu hồi'
                  : ''}
              </div>
            </div>
          </div>
          <div className="box-line margin-bottom border-botom padding-bottom">
            <div className="label">Chính sách</div>
            <div className="value">
              <a onClick={OpenModalDetailPolicy}>{props?.dataDetailLoan ? props?.dataDetailLoan?.policyCode : '-'}</a>
            </div>
          </div>
          {/* //date */}
        </div>
      </div>
      {props?.dataDetailLoan ? <ModalDetailPolicy policyCode={props?.dataDetailLoan?.policyCode}></ModalDetailPolicy> : null}
    </div>
  );
}
