import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { PolicyContext, PolicyProvider } from './Context';
import TablePolicy from './Component/TablePolicy';

export default function PolicyManagement() {
  const { dispatchPolicy } = useContext(PolicyContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý chính sách',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Quản lý chính sách`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchPolicy, dispatch]);
  return (
    <PolicyProvider>
      <PageTitle>Quản lý chính sách</PageTitle>

      <div>
        <TablePolicy></TablePolicy>
      </div>
    </PolicyProvider>
  );
}
