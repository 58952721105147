import React from 'react';
import { Col, Row } from 'antd';
import { ProfileDropdown } from '../components/profileDropdown/ProfileDropdown/ProfileDropdown';
import * as S from '../Header.styles';

interface DesktopHeaderProps {
  isTwoColumnsLayout: boolean;
}

export const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isTwoColumnsLayout }) => {
  return (
    <S.ProfileColumn $isTwoColumnsLayout={isTwoColumnsLayout}>
      <Row align="middle" justify="end" gutter={[10, 10]}>
        {/*<Col>*/}
        {/*  <Row gutter={[{ xxl: 10 }, { xxl: 10 }]}>*/}
        {/*    <Col>*/}
        {/*      <HeaderFullscreen />*/}
        {/*    </Col>*/}

        {/*    <Col>*/}
        {/*      <NotificationsDropdown />*/}
        {/*    </Col>*/}

        {/*    <Col>*/}
        {/*      <SettingsDropdown />*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*</Col>*/}

        <Col>
          <ProfileDropdown />
        </Col>
      </Row>
    </S.ProfileColumn>
  );
};
