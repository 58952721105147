import { CaretRightOutlined, SwapRightOutlined } from '@ant-design/icons';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import React, { useContext, useState } from 'react';
import * as S from '../Loan.styles';
import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import IconNotPayment from '@app/assets/icons/icon-not-payment.svg';
import { formatNumberWithCommas } from '@app/utils/utils';
import { DataDetailPaymentType, DataLoanDetailType } from '../interface';
import { Tooltip } from 'antd';
import ModalCreateDebtDetailLoan from './ModalCreatePaymentDetail';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import ModalShowPaymentPdf from './Modal/ModalShowPaymentPdf';

type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
  callApiDetailLoan?: () => void;
};
export default function DebtSection(props: Props) {
  const [isModalOpenModalCreate, setIsModalOpenModalCreate] = useState(false);
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [saveKeyString, setsaveKeyString] = useState<string>();
  const showModalCreateDebt = () => {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList?.stateModal, stateModalDebt: true }));
  };

  const handleCancelModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  const handleOkModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  function handlePDF(item: DataDetailPaymentType) {
    setsaveKeyString(item?.proofOfPayment);
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, modalShowPaymentPdf: true }));
  }
  return (
    <div className="section-disbursement section-debt">
      <div className="title-disbursement">Thu nợ</div>
      <div className="content">
        <div className="box-item-disbursement">
          {props.dataDetailLoan?.payments && props.dataDetailLoan?.payments.length > 0 ? (
            props.dataDetailLoan?.payments?.map((item, index) => (
              <div className="line-transaction" key={index}>
                <CaretRightOutlined className="css-icon-caret" />
                <div className="box-item style-fund">
                  <div className="label">Chuyển từ</div>
                  <Tooltip placement="top" title={<span>{item?.customerName ? item?.customerName : ''}</span>}>
                    <div className="value">{item?.customerName ? item?.customerName : ''}</div>
                  </Tooltip>
                </div>
                <SwapRightOutlined className="css-icon-swap" />
                <div className="box-item style-partner">
                  <div className="label">Đến</div>
                  <Tooltip placement="top" title={<span>{item?.partnerName ? item?.partnerName : ''}</span>}>
                    <div className="value">{item?.partnerName ? item?.partnerName : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item amount-disbursement style-amount">
                  <div className="label">Số tiền thanh toán</div>
                  <Tooltip placement="top" title={<span>{item?.paymentAmount ? formatNumberWithCommas(item?.paymentAmount) : 0}</span>}>
                    <div className="value style-amount-disbursement">{item?.paymentAmount ? formatNumberWithCommas(item?.paymentAmount) : 0}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-code">
                  <div className="label">Mã thanh toán</div>
                  <Tooltip placement="top" title={<span>{item?.code ? item?.code : ''}</span>}>
                    <div className="value value-yellow">{item?.code ? item?.code : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-transDate break-word-date">
                  <div className="label">Ngày thanh toán</div>
                  <Tooltip placement="top" title={<span>{item?.paymentDate ? item?.paymentDate.toLocaleString() : ''}</span>}>
                    <div className="value style-maxwidth-date">{item?.paymentDate ? item?.paymentDate.toLocaleString() : ''}</div>
                  </Tooltip>
                </div>
                <div className="box-item style-pdf">
                  <div className="label">Chứng từ</div>
                  {item?.proofOfPayment ? (
                    <div className="value value-yellow " style={{ cursor: 'pointer' }}>
                      <IconUploadPDF style={{ marginRight: '5px', marginTop: '3px' }}></IconUploadPDF>
                      <Tooltip placement="top" title={<span>{item?.proofOfPayment ? item?.proofOfPayment : ''}</span>}>
                        <div onClick={() => handlePDF(item)}> File.pdf</div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className="value value-yellow">Nothing</div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="box-not-payment">
              <div>
                <div className="box-img">
                  <img src={IconNotPayment} />
                </div>
                <div>Chưa có khoản thanh toán</div>
              </div>
            </div>
          )}
        </div>

        <S.DflexFlexEnd style={{ padding: '16px 24px 16px 24px', marginTop: '0px' }}>
          <BtnCreateStyle onClick={showModalCreateDebt} label={'Phiếu thanh toán'}></BtnCreateStyle>
        </S.DflexFlexEnd>
      </div>

      <ModalShowPaymentPdf dataKey={saveKeyString}></ModalShowPaymentPdf>

      <ModalCreateDebtDetailLoan
        callApiDetailLoan={props?.callApiDetailLoan && props?.callApiDetailLoan}
        isModalOpenModalCreate={isModalOpenModalCreate}
        handleOkModalCreate={handleOkModalCreate}
        handleCancelModalCreate={handleCancelModalCreate}
      ></ModalCreateDebtDetailLoan>
    </div>
  );
}
