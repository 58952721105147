import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ColumnsType } from 'antd/lib/table';
import { DataTablePartner } from '../interface';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { updateFiltersPartner } from '../Action';
import { PartnerContext } from '../Context';
import { FormSearchPartner } from './FormSearchPartner';
import { DataResponseApiPartnerList } from '../interface';

import { getPartnerApi } from '../Partner.api';
import ModalCreatePartner from './ModalCreatePartner';

export default function TablePartner() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataPartner, setDataPartner] = useState<DataResponseApiPartnerList>();
  const { statePartner, dispatchPartner } = useContext(PartnerContext);

  const callApiListPartner = async () => {
    setcheckLoading(true);
    await getPartnerApi(queryStringParams(statePartner?.filtersPartner))
      .then((res) => {
        setDataPartner(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setDataPartner(undefined);
        setcheckLoading(false);
      });
  };

  const handleMoveDetail = (value: DataTablePartner) => {
    location.href = `/partner/management/${value?.uuid}`;
  };

  useEffect(() => {
    callApiListPartner();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePartner?.filtersPartner]);

  const columnsConfig: ColumnsType<DataTablePartner> = [
    {
      width: '1000px',
      title: 'Tên đối tác',
      key: 'name',
      dataIndex: 'name',
      render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
    },

    {
      width: '134px',
      title: 'Hành động',
      fixed: 'right',
      render: (value: DataTablePartner) => {
        return (
          <div className="text-watch" onClick={() => handleMoveDetail(value)}>
            Chi tiết
          </div>
        );
      },
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <FormSearchPartner></FormSearchPartner>
          <Table
            scroll={{ x: 1900 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataPartner && dataPartner?.data}
            columns={columnsConfig}
            loading={checkLoading}
            sticky={true}
            rowKey="code"
            pagination={{
              current: dataPartner?.pageIndex,
              pageSize: dataPartner?.pageSize,
              total: dataPartner?.total,
              onChange: (page: number, pageSize: number) => {
                {
                  dispatchPartner(updateFiltersPartner({ ...statePartner?.filtersPartner, pageIndex: page, pageSize: pageSize }));
                }
              },
            }}
          />
        </div>
      </GS.ContainerTable>
      <ModalCreatePartner callApiListPartner={callApiListPartner}></ModalCreatePartner>
    </GS.WrapperOverrideInput>
  );
}
