import { Form, Input, Select } from 'antd';
import React, { useContext, useState } from 'react';
import * as S from '../RequestLoanManagement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { DataResponseListRequestLoanType } from '../interface';
import { RejectRequestLoanManagementApi } from '../RequestLoanManagement.api';
import { notificationController } from '@app/controllers/notificationController';
import { RequestLoanContext } from '../Context';
import { updateModalsRequestLoan } from '../Action';
import _ from 'lodash';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
type Props = {
  isModalOpenReject?: boolean;
  handleOkReject?: (e: any) => void;
  handleCancelReject?: (e: any) => void;
  dataListRequestLoan?: DataResponseListRequestLoanType;
  callApiRequestLoan?: () => void;
};
export default function ModalReject(props: Props) {
  const [form] = Form.useForm();
  const [checkLoading, setcheckLoading] = useState(false);
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);

  const { TextArea } = Input;

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const onFinish = (values: any) => {
    setcheckLoading(true);
    if (props?.callApiRequestLoan && values && props?.dataListRequestLoan) {
      RejectRequestLoanManagementApi({
        code: stateRequestLoan?.filterRequestReject.code && stateRequestLoan?.filterRequestReject.code,
        reason: values && values?.reason,
        // description: values && values?.description,
      })
        .then((res) => {
          setcheckLoading(false);
          dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: false }));

          if (res?.error) {
            dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: false }));
          } else {
            notificationController.success({ message: `Từ chối thành công` });
            props?.callApiRequestLoan && props?.callApiRequestLoan();
            form.setFieldsValue({
              reason: '',
              description: '',
            });
          }
        })
        .catch((err) => {
          notificationController.error({ message: _.get(err, `Reject fail`, `${err} err API`) });
          dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: false }));
        });
    }
  };

  const handleCancelModalReject = () => {
    dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan.modals, isModalOpenModalReject: false }));
    form.setFieldsValue({
      reason: '',
      description: '',
    });
  };

  return (
    <S.ModalReject title="Lý do từ chối duyệt khoản vay " open={stateRequestLoan?.modals?.isModalOpenModalReject} onCancel={handleCancelModalReject}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <S.DflexPara style={{ display: 'flex' }}>
            <S.StyleStar>*</S.StyleStar>
            <S.StylePara>Chọn lý do từ chối duyệt khoản vay</S.StylePara>
          </S.DflexPara>
          <S.StyleInput className={'filter-item'}>
            <Form.Item name={'reason'} rules={[{ required: true, type: 'string' }]}>
              <Select placeholder={'Chọn lý do từ chối duyệt'} size={'small'} dropdownMatchSelectWidth={200}>
                <Select.Option value={'Không đủ điều kiện vay'}>Không đủ điều kiện vay</Select.Option>
                <Select.Option value={'Khác'}>Khác</Select.Option>
              </Select>
            </Form.Item>
          </S.StyleInput>
          <S.StylePara>Chi tiết lý do</S.StylePara>
          <S.InputPara>
            <Form.Item name={'description'} style={{ marginTop: '20px' }}>
              <TextArea rows={4}></TextArea>
            </Form.Item>
          </S.InputPara>
          <S.DflexEnd style={{ marginTop: '32px' }}>
            <BtnCreateStyle label={'Lưu thay đổi'} loading={checkLoading} htmlType="submit" haveNotIcon={true}></BtnCreateStyle>
          </S.DflexEnd>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalReject>
  );
}
