import React, { useContext } from 'react';
import { Form, Button, DatePickerProps, Input, DatePicker } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../Management/CustomerManagement.styles';
import * as S2 from '../RequestLoanManagement.styles';

import { AiOutlineExport, AiOutlineSearch } from 'react-icons/ai';
import { RequestLoanContext } from '../Context';
import { updateFiltersRequestLoan, updateModalsRequestLoan } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
const { RangePicker } = DatePicker;

export const FormSearchLoanRequest = () => {
  // const datafake = [
  //   ['velacorp1', 'velacorp2', 'velacorp3'],
  //   ['ubox1', 'ubox2', 'ubox3'],
  //   ['hanoi1', 'hanoi2', 'hanoi3'],
  // ];
  // function ExportExcelFunc() {
  //   let csvContent = 'data:text/csv;charset=utf-8,';
  //   datafake.forEach(function (infoArray, index) {
  //     const dataString = infoArray.join(',');
  //     csvContent += index < datafake.length ? dataString + '\n' : dataString;
  //   });
  //   const encodedUri = encodeURI(csvContent);
  //   const link = document.createElement('a');
  //   link.setAttribute('href', encodedUri);
  //   link.setAttribute('download', 'Ubox_Synv.fintech.csv');
  //   document.body.appendChild(link);

  //   link.click();
  // }
  const { stateRequestLoan, dispatchRequestLoan } = useContext(RequestLoanContext);

  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchRequestLoan(updateFiltersRequestLoan({ ...stateRequestLoan?.filters, createdFromDate: dateString[0], createdToDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchRequestLoan(updateFiltersRequestLoan({ ...stateRequestLoan?.filters, createdFromDate: '', createdToDate: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchRequestLoan(updateFiltersRequestLoan({ ...stateRequestLoan?.filters, text: e.target.value, pageIndex: 1 }));
    }
  }
  function openModalCreateLoan() {
    dispatchRequestLoan(updateModalsRequestLoan({ ...stateRequestLoan?.modals, modalCreateLoan: true }));
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd>
            <S2.Dflex>
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID khoản vay, tên khách hàng, mã đơn hàng'} />
              </Form.Item>
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
                </Form.Item>
              </div>
              {/* <Form.Item name={'statuses'}>
                <Select placeholder={'Level'} size={'small'} dropdownMatchSelectWidth={200}>
                  <Select.Option value={''}>Tất Cả</Select.Option>
                  <Select.Option value={'LEVEL_1'}>1</Select.Option>
                  <Select.Option value={'LEVEL_2'}>2</Select.Option>
                  <Select.Option value={'LEVEL_3'}>3</Select.Option>
                  <Select.Option value={'LEVEL_4'}>4</Select.Option>
                </Select>
              </Form.Item> */}
            </S2.Dflex>
            <div className="box-end">
              <Form.Item style={{ marginRight: '8px' }}>
                <BtnCreateStyle label="Tạo khoản vay" onClick={openModalCreateLoan}></BtnCreateStyle>
              </Form.Item>

              <Form.Item>
                <Button icon={<AiOutlineExport />}>Xuất Excel</Button>
              </Form.Item>
            </div>
          </S2.DflexStartEnd>
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
