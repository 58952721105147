import React from 'react';
import { initRefund, useRefundReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const RefundContext = React.createContext({ ...initRefund });
export const RefundProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateRefund, dispatchRefund] = useRefundReducer();

  const wrap = {
    stateRefund,
    dispatchRefund,
  };

  return <RefundContext.Provider value={wrap}>{children}</RefundContext.Provider>;
};
