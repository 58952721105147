import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_INTEREST, UPDATE_FILTERS__FEE } from './Action';
import { ReducerType } from './interface';

export const initInterestAndFeeList = {
  stateInterestAndFeeList: {
    form: null,
    filtersInterest: {
      key: '',
      fromDate: '',
      toDate: '',
      transactionLogType: '',
      pageSize: 10,
      pageIndex: 1,
    },
    filtersFee: {
      key: '',
      fromDate: '',
      toDate: '',
      transactionLogType: '',
      pageSize: 10,
      pageIndex: 1,
    },
  },
  dispatchInterestAndFeeList: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_INTEREST:
      return {
        ...state,
        filtersInterest: action.filtersInterest,
      };
    case UPDATE_FILTERS__FEE:
      return {
        ...state,
        filtersFee: action.filtersFee,
      };

    default:
      return {
        ...state,
      };
  }
};

export const useInterestAndFeeListReducer = () => {
  return useReducer(reducer, initInterestAndFeeList.stateInterestAndFeeList);
};
