import React, { useContext } from 'react';
import { Table, Space } from 'antd';
import { CustomerManagementContext } from '@app/modules/Backline/Customers/pages/Management/context';
import * as GS from '@app/styles/GlobalStyle';
import type { ColumnsType } from 'antd/es/table';
import { DataResponseCustomerType, DataTableType } from '@app/modules/Backline/Customers/pages/Management/interfaces';
import { updateFiltersCustomer } from '@app/modules/Backline/Customers/pages/Management/actions';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import UserNameAvatarColumn from '@app/components/common/UserNameAvatarColumn/UserNameAvatarColumn';
import Avatar from '@app/assets/icons/avatar.svg';
import { formatNumberWithCommas } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';

interface Props {
  data?: DataResponseCustomerType;
  checkLoading?: boolean;
}

export const TableList = (props: Props) => {
  const navigate = useNavigate();

  const { dispatchCustomerManagement } = useContext(CustomerManagementContext);
  function moveDetail(record: any) {
    navigate(`/customers/management/${record.id}`);
  }
  const columns: ColumnsType<DataTableType> = [
    {
      title: 'ID',
      key: 'code',
      width: 155,
      dataIndex: 'code',
      render: (value) => {
        return <a>{value ? value : ''}</a>;
      },
    },
    {
      title: 'Tên khách hàng',
      key: 'fullName',
      width: 230,
      dataIndex: 'fullName',
      render: (value) => {
        return <UserNameAvatarColumn avatar={Avatar} name={value ? value : ''}></UserNameAvatarColumn>;
      },
    },
    {
      title: 'Số điện thoại',
      key: 'phone',
      width: 190,
      dataIndex: 'phone',
      render: (value) => {
        return <div>{value ? value : ''}</div>;
      },
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      render: (value) => {
        return <div>{value ? value : ''}</div>;
      },
    },
    {
      title: 'Thời gian tạo tài khoản',
      key: 'createAccountTime',
      width: 200,

      dataIndex: 'createAccountTime',
      render: (value) => {
        return <div>{value ? value : '-'}</div>;
      },
    },
    {
      title: () => {
        return (
          <>
            <Space direction={'vertical'}>
              <span>CCCD/</span>
              <span>CMND</span>
            </Space>
          </>
        );
      },
      key: 'idCard',
      width: 145,

      dataIndex: 'idCard',
    },
    {
      width: 90,
      title: 'Level',
      align: 'center',
      key: 'level',
      dataIndex: 'level',
      render: (value) => {
        return <div>{value ? value : ''}</div>;
      },
    },

    {
      align: 'right',
      title: 'Tổng tiền đã vay',
      key: 'totalAmount',
      dataIndex: 'totalAmount',
      // filters: [
      //   {
      //     text: 'Rất cao',
      //     value: 'heightest',
      //   },
      //   {
      //     text: 'Cao',
      //     value: 'height',
      //   },
      //   {
      //     text: 'Trung bình',
      //     value: 'middle',
      //   },
      //   {
      //     text: 'Thấp',
      //     value: 'low',
      //   },
      //   {
      //     text: 'Rất thấp',
      //     value: 'lowest',
      //   },
      // ],
      render: (value) => {
        return <div> {value ? formatNumberWithCommas(value) : ''}</div>;
      },
    },
    {
      width: 180,
      title: 'Trạng thái Ekyc',
      key: 'ekycStatus',
      dataIndex: 'ekycStatus',
      render: (value) => {
        return <StatusesText status={value ? value : ''} context={'EKYC'} />;
      },
    },
    {
      width: 160,
      title: 'Trạng thái',
      key: 'status',
      dataIndex: 'status',
      fixed: 'right',
      render: (value) => {
        return <StatusesText status={value ? value : ''} context={'CUSTOMER'} />;
      },
    },
    {
      width: 100,
      title: 'Hành động',
      key: 'customer_action',
      fixed: 'right',
      render: (value) => {
        return (
          <div className="action-view-detail" onClick={() => moveDetail(value)}>
            Xem
          </div>
        );
      },
    },
  ];
  return (
    <GS.WrapperOverrideInput>
      <Table
        scroll={{ x: 1900 }}
        rowSelection={{ type: 'checkbox' }}
        dataSource={props?.data ? props?.data?.data : []}
        columns={columns}
        loading={props.checkLoading}
        rowKey="id"
        sticky={true}
        pagination={{
          current: props?.data?.pageIndex,
          pageSize: props?.data?.pageSize,
          total: props?.data?.total,
          onChange: (page: number, pageSize: number) => {
            dispatchCustomerManagement(updateFiltersCustomer({ pageIndex: page, pageSize: pageSize }));
          },
        }}
      />
    </GS.WrapperOverrideInput>
  );
};
