import { Form, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import * as S from '../../Disbursement.styles';
import * as GS from '@app/styles/GlobalStyle';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { DisbursementContext } from '../../Context';
import { updateDataErrDetail, updateStateModalDisbursement } from '../../Action';
import { getFundApi, updatePendingDisbursementApi } from '../../Disbursement.api';
import { notificationController } from '@app/controllers/notificationController';
type Props = {
  dateReview: string | undefined;
  callApiLoanDetailWaitForDisbursement: any;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function ModalSelectFund(props: Props) {
  const [form] = Form.useForm();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [checkLoading, setcheckLoading] = useState(false);
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [dataListFund, setdataListFund] = useState<any>();
  const [checkValidateCheckbox, setcheckValidateCheckbox] = useState<boolean>();
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  // const onFinish = () => {
  //   // setcheckLoading(true);
  //   dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalConfirm: true }));
  // };

  const handleCancelModalSelectFund = () => {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalSelectFund: false }));
    form.setFieldsValue({
      description: '',
      reason: undefined,
    });
  };

  function onFinish() {
    if (stateDisbursement?.arrRowCheckBoxSave?.arrRowCheckBoxSave.length > 0) {
      setcheckValidateCheckbox(false);
    } else {
      setcheckValidateCheckbox(true);
    }
    if (dataListFund && stateDisbursement.arrRowCheckBoxSave.arrRowCheckBoxSave.length > 0) {
      setcheckLoading(true);

      updatePendingDisbursementApi({
        transactionCode: stateDisbursement.arrRowCheckBoxSave.arrRowCheckBoxSave,
        fundCode: form.getFieldValue('fund'),
      })
        .then((res) => {
          setcheckLoading(false);

          if (res?.failedTransactions) {
            // notificationController.error({ message: `${res?.message}` });
            // if (res?.errorDetails) {
            dispatchDisbursement(updateDataErrDetail({ ...stateDisbursement?.dataErrDetail, dataErrDetail: res?.failedTransactions, totalTransactionsFailed: res?.totalTransactionsFailed }));
            // }
            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, checkStateModalListErrDetail: true, stateModalSelectFund: false }));
          } else {
            setcheckLoading(false);
            dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalSelectFund: false }));
            props?.callApiLoanDetailWaitForDisbursement();
            notificationController.success({ message: `Tạo thành công` });
          }
        })
        .catch(() => {
          setcheckLoading(false);
          notificationController.success({ message: `Lỗi xảy ra với Api` });
          dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalSelectFund: false }));

          console.log('err');
        });
    }
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalConfirm: false }));
    // setTimeout(() => {
    // dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalSelectFund: false }));
    // }, 200);
  }
  const getFundCall = async () => {
    await getFundApi(undefined)
      .then((res) => {
        setdataListFund(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getFundCall();
  }, []);

  useEffect(() => {
    if (dataListFund) {
      form.setFieldsValue({
        fund: dataListFund[0]?.code,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListFund]);

  useEffect(() => {
    if (stateDisbursement.arrRowCheckBoxSave.arrRowCheckBoxSave.length > 0) {
      setcheckValidateCheckbox(false);
    }
  }, [stateDisbursement.arrRowCheckBoxSave.arrRowCheckBoxSave]);

  return (
    <S.ModalSelectFund title="Chọn nguồn vốn giải ngân" open={stateDisbursement?.stateModal?.stateModalSelectFund} onCancel={handleCancelModalSelectFund}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <S.DflexPara style={{ display: 'flex' }}>
            <S.StyleStar>*</S.StyleStar>
            <S.StylePara>Chọn nguồn vốn giải ngân</S.StylePara>
          </S.DflexPara>
          <S.StyleInput className={'filter-item'}>
            <Form.Item label="Chọn nguồn vốn giải ngân" name={'fund'} rules={[{ required: true, type: 'string' }]}>
              <Select placeholder={'Chọn nguồn vốn giải ngân'} size={'small'} dropdownMatchSelectWidth={200}>
                {dataListFund &&
                  dataListFund?.map((item: any, index: any) => (
                    <Select.Option key={index} value={`${item?.code}`}>
                      {item?.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </S.StyleInput>
          {checkValidateCheckbox ? <div className="err-text">Hãy chọn khoản giải ngân trước khi duyệt</div> : null}

          <S.DflexEnd style={{ marginTop: '32px' }}>
            <BtnCreateStyle htmlType="submit" label={'Lưu'} loading={checkLoading} haveNotIcon={true}></BtnCreateStyle>
          </S.DflexEnd>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalSelectFund>
  );
}
