import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../ReconciliationDisbursement.style';
const { RangePicker } = DatePicker;

import { DataResponseReconciliationDisbursement } from '../interface';
import { ReconciliationDisbursementContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getReconciliationDisbursementListApi } from '../ReconciliationDisbursement.api';
import { updateFiltersReconciliationDisbursementList } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';
import { columnsConfig } from './columnsConfig';

export default function TableReconciliationDisbursementList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const [data, setData] = useState<DataResponseReconciliationDisbursement>();
  const { stateReconciliationDisbursement, dispatchReconciliationDisbursement } = useContext(ReconciliationDisbursementContext);
  function onChangeDate(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    dispatchReconciliationDisbursement(
      updateFiltersReconciliationDisbursementList({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementList, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }),
    );
  }
  const callApiListDisbursement = async () => {
    setcheckLoading(true);
    await getReconciliationDisbursementListApi(queryStringParams(stateReconciliationDisbursement?.filtersReconciliationDisbursementList))
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((res) => {
        setData(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiListDisbursement();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReconciliationDisbursement?.filtersReconciliationDisbursementList]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxReconciliationDisbursement>
            <div className={'filter-item'}>
              <Form.Item name={'statuses'}>
                <RangePicker format="YYYY-MM-DD" onChange={onChangeDate} />
              </Form.Item>
            </div>
            <Table
              scroll={{ x: 1180 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="date"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchReconciliationDisbursement(updateFiltersReconciliationDisbursementList({ ...stateReconciliationDisbursement?.filtersReconciliationDisbursementList, pageIndex: page }));
                },
              }}
            />
          </S.BoxReconciliationDisbursement>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
