import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ReconciliationDisbursementContext, ReconciliationDisbursementProvider } from '../Context';
import Table from './SectionDetail/Table';

export default function DetailReconciliationDisbursement() {
  const { date } = useParams();
  const { dispatchReconciliationDisbursement } = useContext(ReconciliationDisbursementContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Đối soát',
          url: `/reconciliation/disbursement/${date}`,
        },
        {
          name: 'Giải ngân',
        },
        {
          name: `${date}`,
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Giải ngân ngày ${date}`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchReconciliationDisbursement, dispatch]);
  return (
    <ReconciliationDisbursementProvider>
      <PageTitle>Đối soát khoản vay</PageTitle>

      <div>
        {/* <GS.H2>Đối soát ngày {takeDate}</GS.H2> */}
        <Table></Table>
      </div>
    </ReconciliationDisbursementProvider>
  );
}
