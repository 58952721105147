import { Button, Form, Input, message, Select, Upload, UploadFile, UploadProps } from 'antd';
// import React, { useContext } from 'react';
import * as S from '../../Disbursement/Loan/Disbursement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import * as S2 from '../Loan.styles';
import { createDisbursementApi } from '../Loan.api';
import { dataFund, DataLoanDetailType } from '../interface';
import ImgCrop from 'antd-img-crop';

import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { LoanListContext } from '../Context';
import { updateStateModalDisbursement } from '../Action';
import { getFundApi } from '../../Disbursement/Loan/Disbursement.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { RcFile } from 'antd/lib/upload';
import { formatNumberWithCommas } from '@app/utils/utils';
import { UploadImgApi } from '@app/api/UploadFIle.api';
// import ModalCreate from './ModalCreateDisbursement';

// import { DebtListContext } from '../Context';

type Props = {
  dataLoanDetail?: DataLoanDetailType | undefined;
  callApiDetailLoan?: () => void;
};
export default function ModalCreateDisbursement(props: Props) {
  const [form] = Form.useForm();
  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [dataFund, setdataFund] = useState<dataFund[] | null>();
  const [checkValidateSelect, setcheckValidateSelect] = useState<boolean>(false);
  const [saveStringImg, setsaveStringImg] = useState<string | undefined>();
  const [loading, setloading] = useState(false);
  function handleCancelModalCreate() {
    dispatchLoanList(updateStateModalDisbursement({ ...stateLoanList.stateModal, stateModalDisbursement: false }));

    form.setFieldsValue({
      amount: '',
      fund: 'Chọn nguồn tiền giải ngân',
    });
    setcheckValidateSelect(false);
  }

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    onPreview: onPreview,
    name: 'file',
    maxCount: 1,

    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'TRANSACTION',
            partnerUuid: props.dataLoanDetail && props.dataLoanDetail?.partnerUuid,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              // message.error(`${info.file.name} file  deleted `);
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  function onchangeFund(e: string) {
    if (e && e != 'Chọn nguồn tiền giải ngân') {
      setcheckValidateSelect(false);
    }
  }
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const getFundCall = async () => {
    if (props?.dataLoanDetail) {
      await getFundApi(queryStringParams({ product: props.dataLoanDetail?.loanProduct?.code }))
        .then((res) => {
          setdataFund(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      loanCode: props.dataLoanDetail?.code,
      amount: '',
      fund: 'Chọn nguồn tiền giải ngân',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataLoanDetail]);

  useEffect(() => {
    getFundCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.dataLoanDetail]);

  const onFinish = async (values: any) => {
    console.log(values);

    const formData = new FormData();
    formData.append('amount', values?.amount);
    formData.append('loanCode', values?.loanCode);

    formData.append('fundCode', values?.fund);
    if (saveStringImg) {
      formData.append('imageUrl', saveStringImg);
    }
    if (values?.fund != 'Chọn nguồn tiền giải ngân') {
      setloading(true);
      await createDisbursementApi(formData)
        .then((res) => {
          if (res?.error) {
            setloading(false);

            handleCancelModalCreate();
          } else {
            setloading(false);
            props?.callApiDetailLoan && props?.callApiDetailLoan();
            notificationController.success({ message: `Tạo thành công` });
            handleCancelModalCreate();
          }
        })
        .catch((err) => {
          handleCancelModalCreate();
          setloading(false);
          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
        });
    } else {
      setcheckValidateSelect(true);
      setloading(false);
    }
  };

  return (
    <S.ModalCreate footer={null} title="Tạo phiếu giải ngân" open={stateLoanList?.stateModal.stateModalDisbursement} onCancel={handleCancelModalCreate}>
      <GS.WrapperOverrideInput style={{ width: '100%' }}>
        <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages} onFinishFailed={onFinishFailed}>
          <div>
            <div style={{ display: 'flex' }}>
              <div className="form-create">
                <div className="label">ID khoản vay</div>
                <Form.Item name="loanCode" label="ID khoản vay" rules={[{ required: true, type: 'string' }]}>
                  <Input size={'small'} className="form-input-create" disabled placeholder={'Nhập mã khoản vay '} />
                </Form.Item>
                <div className="box-form">
                  <div className="label">Số tiền giải ngân</div>

                  <Form.Item
                    name="amount"
                    label="Số tiền giải ngân"
                    rules={[
                      { required: true },
                      ({}) => ({
                        validator(_, value) {
                          if (props.dataLoanDetail?.amount) {
                            if (value > props.dataLoanDetail?.amount - props.dataLoanDetail?.disbursedAmount) {
                              return Promise.reject(new Error('số tiền (giải ngân + đã giải ngân) <= số tiền vay'));
                            }
                            if (value == 0) {
                              return Promise.reject(new Error('số tiền phải > 0'));
                            }
                            if (value < 0) {
                              return Promise.reject(new Error('số tiền phải > 0'));
                            }
                            return Promise.resolve();
                          }
                        },
                      }),
                    ]}
                  >
                    <Input size={'small'} type="number" className="form-input-create" placeholder={'Nhập số tiền giải ngân '} />
                  </Form.Item>
                </div>
                <div className="box-form">
                  <div className="label">nguồn tiền giải ngân</div>
                  <div className={'filter-item input-form-css-create'}>
                    <Form.Item name="fund">
                      <Select placeholder={'Chọn nguồn tiền giải ngân'} size={'small'} dropdownMatchSelectWidth={200} onChange={(e: string) => onchangeFund(e)}>
                        {dataFund && dataFund.length > 0 ? (
                          dataFund.map((item, index) => (
                            <Select.Option key={index} value={`${item?.code}`}>
                              {item?.name}
                            </Select.Option>
                          ))
                        ) : (
                          <Select.Option value={``}>Không có đối tác liên kết</Select.Option>
                        )}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
                <div className="err-text-select">{checkValidateSelect ? 'Hãy chọn nguồn tiền giải ngân' : ''}</div>
                <div className="label style-label-img">Chứng từ</div>

                <div className="save-image">
                  <ImgCrop rotate>
                    <Dragger {...propsUpdate}>
                      <p className="ant-upload-drag-icon">
                        <IconUploadFile />
                      </p>
                      <p className="ant-upload-text">
                        Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                      </p>
                    </Dragger>
                  </ImgCrop>
                </div>
              </div>
              <div className="information">
                <div className="infor-title">Thông tin khoản vay {props.dataLoanDetail?.code} </div>
                <div className="infor-content">
                  <div className="box-branch">
                    <div className="label">Tên khách hàng</div>
                    <div>{props.dataLoanDetail?.user?.fullName}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Số tiền vay</div>
                    <div>{props.dataLoanDetail?.amount ? formatNumberWithCommas(props.dataLoanDetail?.amount) : 0}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Gốc hoàn (trước GN)</div>
                    <div>{props.dataLoanDetail?.refundPrincipalBeforeDisbursement ? formatNumberWithCommas(props.dataLoanDetail?.refundPrincipalBeforeDisbursement) : 0}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Số tiền cần giải ngân</div>
                    <div>{props.dataLoanDetail?.amount ? formatNumberWithCommas(props.dataLoanDetail?.amount - props?.dataLoanDetail?.refundPrincipalBeforeDisbursement) : 0}</div>
                  </div>

                  <div className="box-branch">
                    <div className="label">Đã giải ngân</div>
                    <div>{props.dataLoanDetail?.disbursedAmount ? formatNumberWithCommas(props.dataLoanDetail?.disbursedAmount) : 0}</div>
                  </div>
                  <div className="box-branch">
                    <div className="label">Chưa giải ngân</div>
                    <div>
                      {props?.dataLoanDetail
                        ? props.dataLoanDetail?.amount | props.dataLoanDetail?.disbursedAmount
                          ? props.dataLoanDetail?.amount - props?.dataLoanDetail?.refundPrincipalBeforeDisbursement - props.dataLoanDetail?.disbursedAmount
                          : 0
                        : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <S2.StyleFooterModalCreate>
            <Button key="back" onClick={handleCancelModalCreate}>
              Huỷ
            </Button>
            <Button className="btn-create-submit" loading={loading} key="submit" htmlType="submit" style={{ marginLeft: '10px' }}>
              Xác nhận giải ngân
            </Button>
          </S2.StyleFooterModalCreate>
        </Form>
      </GS.WrapperOverrideInput>
    </S.ModalCreate>
  );
}
