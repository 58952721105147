import { Button } from 'antd';
import React from 'react';
import { AiOutlineExport } from 'react-icons/ai';

type Props = {
  onClick: (e: any) => void;
};

export default function BtnExportExportExcel(props: Props) {
  return (
    <Button onClick={props.onClick} icon={<AiOutlineExport />}>
      Xuất Excel
    </Button>
  );
}
