import { Dispatch, useReducer } from 'react';
import { ReducerType } from './interface';
import { UPDATE_FILTERS_REFUND } from './Action';

export const initRefund = {
  stateRefund: {
    form: null,
    filtersRefund: {
      key: '',
      fromDate: '',
      toDate: '',
      pageSize: 10,
      pageIndex: 1,
    },
  },
  dispatchRefund: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_REFUND:
      return {
        ...state,
        filtersRefund: action.filtersRefund,
      };

    default:
      return {
        ...state,
      };
  }
};

export const useRefundReducer = () => {
  return useReducer(reducer, initRefund.stateRefund);
};
