import React from 'react';
import { initEkycManagement, useEkycManagementReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const EkycManagementContext = React.createContext({ ...initEkycManagement });
export const EkycManagementProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateEkycManagement, dispatchEkycManagement] = useEkycManagementReducer();

  const wrap = {
    stateEkycManagement,
    dispatchEkycManagement,
  };

  return <EkycManagementContext.Provider value={wrap}>{children}</EkycManagementContext.Provider>;
};
