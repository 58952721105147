import { FiltersPartnerType, ModalsPartnerType, ReducerType, filtersPartnerDetailProductType } from './interface';

export const UPDATE_FILTERS_PARTNER = 'UPDATE_FILTERS_PARTNER';
export const UPDATE_MODALS_PARTNER = 'UPDATE_MODALS_PARTNER';
export const UPDATE_FILTERS_PARTNER_DETAIL_PRODUCT = 'UPDATE_FILTERS_PARTNER_DETAIL_PRODUCT';

export const updateFiltersPartner = (filtersPartner: FiltersPartnerType): ReducerType => {
  return {
    type: UPDATE_FILTERS_PARTNER,
    filtersPartner: filtersPartner,
  };
};

export const updateFiltersPartnerDetailProduct = (filtersPartnerDetailProduct: filtersPartnerDetailProductType): ReducerType => {
  return {
    type: UPDATE_FILTERS_PARTNER_DETAIL_PRODUCT,
    filtersPartnerDetailProduct: filtersPartnerDetailProduct,
  };
};

export const updateModalsPartner = (modalsPartner: ModalsPartnerType): ReducerType => {
  return {
    type: UPDATE_MODALS_PARTNER,
    modalsPartner: modalsPartner,
  };
};
