import { DataCollectionType } from '@app/interfaces/interfaces';
import { DataResponseListRequestLoanType, FilterRequestLoanType, FilterRequestRejectType, FilterVotesType, ModalsType, PaginationMetaTye, ReducerType } from './interface';

export const UPDATE_FORM = 'UPDATE_FORM';
export const UPDATE_DATA_REQUESTLOAN = 'UPDATE_DATA_REQUESTLOAN';
export const UPDATE_FILTERS_REQUESTLOAN = 'UPDATE_FILTERS_REQUESTLOAN';
export const UPDATE_FILTERS_VOTES = 'UPDATE_FILTERS_VOTES';

export const UPDATE_PAGINATION_META_REQUESTLOAN = 'UPDATE_PAGINATION_META_CUSTOMER';
export const UPDATE_DATA_LISTING = 'UPDATE_DATA_LISTING';
export const UPDATE_FILTERS_ACTION_REJECT_REQUESTLOAN = 'UPDATE_FILTERS_ACTION_REJECT_REQUESTLOAN';
export const UPDATE_MODALS_REQUESTLOAN = 'UPDATE_MODALS_REQUESTLOAN';

export const updateForm = (form: any): ReducerType => {
  return {
    type: UPDATE_FORM,
    form: form,
  };
};

export const updateModalsRequestLoan = (modals: ModalsType): ReducerType => {
  return {
    type: UPDATE_MODALS_REQUESTLOAN,
    modals: modals,
  };
};

export const updateDataRequestLoan = (dataRequestLoan: DataCollectionType): ReducerType => {
  return {
    type: UPDATE_DATA_REQUESTLOAN,
    dataRequestLoan: dataRequestLoan,
  };
};

export const updateFiltersRequestLoan = (filtersRequestLoan: FilterRequestLoanType): ReducerType => {
  return {
    type: UPDATE_FILTERS_REQUESTLOAN,
    filtersRequestLoan: filtersRequestLoan,
  };
};

export const updateFiltersVotes = (filterVotes: FilterVotesType): ReducerType => {
  return {
    type: UPDATE_FILTERS_VOTES,
    filterVotes: filterVotes,
  };
};

export const updateFiltersActionRejectRequestLoan = (filterRequestReject: FilterRequestRejectType): ReducerType => {
  return {
    type: UPDATE_FILTERS_ACTION_REJECT_REQUESTLOAN,
    filterRequestReject: filterRequestReject,
  };
};

export const updatePaginationMetaRequestLoan = (metaRequestLoanData: PaginationMetaTye): ReducerType => {
  return {
    type: UPDATE_PAGINATION_META_REQUESTLOAN,
    metaRequestLoanData: metaRequestLoanData,
  };
};

export const UPDATE_FORCE_DATA_LISTING = (DataList: DataResponseListRequestLoanType): ReducerType => {
  return {
    type: UPDATE_DATA_LISTING,
    DataList: DataList,
  };
};
