import { Dispatch, useReducer } from 'react';
import { ReducerType } from './interface';
import { UPDATE_FILTERS_PRODUCT, UPDATE_MODALS_PRODUCT } from './Action';

export const initProduct = {
  stateProduct: {
    form: null,
    filtersProduct: {
      key: '',
      fromDate: '',
      toDate: '',
      pageSize: 10,
      pageIndex: 1,
    },
    modalsProduct: {
      modalEdit: false,
      modalCreate: false,
      modalDetail: false,
    },
  },
  dispatchProduct: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_PRODUCT:
      return {
        ...state,
        filtersProduct: action.filtersProduct,
      };
    case UPDATE_MODALS_PRODUCT:
      return {
        ...state,
        modalsProduct: action.modalsProduct,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useProductReducer = () => {
  return useReducer(reducer, initProduct.stateProduct);
};
