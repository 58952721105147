import React, { useContext, useEffect, useState } from 'react';
import { DatePicker, DatePickerProps, Form, Input, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../../Management/CustomerManagement.styles';
import * as S2 from '../Disbursement.styles';
import { AiOutlineExport, AiOutlineSearch } from 'react-icons/ai';
const { RangePicker } = DatePicker;

import ModalCreateDisbursement from '../ComponentDisbursement/ModalCreateDisbursement';
import { DisbursementContext } from '../Context';
import { updateFiltersDisbursement, updateStateModalDisbursement } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { RangePickerProps } from 'antd/lib/date-picker';
import { DataResponseApiProductList } from '../../../Product/interface';
import { getProductApi } from '../../../Product/Product.api';
import { ExportExcelDisbursemented, getFundApi } from '../Disbursement.api';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { notificationController } from '@app/controllers/notificationController';

interface Props {
  checkDisableBtn?: boolean;
}
export const FormSearchDisbursement = (props: Props) => {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  const [dataListProduct, setdataListProduct] = useState<DataResponseApiProductList[]>();
  const [dataListFund, setdataListFund] = useState<any>();
  const [checkLoading, setcheckLoading] = useState(false);
  const showModalCreate = () => {
    // setIsModalOpenModalCreate(true);
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, stateModalDisbursement: true }));
  };

  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, transFromDate: dateString[0], transToDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, transFromDate: '', transToDate: '', pageIndex: 1 }));
    }
  }

  function onChangeFundSelect(e: string) {
    dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, fund: e, pageIndex: 1 }));
  }

  function onChangeProductSelect(e: string) {
    dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, product: e, pageIndex: 1 }));
  }
  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchDisbursement(updateFiltersDisbursement({ ...stateDisbursement?.filters, key: e.target.value, pageIndex: 1 }));
    }
  }
  const callApiListProduct = async () => {
    await getProductApi()
      .then((res) => {
        if (res?.error) {
          setdataListProduct(undefined);
        } else {
          setdataListProduct(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setdataListProduct(undefined);
      });
  };

  const getFundCall = async () => {
    await getFundApi(undefined)
      .then((res) => {
        if (res?.error) {
          setdataListFund(undefined);
        } else {
          setdataListFund(res);
        }
      })
      .catch((err) => {
        console.log(err);
        setdataListFund(undefined);
      });
  };
  useEffect(() => {
    callApiListProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFundCall();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ExportExcelFunc() {
    setcheckLoading(true);
    ExportExcelDisbursemented(
      queryStringParams({
        fund: stateDisbursement?.filters.fund,
        key: stateDisbursement?.filters.key,
        product: stateDisbursement?.filters.product,
        transFromDate: stateDisbursement?.filters.transFromDate,
        transToDate: stateDisbursement?.filters.transToDate,
      }),
    )
      .then((res) => {
        if (!res?.error) {
          setcheckLoading(false);
          notificationController.success({ message: `Xuất file thành công , vui lòng check mail` });
        } else {
          setcheckLoading(false);
        }
      })
      .catch(() => {
        setcheckLoading(false);
        console.log('err');
      });
  }

  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd>
            <S2.Dflex>
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID Khoản vay, ID giải ngân ...'} />
              </Form.Item>
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeFundSelect} placeholder={'Nguồn giải ngân'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value="">Tất cả</Select.Option>

                    {dataListFund &&
                      dataListFund?.map((item: any, index: any) => (
                        <Select.Option key={index} value={`${item?.name}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeProductSelect} placeholder={'Loại sản phẩm'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value="">Tất cả</Select.Option>
                    {dataListProduct &&
                      dataListProduct?.map((item, index) => (
                        <Select.Option key={index} value={`${item?.name}`}>
                          {item?.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </div>
            </S2.Dflex>
            {props.checkDisableBtn ? (
              ''
            ) : (
              <div style={{ display: 'flex' }}>
                <Form.Item>
                  <GS.StyleBtnExcel loading={checkLoading} onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
                    Xuất Excel
                  </GS.StyleBtnExcel>
                </Form.Item>
                <Form.Item>
                  <BtnCreateStyle onClick={showModalCreate} label={'Phiếu giải ngân'}></BtnCreateStyle>
                </Form.Item>
              </div>
            )}
          </S2.DflexStartEnd>
          <ModalCreateDisbursement></ModalCreateDisbursement>
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
