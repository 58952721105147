import React from 'react';
import * as S from '../../CustomerManagement.styles';
import { DataDetailCusomerType } from '../../interfaces';
import TabDataVerify from './TabDataVerify';
import TableDetail from './TableDetail';

type Props = {
  dataDetail?: DataDetailCusomerType;
};

export default function SectionTabsDetail(props: Props) {
  const onChange = (key: string) => {
    console.log(key);
  };

  const itemsContent = [
    {
      label: `Danh sách khoản vay`,
      key: '1',
      children: <TableDetail dataDetail={props.dataDetail}></TableDetail>,
    },
    {
      label: `Dữ liệu`,
      key: '2',
      children: <TabDataVerify></TabDataVerify>,
    },
  ];
  return (
    <div className="section-tabs-detail">
      <div className="title">
        <div className="box-title">
          <S.TabStyle defaultActiveKey="1" onChange={onChange} items={itemsContent} />
        </div>
      </div>
    </div>
  );
}
