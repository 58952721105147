import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { FormSearchLoan } from './FormSearchLoan';
import { DataResponseType, DataTypeTableLoan } from '../interface';
import { getLoanListApi } from '../Loan.api';
import { LoanListContext } from '../Context';
import * as S from '../Loan.styles';

import { updateFiltersLoanList } from '../Action';
import { columns } from './ColumnConfig';
import { queryStringParams } from '@app/controllers/queryStringParams';

export default function TableLoan() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dataLoanList, setDataLoanList] = useState<DataTypeTableLoan[]>();
  const [dataResponse, setDataResponse] = useState<DataResponseType>();

  const { stateLoanList, dispatchLoanList } = useContext(LoanListContext);
  const [checkLoading, setcheckLoading] = useState(false);

  const callApiGetLoanList = async () => {
    setcheckLoading(true);
    await getLoanListApi(queryStringParams(stateLoanList.filters))
      .then((res) => {
        setDataResponse(res);
        setcheckLoading(false);

        setDataLoanList(res?.data);
      })
      .catch((error) => {
        setcheckLoading(false);
        console.log(error);
        setDataResponse(undefined);
        setDataLoanList(undefined);
      });
  };

  useEffect(() => {
    callApiGetLoanList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateLoanList?.filters]);
  return (
    <GS.WrapperOverrideInput>
      <S.ContainerLoan className="container-request-loan">
        <div className="box-content">
          <FormSearchLoan></FormSearchLoan>
          <Table
            style={{ padding: '12px 24px 24px 24px' }}
            scroll={{ x: 4150 }}
            rowSelection={{ type: 'checkbox' }}
            dataSource={dataLoanList && dataLoanList?.length > 0 ? dataLoanList : []}
            loading={checkLoading}
            rowKey="id"
            columns={columns}
            sticky={true}
            pagination={{
              current: dataResponse?.pageIndex,
              pageSize: dataResponse?.pageSize,
              total: dataResponse?.total,
              onChange: (page: number, pageSize: number) => {
                dispatchLoanList(updateFiltersLoanList({ ...stateLoanList?.filters, pageIndex: page, pageSize: pageSize }));
              },
            }}
          />
        </div>
      </S.ContainerLoan>
    </GS.WrapperOverrideInput>
  );
}
