import { Button, Form, Select } from 'antd';
import React, { useContext, useState } from 'react';
import * as S from '../../CustomerManagement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { CustomerManagementContext } from '../../context';
import { updateModals } from '../../actions';
import ModalConfirm from '@app/components/common/ModalConfirm/ModalConfirm';
import { updateLevelApi } from '../../CustomerManagement.api';
import { DataDetailCusomerType } from '../../interfaces';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';
import { LevelCustomerValueType } from '@app/interfaces/interfaces';

type Props = {
  dataDetail: DataDetailCusomerType | undefined;
  callApiDetailCustomer: () => void;
};

export default function ModalEditLevelCustomer(props: Props) {
  const { stateCustomerManagement, dispatchCustomerManagement } = useContext(CustomerManagementContext);
  const [checkBtnLoading, setcheckBtnLoading] = useState(false);
  const [saveLevel, setsaveLevel] = useState<LevelCustomerValueType>('LEVEL_0');
  // const handleSave = (e: any) => {
  //   console.log(e);
  //   dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalsEditCustomer: false }));
  // };

  const handleOkConfirm = () => {
    setTimeout(location.reload, 3000);

    if (props?.dataDetail && saveLevel) {
      setcheckBtnLoading(true);
      updateLevelApi({
        id: props?.dataDetail && props?.dataDetail?.user.id,
        level: saveLevel,
      })
        .then((res) => {
          if (res?.error) {
            setcheckBtnLoading(false);
            dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalConfirm: false }));
          } else {
            dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, checkRender: !stateCustomerManagement?.modals?.checkRender }));
            notificationController.success({ message: _.get(res?.error?.message, `Change Success`, `Change Success`) });

            dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalConfirm: false }));
            setcheckBtnLoading(false);

            setTimeout(() => {
              dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalsEditCustomer: false }));
            }, 500);
            props?.callApiDetailCustomer();
            // setTimeout(() => {
            //   location.reload();
            // }, 2000);
          }
        })
        .catch((err) => {
          console.log(err);
          setcheckBtnLoading(false);
          dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalConfirm: false }));
        });
    } else {
      notificationController.error({ message: _.get(`Hãy thay đổi level khách hàng`, `Hãy thay đổi level khách hàng`, `Hãy thay đổi level khách hàng`) });
    }
    // dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalsEditCustomer: false }));
  };

  function onChangeSelectLevel(e: LevelCustomerValueType | string | any) {
    if (e) {
      setsaveLevel(e);
    }
  }
  function handleCancel() {
    dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalsEditCustomer: false }));
  }

  function handleCancelConfirm() {
    dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalConfirm: false }));
  }

  function handleOpenConfirm() {
    dispatchCustomerManagement(updateModals({ ...stateCustomerManagement?.modals, modalConfirm: true }));
  }

  // const rendermodalconfirm = async () => {
  //   return (
  //     <ModalConfirm
  //       isModalOpen={stateCustomerManagement.modals.modalConfirm}
  //       handleOk={handleOk}
  //       handleCancel={handleCancelConfirm}
  //       loading={checkBtnLoading}
  //       labelTitle="Bạn có chắc muốn thay đổi Level của khách hàng?"
  //     ></ModalConfirm>
  //   );
  // };

  return (
    <S.StyleModalEditDetail footer={null} open={stateCustomerManagement?.modals?.modalsEditCustomer} onCancel={handleCancel}>
      <GS.WrapperOverrideInput>
        <div className="box-title-modal">
          <div className="title-modal">Sửa Level khách hàng</div>
        </div>
        <div className="content-body">
          <div className="label">
            <span className="icon-special">*</span> Chọn level
          </div>
          <div className="value">
            <Form.Item name={'para'}>
              <Select onChange={onChangeSelectLevel} className="style-select-partner" defaultValue={'Level_0'}>
                <Select.Option value={'LEVEL_0'}>Level 0</Select.Option>
                <Select.Option value={'LEVEL_1'}>Level 1</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="section-btn">
            <Button className="btn-cancel" onClick={handleCancel}>
              Hủy
            </Button>
            <Button className="btn-change" onClick={handleOpenConfirm}>
              Lưu thay đổi
            </Button>
          </div>
        </div>
        <ModalConfirm
          isModalOpen={stateCustomerManagement.modals.modalConfirm}
          handleOk={handleOkConfirm}
          handleCancel={handleCancelConfirm}
          loading={checkBtnLoading}
          labelTitle="Bạn có chắc muốn thay đổi Level của khách hàng?"
        ></ModalConfirm>
      </GS.WrapperOverrideInput>
    </S.StyleModalEditDetail>
  );
}
