import { Dispatch, useReducer } from 'react';
import { ReducerType } from './interface';
import { UPDATE_FILTERS_PARTNER, UPDATE_FILTERS_PARTNER_DETAIL_PRODUCT, UPDATE_MODALS_PARTNER } from './Action';

export const initPartner = {
  statePartner: {
    form: null,
    filtersPartner: {
      name: '',
      pageSize: 20,
      pageIndex: 1,
    },
    filtersPartnerDetailProduct: {
      productName: '',
      pageSize: 10,
      pageIndex: 1,
    },
    modalsPartner: {
      modalEdit: false,
      modalCreate: false,
      modalEditPolicyDefault: false,
      ModalDetailCampaign: false,
      ModalEditCampaign: false,
      ModalCreateCampaign: false,
    },
  },
  dispatchPartner: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_PARTNER:
      return {
        ...state,
        filtersPartner: action.filtersPartner,
      };
    case UPDATE_FILTERS_PARTNER_DETAIL_PRODUCT:
      return {
        ...state,
        filtersPartnerDetailProduct: action.filtersPartnerDetailProduct,
      };
    case UPDATE_MODALS_PARTNER:
      return {
        ...state,
        modalsPartner: action.modalsPartner,
      };

    default:
      return {
        ...state,
      };
  }
};

export const usePartnerReducer = () => {
  return useReducer(reducer, initPartner.statePartner);
};
