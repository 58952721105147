import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../../ReconciliationLoan.style';
import { ColumnsType } from 'antd/lib/table';
import { DataResponseReconciliationLoanDetail, DataTypeTableDetail } from '../../interface';
import { StatusesText } from '@app/components/common/Statuses/Statuses';

import { ReconciliationLoanListContext } from '../../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getReconciliationLoanDetailApi } from '../../ReconciliationLoan.api';
import { updateFiltersReconciliationLoanDetail } from '../../Action';
import { FormSearch } from './FormSearch';
import { columnsConfigOriginal } from './columnConfigOriginal';
import TableColumnReconciliation from '@app/components/common/TableColumnTime/TableColumnReconciliation';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';
import { useParams } from 'react-router-dom';

export default function TableReconciliationLoanList() {
  const [checkLoading, setcheckLoading] = useState(false);
  const { date } = useParams();
  const [data, setData] = useState<DataResponseReconciliationLoanDetail>();
  const { stateReconciliationLoan, dispatchReconciliationLoanList } = useContext(ReconciliationLoanListContext);

  const callApiLoanDetail = async () => {
    setcheckLoading(true);
    if (stateReconciliationLoan?.filtersReconciliationLoanDetail.day && stateReconciliationLoan?.filtersReconciliationLoanDetail.day != '') {
      await getReconciliationLoanDetailApi(queryStringParams(stateReconciliationLoan?.filtersReconciliationLoanDetail))
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          setData(res);
          setcheckLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setcheckLoading(false);
        });
    }
  };

  const columnsConfig: ColumnsType<any> = [
    {
      width: '185px',
      title: 'Thời gian đối soát',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a: any, b: any) => a.age - b.age,
      render: (value: string) => <TableColumnTime value={value && value}></TableColumnTime>,
    },
    {
      title: 'ID khoản vay (BG/BF)',
      key: 'loanCode',
      render: (value: DataTypeTableDetail) => (
        <a className="cursor-pointer-none">
          <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.loanCodePartner} valueBF={value?.loanCodeBifiin}></TableColumnReconciliation>
        </a>
      ),
    },
    {
      width: '265px',
      title: 'Số tiền vay (BG/BF)',
      key: 'loanCodeBifiin',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="number" status={value ? value?.status : ''} valuePartner={value?.loanAmountPartner} valueBF={value?.loanAmountBifiin}></TableColumnReconciliation>
      ),
    },
    {
      width: '233px',
      title: 'Tổng tiền đơn hàng (BG/BF)',
      key: 'baseAmount',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation
          type="number"
          status={value ? value?.status : ''}
          valuePartner={value && value?.baseAmountPartner}
          valueBF={value && value?.baseAmountBifiin}
        ></TableColumnReconciliation>
      ),
    },
    {
      width: '148px',
      title: 'Hình thức vay (BG/BF)',
      key: 'loanType',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.loanTypePartner} valueBF={value?.loanTypeBifiin}></TableColumnReconciliation>
      ),
    },
    {
      width: '317px',
      title: 'Thời gian tạo khoản vay (BG/BF)',
      key: 'approvalDate',
      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.approvalDatePartner} valueBF={value?.approvalDateBifiin}></TableColumnReconciliation>
      ),
    },
    {
      title: 'REF ID (BG/BF)',
      key: 'referenceId',

      render: (value: DataTypeTableDetail) => (
        <TableColumnReconciliation type="string" status={value ? value?.status : ''} valuePartner={value?.referenceIdPartner} valueBF={value?.referenceIdBifiin}></TableColumnReconciliation>
      ),
    },
    {
      width: '181px',
      title: 'Ghi chú',
      key: 'note',
      dataIndex: 'note',

      render: (value: string) => <div className="text-loancode">{value ? value : ''}</div>,
    },
    {
      width: '134px',
      title: 'Trạng thái đối soát',
      key: 'status',
      dataIndex: 'status',
      render: (value) => <StatusesText context="RECONCILIATION" status={value}></StatusesText>,
    },
    {
      width: '111px',

      title: 'Hành động',
      key: 'action',
      render: (value: DataTypeTableDetail) => {
        return value && value?.status == 'SUCCESS' ? <div className="action-approval-disable">Duyệt</div> : <div className="action-approval">Duyệt</div>;
      },
    },
  ];

  useEffect(() => {
    dispatchReconciliationLoanList(updateFiltersReconciliationLoanDetail({ ...stateReconciliationLoan?.filtersReconciliationLoanDetail, pageIndex: 1, day: date }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    callApiLoanDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateReconciliationLoan?.filtersReconciliationLoanDetail]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxReconciliationLoan>
            <FormSearch></FormSearch>
            <Table
              scroll={{ x: stateReconciliationLoan?.checkDataOriginal ? 1184 : 2360 }}
              // rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              columns={stateReconciliationLoan?.checkDataOriginal ? columnsConfigOriginal : columnsConfig}
              loading={checkLoading}
              sticky={true}
              rowKey="id"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number) => {
                  dispatchReconciliationLoanList(updateFiltersReconciliationLoanDetail({ ...stateReconciliationLoan?.filtersReconciliationLoanDetail, pageIndex: page }));
                },
              }}
            />
          </S.BoxReconciliationLoan>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
