import { Dispatch, useReducer } from 'react';
import { UPDATE_DATA_CUSTOMERS, UPDATE_FILTERS_CUSTOMERS, UPDATE_FORM, UPDATE_MODALS } from '@app/modules/Backline/Customers/pages/Management/actions';
import { ReducerType } from '@app/modules/Backline/Customers/pages/Management/interfaces';

export const initCustomerManagement = {
  stateCustomerManagement: {
    form: null,
    filters: {
      status: '',
      key: '',
      point: '',
      pageSize: 20,
      pageIndex: 1,
    },
    modals: {
      modalsEditCustomer: false,
      modalConfirm: false,
      checkRender: false,
    },
    data: {
      customer: {
        collection: [],
        meta: {
          pageSize: 1,
          current: 1,
          total: 0,
        },
      },
    },
  },
  dispatchCustomerManagement: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FORM:
      return {
        ...state,
        form: action.form,
      };
    case UPDATE_MODALS:
      return {
        ...state,
        modals: action.modals,
      };
    case UPDATE_DATA_CUSTOMERS:
      return {
        ...state,
        data: {
          ...state.data,
          customer: action.dataCustomer,
        },
      };
    case UPDATE_FILTERS_CUSTOMERS:
      return {
        ...state,
        filters: action.filtersCustomer,
      };

    default:
      return {
        ...state,
      };
  }
};

export const useCustomerManagementReducer = () => {
  return useReducer(reducer, initCustomerManagement.stateCustomerManagement);
};
