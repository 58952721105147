import React from 'react';
import { DataDetailEkycType } from '../interface';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { Tooltip } from 'antd';

type Props = {
  dataDetail: DataDetailEkycType | undefined;
};

export default function SectionInforGeneralEkyc(props: Props) {
  return (
    <div className="section-user-infor">
      <div className="title">
        <div className="box-title">Thông tin chung</div>
      </div>
      <div className="content-user">
        <div className="item-column">
          <div className="label">Trạng thái eKYC</div>
          <div className="value margin-bottom-0">
            <StatusesText status={props?.dataDetail?.status} context="EKYC"></StatusesText>
          </div>
        </div>
        <div className="item-column">
          <div className="label">ID khách hàng</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.customerCode : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail ? props?.dataDetail.customerCode : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Số điện thoại</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.phone : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail ? props?.dataDetail.phone : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Email</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.email : '-'}</span>}>
            <div className="value margin-bottom-0">{props?.dataDetail ? props?.dataDetail.email : '-'}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
}
