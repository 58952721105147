import { Dispatch, useReducer } from 'react';
import { UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT, UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_DISBURSEMENT, UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT_DETAIL } from './Action';
import { ReducerType } from './interface';

export const initReconciliationDisbursement = {
  stateReconciliationDisbursement: {
    form: null,
    filtersReconciliationDisbursementList: {
      fromDate: '',
      toDate: '',
      pageSize: 5,
      pageIndex: 1,
    },
    filtersReconciliationDisbursementDetail: {
      key: '',
      day: '',
      status: '',
      typeData: '',
      pageSize: 10,
      pageIndex: 1,
    },
    checkDataOriginal: false,
  },
  dispatchReconciliationDisbursement: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT:
      return {
        ...state,
        filtersReconciliationDisbursementList: action.filtersReconciliationDisbursement,
      };
    case UPDATE_FILTERS_RECONCILIATION_DISBURSEMENT_DETAIL:
      return {
        ...state,
        filtersReconciliationDisbursementDetail: action.filtersReconciliationDisbursementDetail,
      };
    case UPDATE_CHECKDATA_ORIGINAL_RECONCILIATION_DISBURSEMENT:
      return {
        ...state,
        checkDataOriginal: action.checkDataOriginal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useReconciliationDisbursementReducer = () => {
  return useReducer(reducer, initReconciliationDisbursement.stateReconciliationDisbursement);
};
