import styled from 'styled-components';
import { Button } from '@app/components/common/buttons/Button/Button.styles';
import { Modal, Tabs } from 'antd';

// export const Loan = styled.div`
// `;
export const Menu = styled.div`
  display: flex;
`;
export const H2 = styled.div`
  padding-left: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const DflexStartEnd = styled.div`
  .style-select-date-payment {
    min-width: 162px;
  }
  .style-select-partner {
    min-width: 152px;
  }
  .style-select-status {
    min-width: 143px;
  }
  /* padding-top: 30px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 8px;
`;
export const Dflex = styled.div`
  display: flex;
  width: 49%;
`;
export const StyledButtonA = styled(Button)`
  color: red;
  background-color: yellow;
`;
export const StyledButtonB = styled(Button)`
  color: pink;
  background-color: green;
`;
export const ModalCreate = styled(Modal)`
  .err-text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f !important;
    margin-top: -15px;
  }
  .box-body-form {
    display: flex;
  }
  .save-image {
    height: 244px;
    width: 100%;
    background-color: #272d35;
    margin-top: 10px;
    img {
      width: 100%;
      object-fit: contain;
      height: 100%;
    }
  }
  .ant-modal-footer {
    padding: 32px;
    button.ant-btn.ant-btn-primary {
      background: #9ea6ac;
      color: #d9d9d9;
      border: none;
    }
  }

  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
  .ant-modal-content {
    width: 818px;
    background: #1a1f25;
    border-radius: 4px;
  }
  .ant-select-selector {
    background-color: #272d35 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }
  .ant-modal-header {
    height: 76px;
    display: flex;
    align-items: center;
    padding: 16px 32px;
  }
  .ant-modal-body {
    width: 100%;
    display: flex;

    padding: 0;
    .form-create {
      width: 57%;
      height: 100%;
      border-right: 1px solid #3c4450;
      .label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;

        color: #8c8c8c;
      }

      .form-input-create {
        background: #272d35;
        border-radius: 2px;
        padding: 4px 12px;
        font-size: 14px;
      }
    }
    .information {
      height: 100%;
    }
    .form-create {
      padding: 32px;
    }
    .information {
      width: 43%;
      height: 100%;
      .infor-title {
        padding: 32px 32px 23px 32px;
        border-bottom: 1px solid #3c4450;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;

        color: #f0f0f0;
      }
      .infor-content {
        padding: 32px 32px 23px 32px;
        width: 100%;
        .box-branch {
          display: flex;
          justify-content: space-between;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #bfbfbf;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const TabsLoan = styled(Tabs)`
  color: pink;
  background-color: green;
`;
export const boxCircle = styled.div`
  display: flex;
  align-items: center;
  margin-right: 5px;
`;
export const circleStyle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;
export const DflexModalAccept = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;
export const BranchNameStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #bfbfbf;
`;
export const BranchResultStyle = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #f5f5f5;
`;
export const DflexPara = styled.div`
  display: flex;
`;
export const StyleStar = styled.div`
  color: #ff4d4f;
  font-size: 14;
  display: flex;
  align-items: center;
  margin-right: 5px;
  margin-top: 4px;
`;

export const StylePara = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #8c8c8c;
`;

export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;

  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 250px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;
export const StyleInput = styled.div`
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    width: 100%;
  }
  .ant-select-selector {
    background: #272d35 !important;
  }
`;
export const InputPara = styled.div`
  input#para {
    background: #272d35 !important;
    margin-top: 15px;
  }
`;
export const DflexEnd = styled.div`
  display: flex;
  justify-content: end;
`;
export const StyleMessage = styled.div`
  .ant-message-notice-content {
    background: #272d35 !important;
    padding: 18px !important;
    margin-top: 60px;
  }
`;
export const StyleBtnCreate = styled(Button)`
  .style-modal-create {
    color: #161a1e;
  }
  background-color: #f2c015;
  color: black !important;
  margin-left: 10px;
  border: none !important;
  :hover {
    color: #fff !important;
  }
`;

export const ModalReject = styled(Modal)`
  .btn-save-change {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    gap: 10px;
    width: 117px;
    height: 32px;
    background: #9ea6ac;
    border: 1px solid #9ea6ac;
    border-radius: 2px;
  }
  .ant-modal-content {
    width: 591px;
  }
  .ant-modal-footer {
    padding: 0px 32px 32px 32px;
  }
  button.ant-btn.ant-btn-primary {
    color: black;
  }
  .ant-modal-header {
    height: 80px;
    padding: 30px;
  }
  span.ant-modal-close-x {
    margin-top: 10px;
    margin-right: 12px;
  }
  .ant-modal-body {
    padding: 30px;
  }
  .ant-modal-footer {
    display: none;
  }
`;

export const StyleTextLoanCode = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #f2c015;
`;
export const StyleCustomerName = styled(Button)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #d9d9d9;
`;
export const BoxDebt = styled.div`
  .ant-table-header.ant-table-sticky-holder {
    top: 70px !important;
  }
  .action-debt {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #52c41a;
  }
  .box-content {
    background-color: #1a1f25;
    padding: 16px 24px 24px 24px;
  }
  .text-loancode {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f2c015;
  }
  .text-paymentcode {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f2c015;
  }
  .box-avatar {
    display: flex;
    .text-customer-name {
      margin-left: 10px;
    }
  }
`;
