import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { paramsExcel } from '../ReconciliationLoan/interface';
import { DataResponseReconciliationDisbursement, DataResponseReconciliationDisbursementDetail } from './interface';

const httpApi = initAxios();

interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: TokenConfig = {
  secured: true,
};

export const getReconciliationDisbursementListApi = (params: string): Promise<DataResponseReconciliationDisbursement> => httpApi.get(`/loans/v1/reconcile/transaction?${params}`, { ...TokenConfig });

export const getReconciliationDisbursementDetailApi = (params: string): Promise<DataResponseReconciliationDisbursementDetail> =>
  httpApi.get(`/loans/v1/reconcile/transaction/by-day?${params}`, { ...TokenConfig });

export const getReconciliationDisbursementExportExcel = (params: paramsExcel): Promise<string> => httpApi.post('/loans/v1/reconcile/transaction/export', params, { ...TokenConfig });
