import React from 'react';
import { initCapital, useCapitalReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const CapitalContext = React.createContext({ ...initCapital });
export const CapitalProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateCapital, dispatchCapital] = useCapitalReducer();

  const wrap = {
    stateCapital,
    dispatchCapital,
  };

  return <CapitalContext.Provider value={wrap}>{children}</CapitalContext.Provider>;
};
