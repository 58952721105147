import React from 'react';
import { initRequestLoan, useRequestLoanReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const RequestLoanContext = React.createContext({ ...initRequestLoan });
export const RequestLoanProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateRequestLoan, dispatchRequestLoan] = useRequestLoanReducer();

  const wrap = {
    stateRequestLoan,
    dispatchRequestLoan,
  };

  return <RequestLoanContext.Provider value={wrap}>{children}</RequestLoanContext.Provider>;
};
