/* eslint-disable prettier/prettier */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useContext, useEffect } from 'react';

import { CapitalContext, CapitalProvider } from './Context';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import TableCapital from './Component/TableList';

export default function Capital() {
  const { dispatchCapital } = useContext(CapitalContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý nguồn vốn',
          url: '/capital/management',
        },
        {
          name: 'Danh sách nguồn vốn',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Danh sách nguồn vốn`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchCapital, dispatch]);
  return (
    <CapitalProvider>
      <PageTitle>Quản lý nguồn vốn</PageTitle>

      <div>
        {/* <S.H2>Danh sách giải ngân</S.H2> */}

        <TableCapital></TableCapital>
      </div>
    </CapitalProvider>
  );
}
