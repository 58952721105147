/* eslint-disable prettier/prettier */
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import React, { useContext, useEffect, useState } from 'react';
import * as GS from '@app/styles/GlobalStyle';

import { DisbursementContext, DisbursementProvider } from './Context';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import TableDisbursementedTab from './ComponentDisbursement/TableDisbursement';
import TableDisbursementList from './ComponentDisbursement/TableWaitForDisbursement';

type labelDisbursementTabType = 'Đã giải ngân' | 'Chờ giải ngân';

export default function Disbursement() {
  const { dispatchDisbursement } = useContext(DisbursementContext);
  const dispatch = useAppDispatch();

  const [ToggleState, setToggleState] = useState(1);
  const [LabelTab, setLabelTab] = useState<labelDisbursementTabType>('Đã giải ngân');

  const toggleTab = (index: number) => {
    if (index == 1) {
      setLabelTab('Đã giải ngân');
    }
    if (index == 2) {
      setLabelTab('Chờ giải ngân');
    }
    setToggleState(index);
  };

  function TabMenu() {
    const getActiveClass = (index: number, className: string) => (ToggleState === index ? className : '');
    return (
      <GS.TabsTable>
        <div className="box-content" style={{ width: '205px' }}>
          <div
            onClick={() => {
              toggleTab(1);
            }}
            className={`item ${getActiveClass(1, 'active-tabs')}`}
          >
            Đã giải ngân
          </div>
          <div
            onClick={() => {
              toggleTab(2);
            }}
            className={`item ${getActiveClass(2, 'active-tabs')}`}
          >
            Chờ giải ngân
          </div>
        </div>
      </GS.TabsTable>
    );
  }
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý Giải Ngân',
          url: '/customers/disbursement',
        },
        {
          name: 'Danh sách giải ngân',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Danh sách giải ngân`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchDisbursement, dispatch]);
  return (
    <DisbursementProvider>
      <PageTitle>Danh sách giải ngân </PageTitle>

      <div>
        {/* <S.H2>Danh sách giải ngân</S.H2> */}

        <div>
          <TabMenu></TabMenu>
          {LabelTab == 'Đã giải ngân' ? <TableDisbursementedTab></TableDisbursementedTab> : null}
          {LabelTab == 'Chờ giải ngân' ? <TableDisbursementList></TableDisbursementList> : null}
        </div>
      </div>
    </DisbursementProvider>
  );
}
