import React, { useContext, useState } from 'react';
import { Form, Input, Button, DatePickerProps, DatePicker, Select } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
const { RangePicker } = DatePicker;

import * as S from '../../../Management/CustomerManagement.styles';
import * as S2 from '../DebtList.styles';

import { AiOutlineSearch, AiOutlineExport } from 'react-icons/ai';
import ModalCreateDebt from './ModalCreateDebt';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';
import { DebtListContext } from '../Context';
import { updateFiltersDebtList, updateModals } from '../Action';
import { RangePickerProps } from 'antd/lib/date-picker';

export const FormSearchDebtList = () => {
  const [isModalOpenModalCreate, setIsModalOpenModalCreate] = useState(false);
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);

  const showModalCreate = () => {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalCreate: true }));
  };

  const handleCancelModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  const handleOkModalCreate = () => {
    setIsModalOpenModalCreate(false);
  };

  const datafake = [
    ['velacorp1', 'velacorp2', 'velacorp3'],
    ['ubox1', 'ubox2', 'ubox3'],
    ['hanoi1', 'hanoi2', 'hanoi3'],
  ];
  function ExportExcelFunc() {
    let csvContent = 'data:text/csv;charset=utf-8,';
    datafake.forEach(function (infoArray, index) {
      const dataString = infoArray.join(',');
      csvContent += index < datafake.length ? dataString + '\n' : dataString;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'Ubox_Synv.fintech.csv');
    document.body.appendChild(link);

    link.click();
  }

  function onChangeTime(value: DatePickerProps['value'] | RangePickerProps['value'], dateString: [string, string] | string) {
    if (dateString) {
      dispatchDebtList(updateFiltersDebtList({ ...stateDebtList?.filtersDebtList, fromDate: dateString[0], toDate: dateString[1], pageIndex: 1 }));
    } else {
      dispatchDebtList(updateFiltersDebtList({ ...stateDebtList?.filtersDebtList, fromDate: '', toDate: '', pageIndex: 1 }));
    }
  }

  function onChangeSearch(e: any) {
    if (e.key == 'Enter') {
      dispatchDebtList(updateFiltersDebtList({ ...stateDebtList?.filtersDebtList, text: e.target.value, pageIndex: 1 }));
    }
  }

  function onChangeSelectPartner(e: string) {
    dispatchDebtList(updateFiltersDebtList({ ...stateDebtList?.filtersDebtList, partner: e, pageIndex: 1 }));
  }
  return (
    <GS.WrapperOverrideInput>
      <GS.FormSearch>
        <S.WrapperFilters>
          <S2.DflexStartEnd>
            <S2.Dflex>
              <Form.Item name={'keyword'}>
                <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'ID khoản vay, tên KH... '} />
              </Form.Item>

              <div className={'filter-item'} style={{ marginLeft: '10px' }}>
                <Form.Item name={'statuses'}>
                  <RangePicker format="DD-MM-YYYY" onChange={onChangeTime} />
                </Form.Item>
              </div>
              <div className={'filter-item'} style={{ marginLeft: '2px' }}>
                <Form.Item name={'statuses'}>
                  <Select onChange={onChangeSelectPartner} className="style-select-partner" placeholder={'Đối tác liên kết'} size={'small'} dropdownMatchSelectWidth={200}>
                    <Select.Option value={''}>Tất cả</Select.Option>

                    <Select.Option value={'BaoGam123'}>Báo Gấm</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </S2.Dflex>
            <div style={{ display: 'flex' }}>
              <Form.Item>
                <Button onClick={ExportExcelFunc} icon={<AiOutlineExport />}>
                  Xuất Excel
                </Button>
              </Form.Item>
              <Form.Item>
                <BtnCreateStyle onClick={showModalCreate} label={'Phiếu thanh toán'}></BtnCreateStyle>
              </Form.Item>
            </div>
          </S2.DflexStartEnd>
          <ModalCreateDebt isModalOpenModalCreate={isModalOpenModalCreate} handleOkModalCreate={handleOkModalCreate} handleCancelModalCreate={handleCancelModalCreate}></ModalCreateDebt>
        </S.WrapperFilters>
      </GS.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
