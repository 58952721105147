import React from 'react';
import { initDisbursement, useDisbursementReducer } from './reducer';

interface CustomerProviderProps {
  children: React.ReactNode;
}

export const DisbursementContext = React.createContext({ ...initDisbursement });
export const DisbursementProvider: React.FC<CustomerProviderProps> = ({ children }) => {
  const [stateDisbursement, dispatchDisbursement] = useDisbursementReducer();

  const wrap = {
    stateDisbursement,
    dispatchDisbursement,
  };

  return <DisbursementContext.Provider value={wrap}>{children}</DisbursementContext.Provider>;
};
