import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect, useState } from 'react';
import TableFee from './Component/TableFee';
import TableInterest from './Component/TableInterest';
import { InterestAndFeeListContext, InterestAndFeeListProvider } from './Context';
import * as S from './InterestAndFee.style';
type labelInterestAndFeeTabType = 'Lịch sử phí' | 'Lịch sử lãi';

export default function InterestAndFee() {
  const { dispatchInterestAndFeeList } = useContext(InterestAndFeeListContext);
  const dispatch = useAppDispatch();
  const [LabelTab, setLabelTab] = useState<labelInterestAndFeeTabType>('Lịch sử phí');

  const [toggleState, setToggleState] = useState<number>(1);
  const toggleTab = (index: number) => {
    if (index == 1) {
      setLabelTab('Lịch sử phí');
    }
    if (index == 2) {
      setLabelTab('Lịch sử lãi');
    }
    setToggleState(index);
  };

  function TabMenu() {
    const getActiveClass = (index: number, className: string) => (toggleState === index ? className : '');

    return (
      <S.TabsTable>
        <div className="box-content fix-width-tab">
          <div
            onClick={() => {
              toggleTab(1);
            }}
            className={`item ${getActiveClass(1, 'active-tabs')}`}
          >
            Lịch sử phí
          </div>
          <div
            onClick={() => {
              toggleTab(2);
            }}
            className={`item ${getActiveClass(2, 'active-tabs')}`}
          >
            Lịch sử lãi
          </div>
        </div>
      </S.TabsTable>
    );
  }
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Quản lý khoản vay',
          url: '/customers/interest-and-fee',
        },
        {
          name: 'Lịch sử phí và lãi',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Lịch sử phí và lãi`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchInterestAndFeeList, dispatch]);
  return (
    <InterestAndFeeListProvider>
      <PageTitle>Lịch sử phí và lãi</PageTitle>

      <div>
        <TabMenu></TabMenu>
        {LabelTab == 'Lịch sử phí' ? <TableFee toggleState={toggleState}></TableFee> : <TableInterest toggleState={toggleState}></TableInterest>}
      </div>
    </InterestAndFeeListProvider>
  );
}
