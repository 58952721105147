import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import { formatNumberWithCommas } from '@app/utils/utils';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { ColumnConfigTableDetailCustomer } from '../../interfaces';
export const columnsConfigDetail: ColumnsType<ColumnConfigTableDetailCustomer> = [
  {
    width: '156px',
    title: 'ID khoản vay',
    key: 'code',
    dataIndex: 'code',
    render: (value: string) => <a className="text-paymentcode">{value ? value : ''}</a>,
  },
  {
    width: '131px',
    title: 'REF ID',
    key: 'referenceId',
    dataIndex: 'referenceId',
    render: (value: string) => <a className="text-loancode">{value ? value : ''}</a>,
  },
  {
    width: '172px',
    title: 'Ngày giải ngân',
    key: 'disbursementDate',
    dataIndex: 'disbursementDate',
    sorter: (a: any, b: any) => a.age - b.age,

    render: (value: string) => <div>{value ? value : ''}</div>,
  },
  {
    align: 'right',
    width: '150px',
    title: 'Số tiền vay',
    key: 'totalAmount',
    dataIndex: 'totalAmount',
    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },
  {
    width: '70px',
    title: 'Lãi suất',
    key: 'interestRate',
    dataIndex: 'interestRate',
    render: (value: string) => <div>{value ? value + '%' : ''}</div>,
  },

  {
    width: '115px',
    title: 'Phí dự kiến',
    key: 'feeAmount',
    dataIndex: 'feeAmount',

    align: 'right',

    render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
  },
  {
    width: '112px',
    title: 'Đối tác',
    key: 'partnerName',
    dataIndex: 'partnerName',
    render: (value: string) => <div>{value ? value : ''}</div>,
  },
  {
    width: '112px',
    title: 'Hình thức vay',
    key: 'referenceType',
    dataIndex: 'referenceType',
    render: (value: string) => <div>{value ? value : ''}</div>,
  },
  {
    width: '155px',
    title: 'Trạng thái',
    key: 'status',
    dataIndex: 'status',
    render: (value: any) => <StatusesText status={value ? value : ''} context="CUSTOMER"></StatusesText>,
  },
];
