import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import TableReconciliationDisbursementList from './Component/Table';
import { ReconciliationDisbursementContext, ReconciliationDisbursementProvider } from './Context';

export default function ReconciliationDisbursement() {
  const { dispatchReconciliationDisbursement } = useContext(ReconciliationDisbursementContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Đối soát',
          url: '/reconciliation/disbursement',
        },
        {
          name: 'Giải ngân',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Giải ngân`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchReconciliationDisbursement, dispatch]);
  return (
    <ReconciliationDisbursementProvider>
      <PageTitle>Đối soát giải ngân</PageTitle>

      <div>
        {/* <GS.H2>Khoản vay</GS.H2> */}
        <TableReconciliationDisbursementList></TableReconciliationDisbursementList>
      </div>
    </ReconciliationDisbursementProvider>
  );
}
