import { Button, Form, Input, Select } from 'antd';
import React, { useContext, useState } from 'react';
import * as S from '../DebtList.styles';
import * as GS from '@app/styles/GlobalStyle';
import { DebtListContext } from '../Context';
import { updateModals } from '../Action';
import { DataTableWaitForApproveType } from '../interface';
import { getDebtListRejectApi } from '../DebtList.api';
import { notificationController } from '@app/controllers/notificationController';
import _ from 'lodash';

type Props = {
  RecordRow: DataTableWaitForApproveType | undefined;
  callApiDebtListWaitForAprrove: () => void;
};

export default function ModalRejectPayment(props: Props) {
  const [saveReason, setsaveReason] = useState<string>();
  const { stateDebtList, dispatchDebtList } = useContext(DebtListContext);
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();

  function handleCancelReject() {
    dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalRejectPayment: false }));
  }
  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };
  function onchangeReason(e: string) {
    setsaveReason(e);
  }
  function handleOkReject() {
    // setloading(true);
    if (props?.RecordRow && saveReason && saveReason != '') {
      getDebtListRejectApi({
        code: props?.RecordRow?.code,
        reason: saveReason,
      })
        .then((res) => {
          if (res?.error) {
            setloading(false);
            dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalRejectPayment: false }));
          } else {
            setloading(false);
            dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalRejectPayment: false }));
            props?.callApiDebtListWaitForAprrove();
            notificationController.success({ message: _.get(res?.message, `Create Success`, `Create Success`) });
          }
        })
        .catch((err) => {
          dispatchDebtList(updateModals({ ...stateDebtList?.modals, modalRejectPayment: false }));
          setloading(false);

          notificationController.error({ message: _.get(err, `Create fail`, `${err} err API`) });
        });
    }
  }
  const { TextArea } = Input;
  return (
    <S.ModalReject title="Lý do từ chối duyệt khoản vay " open={stateDebtList?.modals?.modalRejectPayment} onOk={handleOkReject} onCancel={handleCancelReject}>
      <Form form={form} name="basic" onFinish={handleOkReject} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <S.DflexPara style={{ display: 'flex' }}>
            <S.StyleStar>*</S.StyleStar>
            <S.StylePara>Chọn lý do từ chối duyệt khoả vay</S.StylePara>
          </S.DflexPara>
          <S.StyleInput className={'filter-item'}>
            <Form.Item name={'statuses'}>
              <Select placeholder={'Chọn lý do từ chối duyệt'} size={'small'} dropdownMatchSelectWidth={200}>
                <Select.Option value={'tot'}>Tốt</Select.Option>
                <Select.Option value={'xau'}>Xấu</Select.Option>
              </Select>
            </Form.Item>
          </S.StyleInput>
          <S.StylePara>Chi tiết lý do</S.StylePara>
          <S.InputPara>
            <Form.Item rules={[{ required: true, type: 'string' }]} name={'para'} style={{ marginTop: '20px' }}>
              <TextArea onChange={(e) => onchangeReason(e.target.value)} rows={4}></TextArea>
            </Form.Item>
          </S.InputPara>
          <S.DflexEnd style={{ marginTop: '32px' }}>
            <Button loading={loading} htmlType="submit" className="btn-save-change">
              Lưu thay đổi
            </Button>
          </S.DflexEnd>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalReject>
  );
}
