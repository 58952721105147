import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../InterestAndFee.style';

import { InterestAndFeeListContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { getInterestListApi } from '../InterestAndFee.api';
import { updateFiltersInterest } from '../Action';
import { DataResponseInterest } from '../interface';
import { columnsConfigInterest } from './columnsConfigInterest';
import { FormSearchInterest } from './FormSearchInterest';

type Props = {
  toggleState: number;
};

export default function TableFee(props: Props) {
  const [checkLoading, setcheckLoading] = useState(false);
  const [dataInterest, setDataInterest] = useState<DataResponseInterest>();

  const { stateInterestAndFeeList, dispatchInterestAndFeeList } = useContext(InterestAndFeeListContext);

  const callApiListInterest = async () => {
    setcheckLoading(true);
    await getInterestListApi(queryStringParams(stateInterestAndFeeList?.filtersInterest))
      .then((res) => {
        setDataInterest(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  useEffect(() => {
    callApiListInterest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateInterestAndFeeList?.filtersInterest, props.toggleState]);

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content-table">
          <S.BoxInterestAndFee>
            <FormSearchInterest></FormSearchInterest>
            <Table
              scroll={{ x: 1700 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={dataInterest?.data}
              columns={columnsConfigInterest}
              loading={checkLoading}
              sticky={true}
              rowKey="code"
              pagination={{
                current: dataInterest?.pageIndex,
                pageSize: dataInterest?.pageSize,
                total: dataInterest?.total,
                onChange: (page: number, pageSize: number) => {
                  {
                    dispatchInterestAndFeeList(updateFiltersInterest({ ...stateInterestAndFeeList?.filtersInterest, pageIndex: page, pageSize: pageSize }));
                  }
                },
              }}
            />
          </S.BoxInterestAndFee>
        </div>
      </GS.ContainerTable>
    </GS.WrapperOverrideInput>
  );
}
