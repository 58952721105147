import { UserModel } from '@app/domain/UserModel';
import { Oauth2Data, UserInfor } from '@app/interfaces/interfaces';
const avatarImg = process.env.REACT_APP_ASSETS_BUCKET + '/avatars/avatar5.webp';

const testUser = {
  id: 1,
  firstName: 'Chris',
  lastName: 'Johnson',
  imgUrl: avatarImg,
  userName: '@john1989',
  email: {
    name: 'chris.johnson@altence.com',
    verified: true,
  },
  phone: {
    number: '+18143519459',
    verified: true,
  },
  sex: 'male',
  birthday: '01/26/2022',
  lang: 'en',
  country: 'GB',
  city: 'London',
  address1: '14 London Road',
  zipcode: 5211,
  website: 'altence.com',
  socials: {
    twitter: '@altence_team',
    facebook: 'https://facebook.com/groups/1076577369582221',
    linkedin: 'https://linkedin.com/company/altence',
  },
};

export const persistToken = (token: string): void => {
  localStorage.setItem('accessToken', token);
};

export const persistOAuth2Data = (data: Oauth2Data): void => {
  localStorage.setItem('oauth2Token', JSON.stringify(data));
};

export const readToken = (): string | null => {
  const oauth2Token = localStorage.getItem('oauth2Token');

  if (oauth2Token) {
    const oauth2Data = JSON.parse(oauth2Token) as Oauth2Data;

    return oauth2Data?.id_token;
  }

  return null;
};

export const readSignNature = (): string | null => {
  const oauth2Token = localStorage.getItem('oauth2Token');

  if (oauth2Token) {
    const oauth2Data = JSON.parse(oauth2Token) as Oauth2Data;

    return oauth2Data?.signature;
  }

  return null;
};

export const readPublicKey = (): string | null => {
  const oauth2Token = localStorage.getItem('oauth2Token');

  if (oauth2Token) {
    const oauth2Data = JSON.parse(oauth2Token) as Oauth2Data;

    return oauth2Data?.pubKey;
  }

  return null;
};

export const readUserProfile = (): UserInfor | null | undefined => {
  const oauth2Token = localStorage.getItem('oauth2Token');

  if (oauth2Token) {
    const oauth2Data = JSON.parse(oauth2Token) as Oauth2Data;

    return oauth2Data?.userInfo;
  }

  return null;
};

export const persistUser = (user: UserModel): void => {
  localStorage.setItem('user', JSON.stringify(user));
};

export const readUser = (): UserModel | null => {
  const userStr = localStorage.getItem('user');

  return userStr ? JSON.parse(userStr) : testUser;
};

export const deleteToken = (): void => localStorage.removeItem('accessToken');
export const deleteUser = (): void => localStorage.removeItem('user');
