// import { OptionLabels } from '@app/constants/creditScoring';
import React, { useContext } from 'react';
import { Form, Input, Select, Button } from 'antd';
import { CustomerManagementContext } from '@app/modules/Backline/Customers/pages/Management/context';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../CustomerManagement.styles';
import { AiOutlineSearch, AiOutlineExport } from 'react-icons/ai';
// import _ from 'lodash';
import { FormSearchInterface } from '@app/modules/Backline/Customers/pages/Management/interfaces';
import { updateFiltersCustomer } from '@app/modules/Backline/Customers/pages/Management/actions';

export const FormSearch: React.FC<FormSearchInterface> = () => {
  const { stateCustomerManagement, dispatchCustomerManagement } = useContext(CustomerManagementContext);

  function onChangeSelectStatus(e: string) {
    dispatchCustomerManagement(updateFiltersCustomer({ ...stateCustomerManagement?.filters, status: e }));
  }

  function onChangeSearch(e: any) {
    if (e.key === 'Enter') {
      dispatchCustomerManagement(updateFiltersCustomer({ ...stateCustomerManagement?.filters, key: e.target.value }));
    }
  }
  return (
    <GS.WrapperOverrideInput>
      <S.WrapperFilters>
        <div className="box-startend">
          <Form.Item name={'keyword'} className="form-search">
            <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Tìm kiếm Tên, sđt, CMND/CCCD...'} />
          </Form.Item>
          <Form.Item name={'statuses'}>
            <Select placeholder={'Trạng thái'} onChange={(e) => onChangeSelectStatus(e)} size={'small'} dropdownMatchSelectWidth={200}>
              <Select.Option value={''}>Tất cả</Select.Option>

              <Select.Option value={'ACTIVE'}>Hoạt động</Select.Option>
              <Select.Option value={'IN_ACTIVE'}>Ngưng hoạt động</Select.Option>
              <Select.Option value={'WAIT_FOR_VERIFY_OTP'}>Chưa xác thực</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <div>
          <Form.Item>
            <Button icon={<AiOutlineExport />}>Xuất Excel</Button>
          </Form.Item>
        </div>
      </S.WrapperFilters>
    </GS.WrapperOverrideInput>
  );
};
