import { Modal, Switch, Tabs } from 'antd';
import styled from 'styled-components';

export const WrapperFilters = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 15px;
  .box-startend {
    display: flex;
    padding-left: 8px;
  }
  .form-search {
    margin-right: 10px;
  }
  .margin-right {
    margin-right: 10px;
  }
`;

export const ContentBody = styled.div`
  .ant-table-header.ant-table-sticky-holder {
    top: -1px !important;
  }
  padding: 10px 0px 0px 24px;
  background-color: #1a1f25;
  .action-view-detail {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    border: 1px solid #3c4450;
  }
  .ant-select-selector {
    border: 1px solid #3c4450 !important;
  }
  .dropdown-heading-value {
    color: #bfbfbf !important;
  }
  .button.ant-btn.ant-btn-default {
    color: #d9d9d9;
    border: 1px solid #d9d9d9 !important;
  }
`;

export const ContainerDetailCustomer = styled.div`
  .title-name {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #f2f2f2;
    padding: 0px 0px 24px 24px;
  }
  .content-body {
    background: #12161c;
    width: 100%;
    padding: 24px;
    .text-note {
      padding-top: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .section-btn-action {
      display: flex;
      justify-content: end;

      .box-btn {
        display: flex;
        justify-content: end;
        .btn-excel {
          height: 32px !important;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          color: #d9d9d9;
          margin-left: 10px;
        }
        .btn-edit {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          background: #f2c015;
          border: 1px solid #f2c015;
          box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
          border-radius: 2px;
          color: #1f1f1f;
        }
      }
    }
    .section-user-infor-payment {
      background-color: #1a1f25;
      margin-top: 24px;
      .margin-left-40 {
        margin-left: 40px;
      }
      .margin-bottom-0 {
        margin-bottom: 0;
      }
      .title {
        border-bottom: 1px solid #3c4450;
        padding-bottom: 10px;
        .box-title {
          padding: 16px 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-same-list-ekyc {
        padding: 24px;
        .title {
          border-bottom: 1px solid #3c4450;
          .box-title {
            padding-left: 0px !important;
          }
        }
        .box-content {
          display: flex;
          margin-top: 25px;
          justify-content: space-between;
          border-bottom: 1px solid #3c4450;
          padding-bottom: 20px;
          .item {
            width: 18%;
            .label {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              color: #8c8c8c;
              margin-bottom: 10px;
            }
            .value {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f0f0f0;
              margin-bottom: 30px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .style-face-samelist {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
      .content-user {
        padding: 24px;
        display: flex;
        flex-wrap: wrap;
        .item-column {
          width: 31%;
          margin-right: 20px;
          .box-img-card {
            margin-top: 20px;
            img {
              object-fit: contain;
            }
            .style-of-face {
              object-fit: contain;
              background: #fff;
            }
          }
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            margin-bottom: 10px;
          }
          .text-err-detail-ekyc {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #ff4d4f;
            margin-top: 20px;
          }
          .value {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .box-edit-customer {
            display: flex;
            .edit-customer {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f2c015;
              cursor: pointer;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .section-user-infor {
      background-color: #1a1f25;
      margin-top: 24px;
      .title {
        border-bottom: 1px solid #3c4450;
        .box-title {
          padding: 16px 24px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-user {
        padding: 24px 72px 24px 72px;
        display: flex;
        justify-content: space-between;
        .avatar-column {
          width: 94px;
          img {
            width: 100%;
          }
        }
        .item-column {
          width: 17%;
          .label {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #8c8c8c;
            margin-bottom: 8px;
          }
          .value {
            margin-bottom: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #f5f5f5;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .box-edit-customer {
            display: flex;
            .edit-customer {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 22px;
              color: #f2c015;
              cursor: pointer;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .section-tabs-detail {
      margin-top: 24px;
      background: #1a1f25;
      .title {
        .box-title {
          padding: 16px 0px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
        }
      }
      .content-verify {
        .title-verify-face {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #f5f5f5;
        }
        .image-verify-face {
          display: flex;
          margin-top: 24px;
          margin-bottom: 24px;
          .box-img {
            width: 140px;
            height: 159px;
            box-sizing: border-box;
            left: 24px;
            top: 118px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
        .CCCD-verify-face {
          display: flex;
          margin-top: 24px;
          .box-img {
            width: 333px;
            box-sizing: border-box;
            height: 194px;
            left: 24px;
            top: 347px;
            border: 1px solid #3c4450;
            border-radius: 16px;
            margin-right: 24px;
          }
        }
      }
    }
  }
`;

export const TabStyle = styled(Tabs)`
  .ant-tabs-nav-wrap {
    border-bottom: 1px solid #3c4450;
  }
  .ant-tabs-content.ant-tabs-content-top {
    padding: 24px;
  }
`;

export const StyleModalEditDetail = styled(Modal)`
  .ant-select-selector {
    background: #272d35;
  }
  .ant-select-selector {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
  }
  .ant-form-item {
    width: 100%;
  }
  .icon-special {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-align: right;
    color: #ff4d4f;
    margin-right: 5px;
    margin-top: 5px;
  }
  .ant-modal-content {
    background: yellow;
    width: 591px;
  }
  .ant-modal-close-x {
    display: flex;
    align-items: center;
    margin-top: 9px;
  }
  .ant-modal-body {
    background: #1a1f25;
    padding: 0;
  }
  .box-title-modal {
    border-bottom: 1px solid #3c4450;
    .title-modal {
      padding: 24px;
    }
  }
  .content-body {
    padding: 24px;
    .section-btn {
      display: flex;
      justify-content: end;
      .btn-cancel {
        box-sizing: border-box;
        display: flex;
        margin-right: 10px;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 15px;
        height: 32px;
        background: #161a1e;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: #d9d9d9;
      }
      .btn-change {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 15px;
        height: 32px;
        background: #f2c015;
        border: 1px solid #f2c015;
        border-radius: 2px;
        /* font-family: 'Poppins'; */
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        text-align: center;
        color: black;
      }
      .btn-change:hover {
        background: #f2c015;
        color: #161a1e;
      }
    }
    .label {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
    .value {
      margin-top: 5px;
      display: flex;
      .text-action {
        margin-left: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #f0f0f0;
      }
    }
    .label-note {
      margin-top: 20px;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8c8c8c;
    }
  }
`;

export const SwitchStyle = styled(Switch)`
  .ant-switch-handle::before {
    background-color: #fff;
  }
`;
export const InputPara = styled.div`
  textarea#para {
    flex-direction: row;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 10px;
    width: 757px;
    height: 139px;
    background: #272d35;
    border: 1px solid #3c4450;
    border-radius: 4px;
  }
`;

export const StyleContentPopconfirm = styled.div`
  width: 284px;
  border-radius: 2px;
  .box-content {
    display: flex;
    .box-icon {
      img {
        margin-top: 5px;
        margin-right: 5px;
      }
    }
    .text-confirm {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #f0f0f0;
    }
  }
  .box-btn {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    & .btn-cancel {
      box-sizing: border-box;
      display: flex;
      margin-right: 10px;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      height: 32px;
      background: #161a1e;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #d9d9d9;
    }
    & .btn-save {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      height: 32px;
      background: #f2c015;
      border: 1px solid #f2c015;
      border-radius: 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: black;
    }
  }
`;
export const StyleModalConfirm = styled(Modal)`
  .ant-modal-body {
    padding: 32px 24px 32px 32px;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
  .text-confirm {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f0f0f0;
  }
  .btn-change {
    width: 98%;
    display: flex;
    justify-content: end;
    margin-top: 32px;
    .btn-cancel {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      gap: 10px;
      width: 59px;
      height: 32px;
      background: #161a1e;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
    }
    .btn-yes {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #161a1e;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      gap: 8px;
      margin-left: 20px;

      height: 32px;
      background: #f2c015;
      border: 1px solid #f2c015;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 2px;
    }
  }
`;
export const StyleTableDetailTab = styled.div`
  .watch-all {
    display: flex;
    margin-top: 20px;
    justify-content: end;
    .text-all {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: right;
      color: #f2c015;
      cursor: pointer;
    }
  }
`;
