import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseApiRefundList, paramsUpdateStatusRefundApiType } from './interface';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

// const TokenConfigTextPlain: CreateDebtConfig = {
//   secured: true,
//   ContentType: 'text/plain',
// };

// const httpApi = initAxios();
export const getRefundApi = (params: string): Promise<DataResponseApiRefundList> => httpApi.get(`/loans/v1/refunds?${params}`, { ...TokenConfig });

export const changeStatusRefundApi = (code: string, params: paramsUpdateStatusRefundApiType): Promise<any> => httpApi.put(`/loans/v1/refunds/${code}/status?`, params, { ...TokenConfig });

export const ExportExcelRefund = (params: string): Promise<any> => httpApi.get(`/loans/v1/refunds/export?${params}`, { ...TokenConfig });
