import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import * as S from '../Capital.styles';

import { ColumnsType } from 'antd/es/table';
import { getCapitalListApi } from '../Capital.api';
import { CapitalContext } from '../Context';
import { queryStringParams } from '@app/controllers/queryStringParams';
import { DataTypeTable } from '../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import { useNavigate } from 'react-router-dom';
import { updateFiltersCapital } from '../Action';
import { FormSearchCapitalList } from './FormSearchCapitalList';
import { StatusesText } from '@app/components/common/Statuses/Statuses';
import ModalCreateCapital from './ModalCreateCapital';

export default function TableCapital() {
  const { stateCapital, dispatchCapital } = useContext(CapitalContext);
  const [checkLoading, setcheckLoading] = useState(false);
  const navigate = useNavigate();

  const [data, setdata] = useState<any>();
  const callApiListCapital = async () => {
    setcheckLoading(true);
    await getCapitalListApi(queryStringParams(stateCapital?.filters))
      .then((res) => {
        setdata(res);
        setcheckLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setcheckLoading(false);
      });
  };

  function handleWatch(record: DataTypeTable) {
    console.log(record);
    navigate(`/capital/management/${record?.id}`);
  }

  useEffect(() => {
    callApiListCapital();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCapital?.filters]);

  const columns: ColumnsType<any> = [
    {
      width: '120px',
      title: 'ID Nguồn vốn',
      key: 'code',
      dataIndex: 'code',
      render: (value: string) => <a>{value ? value : ''}</a>,
    },
    {
      width: '150px',
      title: 'Tên nguồn vốn',
      key: 'name',
      render: (value: DataTypeTable) => {
        return (
          <div>
            <a className="margin-left">{value ? value?.name : ''}</a>
          </div>
        );
      },
    },
    {
      width: '131px',
      title: 'Tổng kí quỹ',
      key: 'topUp',
      align: 'right',
      dataIndex: 'topUp',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      align: 'right',
      width: '150px',
      title: 'Tiền đang giải ngân',
      key: 'disbursing',
      dataIndex: 'disbursing',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      width: '121px',
      title: 'Số dư hiện tại',
      align: 'right',
      key: 'balance',
      dataIndex: 'balance',
      render: (value: number) => <div>{value ? formatNumberWithCommas(value) : ''}</div>,
    },
    {
      width: '150px',
      title: 'Loại khoản vay',
      key: 'loanProductCode',
      dataIndex: 'loanProductCode',
      render: (value: number) => <div>{value ? value : ''}</div>,
    },
    {
      width: '130px',
      title: 'Chứng từ',
      key: 'contractFile',
      dataIndex: 'contractFile',
      render: (value: string) => <a> {value ? value : ''}</a>,
    },

    {
      width: '130px',
      title: 'Trạng thái',
      key: 'maker',

      render: (value: DataTypeTable) => <StatusesText status={value?.status} context="CAPITAL"></StatusesText>,
    },
    {
      width: '120px',
      title: 'Hành động',
      key: 'action',
      align: 'center',
      render: (record: DataTypeTable) => (
        <div className="text-watch" onClick={() => handleWatch(record)}>
          Xem
        </div>
      ),
    },
  ];

  return (
    <GS.WrapperOverrideInput>
      <GS.ContainerTable>
        <div className="box-content">
          <S.BoxCapital>
            <FormSearchCapitalList></FormSearchCapitalList>
            <Table
              className="padding-table"
              scroll={{ x: 1700 }}
              rowSelection={{ type: 'checkbox' }}
              dataSource={data && data?.data}
              sticky={true}
              columns={columns}
              loading={checkLoading}
              rowKey="code"
              pagination={{
                current: data?.pageIndex,
                pageSize: data?.pageSize,
                total: data?.total,
                onChange: (page: number, pageSize: number) => {
                  dispatchCapital(updateFiltersCapital({ ...stateCapital?.filters, pageIndex: page, pageSize: pageSize }));
                },
              }}
            />
          </S.BoxCapital>
        </div>
      </GS.ContainerTable>
      <ModalCreateCapital></ModalCreateCapital>
    </GS.WrapperOverrideInput>
  );
}
