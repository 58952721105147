import { Dispatch, useReducer } from 'react';
import {
  UPDATE_ARR_ROW_CHECKBOX,
  UPDATE_DATA_ERR_DETAIL,
  UPDATE_FILTERS_DISBURSEMENT,
  UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT,
  UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT_DAY,
  UPDATE_STATE_MODAL_DISBURSEMENT,
} from './Action';
import { ReducerType } from './interface';

export const initDisbursement = {
  stateDisbursement: {
    form: null,
    filters: {
      transDate: '',
      fund: '',
      key: '',
      pageIndex: 1,
      product: '',
      pageSize: 20,
      transFromDate: '',
      transToDate: '',
    },
    filtersTabWaitForDisbursement: {
      // transDate: '',
      reviewToDate: '',
      reviewFromDate: '',
      pageIndex: 1,
      pageSize: 20,
    },
    filtersTabWaitForDisbursementDetailDay: {
      pageIndex: 1,
      pageSize: 20,
      product: '',
      fund: '',
      status: '',
      partner: '',
    },
    stateModal: {
      stateModalDisbursement: false,
      stateModalDetail: false,
      stateModalConfirm: false,
      stateModalCreateProof: false,
      stateModalCreateProofDetail: false,
      stateModalShowEditDetail: false,
      stateModalSelectFund: false,
      stateModalListErrDetail: false,
      checkStateModalListErrDetail: false,
    },
    arrRowCheckBoxSave: {
      arrRowCheckBoxSave: [],
    },
    dataErrDetail: {
      dataErrDetail: [],
      totalTransactionsFailed: undefined,
    },
  },
  dispatchDisbursement: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_DISBURSEMENT:
      return {
        ...state,
        filters: action.filtersDisbursementList,
      };
    case UPDATE_ARR_ROW_CHECKBOX:
      return {
        ...state,
        arrRowCheckBoxSave: action.arrRowCheckBoxSave,
      };
    case UPDATE_DATA_ERR_DETAIL:
      return {
        ...state,
        dataErrDetail: action.dataErrDetail,
      };
    case UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT:
      return {
        ...state,
        filtersTabWaitForDisbursement: action.filtersTabWaitForDisbursement,
      };
    case UPDATE_FILTERS_TAB_WAIT_FOR_DISBURSEMENT_DAY:
      return {
        ...state,
        filtersTabWaitForDisbursementDetailDay: action.filtersTabWaitForDisbursementDetailDay,
      };
    case UPDATE_STATE_MODAL_DISBURSEMENT:
      return {
        ...state,
        stateModal: action.stateModal,
      };
    default:
      return {
        ...state,
      };
  }
};

export const useDisbursementReducer = () => {
  return useReducer(reducer, initDisbursement.stateDisbursement);
};
