import { StatusesText } from '@app/components/common/Statuses/Statuses';
import { formatNumberWithCommas } from '@app/utils/utils';
import React from 'react';
import { ResponseApiDetail } from '../../interface';
import { Tooltip } from 'antd';
type Props = {
  dataDetail: ResponseApiDetail | undefined | null;
};
export default function SectionCapitalInfor(props: Props) {
  return (
    <div className="section-user-infor">
      <div className="title">
        <div className="box-title">Thông tin nguồn vốn</div>
      </div>
      <div className="content-user">
        <div className="item-column">
          <div className="label">Trạng thái</div>

          <div className="value">{props?.dataDetail ? <StatusesText context="CAPITAL" status={props?.dataDetail?.status}></StatusesText> : ''}</div>
          <div className="label">Kv đã tất toán/ Kv đã giải ngân</div>
          <div className="value">
            {props?.dataDetail
              ? `${formatNumberWithCommas(props?.dataDetail ? props?.dataDetail.totalSettlementLoan : 0)} / ${formatNumberWithCommas(props?.dataDetail ? props?.dataDetail?.totalLoansActive : 0)}`
              : ''}
          </div>
        </div>
        <div className="item-column">
          <div className="label">Loại khoản vay</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.loanProductCode : ''}</span>}>
            <div className="value">{props?.dataDetail ? props?.dataDetail.loanProductCode : ''}</div>
          </Tooltip>
          <div className="label">Tổng khoản vay</div>
          <div className="value">{props?.dataDetail ? formatNumberWithCommas(props?.dataDetail?.totalLoansActive ? props?.dataDetail?.totalLoansActive : 0) : ''}</div>
        </div>
        <div className="item-column">
          <div className="label">Tổng kí quỹ</div>
          <div className="value">{props?.dataDetail ? formatNumberWithCommas(props?.dataDetail?.topUp ? props?.dataDetail?.topUp : 0) : ''}</div>
          <div className="label">Lãi phí đã thu/ Tổng lãi phí</div>
          <div className="value">
            {props?.dataDetail
              ? `${formatNumberWithCommas(props?.dataDetail.totalInterestFeePaid ? props?.dataDetail.totalInterestFeePaid : 0)} / ${formatNumberWithCommas(
                  props?.dataDetail?.totalInterestFee ? props?.dataDetail?.totalInterestFee : 0,
                )}
                `
              : ''}
          </div>
        </div>
        <div className="item-column">
          <div className="label">Số dư hiện tại</div>
          <div className="value">{props?.dataDetail ? formatNumberWithCommas(props?.dataDetail ? props?.dataDetail.balance : 0) : ''}</div>
          <div className="label">Gốc đã thu/ Tiền đang giải ngân</div>
          <div className="value">
            {props?.dataDetail
              ? `${formatNumberWithCommas(props?.dataDetail.totalPrincipalPaid ? props?.dataDetail.totalPrincipalPaid : 0)} / ${formatNumberWithCommas(
                  props?.dataDetail?.disbursing ? props?.dataDetail?.disbursing : 0,
                )}`
              : ''}
          </div>
        </div>
        <div className="item-column">
          <div className="label">Tiền đã rút</div>
          <div className="value">{props?.dataDetail ? formatNumberWithCommas(props?.dataDetail.cashOut ? props?.dataDetail.cashOut : 0) : ''}</div>
        </div>
      </div>
    </div>
  );
}
