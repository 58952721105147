import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { paramsExcel } from '../ReconciliationLoan/interface';
import { DataResponseReconciliationPayment, DataResponseReconciliationPaymentDetail } from './interface';

const httpApi = initAxios();

interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: TokenConfig = {
  secured: true,
};

export const getReconciliationPaymentListApi = (params: string): Promise<DataResponseReconciliationPayment> => httpApi.get(`/loans/v1/reconcile/payment?${params}`, { ...TokenConfig });

export const getReconciliationPaymentDetailApi = (params: string): Promise<DataResponseReconciliationPaymentDetail> => httpApi.get(`/loans/v1/reconcile/payment/by-day?${params}`, { ...TokenConfig });

export const getReconciliationPaymentExportExcel = (params: paramsExcel): Promise<string> => httpApi.post('/loans/v1/reconcile/payment/export', params, { ...TokenConfig });
