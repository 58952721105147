import React from 'react';
import { Link } from 'react-router-dom';
import * as S from './ProfileOverlay.styles';
import { DropdownMenu } from '@app/components/header/Header.styles';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  return (
    <DropdownMenu selectable={false} {...props}>
      {/* <S.MenuItem key={0}>
        <S.Text>
          <Link to="/profile">{t('profile.title')}</Link>
        </S.Text>
      </S.MenuItem> */}
      <S.ItemsDivider />
      <S.MenuItem key={1}>
        <S.Text>
          <Link className="style-logout" to="/logout">
            Logout
          </Link>
        </S.Text>
      </S.MenuItem>
    </DropdownMenu>
  );
};
