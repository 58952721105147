import { Dispatch, useReducer } from 'react';
import { ReducerType } from './interface';
import { UPDATE_FILTERS_POLICY, UPDATE_MODALS_POLICY } from './Action';

export const initPolicy = {
  statePolicy: {
    form: null,
    filtersPolicy: {
      key: '',
      pageSize: 10,
      pageIndex: 1,
    },
    modalsPolicy: {
      modalEdit: false,
      modalCreate: false,
      modalDetail: false,
    },
  },
  dispatchPolicy: (() => undefined) as Dispatch<any>,
};

const reducer = (state: any, action: ReducerType) => {
  switch (action.type) {
    case UPDATE_FILTERS_POLICY:
      return {
        ...state,
        filtersPolicy: action.filtersPolicy,
      };
    case UPDATE_MODALS_POLICY:
      return {
        ...state,
        modalsPolicy: action.modalsPolicy,
      };
    default:
      return {
        ...state,
      };
  }
};

export const usePolicyReducer = () => {
  return useReducer(reducer, initPolicy.statePolicy);
};
