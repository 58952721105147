import { ColumnsType } from 'antd/lib/table';
import { DataTypeTable } from '../../interface';
import { formatNumberWithCommas } from '@app/utils/utils';
import TableColumnTime from '@app/components/common/TableColumnTime/TableColumnTime';

export const columnsConfigOriginal: ColumnsType<DataTypeTable> = [
  {
    width: '175px',
    title: 'Thời gian đối soát',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorter: (a: any, b: any) => a.age - b.age,

    render: (value: string) => <div className="text-paymentcode">{value ? value : ''}</div>,
  },
  {
    width: '123px',
    title: 'ID giải ngân',
    key: 'transactionCodePartner',
    dataIndex: 'transactionCodePartner',

    render: (value: string) => <a className="cursor-pointer-none">{value ? value : ''}</a>,
  },
  {
    width: '123px',
    title: 'ID khoản vay',
    key: 'loanCodePartner',
    dataIndex: 'loanCodePartner',

    render: (value: string) => <a className="cursor-pointer-none">{value ? value : ''}</a>,
  },

  {
    width: '224px',
    title: 'Thời gian giải ngân ',
    key: 'disbursementDatePartner',
    dataIndex: 'disbursementDatePartner',

    render: (value: string) => <TableColumnTime value={value ? value : ''}></TableColumnTime>,
  },
  {
    width: '166px',
    title: 'Số tiền giải ngân',
    key: 'amountPartner',
    dataIndex: 'amountPartner',
    align: 'right',
    render: (value: number) => <div className="text-loancode">{value ? formatNumberWithCommas(value) : ''}</div>,
  },
  {
    width: '131px',
    title: 'REF ID ',
    key: 'fundNamePartner',
    dataIndex: 'fundNamePartner',

    render: (value: string) => <div className="text-loancode">{value ? value : ''}</div>,
  },
];
