import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { setBreadcrumb, setBreadcrumbTitle } from '@app/store/slices/breadcrumbSlice';
import React, { useContext, useEffect } from 'react';
import TableReconciliationLoanList from './Component/Table';
import { ReconciliationLoanListContext, ReconciliationLoanListProvider } from './Context';
export default function ReconciliationLoan() {
  const { dispatchReconciliationLoanList } = useContext(ReconciliationLoanListContext);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          name: 'Đối soát',
          url: '/reconciliation/loan',
        },
        {
          name: 'Khoản vay',
        },
      ]),
    );
    dispatch(setBreadcrumbTitle(`Khoản vay`));

    return () => {
      dispatch(setBreadcrumb([]));
      dispatch(setBreadcrumbTitle(''));
    };
  }, [dispatchReconciliationLoanList, dispatch]);
  return (
    <ReconciliationLoanListProvider>
      <PageTitle>Đối soát khoản vay</PageTitle>

      <div>
        {/* <GS.H2>Khoản vay</GS.H2> */}
        <TableReconciliationLoanList></TableReconciliationLoanList>
      </div>
    </ReconciliationLoanListProvider>
  );
}
