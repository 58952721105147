import React from 'react';
import * as S from './UserNameAvatarColumn.style';

type Props = {
  avatar?: any;
  name: string;
};

export default function UserNameAvatarColumn(props: Props) {
  return (
    <S.StyleColumnAvatar>
      {/* <img src={props.avatar} alt="" /> */}
      <div className="text-customer-name">{props.name}</div>
    </S.StyleColumnAvatar>
  );
}
