import { formatNumberWithCommas } from '@app/utils/utils';

import React from 'react';
// import { ReactComponent as IconUploadPDF } from '@app/assets/icons/icon-upload-pdf.svg';
import { DataLoanDetailType } from '../interface';

type Props = {
  dataDetailLoan: DataLoanDetailType | undefined;
};
export default function InforPaymentSection(props: Props) {
  return (
    <div className="section-infor-payment-general" style={{ marginTop: '20px' }}>
      <div className="title-inforgeneral">Thông tin thanh toán</div>
      <div className="content">
        <div className="box-content-item">
          <div className="box-line margin-bottom ">
            <div className="label">Tổng tiền vay</div>
            <div className="value">{props?.dataDetailLoan?.amount ? formatNumberWithCommas(props?.dataDetailLoan?.amount) : 0}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Gốc hoàn(Trước GN)</div>
            <div className="value">{props?.dataDetailLoan?.refundPrincipalBeforeDisbursement ? formatNumberWithCommas(props?.dataDetailLoan?.refundPrincipalBeforeDisbursement) : 0}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Gốc đã giải ngân</div>
            <div className="value">{props?.dataDetailLoan?.disbursedAmount ? formatNumberWithCommas(props?.dataDetailLoan?.disbursedAmount) : 0}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Gốc hoàn(Sau GN)</div>
            <div className="value">{props?.dataDetailLoan?.refundPrincipalAmount ? formatNumberWithCommas(props?.dataDetailLoan?.refundPrincipalAmount) : 0}</div>
          </div>

          <div className="box-line margin-bottom border-botom-payment-section padding-bottom-12">
            <div className="label">Gốc đã thanh toán</div>
            <div className="value">{props?.dataDetailLoan?.principalPaidAmount ? formatNumberWithCommas(props?.dataDetailLoan?.principalPaidAmount) : 0}</div>
          </div>
          <div className="box-line ">
            <div className="label label-bold">Gốc còn lại</div>
            <div className="value value-blue">{props?.dataDetailLoan?.remainingPrincipalAmount ? formatNumberWithCommas(props?.dataDetailLoan?.remainingPrincipalAmount) : 0}</div>
          </div>
        </div>
        {/* //new */}
        <div className="box-content-item margin-top-8">
          <div className="box-line margin-bottom ">
            <div className="label">Lãi lũy kế</div>
            <div className="value">{props?.dataDetailLoan?.accruedInterestAmount ? formatNumberWithCommas(props?.dataDetailLoan?.accruedInterestAmount) : 0}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Lãi hoàn(Sau GN)</div>
            <div className="value">{props?.dataDetailLoan?.refundInterestAmount ? formatNumberWithCommas(props?.dataDetailLoan?.refundInterestAmount) : 0}</div>
          </div>

          <div className="box-line margin-bottom border-botom-payment-section padding-bottom-12">
            <div className="label">Lãi đã thanh toán</div>
            <div className="value">{props?.dataDetailLoan?.interestPaidAmount ? formatNumberWithCommas(props?.dataDetailLoan?.interestPaidAmount) : 0}</div>
          </div>
          <div className="box-line ">
            <div className="label label-bold">Lãi còn lại</div>
            <div className="value value-blue">{props?.dataDetailLoan?.remainingInterestAmount ? formatNumberWithCommas(props?.dataDetailLoan?.remainingInterestAmount) : 0}</div>
          </div>
        </div>

        {/* new */}
        <div className="box-content-item margin-top-8">
          <div className="box-line margin-bottom ">
            <div className="label">Phí lũy kế</div>
            <div className="value">{props?.dataDetailLoan?.accruedFeeAmount ? formatNumberWithCommas(props?.dataDetailLoan?.accruedFeeAmount) : 0}</div>
          </div>
          <div className="box-line margin-bottom ">
            <div className="label">Phí quá hạn</div>
            <div className="value">{props?.dataDetailLoan?.totalOverdueFee ? formatNumberWithCommas(props?.dataDetailLoan?.totalOverdueFee) : 0}</div>
          </div>
          <div className="box-line margin-bottom">
            <div className="label">Phí hoàn(Sau GN)</div>
            <div className="value">{props?.dataDetailLoan?.refundFeeAmount ? formatNumberWithCommas(props?.dataDetailLoan?.refundFeeAmount) : 0}</div>
          </div>

          <div className="box-line margin-bottom border-botom-payment-section padding-bottom-12">
            <div className="label">Phí đã thanh toán</div>
            <div className="value">{props?.dataDetailLoan?.feePaidAmount ? formatNumberWithCommas(props?.dataDetailLoan?.feePaidAmount) : 0}</div>
          </div>
          <div className="box-line ">
            <div className="label label-bold">Phí còn lại</div>
            <div className="value value-blue">{props?.dataDetailLoan?.remainingFeeAmount ? formatNumberWithCommas(props?.dataDetailLoan?.remainingFeeAmount) : 0}</div>
          </div>
        </div>

        {/* total */}
        <div className="box-content-item-result margin-top-8 ">
          <div className="box-line ">
            <div className="label label-bold">Dư nợ còn lại</div>
            <div className="value value-green">{props?.dataDetailLoan?.totalAmountPay ? formatNumberWithCommas(props?.dataDetailLoan?.totalAmountPay) : 0}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
