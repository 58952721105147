import React, { useContext } from 'react';
import * as S from '../../Disbursement.styles';
import * as GS from '@app/styles/GlobalStyle';
import { DisbursementContext } from '../../Context';
import { updateStateModalDisbursement } from '../../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';

export default function ModalListErrDetail() {
  const { stateDisbursement, dispatchDisbursement } = useContext(DisbursementContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function render() {
    return (
      <span>
        Có <span style={{ color: '#F2C015' }}>{stateDisbursement?.dataErrDetail?.totalTransactionsFailed}</span> phiếu giải ngân không thành công
      </span>
    );
  }
  function handleCancelModalListErrDetail() {
    dispatchDisbursement(updateStateModalDisbursement({ ...stateDisbursement.stateModal, checkStateModalListErrDetail: false, stateModalListErrDetail: false }));
  }

  return (
    <S.ModalListErrDetail title={render()} open={stateDisbursement?.stateModal?.stateModalListErrDetail} footer={null} onCancel={handleCancelModalListErrDetail}>
      <GS.WrapperOverrideInput>
        <div className="box-content">
          <div className="line-label">
            <div className="col-1">ID giải ngân</div>
            <div className="col-3">Lý do</div>
          </div>
          {stateDisbursement?.dataErrDetail?.dataErrDetail && stateDisbursement?.dataErrDetail?.dataErrDetail.length > 0
            ? stateDisbursement?.dataErrDetail?.dataErrDetail.map((item: any, index: any) => (
                <div className="line-item" key={index}>
                  <div className="col-1">{item?.referenceId ? item?.referenceId : '-'}</div>
                  <div className="col-3">{item?.message ? item?.message : '-'}</div>
                </div>
              ))
            : 'Không có lỗi xảy ra'}
          <div className="section-btn">
            <BtnCreateStyle onClick={handleCancelModalListErrDetail} haveNotIcon={true} label="Xác nhận"></BtnCreateStyle>
          </div>
        </div>
      </GS.WrapperOverrideInput>
    </S.ModalListErrDetail>
  );
}
