import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataDetailCusomerType, DataResponseCustomerType, ParamsApiUpdateLevelType } from './interfaces';
const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};
interface CreateDebtConfig extends AxiosRequestConfig {
  secured: boolean;
  ContentType: string;
}
const TokenConfigTextPlain: CreateDebtConfig = {
  secured: true,
  ContentType: 'text/plain',
};

// const httpApi = initAxios();
export const getCustomers = (params: string): Promise<DataResponseCustomerType> => httpApi.get(`/loans/v1/users?${params}`, { ...TokenConfig });

export const getDetailCustomers = (id: number | string | undefined): Promise<DataDetailCusomerType> => httpApi.get(`/loans/v1/users/${id}`, { ...TokenConfig });

export const updateLevelApi = (params: ParamsApiUpdateLevelType): Promise<any> => httpApi.post(`/loans/v1/users/level`, params, { ...TokenConfigTextPlain });
