import axios from 'axios';
import _ from 'lodash';

export const httpApi = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
});

httpApi.interceptors.request.use((config) => {
  if (_.get(config, 'secured', false)) {
    config.headers = { ...config.headers, Authorization: `Bearer ` };
  }

  return config;
});

export interface ApiErrorData {
  message: string;
}
