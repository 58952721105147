import styled from 'styled-components';

export const FooterModal = styled.div`
  .footer {
    padding: 24px 42px;
    display: flex;
    justify-content: end;
    .box-btn {
      display: flex;
      .btn-cancel {
        margin-right: 12px;
        background: #1a1f25;
        border: 1px solid #d9d9d9;
        box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
        border-radius: 2px;
        box-sizing: border-box;
      }
      .btn-save {
        background: #f2c015 !important;
        box-sizing: border-box;
        border: 1px solid #f2c015 !important;

        border-radius: 2px;
        color: black !important;
      }
      .css-btn-yellow {
        background: #f2c015 !important;
        color: black !important;
        border-radius: 2px;
        border: 1px solid #f2c015 !important;
      }
    }
  }
  .ant-btn {
    padding: 3px 15px !important;
  }
`;
