import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';
import { readPublicKey, readSignNature, readToken } from '@app/services/localStorage.service';
import { notificationController } from '@app/controllers/notificationController';

const initAxios = (): AxiosInstance => {
  const httpApi = axios.create({
    baseURL: process.env.REACT_APP_GATEWAY_URL,
    validateStatus: function () {
      return true;
    },
  });

  httpApi.interceptors.request.use((config) => {
    if (_.get(config, 'secured', false)) {
      config.headers = { ...config.headers, Authorization: `Bearer ${readToken()}`, 'x-signature': `${readSignNature()}`, 'x-pub-key': `${readPublicKey()}` };
    }
    if (_.get(config, 'text/plain', false)) {
      config.headers = { ...config.headers, 'Content-Type': 'text/plain' };
    }
    if (_.get(config, 'ContentType', false)) {
      config.headers = {
        ...config.headers,
        'Content-Type': _.toString(_.get(config, 'ContentType')),
      };
    } else {
      config.headers = {
        ...config.headers,
        'Content-Type': 'application/json',
      };
    }

    return config;
  });
  httpApi.interceptors.response.use(
    (resp) => {
      if (_.includes([401], resp.status)) {
        localStorage.removeItem('oauth2Token');
        location.href = '/';
      }

      if (_.includes([200, 201], resp.status) && typeof resp.data !== 'undefined') {
        return resp.data;
      } else {
        notificationController.error({ message: `${resp.data.message ? resp.data.message : 'Something went wrong with Api'}` });

        return {
          error: {
            data: resp.data ?? null,
            message: resp?.data?.message ?? null,
            status: resp.status,
            code: resp?.data?.code ?? -1,
          },
        };
      }
    },
    (error) => error,
  );

  return httpApi;
};

export default initAxios;
