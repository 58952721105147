import React, { useContext } from 'react';
import { DataDetailEkycType } from '../interface';
import { Button, Tooltip } from 'antd';
import { EkycManagementContext } from '../Context';
import { updateStateModalEkycManagement } from '../Action';

type Props = {
  dataDetail: DataDetailEkycType | undefined;
};

export default function InforPaper(props: Props) {
  const { stateEkycManagement, dispatchEkycManagement } = useContext(EkycManagementContext);

  function openModalEditEkyc() {
    dispatchEkycManagement(updateStateModalEkycManagement({ ...stateEkycManagement.stateModal, stateModalEditPaperEkyc: true }));
  }
  return (
    <div className="section-user-infor">
      <div className="title">
        <div className="box-title">Thông tin giấy tờ</div>
      </div>
      <div className="content-user">
        <div className="item-column">
          <div className="label">Loại giấy tờ</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.cardIdType : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.cardIdType : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Ngày sinh</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.dob : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.dob : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Nơi cấp</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.placeOfCardId : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.placeOfCardId : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Nơi thường trú</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.address : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.address : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Số giấy tờ</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.cardCode : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.cardCode : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Giới tính</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.gender : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.gender : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Quốc tịch</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.nationality : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.nationality : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Quê quán</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.resident : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.resident : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Tên khách hàng</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.fullName : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.fullName : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Ngày cấp</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.dateOfCardId : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.dateOfCardId : '-'}</div>
          </Tooltip>
        </div>
        <div className="item-column">
          <div className="label">Ngày hết hạn</div>
          <Tooltip placement="top" title={<span>{props?.dataDetail ? props?.dataDetail.expirationDateOfCardId : '-'}</span>}>
            <div className="value ">{props?.dataDetail ? props?.dataDetail.expirationDateOfCardId : '-'}</div>
          </Tooltip>
        </div>
      </div>
      {props?.dataDetail && props?.dataDetail?.status == 'WAIT_FOR_APPROVE' ? (
        <div className="section-footer-edit">
          <Button onClick={openModalEditEkyc}>Sửa thông tin</Button>
        </div>
      ) : null}
    </div>
  );
}
