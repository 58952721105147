import styled, { createGlobalStyle } from 'styled-components';
import { resetCss } from './resetCss';
import { BREAKPOINTS, FONT_SIZE, FONT_WEIGHT, media } from './themes/constants';
import { lightThemeVariables, darkThemeVariables, commonThemeVariables, antOverrideCssVariables } from './themes/themeVariables';
import { MultiSelect } from 'react-multi-select-component';
import { Button, Checkbox, Modal, Popconfirm, Switch } from 'antd';

export default createGlobalStyle` 
  button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
      /* display: none !important; */
    opacity: 1 !important;
  }
  &span.anticon.anticon-delete {
    width: 16px !important;
  }
  
  &.ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
  }
.box-img-content-style{
  .ant-upload-list-item-error,
  .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
  .ant-upload-list-item-error .ant-upload-list-item-name,
  .ant-upload-list-item-error .ant-upload-text-icon > .anticon {
    color: #8c8c8c;
  }
  .span.ant-upload-span {
    margin-top: -65px !important;
    padding: 20px !important;
  }
  .ant-upload.ant-upload-drag p.ant-upload-text {
    margin: 0 0 4px;
    color: var(--heading-color);
    font-size: 13px;
    padding: 0px 32px 0px 32px;
  }

  .ant-upload-list.ant-upload-list-text {
    margin-top: -60px;
    padding: 0px 20px;
  }
}
&.ant-input[disabled] {
  color: #fff !important;
}
 &.ant-btn {
    padding: 3px 15px !important;
    height: unset;
  }
  th.ant-table-cell {
    text-align: left !important;
  }
  .ant-table-tbody>tr.ant-table-row:hover>td, .ant-table-tbody>tr>td.ant-table-cell-row-hover {
      background: #1A1F25 !important;
  }
.ant-form-item-explain-error {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ff4d4f;
    font-size: 14px;
  }
 label.ant-form-item-required {
    display: none;
  }
.anticon svg {
    color: #fff !important;
  }
  button.ant-picker-header-super-prev-btn {
      color: #fff;
  }
  button.ant-picker-header-super-next-btn {
    color: #fff;
  }
   .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-popover-arrow {
    display: none;
  }

  span.ant-badge-status-text {
    font-size: 14px;
  }
  .ant-table-sticky-scroll {
    display: none;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .ant-steps-item-wait .ant-steps-item-icon {
    background: #8C8C8C !important;
  }
  .ant-tabs-bottom>.ant-tabs-nav, .ant-tabs-bottom>div>.ant-tabs-nav, .ant-tabs-top>.ant-tabs-nav, .ant-tabs-top>div>.ant-tabs-nav {
    margin: 0;
  }
  .ant-table-pagination.ant-pagination {
      padding: 16px 24px;
      margin: 0;
  }

  .ant-tabs-nav-wrap {
    padding-left: 24px;
  }
  .ant-layout-sider-children {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  }
  .ant-divider-vertical {
    border-left: 1px solid #3C4450 !important;
    margin-top: 5px;
  }
  .ant-tabs-tab {
    color: #D9D9D9 !important;
    font-weight: 400;
  }
  .ant-tabs-bottom>.ant-tabs-nav::before, .ant-tabs-bottom>div>.ant-tabs-nav::before, .ant-tabs-top>.ant-tabs-nav::before, .ant-tabs-top>div>.ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-table-tbody>tr>td {
    border-bottom: 1px solid #3C4450;
  }
  .ant-modal-header {
    border-bottom: 1px solid #3c4450;
  }
  .ant-modal-footer {
    border-top: 1px solid #3c4450;
  }
  .ant-message-notice-content {
    background-color: #272D35;
    border: 1px solid rgba(82, 196, 26, 0.5);
  }
  ${resetCss}

  [data-theme='light'],
  :root {
    ${lightThemeVariables}
  }

  [data-theme='dark'] {
    ${darkThemeVariables}
  }

  :root {
    ${commonThemeVariables};
    ${antOverrideCssVariables};
  } 

  [data-no-transition] * {
    transition: none !important;
  }
  
  .range-picker {
    & .ant-picker-panels {
      @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px) {
        display: flex;
      flex-direction: column;
      }
    }
  }

  .search-dropdown {
    box-shadow: var(--box-shadow);

    @media only screen and ${media.xs} and (max-width: ${BREAKPOINTS.md - 0.02}px)  {
      width: calc(100vw - 16px);
    max-width: 600px;
    }

    @media only screen and ${media.md} {
      max-width: 323px;
    }
  }

  a {
    color: var(--primary-color);
    &:hover,:active {
      color: var(--ant-primary-color-hover);
    }
  }
  
  .d-none {
    display: none;
  }

  .ant-picker-cell {
    color: var(--text-main-color);
  }

  .ant-picker-cell-in-view .ant-picker-calendar-date-value {
    color: var(--text-main-color);
    font-weight: ${FONT_WEIGHT.bold};
  }

  .ant-picker svg {
    color: var(--text-light-color);
  }
  .ant-notification-notice-with-icon {
    padding: 18px 18px 23px 18px !important;
  }
  a.ant-notification-notice-close {
      color: #8796A1 !important;
  }
  .ant-notification.ant-notification-topRight{
    margin-top: 40px;
  }
  // notifications start
  .ant-notification-notice {
    width: 36rem;
    padding: 0px 0px 10px 0px;
    
    .ant-notification-notice-with-icon .ant-notification-notice-message {
      margin-bottom: 0;
      margin-left: 2.8125rem;
    }

    .ant-notification-notice-with-icon .ant-notification-notice-description {
      margin-left: 4.375rem;
      margin-top: 0;
    }

    .ant-notification-notice-icon {
      font-size: 2.8125rem;
      margin-left: 0
    }

    .ant-notification-notice-close {
      top: 1.65rem;
      right: 1.25rem;
    }
    .ant-notification-notice.ant-notification-notice-error {
 
    }  
    .ant-notification-notice-close-x {
      display: flex;
      font-size: 0.9375rem;
    }
    .title.title-only {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #FFFFFF !important;
}
    .notification-without-description {
      .ant-notification-notice-close {
        top: 1.875rem;
      }
      .ant-notification-notice-with-icon .ant-notification-notice-description  {
        margin-top: 0.625rem;
      }
    }
    
    .title {
      font-size: ${FONT_SIZE.xxl};
      height: 3rem;
      margin-left: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: ${FONT_WEIGHT.bold};

      &.title-only {
        color: var(--text-main-color);
        font-size: ${FONT_SIZE.md};
        height: 2rem;
        line-height: 2rem;
        margin-left: 0.75rem;
        font-weight: ${FONT_WEIGHT.semibold};
      }
  }
  
    .description {
      color: #404040;
      font-size: ${FONT_SIZE.md};
      font-weight: ${FONT_WEIGHT.semibold};
      line-height: 1.375rem;
    }
  
    &.ant-notification-notice-success {
      background: #272D35;
      border: 1px solid #B7EB8F;
      border-radius: 4px;
      
      .title {
        color: var(--success-color);
      }
    }
  
    &.ant-notification-notice-info {
      border: 1px solid var(--primary-color);
      background: var(--notification-primary-color);
  
      .title {
        color: var(--primary-color);
      }
    }
  
    &.ant-notification-notice-warning {
      border: 1px solid #FFD666;
      background: #272D35;
      border-radius: 4px;
  
      .title {
        color: var(--warning-color);
      }
    }
  
    &.ant-notification-notice-error {
      background: #272D35;
      border: 1px solid #FF7875;
      border-radius: 4px;
  
      .title {
        color: var(--error-color);
      }
    }
  
    .success-icon {
      color: var(--success-color);
    }
  
    .info-icon {
      color: var(--primary-color);
    }
  
    .warning-icon {
      color: var(--warning-color);
    }
  
    .error-icon {
      color: var(--error-color);
    }
  }

  .ant-menu-inline, .ant-menu-vertical {
    border-right: 0;
  }
  // notifications end
  
  .ant-menu-inline-collapsed-tooltip {
    .ant-tooltip-content {
      display: none;
    }
  }
`;

export const H2 = styled.div`
  padding-left: 24px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #f2f2f2;
  padding-bottom: 25px;
`;
export const SwitchStyle = styled(Switch)`
  .ant-switch-handle::before {
    background-color: #fff;
  }
`;

export const ContentTableCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const GSTableRowProof = styled.div`
  .margin-top-5 {
    margin-top: 5px;
  }
  .d-flex {
    display: flex;
  }
  .style-text-pdf {
    margin-left: 10px;
    margin-bottom: 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
  }
`;

export const ContainerTable = styled.div`
  background-color: #12161c;
  padding: 24px 24px 24px 24px;
  .box-content {
    padding: 16px 24px 24px 24px;
    background-color: #1a1f25;
    .ant-table-header.ant-table-sticky-holder {
      top: 70px;
    }
  }
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
`;

export const FormSearch = styled.div`
  &.ant-btn:focus {
    background-color: none !important;
  }
  margin-left: 3px;

  span.ant-input-affix-wrapper.filter-keyword.ant-input-affix-wrapper-sm {
    width: 340px;
    height: 32px;
  }
  .ant-picker-panel-container {
    font-size: 13px !important;
  }
  .ant-select-selector {
    width: 247px;
  }
  .ant-picker {
    height: 32px;
    width: 180px;
    border-radius: 4px;
  }
  justify-content: start;
  .ant-picker-datetime-panel {
    font-size: 13px;
  }
  .box-formsearch {
    display: flex;
    justify-content: start;
  }
  .ant-picker-header button {
    color: #fff !important;
  }
`;

export const StyleFooterModalCreate = styled.div`
  display: flex;
  justify-content: end;
  padding: 24px;
  border-top: 1px solid #3c4450;
`;
export const CheckboxStyle = styled(Checkbox)`
  span.ant-checkbox.ant-checkbox-checked {
    background: #f2c015;
  }
`;
export const StyleModalConfirm = styled(Modal)`
  .ant-modal-body {
    padding: 32px 24px 32px 32px;
  }
  .ant-modal-header {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
  .text-confirm {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #f0f0f0;
  }
  .btn-change {
    width: 98%;
    display: flex;
    justify-content: end;
    margin-top: 32px;
    .btn-cancel {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      gap: 10px;
      width: 59px;
      height: 32px;
      background: #161a1e;
      border: 1px solid #d9d9d9;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
      border-radius: 2px;
    }
    .btn-yes {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: #161a1e;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 4px 15px;
      gap: 8px;
      margin-left: 20px;

      height: 32px;
      background: #f2c015;
      border: 1px solid #f2c015;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
      border-radius: 2px;
    }
  }
`;

export const StyleBtnExcel = styled(Button)`
  &.ant-btn:focus {
    text-decoration: none;
    background-color: unset !important;
  }
`;

export const WrapperOverrideInput = styled.div`
  &span.anticon.anticon-delete {
    width: 16px;
  }

  &.ant-upload-list-item-card-actions-btn {
    opacity: 1 !important;
  }
  .err-text {
    margin-left: 5px;
    color: var(--character-danger, #ff4d4f);
    font-size: 14px;
    font-family: Be Vietnam Pro;
    line-height: 22px;
  }
  &.ant-btn:focus {
    background-color: unset !important;
  }
  .title-section-img-common {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
    .box-startend {
      display: flex;
    }
    .name-img {
      display: flex;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #f2c015;
      margin-left: 5px;
    }
  }
  .text-watch {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #85a5ff;
    cursor: pointer;
  }
  .btn-create-submit {
    background: rgb(251, 212, 72);
    margin-left: 10px;
    color: black !important;
    border: none !important;
  }
  .ant-picker.ant-picker-range {
    width: 316px;
    margin-right: 8px;
  }
  .ant-modal-header {
    background-color: #1a1f25 !important;
  }
  .cursor-pointer-none {
    cursor: unset;
  }
  .ant-popover-inner {
    background-color: #212830 !important;
  }
  .box-content-table {
    background-color: #1a1f25;
    padding: 16px 24px;
  }
  & .ant-input,
  & .filter-item .ant-select-selector,
  & .ant-input-affix-wrapper {
    padding: 3px 5px;
    border-radius: 2px;
    /* border: 1px solid #3c4450; */

    &::placeholder {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  & .filter-keyword {
    width: 303px;
    /*height: 33px;*/
  }

  & .filter-item {
    & .ant-select {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
    & .ant-select-selector {
      /*height: 33px;*/
      & .ant-select-selection-item {
        line-height: 22px;
      }
    }
    & .ant-select-arrow {
      color: white;
    }
  }
  .ant-btn:active,
  .ant-btn:focus {
    text-decoration: none;
    background-color: rgb(242, 192, 21);
  }
  & .ant-btn-default {
    height: 33px;
    line-height: 0;
    border-radius: 2px;
    border: 1px solid #d9d9d9;
    display: flex;
    color: #d9d9d9;
    align-items: center;
    gap: 10px;
    & span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }

  & .ant-table-container {
    & td.ant-table-cell,
    & th.ant-table-cell {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #d9d9d9 !important;
      border-bottom: 1px solid #3c4450 !important;
    }
    th.ant-table-cell {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #f5f5f5 !important;
    }
  }

  & .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:focus-visible .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    color: gray;
  }
  .ant-message-notice-content {
    background-color: red !important;
    border: 1px solid rgba(82, 196, 26, 0.5);
  }
  & .ant-pagination-jump-next,
  .ant-pagination-jump-prev,
  .ant-pagination-next,
  .ant-pagination-prev,
  .ant-pagination-item {
    min-width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 2px;

    & button {
      border-radius: 2px;
      border: 1px solid #3c4450;
    }
  }

  & .ant-table-tbody > tr.ant-table-row:hover > td[class*='ant-table-cell-fix-'],
  & td[class*='ant-table-cell-fix-'],
  & th[class*='ant-table-cell-fix-'] {
    background: #1a1f25;
  }

  & .ant-checkbox {
    & .ant-checkbox-inner {
      background: rgba(255, 255, 255, 1e-5);
      border: 1px solid #3c4450;
      border-radius: 2px;
    }
    &:hover {
      .ant-checkbox-inner {
        border: none;
        border-radius: 2px;
      }
    }
  }

  & .ant-table-tbody > tr.ant-table-row-selected:hover > td {
    background: rgb(25 25 25);
  }
  .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px !important;
    height: 1.6em;
    background-color: #3c4450 !important;
    transform: translateY(-50%);
    transition: background-color 0.3s;
    content: '';
  }
  .ant-table-footer,
  .ant-table-thead > tr > th {
    background: #21272f !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  tr.ant-table-row.ant-table-row-level-0 {
    background-color: #1a1f25 !important;
  }
  .ant-select.ant-select-sm.ant-select-in-form-item.ant-select-single.ant-select-show-arrow {
    border: 1px solid #070707;
  }
  input#basic_keyword {
    border: 1px solid #070707 !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 32px !important;
    padding: 0 11px;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    align-items: center;
  }
  li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.sc-jcMfQk.eCPtqJ {
    width: 67px !important;
    min-width: unset !important;
  }
  .ant-dropdown.ant-dropdown-placement-bottomRight {
    min-width: unset !important;
  }
`;

export const TabsTable = styled.div`
  border-bottom: 24px solid #12161c;
  position: sticky;
  top: -1px;
  font-variant: JIS04;
  font-size: 3em;
  z-index: 10;
  transition: 0.2s ease-out;
  background: #1a1f25;
  padding-left: 24px;
  .box-content {
    display: flex;
    width: 180px;
    justify-content: space-between;
    .item {
      cursor: pointer;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #d9d9d9;
      padding: 12px 0px 12px 0px;
      border-bottom: 2px solid #1a1f25 !important;
    }
    .active-tabs {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      display: flex;
      align-items: center;
      color: #f2c015;
      border-bottom: 2px solid #f2c015 !important;
    }
  }
`;

export const MulSelect = styled(MultiSelect)<{ defaultTitle?: string }>`
  & .dropdown-container {
    background: none;
    border-radius: 2px;
    border: 1px solid #3c4450;
    width: 149px;
    height: 33px;
    align-items: center;
    display: flex;

    & .dropdown-heading {
      & .dropdown-heading-value {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        & span.gray {
          display: none;
        }
      }
      & .dropdown-heading-value:after {
        content: '${(props) => props?.defaultTitle ?? 'Select ...'}';
      }
    }
  }

  & .dropdown-content {
    z-index: 1000;
    right: 0;

    & .panel-content {
      background: #212830;

      & .select-item {
        & span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
        }
      }
      & .select-item:hover,
      & .select-item.selected {
        background: #1a1f25;
      }
    }
  }
`;
export const PopconfirmStyleGlobal = styled(Popconfirm)`
  svg {
    color: #faad14;
    width: 12px;
  }
  .ant-popover-inner-content {
    background: #272d35;
  }

  & h2.title {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #f0f0f0;
  }
  &.ant-popover-content {
    width: 284px;
  }
  &.ant-popover-message {
    color: red;
  }
`;
