import React, { useContext, useEffect, useState } from 'react';
import * as S from '../Partner.style';
import { PartnerContext } from '../Context';
import FooterModal from '@app/components/common/FooterModal/FooterModal';
import { updateModalsPartner } from '../Action';
import { Form, Input, Radio, RadioChangeEvent, UploadFile, message } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import { ReactComponent as IconUploadFile } from '@app/assets/icons/icon-uploadfile.svg';
import ImgCrop from 'antd-img-crop';
import { CreatePartnerApi } from '../Partner.api';
import { notificationController } from '@app/controllers/notificationController';
import Upload, { RcFile, UploadProps } from 'antd/lib/upload';
import { UploadImgApi } from '@app/api/UploadFIle.api';
import { queryStringParams } from '@app/controllers/queryStringParams';

type Props = {
  callApiListPartner: () => void;
};

export default function ModalCreatePartner(props: Props) {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);
  const [form] = Form.useForm();
  const [loading, setloading] = useState(false);
  const [saveStringImg, setsaveStringImg] = useState<string | null>(null);

  const validateMessages = {
    required: 'Cần nhập ${label}!',
    types: {
      email: '${label} is not a valid email!',
      string: '${label} không hợp lệ!',
    },
  };
  function handleCancel() {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalCreate: false }));
    form.setFieldsValue({
      name: '',
      code: '',
      redirectUri: '',
      profilePartnerUri: '',
      image: '',
      type: undefined,
      website: '',
    });
    setValue('ORDER');
    form.resetFields();
  }

  useEffect(() => {
    form.setFieldsValue({
      type: 'ORDER',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statePartner?.modalsPartner?.modalCreate]);

  const onPreview = async (file: UploadFile) => {
    let src = file.url as string;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj as RcFile);
        reader.onload = () => resolve(reader.result as string);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const { Dragger } = Upload;
  const propsUpdate: UploadProps = {
    onPreview: onPreview,
    name: 'file',
    multiple: true,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    beforeUpload(file, FileList) {
      return false;
    },

    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        const formData = new FormData();

        console.log(info.file);
        formData.append('file', info.file);

        UploadImgApi(
          formData,
          queryStringParams({
            packageType: 'PARTNER',
            partnerUuid: undefined,
          }),
        )
          .then((res) => {
            if (!res.error) {
              message.success(`${info.file.name} tải lên thành công`);
              if (res) {
                setsaveStringImg(res?.key);
              }
            } else {
              setsaveStringImg('');
            }
          })
          .catch((err) => {
            message.error(`${err} Err 404`);
          });
      }
    },
  };

  const onFinish = async (values: any) => {
    setloading(true);
    if (values) {
      CreatePartnerApi({
        name: values?.name && values?.name,
        code: values?.code && values?.code,
        redirectUri: values?.redirectUri && values?.redirectUri,
        profilePartnerUri: values?.profilePartnerUri && values?.profilePartnerUri,
        image: saveStringImg ? saveStringImg : '',
        type: values?.type ? values?.type : 'ORDER',
        website: values?.website && values?.website,
      })
        .then((res) => {
          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalCreate: false }));
          props?.callApiListPartner();

          if (res?.error) {
            setloading(false);
            setValue('ORDER');

            form.resetFields();
            form.setFieldsValue({
              name: '',
              code: '',
              redirectUri: '',
              profilePartnerUri: '',
              image: '',
              type: undefined,
              website: '',
            });
          } else {
            setloading(false);
            notificationController.success({ message: `Tạo thành công` });
            setValue('ORDER');

            form.setFieldsValue({
              name: '',
              code: '',
              redirectUri: '',
              profilePartnerUri: '',
              image: '',
              type: undefined,
              website: '',
            });
          }
        })
        .catch(() => {
          notificationController.error({ message: `Something went wrong  Api` });
          setloading(false);
          form.setFieldsValue({
            name: '',
            code: '',
            redirectUri: '',
            profilePartnerUri: '',
            image: '',
            type: undefined,
          });
          setValue('ORDER');

          dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalCreate: false }));
        });
    }
  };

  const [value, setValue] = useState<any>();

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  return (
    <S.ModalCreatePartner title={'Thêm đối tác'} open={statePartner?.modalsPartner?.modalCreate} footer={null} onCancel={handleCancel}>
      <Form form={form} name="basic" onFinish={onFinish} validateMessages={validateMessages}>
        <GS.WrapperOverrideInput>
          <div>
            <div className="content-header">
              <div className="item-form">
                <div className="label">
                  Tên đối tác <span className="err-text">*</span>
                </div>
                <Form.Item name={'name'} label="Tên đối tác" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
              <div className="item-form">
                <div className="label">
                  Code <span className="err-text">*</span>
                </div>
                <Form.Item name={'code'} label="Code" rules={[{ required: true, type: 'string' }]}>
                  <Input type="string" size={'small'} className={'style-input-create'} />
                </Form.Item>
              </div>
            </div>

            <div className="content-body">
              <div className="item-line">
                <div className="item-form">
                  <div>
                    <div className="label">
                      redirect_uri <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'redirectUri'} label="redirect_uri" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">profile_partner_uri</div>
                    <Form.Item name={'profilePartnerUri'}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">
                      Link web <span className="err-text">*</span>
                    </div>
                    <Form.Item name={'website'} label="Link web" rules={[{ required: true, type: 'string' }]}>
                      <Input size={'small'} className={'style-input-create'} />
                    </Form.Item>
                  </div>
                  <div>
                    <div className="label">
                      Đối tác <span className="err-text">*</span>
                    </div>
                    <Form.Item label="Loại đối tác" name={'type'} rules={[{ required: true, type: 'string' }]}>
                      <div style={{ display: 'flex' }}>
                        <Radio.Group defaultValue={'ORDER'} onChange={onChange} value={value}>
                          <Radio value={'ORDER'}>ORDER</Radio>
                          <Radio value={'COD'}>COD</Radio>
                        </Radio.Group>
                      </div>
                    </Form.Item>
                  </div>
                </div>
                <div className="item-form">
                  <div className="label">Image</div>
                  <div className="save-image box-img-content-style">
                    <ImgCrop>
                      <Dragger {...propsUpdate}>
                        <p className="ant-upload-drag-icon">
                          <IconUploadFile />
                        </p>
                        <p className="ant-upload-text">
                          Chọn hoặc kéo ảnh <span style={{ color: '#85A5FF' }}> hoá đơn chuyển khoản</span> vào đây để tải ảnh lên
                        </p>
                      </Dragger>
                    </ImgCrop>
                  </div>
                </div>
              </div>
            </div>
            <FooterModal handleClickCancel={handleCancel} handleLoading={loading} type="submit" padding="24px 0px 0px 0px" LabelSave="Lưu thay đổi" labelCancel="Huỷ"></FooterModal>
          </div>
        </GS.WrapperOverrideInput>
      </Form>
    </S.ModalCreatePartner>
  );
}
