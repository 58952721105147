import React, { useContext } from 'react';
import Avatar from '@app/assets/icons/avatar.svg';
import { Tooltip } from 'antd';
import { updateModalsPartner } from '../Action';
import { PartnerContext } from '../Context';
import { DataTablePartner } from '../interface';

type Props = {
  dataDetail: DataTablePartner | undefined;
};

export default function SectionInforPartner(props: Props) {
  const { statePartner, dispatchPartner } = useContext(PartnerContext);

  const handleOpenModalEdit = () => {
    dispatchPartner(updateModalsPartner({ ...statePartner?.modalsPartner, modalEdit: true }));
  };

  return (
    <div className="section-partner-product">
      <div className="avatar-column">
        <img src={Avatar} alt="" />
      </div>
      <div className="column-content">
        <div className="row-1">
          Tên đối tác: <span className="value">{props?.dataDetail?.name ? props?.dataDetail?.name : '-'}</span>
        </div>
        <div className="row-2">
          <Tooltip placement="top" title={<span>{props?.dataDetail?.code ? props?.dataDetail?.code : '-'}</span>}>
            <div className="item-code">
              Code:<span className="value">{props?.dataDetail?.code ? props?.dataDetail?.code : '-'}</span>
            </div>
          </Tooltip>
          <Tooltip placement="top" title={<span>{props?.dataDetail?.profilePartnerUri ? props?.dataDetail?.profilePartnerUri : '-'}</span>}>
            <div className="item-profile">
              profile_partner_uri:<span className="value">{props?.dataDetail?.profilePartnerUri ? props?.dataDetail?.profilePartnerUri : '-'}</span>
            </div>
          </Tooltip>
          <Tooltip placement="top" title={<span>{props?.dataDetail?.redirectUri ? props?.dataDetail?.redirectUri : '-'}</span>}>
            <div className="item-redirect">
              redirect_uri: <span className="value">{props?.dataDetail?.redirectUri ? props?.dataDetail?.redirectUri : '-'}</span>
            </div>
          </Tooltip>

          <div className="label text-watch" onClick={handleOpenModalEdit}>
            Sửa
          </div>
        </div>
      </div>
    </div>
  );
}
