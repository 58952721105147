import React, { useContext } from 'react';
import { Form } from 'antd';
import * as GS from '@app/styles/GlobalStyle';
import 'dayjs/locale/zh-cn';
import * as S2 from '../Product.style';
// import { AiOutlineSearch } from 'react-icons/ai';
import { ProductContext } from '../Context';
import { updateModalsProduct } from '../Action';
import BtnCreateStyle from '@app/components/common/BtnCreateStyle/BtnCreateStyle';

export const FormSearchProduct = () => {
  const { stateProduct, dispatchProduct } = useContext(ProductContext);

  // function onChangeSearch(e: any) {
  //   if (e.key == 'Enter') {
  //     dispatchProduct(updateFiltersProduct({ ...stateProduct?.filtersProduct, key: e.target.value, pageIndex: 1 }));
  //   }
  // }
  function handleOpenModalCreate() {
    dispatchProduct(updateModalsProduct({ ...stateProduct?.modalsProduct, modalCreate: true }));
  }
  return (
    <GS.WrapperOverrideInput>
      <S2.FormSearch>
        <div className="box-formsearch">
          <Form.Item name={'keyword'}>{/* <Input onKeyUp={(e) => onChangeSearch(e)} size={'small'} className={'filter-keyword'} prefix={<AiOutlineSearch />} placeholder={'Sản phẩm'} /> */}</Form.Item>
          <div className="margin-top-10">
            <BtnCreateStyle onClick={handleOpenModalCreate} label="Thêm sản phẩm"></BtnCreateStyle>
          </div>
        </div>
      </S2.FormSearch>
    </GS.WrapperOverrideInput>
  );
};
