import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataDetailEkycType, DataResponseEkycManagement, ParamsBodyApprovalApiType, ParamsBodyRejectApiType, ParamsEditEkycApiType } from './interface';

const httpApi = initAxios();
interface LoanRequestConfig extends AxiosRequestConfig {
  secured: boolean;
}

const TokenConfig: LoanRequestConfig = {
  secured: true,
};

// export const getLoan = (params: any): Promise<ResponseCollectionBody> => httpApi.post('/v1/loan', { ...params });
export const getListEkycManagementApi = (params: string): Promise<DataResponseEkycManagement> => httpApi.get(`users/v1/ekycs?${params}`, { ...TokenConfig });

export const getDetailEkyc = (code: string | undefined): Promise<DataDetailEkycType> => httpApi.get(`/users/v1/ekycs/${code}`, { ...TokenConfig });

export const approvalDetailEkycApi = (paramsBody: ParamsBodyApprovalApiType): Promise<any> => httpApi.put(`/users/v1/ekycs/approve`, paramsBody, { ...TokenConfig });

export const rejectEkycApi = (paramsBody: ParamsBodyRejectApiType): Promise<any> => httpApi.put(`/users/v1/ekycs/reject`, paramsBody, { ...TokenConfig });

export const EditEkycApi = (code: string, paramsBody: ParamsEditEkycApiType): Promise<any> => httpApi.put(`/users/v1/ekycs/${code}`, paramsBody, { ...TokenConfig });
