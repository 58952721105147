import initAxios from '@app/api/API.v2';
import { AxiosRequestConfig } from 'axios';
import { DataResponseCapital, ParamsCreateCapitalType, paramsUpdate, ResponseApiCreateCapitalType, ResponseApiDetail, ResponseApiHistoryDetail } from './interface';
const httpApi = initAxios();

interface TokenConfig extends AxiosRequestConfig {
  secured: boolean;
}
export interface CustomGrantTokenConfig extends AxiosRequestConfig {
  headers: {
    Authorization?: string;
  };
}

const TokenConfig: TokenConfig = {
  secured: true,
};

export const getImg = (params: string): Promise<any> => httpApi.get(`/loans/v1/files/${params}`, { ...TokenConfig });

export const getCapitalListApi = (params: string): Promise<DataResponseCapital> => httpApi.get(`/loans/v1/funds/search?${params}`, { ...TokenConfig });

export const getCapitalDetailApi = (id: string | undefined): Promise<ResponseApiDetail> => httpApi.get(`/loans/v1/funds/${id}`, { ...TokenConfig });

export const createCapitalApi = (params: ParamsCreateCapitalType): Promise<ResponseApiCreateCapitalType> => httpApi.post(`/loans/v1/funds/`, params, { ...TokenConfig });

export const getCapitalDetailHistoryApi = (id: string | undefined): Promise<ResponseApiHistoryDetail[]> => httpApi.get(`/loans/v1/funds/${id}/cash-history`, { ...TokenConfig });

export const updateCapitalApi = (id: string | undefined, params: paramsUpdate): Promise<ResponseApiCreateCapitalType> => httpApi.put(`/loans/v1/funds/${id}`, params, { ...TokenConfig });
